// This is the Child Requirements table as used by caseworkers and Binti admins.
// For the caregiver-facing version, see CaregiverChildRequirementsTable.
import { useQuery, useMutation } from "@apollo/client";
import {
  Button,
  ContentTabs,
  Flex,
  LiveRegion,
  Surface,
  ArrayDataTable,
} from "@heart/components";
import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useCallback, useState, useMemo } from "react";

import { translationWithRoot } from "@components/T";

import AttachFormInstanceFiles from "@graphql/mutations/AttachFormInstanceFiles.graphql";
import CompleteFormInstance from "@graphql/mutations/CompleteFormInstance.graphql";
import CreateChildFormOverride from "@graphql/mutations/CreateChildFormOverride.graphql";
import CreateChildUploadTypeOverride from "@graphql/mutations/CreateChildUploadTypeOverride.graphql";
import CreateChildUploadedRecord from "@graphql/mutations/CreateChildUploadedRecord.graphql";
import DeleteChildOverrideRecord from "@graphql/mutations/DeleteChildOverrideRecord.graphql";
import DeleteFormInstanceAttachment from "@graphql/mutations/DeleteFormInstanceAttachment.graphql";
import DeleteRequirement from "@graphql/mutations/DeleteRequirement.graphql";
import DeleteUploadedRecordAttachment from "@graphql/mutations/DeleteUploadedRecordAttachment.graphql";
import SetUploadedRecordCompletedDate from "@graphql/mutations/SetUploadedRecordCompletedDate.graphql";
import ChildRequirementReconciliation from "@graphql/queries/ChildRequirementReconciliation.graphql";

import { sortDatesDesc } from "@lib/dates";

import ActionsButtons from "./ActionsButtons";
import ActionsMenu from "./ActionsMenu";
import DocumentCell from "./DocumentCell";
import DueDateCell from "./DueDateCell";
import ProgressCell from "./ProgressCell";
import { sortByDocument, sortByProgress } from "./sortRequirements";
import uploadRequirementFiles from "./uploadRequirementFiles";

const { t } = translationWithRoot("requirements.requirement_table");

export const attachmentIds = data =>
  data.flatMap(({ allAttachmentsForRecord }) =>
    allAttachmentsForRecord.flatMap(({ id }) => id)
  );

const ChildRequirementTableWrapper = ({
  childId,
  collatedAttachmentsPdfPath,
}) => {
  const refetchQueries = [
    { query: ChildRequirementReconciliation, variables: { childId } },
  ];
  const { data, loading } = useQuery(ChildRequirementReconciliation, {
    variables: { childId },
  });
  const [attachFormInstanceFiles] = useMutation(AttachFormInstanceFiles, {
    refetchQueries,
  });
  const [createChildUploadedRecord] = useMutation(CreateChildUploadedRecord, {
    refetchQueries,
  });
  const [createChildFormOverride] = useMutation(CreateChildFormOverride, {
    refetchQueries,
  });
  const [createChildUploadTypeOverride] = useMutation(
    CreateChildUploadTypeOverride,
    { refetchQueries }
  );
  const [completeFormInstance] = useMutation(CompleteFormInstance);
  const [deleteChildUploadedRecordAttachment] = useMutation(
    DeleteUploadedRecordAttachment,
    {
      refetchQueries,
    }
  );
  const [deleteFormInstanceAttachment] = useMutation(
    DeleteFormInstanceAttachment,
    {
      refetchQueries,
    }
  );
  const [deleteOverrideRecord] = useMutation(DeleteChildOverrideRecord, {
    refetchQueries,
  });
  const [deleteRequirement] = useMutation(DeleteRequirement);
  const [setUploadedRecordCompletedDate] = useMutation(
    SetUploadedRecordCompletedDate
  );

  const tableData = {
    all: [],
    overdue: [],
    next30Days: [],
    moreThan30Days: [],
    waived: [],
    completed: [],
  };
  const graphQLData = useMemo(
    () =>
      get(data, "childRequirementReconciliation.requirementFulfillments") || [],
    [data]
  );

  graphQLData.forEach(record => {
    const { isFulfilled, isOverridden, isActionCritical, isActionNeededSoon } =
      record;

    tableData.all.push(record);
    if (isOverridden) tableData.waived.push(record);
    else if (isFulfilled) tableData.completed.push(record);
    else if (isActionCritical) tableData.overdue.push(record);
    else if (isActionNeededSoon) tableData.next30Days.push(record);
    else tableData.moreThan30Days.push(record);
  });

  tableData.all = sortDatesDesc(tableData.all, "dateActionNeededBy");
  tableData.completed = sortDatesDesc(
    tableData.completed,
    "dateActionNeededBy"
  );
  tableData.waived = sortDatesDesc(tableData.waived, "dateActionNeededBy");
  tableData.overdue = sortDatesDesc(tableData.overdue, "dateActionNeededBy");
  tableData.next30Days = sortDatesDesc(
    tableData.next30Days,
    "dateActionNeededBy"
  );
  tableData.moreThan30Days = sortDatesDesc(
    tableData.moreThan30Days,
    "dateActionNeededBy"
  );

  return (
    <ChildRequirementsTable
      loading={loading}
      /** We could pull this directly off the graphql data, but we then
       * lose the ability to assert that holderToken is required in all
       * our subsequent components as it will be undefined until data
       * loads.
       */
      holderToken={`Child-${childId}`}
      tableData={tableData}
      collatedAttachmentsPdfPath={collatedAttachmentsPdfPath}
      /** GraphQL Mutations */
      attachFormInstanceFiles={attachFormInstanceFiles}
      createChildUploadedRecord={createChildUploadedRecord}
      createChildFormOverride={createChildFormOverride}
      createChildUploadTypeOverride={createChildUploadTypeOverride}
      completeFormInstance={completeFormInstance}
      deleteChildUploadedRecordAttachment={deleteChildUploadedRecordAttachment}
      deleteFormInstanceAttachment={deleteFormInstanceAttachment}
      deleteOverrideRecord={deleteOverrideRecord}
      deleteRequirement={deleteRequirement}
      setUploadedRecordCompletedDate={setUploadedRecordCompletedDate}
    />
  );
};
ChildRequirementTableWrapper.propTypes = {
  childId: PropTypes.number.isRequired,
  collatedAttachmentsPdfPath: PropTypes.string.isRequired,
};

export const ChildRequirementsTable = ({
  loading,
  holderToken,
  tableData,
  collatedAttachmentsPdfPath,
  /** GraphQL Mutations */
  attachFormInstanceFiles,
  createChildUploadedRecord,
  createChildFormOverride,
  createChildUploadTypeOverride,
  completeFormInstance,
  deleteChildUploadedRecordAttachment,
  deleteFormInstanceAttachment,
  deleteOverrideRecord,
  deleteRequirement,
  setUploadedRecordCompletedDate,
}) => {
  const Table = ({ tableDataKey }) => {
    const [visibleIds, setVisibleIds] = useState([]);

    return (
      <Flex column>
        <ArrayDataTable
          loading={loading}
          onUpload={uploadRequirementFiles({
            holderToken,
            attachFormInstanceFiles,
            createUploadedRecord: createChildUploadedRecord,
          })}
          disableUploadForRow={fulfillment => fulfillment.isOverridden}
          disableUploadColumnIdCellIndexes={{
            columnId: "actions",
            indexes: [1],
          }}
          filteredDataCallback={useCallback(
            data => setVisibleIds(attachmentIds(data)),
            []
          )}
          columns={useMemo(
            () => [
              {
                columnName: { name: t("cols.title") },
                id: "title",
                iconOnlyUpload: true,
                cell: "requirement.title",
                columnSort: "requirement.title",
                filter: {
                  type: "search",
                  filterBy: "requirement.title",
                  label: t("filters.search"),
                  topLevel: true,
                },
              },
              {
                columnName: { name: t("cols.progress") },
                id: "progress",
                iconOnlyUpload: true,
                cell: fulfillment => <ProgressCell fulfillment={fulfillment} />,
                columnSort: sortByProgress,
              },
              {
                columnName: {
                  name: t("cols.due_date"),
                  justify: "center",
                },
                id: "due_date",
                iconOnlyUpload: true,
                cell: fulfillment => <DueDateCell fulfillment={fulfillment} />,
                filter: {
                  type: "custom_dates",
                  filterBy: "dateActionNeededBy",
                  label: t("filters.due_date"),
                },
                columnSort: "dateActionNeededBy",
              },
              {
                columnName: { name: t("cols.document") },
                id: "document",
                iconOnlyUpload: true,
                cell: fulfillment => (
                  <DocumentCell
                    holderToken={holderToken}
                    fulfillment={fulfillment}
                    /** GraphQL Mutations */
                    createFormOverride={createChildFormOverride}
                    createUploadTypeOverride={createChildUploadTypeOverride}
                    deleteUploadedRecordAttachment={
                      deleteChildUploadedRecordAttachment
                    }
                    deleteFormInstanceAttachment={deleteFormInstanceAttachment}
                    deleteOverrideRecord={deleteOverrideRecord}
                    setUploadedRecordCompletedDate={
                      setUploadedRecordCompletedDate
                    }
                  />
                ),
                columnSort: sortByDocument,
              },
              {
                columnName: {
                  name: t("cols.actions"),
                  justify: "end",
                },
                id: "actions",
                cell: [
                  fulfillment => (
                    <ActionsButtons
                      holderToken={holderToken}
                      fulfillment={fulfillment}
                      /** GraphQL Mutations */
                      attachFormInstanceFiles={attachFormInstanceFiles}
                      createUploadedRecord={createChildUploadedRecord}
                      createFormOverride={createChildFormOverride}
                      createUploadTypeOverride={createChildUploadTypeOverride}
                    />
                  ),
                  fulfillment => (
                    <ActionsMenu
                      holderToken={holderToken}
                      fulfillment={fulfillment}
                      /** GraphQL Mutations */
                      completeFormInstance={completeFormInstance}
                      deleteRequirement={deleteRequirement}
                    />
                  ),
                ],
              },
            ],
            []
          )}
          data={tableData[tableDataKey]}
        />
        <LiveRegion>
          <Flex justify="center">
            <If condition={!isEmpty(visibleIds)}>
              <Button
                href={`${collatedAttachmentsPdfPath}.pdf?attachment_ids=${visibleIds.join(
                  ","
                )}`}
                variant="secondary"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t("pdf_of_all_documents")}
              </Button>
            </If>
          </Flex>
        </LiveRegion>
      </Flex>
    );
  };
  Table.propTypes = { tableDataKey: PropTypes.string.isRequired };

  return (
    <Surface hideTitle title="Requirements">
      <ContentTabs
        tabs={[
          {
            title: t("tabs.all"),
            slug: "all",
            contents: Table({ tableDataKey: "all" }),
            count: tableData.all.length,
          },
          {
            title: t("tabs.overdue"),
            slug: "overdue",
            contents: Table({ tableDataKey: "overdue" }),
            count: tableData.overdue.length,
          },
          {
            title: t("tabs.due_next_30_days"),
            slug: "due_next_30_days",
            contents: Table({ tableDataKey: "next30Days" }),
            count: tableData.next30Days.length,
          },
          {
            title: t("tabs.due_over_30_days"),
            slug: "due_over_30_days",
            contents: Table({ tableDataKey: "moreThan30Days" }),
            count: tableData.moreThan30Days.length,
          },
          {
            title: t("tabs.waived"),
            slug: "waived",
            contents: Table({ tableDataKey: "waived" }),
            count: tableData.waived.length,
          },
          {
            title: t("tabs.completed"),
            slug: "completed",
            contents: Table({ tableDataKey: "completed" }),
            count: tableData.completed.length,
          },
        ]}
      />
    </Surface>
  );
};
ChildRequirementsTable.propTypes = {
  loading: PropTypes.bool,
  holderToken: PropTypes.string.isRequired,
  tableData: PropTypes.shape({
    all: PropTypes.array.isRequired,
    overdue: PropTypes.array.isRequired,
    next30Days: PropTypes.array.isRequired,
    moreThan30Days: PropTypes.array.isRequired,
    waived: PropTypes.array.isRequired,
    completed: PropTypes.array.isRequired,
  }),
  collatedAttachmentsPdfPath: PropTypes.string.isRequired,
  /** GraphQL Mutations */
  attachFormInstanceFiles: PropTypes.func.isRequired,
  createChildUploadedRecord: PropTypes.func.isRequired,
  createChildFormOverride: PropTypes.func.isRequired,
  createChildUploadTypeOverride: PropTypes.func.isRequired,
  completeFormInstance: PropTypes.func.isRequired,
  deleteChildUploadedRecordAttachment: PropTypes.func.isRequired,
  deleteOverrideRecord: PropTypes.func.isRequired,
  deleteFormInstanceAttachment: PropTypes.func.isRequired,
  deleteRequirement: PropTypes.func.isRequired,
  setUploadedRecordCompletedDate: PropTypes.func.isRequired,
};

export default ChildRequirementTableWrapper;
