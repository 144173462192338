import styles from "./BintiAssistNotice.module.scss";
import aiIconWhite from "./binti-assist-icon-white.svg";
import aiIcon from "./binti-assist-icon.svg";

/**
 * Icon for Binti Assist/AI/Binti Blue.
 *
 * @param [alt] Optional alt text for the icon. If not provided, it will be empty,
 * making this effectively aria-hidden, which is fine if the icon is purely decorative.
 * @param style The style of the icon. "dark-on-light" is the default, and "light-on-dark"
 * is used when the icon is on a dark background.
 */
const BintiAssistIcon = ({
  alt = "",
  style = "dark-on-light",
}: {
  alt?: string;
  style?: "dark-on-light" | "light-on-dark";
}) => (
  <img
    src={style === "dark-on-light" ? aiIcon : aiIconWhite}
    alt={alt}
    className={styles.bintiAssistIcon}
    data-heart-component="BintiAssistIcon"
  />
);

export default BintiAssistIcon;
