import { translationWithRoot } from "@components/T";

import { formatAsLongDate } from "@lib/dates";

const { t } = translationWithRoot("common");

/**
 * Given a human's full name and date of birth, generates a label containing the
 * name and the date of birth in parentheses, if available. If the person is
 * confidential or sealed, the respective status indicator will be appended.
 *
 * @param {string} fullName - The full name of the human.
 * @param {Date|string} [dateOfBirth] - The human's date of birth.
 * @param {Boolean} [isEstimatedDateOfBirth] - Boolean as to whether the human has an estimated date of birth
 * @param {Date|string} [estimatedDateOfBirth] - The human's estimated date of birth.
 * @param {Object} [options] - Options passed to `DateTime.fromISO`.
 * @param {Boolean} [useUnknown] - Boolean as to whether to display "Unknown" for date of birth when it is not available.
 * @param {Boolean} [confidential] - Boolean indicating if the person is confidential.
 * @param {Boolean} [sealed] - Boolean indicating if the person is sealed.
 * @returns {string} The human's full name, with date of birth appended in parentheses if available.
 * If confidential or sealed is true, the respective indicator will be appended after the date of birth.
 * Note: a child would never be both confidential and sealed simultaneously.
 */
const createNameAndLabels = ({
  fullName,
  dateOfBirth,
  isEstimatedDateOfBirth,
  estimatedDateOfBirth,
  options,
  useUnknown = false,
  confidential = false,
  sealed = false,
}) => {
  let nameAndLabels = "";

  if (isEstimatedDateOfBirth) {
    nameAndLabels = t("name_with_estimated_dob", {
      name: fullName,
      dob: formatAsLongDate(estimatedDateOfBirth, options),
    });
  } else if (dateOfBirth || useUnknown) {
    nameAndLabels = t("name_with_dob", {
      name: fullName,
      dob: dateOfBirth ? formatAsLongDate(dateOfBirth, options) : t("unknown"),
    });
  } else {
    nameAndLabels = fullName;
  }

  // Add status indicator if needed
  // A child would never be both confidential and sealed simultaneously
  if (confidential) {
    nameAndLabels = `${nameAndLabels} ${t("confidential")}`;
  } else if (sealed) {
    nameAndLabels = `${nameAndLabels} ${t("sealed")}`;
  }

  return nameAndLabels;
};

export default createNameAndLabels;
