import {
  InputDate,
  InputDropdown,
  InputMoney,
  InputText,
  MultiInputTemplate,
  SurfaceFieldset,
} from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

const { T, t } = translationWithRoot("prevention.agency_service_rates");

/**
 * @param {Array} billingStructures
 * @param {String} error
 * @param {Function} multiUpdate
 * @param {Function} checkForErrors
 */
const AddAgencyServiceRate = ({
  multiUpdate,
  checkForErrors,
  error,
  billingStructures,
}) => (
  <SurfaceFieldset title={<T t="add_form_title" />}>
    <MultiInputTemplate
      error={error}
      checkForErrors={checkForErrors}
      addSectionText={t("add")}
      hideTitle
      defaultValues={{}}
      onChange={multiUpdate}
      onDelete={() => {}}
      removeSectionText={t("remove")}
      data-testid={"agency-service-rate-inputs"}
      sectionInputs={({ getMultiInputProps }) => {
        // collect the billingStructure props here so we can use it
        // for the conditional below
        const billingStructure = getMultiInputProps({
          id: "billingStructure",
        })?.value;

        return (
          <Fragment>
            <InputText
              {...getMultiInputProps({ id: "label" })}
              label={<T t="label" />}
              required
            />
            <InputText
              {...getMultiInputProps({ id: "code" })}
              label={<T t="code" />}
              description={<T t="code_description" />}
              required={false}
            />
            <InputDate
              {...getMultiInputProps({ id: "startDate" })}
              label={<T t="start_date" />}
              required
            />
            <InputDate
              {...getMultiInputProps({ id: "endDate" })}
              label={<T t="end_date" />}
              required
            />
            <InputDropdown
              {...getMultiInputProps({ id: "billingStructure" })}
              label={<T t="billing_structure" />}
              values={billingStructures}
              required
            />
            <If condition={billingStructure !== "variable_one_time_fee"}>
              <InputMoney
                {...getMultiInputProps({ id: "amount" })}
                label={<T t="amount" />}
                required
              />
            </If>
            <If condition={billingStructure === "fee_per_service"}>
              <InputText
                {...getMultiInputProps({ id: "sessionsPerService" })}
                label={<T t="sessions_per_service" />}
                type="number"
                required
              />
            </If>
          </Fragment>
        );
      }}
      startingValues={[]}
      title={<T t="title" />}
    />
  </SurfaceFieldset>
);

AddAgencyServiceRate.propTypes = {
  multiUpdate: PropTypes.func,
  checkForErrors: PropTypes.func,
  billingStructures: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string,
};

export default AddAgencyServiceRate;
