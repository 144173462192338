import { useMutation, useReactiveVar } from "@apollo/client";
import { UploadButton } from "@heart/components";
import React, { Fragment, useEffect } from "react";

import { translationWithRoot } from "@components/T";
import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import RegisterChildDocuments from "@graphql/mutations/prevention/RegisterChildDocuments.graphql";
import AgencyHumanRequirementReconciliation from "@graphql/queries/prevention/AgencyHumanRequirementReconciliation.graphql";

import useShrine from "@lib/useShrine";

import useErrorModal from "./ErrorModal";

const { t } = translationWithRoot("prevention.documents_and_requirements");

/**
 * Creates upload button and alert modal for uploading documents for a child in
 * Prevention
 *
 * @param agencyHumanId
 * @returns {{AlertModal: React.JSX.Element, UploadButton: React.JSX.Element}}
 */
export default agencyHumanId => {
  const queryVariables = useReactiveVar(queryVariablesVar);
  const [registerDocuments, { error, data, called, reset }] = useMutation(
    RegisterChildDocuments,
    {
      refetchQueries: [
        {
          query: AgencyHumanRequirementReconciliation,
          variables: queryVariables,
        },
      ],
    }
  );

  const [ErrorModal, setErrorCode] = useErrorModal();

  useEffect(() => {
    if (called) {
      if (error) {
        setErrorCode("generic_error");
      } else if (data?.preventionRegisterChildDocuments?.errors?.length) {
        const virusError = data.preventionRegisterChildDocuments.errors.find(
          gqError => gqError.message.match(/virus/i)
        );

        if (virusError) {
          setErrorCode("virus_detected");
        } else {
          setErrorCode("generic_error");
        }
      }
      reset();
    }
  }, [called, error, data, reset, setErrorCode]);

  const { prepareShrineFiles, ShrineErrorAlert } = useShrine();
  const onUpload = async files => {
    const shrineFiles = await prepareShrineFiles(files);

    // Not all files were uploaded.
    if (shrineFiles.length < files.length) {
      return setErrorCode("generic_error");
    }

    if (shrineFiles.length) {
      registerDocuments({
        variables: { agencyHumanId, files: shrineFiles },
      });
    }

    return null;
  };

  const UploadBtn = () => (
    <UploadButton onUpload={onUpload} label={t("upload_documents")} />
  );

  return {
    UploadButton: UploadBtn,
    UploadBtnModals: () => (
      <Fragment>
        <ErrorModal />
        <ShrineErrorAlert />
      </Fragment>
    ),
  };
};
