import { gql, useMutation } from "@apollo/client";
import { BintiAssistIcon, Button, Flex, Text } from "@heart/components";
import { Times } from "@heart/components/icon/Icon";
import { useKeyboardEvent } from "@react-hookz/web";
import I18n from "i18n-js";
import { useCallback, useState } from "react";
import ReactModal from "react-modal";
import { Fragment } from "react/jsx-runtime";

import { useGeneratedId } from "@lib/generateId";

import styles from "./LicenseChat.module.scss";
import TextChat from "./common/TextChat";
import { MessageProps } from "./common/common";

const SUBMIT_MESSAGE = gql`
  mutation SubmitMessage($licenseId: ID!, $inputText: String!) {
    licenseChat(input: { licenseId: $licenseId, inputText: $inputText }) {
      response
    }
  }
`;

const CLEAR = gql`
  mutation ClearChat($licenseId: ID!) {
    clearLicenseChat(input: { licenseId: $licenseId }) {
      response
    }
  }
`;

/**
 * Chat for interacting with a case note assistant.
 *
 * @param {object} props
 * @param {MessageProps[]} props.initialMessages The initial messages to display in the chat, if any.
 * @param {string} props.licenseId The ID of the license about which to chat.
 * @param {boolean} [props.showClear=false] Whether to show the clear chat button.
 */
const LicenseChat = ({
  licenseId,
  initialMessages = [],
  showClear = false,
}: {
  licenseId: string | number;
  initialMessages?: MessageProps[];
  showClear?: boolean;
}) => {
  const dialogHeaderId = useGeneratedId();
  const [submitMessage, { loading }] = useMutation(SUBMIT_MESSAGE);
  const [clear] = useMutation(CLEAR, { variables: { licenseId } });
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const onSubmit = useCallback(
    async ({ input }) => {
      // submit the message
      const {
        data: {
          licenseChat: { response },
        },
      } = await submitMessage({
        variables: {
          licenseId,
          inputText: input,
        },
      });

      return { response };
    },
    [licenseId, submitMessage]
  );

  const onClose = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  useKeyboardEvent("Escape", () => {
    if (setDrawerOpen) setDrawerOpen(false);
  });

  return (
    <Fragment>
      <Button onClick={() => setDrawerOpen(true)}>
        {I18n.t("javascript.components.text_chat.ask_binti_ai")}
        <BintiAssistIcon style="light-on-dark" />
      </Button>

      <ReactModal
        isOpen={isDrawerOpen}
        onRequestClose={onClose}
        direction="right"
        style={{
          content: {
            top: "0",
            right: "0",
            left: "65vw",
            bottom: "0",
            padding: "10px",
          },
        }}
      >
        <div role="dialog" aria-labelledby={dialogHeaderId}>
          <Flex row justify="space-between">
            <header id={dialogHeaderId} className={styles.chatHeader}>
              <Text textStyle="emphasis-200">
                {I18n.t("javascript.components.text_chat.ask_binti_ai")}
              </Text>
              <BintiAssistIcon />
            </header>
            <Times
              description={I18n.t("views.common.close")}
              onClick={onClose}
            />
          </Flex>

          <TextChat
            showClear={showClear}
            onClear={clear}
            onSubmit={onSubmit}
            loading={loading}
            initialMessages={initialMessages}
          />
        </div>
      </ReactModal>
    </Fragment>
  );
};

export default LicenseChat;
