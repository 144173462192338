import useBintiForm from "@heart/components/forms/useBintiForm";
import { agencyServicePath } from "routes";

import AgencyServiceForm from "./AgencyServiceForm";
import {
  AgencyServiceFormState,
  ExistingAgencyService,
  FormProps,
} from "./types";

/**
 * Form to edit an existing agency service
 */
const EditAgencyServiceForm = ({
  agencyService,
  ...formProps
}: FormProps & { agencyService: ExistingAgencyService }) => {
  const { formState, setFormAttribute } = useBintiForm(null, {
    initialValue: convertToFormState(agencyService),
  });

  return (
    <AgencyServiceForm
      {...formProps}
      onSubmit={() => {}}
      setFormAttribute={setFormAttribute}
      formState={formState}
      cancelHref={agencyServicePath(agencyService.id)}
    />
  );
};

const convertToFormState = (
  agencyService: ExistingAgencyService
): AgencyServiceFormState => ({
  providerAgency: {
    label: agencyService.providerAgency.name,
    value: agencyService.providerAgency.id.toString(),
  },
  name: agencyService.name,
  isFfpsa: agencyService.isFfpsa,
  evidenceBasedPractice: {
    value: agencyService.evidenceBasedPractice.id.toString(),
    label: agencyService.evidenceBasedPractice.displayTitle,
  },
  fundingSources: agencyService.fundingSources.map(source => ({
    label: source,
    value: source,
  })),
  serviceTypes: agencyService.serviceTypes.map(service => ({
    label: service,
    value: service,
  })),
  areasOfCoverage: agencyService.areasOfCoverage.map(area => ({
    label: area,
    value: area,
  })),
});

export default EditAgencyServiceForm;
