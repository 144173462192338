import {
  CountrySelect,
  Flex,
  InputText,
  InputTextWithAddressAutocomplete,
  InputDropdown,
  InputCheckbox,
  InputPostalCode,
  InputTextarea,
  PrimarySubdivisionSelect,
  SecondarySubdivisionSelect,
} from "@heart/components";
import { sortBy, without } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { translationWithRoot } from "@components/T";

import { formatDateTimeAsShortDate } from "@lib/dates";
import useFeatureFlag from "@lib/useFeatureFlag";

import {
  AGENCY_HUMAN_ADDRESS_TYPES,
  ADDRESS_TYPE_MAILING,
  ADDRESS_TYPE_PHYSICAL_INCARCERATED,
} from "@root/constants";

const { t } = translationWithRoot("heart.components.inputs.input_address");

const FIELD_NAME_DEFAULTS = {
  addressLine1: "addressLine1",
  addressLine2: "addressLine2",
  city: "city",
  primarySubdivision: "primarySubdivision",
  secondarySubdivision: "secondarySubdivision",
  countryCode: "countryCode",
  postalCode: "postalCode",
  useAsMailing: "useAsMailing",
  type: "type",
  primary: "primary",
  inactive: "inactive",
  inactiveDetails: "inactiveDetails",
};

const DEFAULT_REQUIRED_FIELDS = {
  type: true,
  bookingNumber: true,
  countryCode: true,
  facilityName: true,
  addressLine1: true,
  postalCode: true,
};

/** ### Usage
 *
 * A component for entering a single address, US or international.
 *
 * It allows a user to:
 *    - Mark an address as primary
 *    - Mark an address as inactive and log notes about why it's inactive
 *    - Specify the type of address (home, work, mailing only)
 *    - Indicate that a home or work address can also be used for mailing
 *
 * The component is intended to be used within MultiInputTemplate or
 * some other pattern that allows for multiple address inputs to be entered.
 *
 * ### Cypress
 *
 * Optionally takes in an object to indicate the address type:
 * `cy.fillInputAddress({ type: "Home" })`
 *
 * If type is "Incarcerated", the facility name and booking number will also
 * be filled out: `cy.fillInputAddress({ type: "Incarcerated" })`
 */
export const InputAddress = ({
  value = {
    type: [],
    addressLine1: "",
    countryCode: "US",
    postalCode: "",
    primary: false,
    inactive: false,
  },
  showSecondarySubdivisionInput = false,
  fieldNameOverrides = {},
  onChange,
  allowedAddressTypes = AGENCY_HUMAN_ADDRESS_TYPES,
  hideInactiveCheckbox = false,
  disablePrimaryCheckbox = false,
  useAsMailingHtmlValueWhenChecked = null,
  usePrimarySubdivisionCodeAsValue = false,
  // If you see this line and autocomplete is already stable in prod, make this true for me?
  useAutocomplete = false,
  allowedCountryCodes = null,
  fieldDescriptions = {},
  requiredFieldOverrides = {},
  hidePrimaryCheckbox = false,
  hideTypes = false,
  hideUseAsMailing = false,
}) => {
  const addressFieldNameMap = { ...FIELD_NAME_DEFAULTS, ...fieldNameOverrides };

  const { flag: ffUseAutocompleteForAddressInputs } = useFeatureFlag(
    "ff_use_autocomplete_for_address_inputs"
  );

  const [address, setAddress] = useState(value);
  useEffect(() => setAddress(value), [value]);

  const containsMailing = address.type.includes(ADDRESS_TYPE_MAILING);
  const isMailingOnly = address.type.length === 1 && containsMailing;
  const updateFields = updates => {
    setAddress({ ...address, ...updates });
    if (onChange) {
      onChange({ ...address, ...updates });
    }
  };

  const requiredness = {
    ...DEFAULT_REQUIRED_FIELDS,
    ...requiredFieldOverrides,
  };

  // Hiding types and allowing more than one is most likely an error, and unlikely
  // to occur in dynamic/runtime generated option settings, so error early to let
  // users know something is amiss!
  if (hideTypes && allowedAddressTypes.length > 1) {
    throw new Error("Cannot hide types if more than one type is allowed");
  }

  return (
    <Flex column data-heart-component="InputAddress">
      <If condition={!hideTypes}>
        <InputDropdown
          label={t("type")}
          description={fieldDescriptions?.type}
          name={addressFieldNameMap.type}
          values={allowedAddressTypes.map(type => [t(type), type])}
          required={allowedAddressTypes.length > 1 || requiredness.type}
          // When there are more than one type, sort reverse alphabetically
          // so that physical_* comes before mailing. Display the physical_* option.
          // Mailing wil be displayed by checkbox below.
          value={
            address.type.length > 1
              ? sortBy(address.type).reverse()[0]
              : address.type[0]
          }
          onChange={val =>
            // Clear out customFields if type changes
            updateFields({ customFields: {}, type: [val] })
          }
          disabled={allowedAddressTypes.length === 1}
        />
      </If>
      <If condition={!hidePrimaryCheckbox}>
        <InputCheckbox
          label={t("primary")}
          description={fieldDescriptions?.primary}
          required={requiredness?.primary}
          name={addressFieldNameMap.primary}
          value={address.primary}
          onChange={val => updateFields({ primary: val })}
          disabled={disablePrimaryCheckbox}
        />
      </If>
      {/* This checkbox is only relevant when the type is physical */}
      <If condition={!isMailingOnly && !hideUseAsMailing}>
        <InputCheckbox
          label={t("use_as_mailing")}
          description={fieldDescriptions?.useAsMailing}
          required={requiredness?.useAsMailing}
          name={addressFieldNameMap.useAsMailing}
          value={containsMailing}
          htmlValue={
            containsMailing && useAsMailingHtmlValueWhenChecked
              ? useAsMailingHtmlValueWhenChecked
              : undefined
          }
          onChange={val => {
            let newType = address.type;
            // if checked, add ADDRESS_TYPE_MAILING to the array
            if (val === true) {
              newType = address.type.concat(ADDRESS_TYPE_MAILING);
              // if un-checked, remove ADDRESS_TYPE_MAILING from the array
            } else {
              newType = without(address.type, ADDRESS_TYPE_MAILING);
            }
            updateFields({ type: newType });
          }}
        />
      </If>
      {/* These inputs are only relevant when the type is incarcerated */}
      <If condition={address.type.includes(ADDRESS_TYPE_PHYSICAL_INCARCERATED)}>
        <InputText
          label={t("booking_number")}
          required={requiredness?.bookingNumber}
          type="text"
          value={address.customFields?.booking_number}
          onChange={val =>
            updateFields({
              customFields: { ...address.customFields, booking_number: val },
            })
          }
        />
        <InputText
          label={t("facility_name")}
          type="text"
          required={requiredness?.facilityName}
          value={address.customFields?.facility_name}
          onChange={val =>
            updateFields({
              customFields: { ...address.customFields, facility_name: val },
            })
          }
        />
      </If>
      <InputTextWithAddressAutocomplete
        label={t("address_line_1")}
        description={fieldDescriptions?.addressLine1}
        required={requiredness?.addressLine1}
        type="text"
        name={addressFieldNameMap.addressLine1}
        value={address.addressLine1}
        onAddressPicked={pickedAddress =>
          updateFields({ ...address, ...pickedAddress })
        }
        autocompleteEnabled={
          useAutocomplete && !!ffUseAutocompleteForAddressInputs
        }
        onChange={val => updateFields({ addressLine1: val })}
      />
      <InputText
        label={t("address_line_2")}
        description={fieldDescriptions?.addressLine2}
        required={requiredness?.addressLine2}
        type="text"
        name={addressFieldNameMap.addressLine2}
        value={address.addressLine2}
        onChange={val => updateFields({ addressLine2: val })}
      />
      <InputText
        label={t("city")}
        description={fieldDescriptions?.city}
        required={requiredness?.city}
        type="text"
        name={addressFieldNameMap.city}
        value={address.city}
        onChange={val => updateFields({ city: val })}
      />
      <CountrySelect
        selectedCountryCode={address?.countryCode}
        description={fieldDescriptions?.countryCode}
        required={requiredness?.countryCode}
        allowedCountryCodes={allowedCountryCodes}
        name={addressFieldNameMap.countryCode}
        onChange={({ code, name }) => {
          if (address?.countryCode !== code) {
            // clear primarySubdivision if countryCode is changed
            updateFields({
              countryCode: code,
              countryName: name,
              primarySubdivisionCode: "",
              primarySubdivisionName: "",
              secondarySubdivision: "",
            });
          } else if (address?.countryName !== name) {
            updateFields({ countryName: name });
          }
        }}
      />
      <PrimarySubdivisionSelect
        primarySubdivisionCode={address?.primarySubdivisionCode}
        description={fieldDescriptions?.primarySubdivision}
        required={requiredness?.primarySubdivision}
        usePrimarySubdivisionCodeAsValue={usePrimarySubdivisionCodeAsValue}
        primarySubdivision={address?.primarySubdivision}
        countryCode={address?.countryCode}
        name={addressFieldNameMap.primarySubdivision}
        onChange={({ code, name }) => {
          const prim = usePrimarySubdivisionCodeAsValue ? code : name;
          const updates = {
            primarySubdivisionCode: code,
            primarySubdivisionName: name,
            primarySubdivision: prim,
          };
          if (
            address?.primarySubdivisionCode !== code &&
            address?.primarySubdivision !== code &&
            address?.primarySubdivision !== name
          ) {
            updates.secondarySubdivision = "";
          }
          updateFields(updates);
        }}
      />
      <InputPostalCode
        label={t("postal_code")}
        description={fieldDescriptions?.postalCode}
        required={requiredness?.postalCode}
        value={address.postalCode}
        name={addressFieldNameMap.postalCode}
        onChange={val => updateFields({ postalCode: val })}
        countryCode={address.countryCode}
      />
      <If condition={showSecondarySubdivisionInput}>
        <SecondarySubdivisionSelect
          countryCode={address?.countryCode}
          description={fieldDescriptions?.secondarySubdivision}
          required={requiredness?.secondarySubdivision}
          primarySubdivisionCode={address?.primarySubdivisionCode}
          value={address?.secondarySubdivision}
          name={addressFieldNameMap.secondarySubdivision}
          onChange={({ secondarySubdivision }) =>
            updateFields({ secondarySubdivision: secondarySubdivision })
          }
        />
      </If>
      <If condition={!hideInactiveCheckbox}>
        <InputCheckbox
          label={t("inactive")}
          value={address.inactive}
          required={requiredness?.inactive}
          name={addressFieldNameMap.inactive}
          description={
            address.inactiveUpdatedAt && address.inactive
              ? t("inactive_description", {
                  inactive_updated_at: formatDateTimeAsShortDate(
                    address.inactiveUpdatedAt
                  ),
                })
              : ""
          }
          onChange={val => updateFields({ inactive: val })}
          error={
            address.inactive && address.primary
              ? t("inactive_primary_error")
              : null
          }
        />
      </If>
      <If condition={address.inactive}>
        <InputTextarea
          label={t("inactive_details")}
          description={fieldDescriptions?.inactiveDetails}
          required={requiredness?.inactiveDetails}
          name={addressFieldNameMap.inactiveDetails}
          value={address.inactiveDetails}
          onChange={val => updateFields({ inactiveDetails: val })}
          placeholder={t("inactive_details_description")}
        />
      </If>
    </Flex>
  );
};

export const valueProps = PropTypes.shape({
  /* id will be populated if value is a persisted address */
  id: PropTypes.string,
  type: PropTypes.array.isRequired,
  addressLine1: PropTypes.string.isRequired,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  primarySubdivision: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  primary: PropTypes.bool.isRequired,
  inactive: PropTypes.bool.isRequired,
  inactiveDetails: PropTypes.string,
  inactiveUpdatedAt: PropTypes.string,
  customFields: PropTypes.object,
});

InputAddress.propTypes = {
  /** The initial (or current) value for the address, as an object */
  value: valueProps.isRequired,

  /** For backporting into active admin pages, allow callers to spacify the `name` of inputs
   * TODO: This param is only needed for active admin compatibility. If active admin no longer
   * calls this because we've deprecated that bit of active admin, this param may be removed.
   */
  fieldNameOverrides: PropTypes.shape({
    type: PropTypes.string,
    primary: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    primarySubdivision: PropTypes.string,
    secondarySubdivision: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    useAsMailing: PropTypes.string,
    inactive: PropTypes.string,
    inactiveDetails: PropTypes.string,
  }),

  /** Descriptions for input fields. Does not override default description if one
   * exists for the field
   */
  fieldDescriptions: PropTypes.shape({
    type: PropTypes.string,
    primary: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    primarySubdivision: PropTypes.string,
    secondarySubdivision: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    useAsMailing: PropTypes.string,
    inactiveDetails: PropTypes.string,
  }),

  /** Overrides default required fields as defined in DEFAULT_REQUIRED_FIELDS */
  requiredFieldOverrides: PropTypes.shape({
    type: PropTypes.bool,
    primary: PropTypes.bool,
    bookingNumber: PropTypes.bool,
    facilityName: PropTypes.bool,
    addressLine1: PropTypes.bool,
    addressLine2: PropTypes.bool,
    city: PropTypes.bool,
    primarySubdivision: PropTypes.bool,
    secondarySubdivision: PropTypes.bool,
    countryCode: PropTypes.bool,
    postalCode: PropTypes.bool,
    useAsMailing: PropTypes.bool,
    inactive: PropTypes.bool,
    inactiveDetails: PropTypes.bool,
  }),

  /** The `onChange` function is invoked with the entire address object */
  onChange: PropTypes.func,

  /** Use this to restrict the address types the user can select.
   *  If only one is allowed, make sure the value passed in has that
   * type pre-set in the type array, because the dropdown will be disabled.
   */
  allowedAddressTypes: PropTypes.array,

  /** Disables the checkbox for marking this address as primary so it can't be
   * changed. To hide the option entirely see hidePrimaryCheckbox, so be sure to set the
   * value for "primary" in the value to either true or false as appropriate for your
   * use case before this component is rendered.
   */
  disablePrimaryCheckbox: PropTypes.bool,

  /* If on, fetch address predictions as the user types in line1, and upon selection opulate
   * the rest of the address fields. Allow editing after selection.
   */
  useAutocomplete: PropTypes.bool,

  /* Show an input for secondary subdivisions (counties in the US)
   */
  showSecondarySubdivisionInput: PropTypes.bool,

  /* HTML Checkboxes generally do not have a "value", the name is simply omitted from the POST
   * data when unchecked, and included when checked. Active admin, however, assumes that
   * checked boxes have a value of "1". If you need to make this active admin compatible,
   * or for another reason need your checkbox to have an * explicit value when the form POSTs,
   * this allows you to specify the value when checked.
   * TODO: This param is only needed for active admin compatibility. If active admin no longer
   * calls this because we've deprecated that bit of active admin, this param may be removed.
   */
  useAsMailingHtmlValueWhenChecked: PropTypes.string,

  /* Historically this component used the primary subdivision (in US, state) name as both the value
   * and label of the dropdown. Other places in our code use the code as the value and name as the
   * label. This lets us do that. In order to make this the default/only behavior we'd need
   * to do a migration of the values already stored in `AddressLocation`. TODO: ENG-20136
   */
  usePrimarySubdivisionCodeAsValue: PropTypes.bool,

  /* Only allow the following countries; if only 1 is listed the input will be hidden.
   * If not provided, a list of all known countries will be fetched from the backend.
   */
  allowedCountryCodes: PropTypes.arrayOf(PropTypes.string),

  /**
   * NOTE about hideXYZ options
   * If you are looking to have a simplified address input that only shows location
   * fields and not metadata fields, SimplifiedInputAddress will wrangle all these
   * options for you. Use these individually if you need to individually hide tidbits or
   * have a more nuanced use case.
   */

  /** Hides the checkbox allowing this address to be listed as inactive. */
  hideInactiveCheckbox: PropTypes.bool,

  /** Hides the address type as long as only one is allowed */
  hideTypes: PropTypes.bool,

  /** Hides the use as mailing checkbox */
  hideUseAsMailing: PropTypes.bool,

  /** Hides the address type as long as only one is allowed */
  hidePrimaryCheckbox: PropTypes.bool,
};

export const { propTypes } = InputAddress;

export default InputAddress;
