import { Icons, Pill } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";

const pillProps = {
  confidential: {
    icon: Icons.EyeSlash,
    text: I18n.t("admin.child.case_status.confidential"),
    variant: "warning",
  },
  sealed: {
    icon: Icons.Lock,
    text: I18n.t("admin.child.case_status.sealed"),
    variant: "warning",
  },
};

/**
 * Determines a child's status based on sealed and confidential flags
 *
 * @param {Object} agencyHuman - The agency human object representing a child
 * @param {boolean} agencyHuman.sealed - Whether the child is sealed
 * @param {boolean} agencyHuman.confidential - Whether the child is confidential
 * @returns {string|null} "sealed", "confidential", or null if neither flag is true
 */
export const getChildCaseStatus = ({ sealed, confidential }) => {
  if (sealed) return "sealed";
  if (confidential) return "confidential";
  return null;
};

/** Determine the props for a pill associated with a child's status */
export const childCaseStatusPillProps = child =>
  pillProps[getChildCaseStatus(child)];

/**
 * A specialized pill for displaying child case status (confidential or sealed)
 *
 * This component abstracts away the common configuration patterns for displaying
 * case status pills, providing a simpler API specific to this use case.
 *
 * ```jsx
 * <StatusPill status="confidential" />
 * ```
 *
 * ```jsx
 * <StatusPill status="sealed" />
 * ```
 */
const StatusPill = ({ status }) => {
  const config = pillProps[status];
  return config ? <Pill {...config} /> : null;
};

StatusPill.propTypes = {
  /**
   * The status to display - must be either "confidential" or "sealed"
   */
  status: PropTypes.oneOf(["confidential", "sealed"]),
};

StatusPill.displayName = "StatusPill";

export default StatusPill;
