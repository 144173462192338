import { ArrayDataTable, Button, Flex, Link, Surface } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import styles from "../CcwisDemo.module.scss";
// import ReasonPlacementEndedCell from "@components/admin/children/ReasonPlacementEndedCell";
import CurrentPlacement from "./current-placement.svg";

const Placements = ({ placementPeriods, childId }) => (
  <div style={{ maxWidth: "98.5%", margin: "0 8px 8px" }}>
    <Flex column>
      <img src={CurrentPlacement} alt="Current Placement" />

      <Surface title="Historical Placements" hideTitle>
        <h2 className={styles.surfaceTitle}>Historical Placements</h2>
        <ArrayDataTable
          actions={
            <Button
              variant="secondary"
              href={`/admin/children/${childId}/placement_periods/new`}
            >
              Add Placement
            </Button>
          }
          columns={[
            {
              id: "creator",
              columnName: { name: "Placement By", justify: "start" },
              cell: "creator",
            },
            {
              id: "type",
              columnName: { name: "Placement Type", justify: "start" },
              cell: "type",
            },
            {
              id: "placement",
              columnName: { name: "Placement", justify: "start" },
              cell: ({
                placementDisplayName,
                placementLink,
                confidential,
                privateAgencyFamilyName,
                privateAgencyAddressComponents,
                privateAgencyFamilyPhoneNumber,
                privateAgencyFamilyLicenseNumber,
              }) => (
                <Fragment>
                  <Choose>
                    <When condition={placementLink}>
                      <Link href={placementLink}>{placementDisplayName}</Link>
                    </When>
                    <Otherwise>{placementDisplayName}</Otherwise>
                  </Choose>
                  <If condition={confidential}>
                    <Flex>
                      <p
                        className="confidential_message_container"
                        data-testid="confidential-row"
                      >
                        <span className="confidential_message inline-hints">
                          <i className="fa fa-eye-slash"></i>{" "}
                          {I18n.t("views.admin.confidential_row_message")}
                        </span>
                      </p>
                    </Flex>
                  </If>
                  <Flex>{privateAgencyFamilyName}</Flex>
                  <Flex column>
                    {privateAgencyAddressComponents.map(component => (
                      <p key={component}>{component}</p>
                    ))}
                  </Flex>
                  <Flex>{privateAgencyFamilyPhoneNumber}</Flex>
                  <Flex>{privateAgencyFamilyLicenseNumber}</Flex>
                </Fragment>
              ),
            },
            {
              id: "startDate",
              columnName: {
                name: "Date Child Joined Placement",
                justify: "start",
              },
              cell: "startDate",
            },
            {
              id: "endDate",
              columnName: {
                name: "Date Placement Ended",
                justify: "start",
              },
              cell: "endDate",
            },
            /* {
              id: "reasonEnded",
              columnName: { name: "Reason Placement Ended", justify: "start" },
              cell: ({
                daysOfNotice,
                explanation,
                placementEndedReason,
                requester,
              }) => (
                <ReasonPlacementEndedCell
                  {...{
                    daysOfNotice,
                    explanation,
                    placementEndedReason,
                    requester,
                  }}
                />
              ),
            }, */
            {
              id: "actions",
              columnName: { name: "Actions", justify: "start" },
              cell: ({ updateLink, destroyLink }) => (
                <Flex row>
                  <If condition={updateLink}>
                    <Link href={updateLink}>Edit</Link>{" "}
                  </If>
                  <If condition={destroyLink}>
                    <Link
                      href={destroyLink}
                      data-confirm="Are you sure?"
                      rel="nofollow"
                      data-method="delete"
                    >
                      Delete
                    </Link>
                  </If>
                </Flex>
              ),
            },
          ]}
          data={placementPeriods}
        />
      </Surface>
    </Flex>
  </div>
);

Placements.propTypes = {
  childId: PropTypes.number.isRequired,
  placementPeriods: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Placements;
