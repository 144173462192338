import { useMutation } from "@apollo/client";
import {
  Button,
  Flex,
  InputDropdown,
  InputRadioGroup,
  Link,
  Notice,
  Text,
} from "@heart/components";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { translationWithRoot } from "@components/T";

import SendOtp from "@graphql/mutations/SendOtp.graphql";

import preventDefault from "@lib/preventDefault";

import styles from "../Login.module.scss";

const { T, t } = translationWithRoot("permissions.otp_login");

const OtpSend = ({ agencyAdminAssignmentId, phoneNumbers, email }) => {
  const [sendOtpMutation, { loading, error }] = useMutation(SendOtp);
  const [dropdownDisabled, setDropdownDisabled] = useState(true);

  const onSendSubmit = preventDefault(ev => {
    const fd = new FormData(ev.target);
    const phoneNumberId = fd.get("phoneNumberDropdown");
    const preferredMethod = fd.get("otpRadio");

    localStorage.setItem("otpPhoneNumberId", phoneNumberId);
    localStorage.setItem("agencyAdminAssignmentId", agencyAdminAssignmentId);
    localStorage.setItem("preferredMethod", preferredMethod);

    sendOtpMutation({
      variables: {
        preferredMethod: preferredMethod,
        phoneNumberId: phoneNumberId,
        agencyAdminAssignmentId: agencyAdminAssignmentId,
      },
      onCompleted: ({ sendOtp: result }) => {
        if (result.status === "pending") {
          window.location.href = "/users/verifications/new?verify=true";
        }
      },
    });
  });

  const onRadioChange = selectedMethod => {
    if (selectedMethod === "sms") {
      setDropdownDisabled(false);
    } else {
      setDropdownDisabled(true);
    }
  };

  const primaryPhoneNumber = phoneNumbers[0];
  const defaultDropdownValue = primaryPhoneNumber?.phoneNumberId || "";
  const dropdownPhoneNumbers = phoneNumbers.map(number => {
    let phoneNumberString = "";
    if (number.category) {
      phoneNumberString = `${number.phoneNumber} (${number.category})`;
    } else {
      phoneNumberString = `${number.phoneNumber}`;
    }

    return [phoneNumberString, number.phoneNumberId];
  });

  return (
    <Flex justify="space-between" column align="center" gap="300">
      <Text textStyle="emphasis-200">
        <T t="send_title" />
      </Text>
      {error && <Notice title={t("otp_send_error")} />}
      <form onSubmit={onSendSubmit}>
        <div className={styles.field}>
          <Flex justify="space-between" column gap="100">
            <InputRadioGroup
              id="otpRadio"
              name="otpRadio"
              hideLabel
              label={t("send_title")}
              onChange={onRadioChange}
              value="email"
              values={[
                {
                  value: "email",
                  label: `${t("email_radio_label")} ${email}`,
                },
                {
                  value: "sms",
                  label: t("sms_radio_label"),
                  disabled: phoneNumbers.length === 0,
                },
              ]}
            ></InputRadioGroup>
            <InputDropdown
              id="phoneNumberDropdown"
              name="phoneNumberDropdown"
              label={t("phone_numbers_for_sms")}
              hideLabel
              hideBlank
              disabled={dropdownDisabled}
              value={defaultDropdownValue}
              values={dropdownPhoneNumbers}
            />
          </Flex>
        </div>
        <br />
        <Flex column align="center">
          <Button type="submit" variant="primary" submitting={loading}>
            <T t="send_button_text" />
          </Button>
        </Flex>
      </form>
      <Link
        href="https://binti.com/mfa_terms/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text textStyle="body-100">{t("mfa_terms_of_use")}</Text>
      </Link>
    </Flex>
  );
};

OtpSend.propTypes = {
  agencyAdminAssignmentId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumbers: PropTypes.arrayOf(PropTypes.object),
};

export default OtpSend;
