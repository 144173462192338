import { useMutation } from "@apollo/client";
import { Flex, FlexItem, Icons, Button } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React from "react";

import CreatePermissionRule from "@graphql/mutations/CreatePermissionRule";
import * as ResourceActionQuery from "@graphql/queries/ResourceAction.graphql";

import PermissionsPropTypes from "./PermissionsPropTypes";
import styles from "./ResourceAction.module.scss";
import ResourceActionDescription from "./ResourceActionDescription";

const NoPermissions = ({ viewOnlyMode, queryVariables }) => {
  const [createPermissionRuleMutation] = useMutation(CreatePermissionRule);

  const createPermissionRule = () =>
    createPermissionRuleMutation({
      variables: { ...queryVariables },
      refetchQueries: [
        {
          query: ResourceActionQuery,
          variables: queryVariables,
        },
      ],
    });

  return (
    <div
      data-testid={`resource-action-group-${queryVariables.resource}-${queryVariables.action}`}
    >
      <Flex justify="space-between" align="center" row>
        <ResourceActionDescription queryVariables={queryVariables} />

        <FlexItem className={styles.noPermission}>
          <span className={styles.iconWithPadding}>
            {<Icons.TimesCircle />}
          </span>
          {I18n.t("javascript.components.permissions.no_permission")}
        </FlexItem>
        <If condition={!viewOnlyMode}>
          <Button
            variant="tertiary"
            onClick={createPermissionRule}
            data-testid="grant-revoke-button"
          >
            {I18n.t("javascript.components.permissions.grant")}
          </Button>
        </If>
      </Flex>
    </div>
  );
};

NoPermissions.propTypes = {
  viewOnlyMode: PropTypes.bool,
  queryVariables: PermissionsPropTypes.queryVariables,
};

export default NoPermissions;
