import { LiveRegion } from "@heart/components";
import LogoSpinner from "@heart/components/loading_overlay/LogoSpinner";
import classNames from "classnames";
import I18n from "i18n-js";
import { isString } from "lodash";
import { Fragment, forwardRef } from "react";
import { If } from "tsx-control-statements/components";

import Clickable, {
  ClickableProps,
  ClickableRefType,
  cleanClickableProps,
} from "../clickable/Clickable";
import styles from "./Button.module.scss";

// Helper types to deal with mutually exclusive props and prop A => prop B
// situations

type IconButtonProps = {
  icon: React.ElementType;
  description: string;
  children?: never;
};

type NonIconButtonProps = {
  children?: React.ReactNode;
  description?: string;
  icon?: never;
};

type EitherIconButtonProps = IconButtonProps | NonIconButtonProps;

type BadgeWithDescriptionProps = {
  badge: number;
  badgeDescription: string;
};

type NoBadgeProps = {
  badge?: never;
  badgeDescription?: never;
};

type EitherBadgeProps = BadgeWithDescriptionProps | NoBadgeProps;

/**
 * A button!
 *
 * The `href` and `onClick` props are mutually exclusive - `href` will
 * render an `<a>` tag and `onClick` will render the children in a
 * `<button>` that has been given the visual style of a link
 *  _([why?](https://www.digitala11y.com/links-vs-buttons-a-perennial-problem/)_)
 *
 * Any of the documented `props` are critical and you should check the documentation
 * about them - but this component also takes any other props and sends them through
 * to the underlying `<button>` element.
 *
 * **A note about forms:** When submitting GraphQL-backed forms, please use the
 * `<Button type="submit">` paired with a `<form onSubmit={persist}>` rather than
 * using the `<Button onClick={persist} />` pattern - we get a lot of free HTML5
 * validation when we do so!
 *
 * **Known issue:** When using Buttons in a flex container, they grow on the
 * cross-axis.  See below for an example and a workaround.
 *
 * @param children Button contents
 * @param description *Required when using an icon-only button.* Used to indicate to
 * screen readers what the purpose of the button is, and puts useful
 * hover text on the button.  A combination of HTML
 * `title` and `aria-label` attributes - for further reading:
 *  * https://silktide.com/blog/i-thought-title-text-improved-accessibility-i-was-wrong/
 * * https://dev.opera.com/articles/ux-accessibility-aria-label/#accessible-name-calculation
 * @param disabled Whether button is clickable
 * @param icon Icon: one of the components from `import { Icons } from "@heart/components"`
 * @param iconOnRight When true, puts the icon to the right of the button contents
 * @param onClick Action to execute when button is clicked
 * @param href Link location
 * @param submitting Whether the button is currently being submitted and should show a submitting state.
 * Also disables the button when true.
 * @param submittingText Custom submitting text, defaults to "Submitting..."
 * @param type Used to indicate when a button submits or resets a form, or is just a clickable button.
 * If you want to use this button to POST the current form (eg to a Rails/AA controller),
 * use `type="submit"`.
 * @param variant Which variant?
 * @param asSpan This supports an exotic use case for UploadButton in which some browsers
 * won't tolerate an actual <button>, so this renders the button as a `<span>`.
 * See PR #12035 for details, but you probably won't need this.
 * @param badge Indicates a number to show in a badge on the button
 * @param badgeDescription text used to describe what the badge indicates, used for accessibility
 * @param round Make the button round. To be used only for our microphone button (VoiceInput).
 * Implemented here to take advantage of the other Button a11y features,
 * but not meant to be used in other contexts at this time. Only supported
 * for <button> Buttons so as not to tempt people to use it in other ways.
 * @param data-testid Test ID for Cypress or Jest
 */
const Button = forwardRef<
  ClickableRefType,
  ClickableProps & {
    iconOnRight?: boolean;
    submitting?: boolean;
    submittingText?: string;
    type?: "button" | "submit" | "reset";
    asSpan?: boolean;
    variant?: "primary" | "secondary" | "danger" | "tertiary";
    round?: boolean;
    "data-testid"?: string;
  } & EitherIconButtonProps &
    EitherBadgeProps
>(
  (
    {
      children,
      description,
      disabled,
      icon: IconComponent,
      iconOnRight,
      onClick,
      href,
      submitting,
      submittingText = I18n.t("views.common.submitting"),
      type = "button",
      asSpan,
      variant = "primary",
      badge,
      badgeDescription,
      "data-testid": testId,
      round = false,
      ...props
    },
    ref
  ) => {
    if (isString(description) && description.trim() === "") {
      throw new Error("Button description is required");
    }

    const desc = submitting
      ? submittingText || I18n.t("views.common.submitting")
      : description;
    const buttonContent = (
      <Fragment>
        <If condition={submitting}>
          <div className={styles.logoSpinnerContainer}>
            <LogoSpinner />
          </div>
        </If>
        <If condition={!submitting && IconComponent}>
          <IconComponent />
        </If>
        {submitting ? submittingText : children}
      </Fragment>
    );
    const commonProps = {
      ref: ref,
      "aria-label": desc,
      title: desc,
      "data-heart-component": "button",
      "data-testid": testId,
    };

    if (asSpan)
      return (
        <span
          {...commonProps}
          className={classNames(styles.button, styles[variant])}
          role="button"
        >
          {buttonContent}
        </span>
      );

    return (
      <Clickable
        {...commonProps}
        {...cleanClickableProps({
          onClick,
          href,
          type,
          disabled: disabled || submitting,
          anchorClassname: classNames(styles.a, styles[variant], {
            [styles.iconOnRight]: iconOnRight,
          }),
          buttonClassname: classNames(styles.button, styles[variant], {
            [styles.iconOnRight]: iconOnRight,
            [styles.round]: round,
          }),
        })}
        {...props}
      >
        {buttonContent}
        <If condition={badgeDescription}>
          {/* Assistive tech requires the LiveRegion to always be present when the component is mounted.
          Theoretically if we're dynamically updating the badge, the badgeDescription should be consistenly
          present, so we're nesting a second conditional in here to avoid adding the LiveRegion to buttons it
          isn't needed for, while also conditionally rendering the badge elements */}
          <LiveRegion>
            <If condition={badge}>
              <div className={styles.hiddenButAccessible}>
                {badgeDescription}
              </div>
              <div className={styles.badge}>{badge}</div>
            </If>
          </LiveRegion>
        </If>
      </Clickable>
    );
  }
);
Button.displayName = "Button";

export default Button;
