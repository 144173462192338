import { Icons, Sidebar, sidebarItem } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React from "react";
import {
  editPersonPath,
  familyFindingDocumentsPersonPath,
  childRelationshipsDashboardPath,
  childRequirementsPath,
  familyFindingSearchesPath,
  personPath,
  childPhotosPath,
  serviceReferralsPersonPath,
} from "routes";

import { translationWithRoot } from "@components/T";
import useChildAgencyHumanPermissions from "@components/agency_humans/useChildAgencyHumanPermissions";

import { stringifyAndEncode } from "@lib/base64Helpers";
import useFeatureFlag from "@lib/useFeatureFlag";

import { B64PARAMS } from "@root/constants";

const { t } = translationWithRoot("admin.child", {
  escapeJavascriptRoot: true,
});

/** A sidebar for our Child relationships dashboard page */
const ChildSidebar = ({
  currentPage,
  avatar,
  label,
  agencyHumanId,
  childId,
  childFullName,
  childPermissions,
  childCaseStatusPillProps,
  viewMatchingFamiliesPath = "",
  modulePage = null,
}) => {
  const { flag: ffIntegratedSearch } = useFeatureFlag(
    "ff_integrated_search_01_2024"
  );
  const permissions = useChildAgencyHumanPermissions(agencyHumanId);

  const items = [
    permissions.mayShow() &&
      sidebarItem({
        currentPage,
        pageId: "view_child_data",
        pageTitle: t("view_child_data"),
        href: personPath(agencyHumanId),
        Icon: Icons.InfoCircle,
      }),
    permissions.mayEdit() &&
      sidebarItem({
        currentPage,
        pageId: "edit_child",
        pageTitle: t("edit_child"),
        href: editPersonPath(agencyHumanId),
        Icon: Icons.Pencil,
      }),
    childPermissions.mayViewPhotos &&
      sidebarItem({
        currentPage,
        pageId: "photos",
        pageTitle: t("photos"),
        href: childPhotosPath(childId),
        Icon: Icons.Images,
      }),
    (!modulePage || ["prevention", "family_finding"].includes(modulePage)) &&
      childPermissions.mayViewServiceReferrals &&
      sidebarItem({
        currentPage,
        pageId: "service_referrals",
        pageTitle: t("service_referrals_dashboard"),
        href: serviceReferralsPersonPath(agencyHumanId),
        Icon: Icons.Handshake,
      }),
    permissions.mayViewRelationships() &&
      sidebarItem({
        currentPage,
        pageId: "relationships_dashboard",
        pageTitle: t("relationships_dashboard"),
        href: childRelationshipsDashboardPath(childId),
        Icon: Icons.Sitemap,
      }),
    ["prevention", "family_finding"].includes(modulePage) &&
      permissions.mayViewFamilyFindingDocuments() &&
      ffIntegratedSearch &&
      sidebarItem({
        currentPage,
        pageId: "family_finding_documents",
        pageTitle: t("family_finding_documents"),
        href: familyFindingDocumentsPersonPath(agencyHumanId),
        Icon: Icons.File,
      }),
    ["prevention", "family_finding"].includes(modulePage) &&
      childPermissions.mayViewFamilyFindingSearches &&
      sidebarItem({
        currentPage,
        pageId: "family_finding_searches",
        pageTitle: t("family_finding_searches"),
        href: familyFindingSearchesPath({
          [B64PARAMS]: stringifyAndEncode({
            query: { status: "all", childNameContains: childFullName },
          }),
        }),
        Icon: Icons.Search,
      }),
    childPermissions.mayViewMatchingFamilies &&
      sidebarItem({
        currentPage,
        pageId: "view_matching_families",
        pageTitle: t("view_matching_families"),
        href: viewMatchingFamiliesPath,
        Icon: Icons.Users,
      }),
    childPermissions.mayViewRequirements &&
      sidebarItem({
        currentPage,
        pageId: "requirements",
        pageTitle: I18n.t("admin.common.requirements"),
        href: childRequirementsPath(childId),
        Icon: Icons.Tasks,
      }),
  ];

  return (
    <Sidebar
      avatar={avatar}
      label={label}
      items={items.filter(Boolean)}
      pillProps={childCaseStatusPillProps}
    />
  );
};
ChildSidebar.propTypes = {
  currentPage: PropTypes.string.isRequired,
  agencyHumanId: PropTypes.number.isRequired,
  childFullName: PropTypes.string.isRequired,
  childId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  avatar: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
  childPermissions: PropTypes.shape({
    mayViewFamilyFindingSearches: PropTypes.bool,
    mayViewMatchingFamilies: PropTypes.bool,
    mayViewPhotos: PropTypes.bool,
    mayViewRequirements: PropTypes.bool,
    mayViewServiceReferrals: PropTypes.bool,
  }).isRequired,
  childCaseStatusPillProps: PropTypes.shape({
    text: PropTypes.string,
    variant: PropTypes.string,
    icon: PropTypes.object,
  }),
  viewMatchingFamiliesPath: PropTypes.string,
  /** Until we reorganize our pages to be better categorized,
   * we don't necessarily want to show every module specific page
   * from pages unrelated to that module. This prop allows us to
   * conditionally show/hide module specific pages.
   */
  modulePage: PropTypes.oneOf(["family_finding", "prevention"]),
};

export default ChildSidebar;
