import { useQuery, useMutation } from "@apollo/client";
import {
  Actions,
  Layout,
  SurfaceForm,
  InputText,
  LoadingOverlay,
  Text,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { familyFindingBulkOutreachCampaignTemplatesPath } from "routes";

import { translationWithRoot } from "@components/T";
import HtmlEditor from "@components/inputs/HtmlEditor";
import AgencyDropdown from "@components/shared/AgencyDropdown";

import CreateBulkOutreachCampaignTemplate from "@graphql/mutations/CreateBulkOutreachCampaignTemplate.graphql";
import BulkOutreachCampaignTemplate from "@graphql/queries/BulkOutreachCampaignTemplate.graphql";

const { t } = translationWithRoot(
  "family_finding.bulk_outreach_campaign_templates.form"
);

/**
 * Component for creating or editing a bulk outreach campaign template
 * which can be used in the Family Finding bulk outreach feature
 * for sending out bulk letters or emails to relatives of a child.
 * Usually agencies set up templates so that the language in the outreach
 * is standardized.
 */
const BulkOutreachCampaignTemplateForm = ({ id, showAgencyDropdown }) => {
  const {
    data: { bulkOutreachCampaignTemplate } = {},
    loading: templateLoading,
  } = useQuery(BulkOutreachCampaignTemplate, {
    variables: { id },
    skip: !id,
  });
  const { formState, setFormAttribute } = useBintiForm(
    bulkOutreachCampaignTemplate
  );

  const [createBulkOutreachCampaignTemplate, { loading: createLoading }] =
    useMutation(CreateBulkOutreachCampaignTemplate);

  const missingContentTemplate =
    // content of Quill editor is "<p><br></p>" when empty
    formState.contentTemplate === "<p><br></p>";

  const [showContentTemplateError, setShowContentTemplateError] =
    useState(false);

  const agencyIdRef = useRef();
  const titleRef = useRef();

  const saveAction = contentTemplateStatus => async () => {
    if (missingContentTemplate) {
      setShowContentTemplateError(true);
    } else {
      setShowContentTemplateError(false);
      await createBulkOutreachCampaignTemplate({
        variables: {
          contentTemplate: formState.contentTemplate,
          contentTemplateStatus,
          title: formState.title,
          agencyId: formState.agencyId,
        },
      });
      window.location = familyFindingBulkOutreachCampaignTemplatesPath();
    }
  };

  return (
    <Layout
      pageTitle={id ? t("page_title_edit") : t("page_title_create")}
      main={{
        content: (
          <LoadingOverlay active={templateLoading}>
            <SurfaceForm
              title={t("form_title")}
              actions={
                <Actions
                  isSubmitting={createLoading}
                  primaryText={t("finalize_template")}
                  // HtmlEditor is more complicated to check validity
                  // for because it has default content so we will check
                  // that ourselves and not pass it through here
                  checkValidityInputRefs={
                    showAgencyDropdown ? [agencyIdRef, titleRef] : [titleRef]
                  }
                  primaryAction={saveAction("final")}
                  secondaryText={t("save_draft")}
                  secondaryAction={saveAction("draft")}
                  cancelHref={familyFindingBulkOutreachCampaignTemplatesPath()}
                />
              }
            >
              <If condition={showAgencyDropdown}>
                <AgencyDropdown
                  label={t("agency")}
                  value={formState.agency?.id}
                  onChange={setFormAttribute("agencyId")}
                  required
                  ref={agencyIdRef}
                />
              </If>
              <InputText
                label={t("template_name")}
                value={formState.title}
                onChange={setFormAttribute("title")}
                required
                fullWidth
                ref={titleRef}
              />
              <HtmlEditor
                id="bulk-outreach-campaign-template-content"
                label={t("content")}
                name={t("content")}
                value={formState.contentTemplate}
                onChange={setFormAttribute("contentTemplate")}
                printOnly
                mergeFieldsClass="bulk_outreach_campaign"
                allowBlockQuotes={false}
                fullWidth
                required
              />
              <If condition={showContentTemplateError}>
                <Text textColor="danger-600" textStyle="supporting-50">
                  {t("required_error")}
                </Text>
              </If>
            </SurfaceForm>
          </LoadingOverlay>
        ),
      }}
    />
  );
};

BulkOutreachCampaignTemplateForm.propTypes = {
  id: PropTypes.string,
  showAgencyDropdown: PropTypes.bool.isRequired,
};

export default BulkOutreachCampaignTemplateForm;
