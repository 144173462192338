import { Flex } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

import SurfaceBase from "./SurfaceBase";

/**
 * A "raised" surface to put controls on top of, breaking up the
 * page into multiple sections.
 */
const Surface = ({
  title,
  subtitle,
  secondary,
  collapsible,
  defaultCollapsed,
  collapsedPillProps,
  hideTitle,
  children,
  gap = "300",
  "data-testid": testId,
}) => (
  <SurfaceBase
    rootElement="section"
    variant="raised"
    title={title}
    subtitle={subtitle}
    secondary={secondary}
    collapsible={collapsible}
    defaultCollapsed={defaultCollapsed}
    collapsedPillProps={collapsedPillProps}
    hideTitle={hideTitle}
    data-testid={testId}
    rootElementProps={{ "data-heart-component": "Surface" }}
  >
    <Flex column gap={gap}>
      {children}
    </Flex>
  </SurfaceBase>
);

Surface.propTypes = {
  /** Title of the Surface, displayed in the top left.  This is always required
   * for accessibility, but if you want to hide it, use the `hideTitle` prop. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** Whether or not the title is hidden.  If the title is hidden and there is no
   * content in `secondary`, the entire header will be hidden. */
  hideTitle: PropTypes.bool,
  /** Subtitle of the Surface, displayed in the top left. Should usually be a
   * simple string, but can be a node if it has links. */
  subtitle: PropTypes.node,
  /** Secondary content - usually a call-to-action or a filter input,
   * displayed in the top right */
  secondary: PropTypes.node,
  /** Whether the surface should be collapsible using a toggle in the top right
   * corner of the surface
   */
  collapsible: PropTypes.bool,
  /** Whether the surface should default to being collapsed on first load. Defaults
   * to false, and only usable in conjunction with `collapsible`
   */
  defaultCollapsed: PropTypes.bool,
  /** If the surface is collapsible, Pill props can be passed through to be rendered
   * when the surface is collapsed. This is useful if there is something within the
   * collapsed form that the user's attention should be drawn to, e.g. an error if the
   * surface contains a form
   */
  collapsedPillProps: PropTypes.shape({
    /** Which variant? */
    variant: PropTypes.oneOf([
      "alert",
      "neutral",
      "info",
      "success",
      "warning",
    ]),
    /** What should the pill say? */
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  }),
  /** Gap between child elements */
  gap: PropTypes.oneOf(["0", "100", "200", "300"]),
  /** Surface contents */
  children: PropTypes.node.isRequired,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};
export default Surface;
