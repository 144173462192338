import { Text, Icons, TableCellList } from "@heart/components";
import { Tr, Td } from "@heart/components/table/Table";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";
import {
  displayAddress,
  displayEmails,
} from "@components/agency_humans/displayAgencyHumanInfo";

import {
  FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_EMAIL,
  ADDRESS_TYPE_MAILING,
} from "@root/constants";

const { t } = translationWithRoot(
  "family_finding.bulk_outreach_campaigns.sidebar"
);

/**
 * Returns the mailing addresses from an array of addresses.
 * If no mailing addresses are found, returns primary addresses.
 */
export const filteredAddresses = addresses => {
  const mailingAddresses = addresses.filter(
    ({ type, inactive }) => type.includes(ADDRESS_TYPE_MAILING) && !inactive
  );

  if (!isEmpty(mailingAddresses)) {
    return mailingAddresses;
  }
  return addresses.filter(({ primary }) => primary);
};

/**
 * Returns the primary and active email address from an array of email addresses.
 */
export const getPrimaryActiveEmail = emailAddresses =>
  emailAddresses?.find(email => email.primary && !email.inactive);

/**
 * BulkOutreachCampaignRecipientRow component
 *
 * Displays a single recipient row in the bulk outreach campaign sidebar,
 * with contact information depending on the channel (email or print).
 */
const BulkOutreachCampaignRecipientRow = ({
  agencyHuman,
  relationshipId,
  allowRemoveRecipients,
  relationshipIds,
  channel,
  setBase64SearchParams,
}) => {
  // Determine contact info based on the channel
  let contactInfo;

  if (channel === FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_EMAIL) {
    // We'll only send to one email, the primary one
    contactInfo = getPrimaryActiveEmail(agencyHuman.emailAddresses);
  } else {
    // For the PRINT channel, use filteredAddresses
    contactInfo = filteredAddresses(agencyHuman.addresses);
  }

  return (
    <Tr key={agencyHuman.id}>
      <Td>{agencyHuman.fullName}</Td>
      <Td>
        <Choose>
          <When condition={agencyHuman.childId}>
            <Text textColor="danger-600">
              <Icons.ExclamationTriangle />{" "}
              {t(`${channel}.child_cannot_be_included`)}
            </Text>
          </When>
          <When condition={!contactInfo || isEmpty(contactInfo)}>
            <Text textColor="danger-600">
              <Icons.ExclamationTriangle />{" "}
              {t(`${channel}.no_contact_info_found`)}
            </Text>
          </When>
          <Otherwise>
            {channel === FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_EMAIL ? (
              displayEmails({
                emailAddresses: [contactInfo],
              })
            ) : (
              <TableCellList
                items={contactInfo}
                transformItem={displayAddress}
              />
            )}
          </Otherwise>
        </Choose>
      </Td>
      {allowRemoveRecipients && (
        <Td>
          <Icons.Times
            description={t("remove_recipient")}
            onClick={() => {
              setBase64SearchParams([
                {
                  attribute: "selectedIds",
                  value: relationshipIds.filter(id => id !== relationshipId),
                },
              ]);
            }}
          />
        </Td>
      )}
    </Tr>
  );
};

BulkOutreachCampaignRecipientRow.propTypes = {
  agencyHuman: PropTypes.object.isRequired,
  relationshipId: PropTypes.string.isRequired,
  allowRemoveRecipients: PropTypes.bool.isRequired,
  relationshipIds: PropTypes.array.isRequired,
  channel: PropTypes.string.isRequired,
  setBase64SearchParams: PropTypes.func.isRequired,
};

export default BulkOutreachCampaignRecipientRow;
