import { useQuery } from "@apollo/client";
import classnames from "classnames";
import PropTypes from "prop-types";

import Spinner from "@components/Spinner";

import ApplicationStageTemplateQuery from "@graphql/queries/ApplicationStageTemplate.graphql";

import styles from "./ApplicationTemplateDesigner.module.scss";
import FormDropppable from "./FormDroppable";
import OtherSections from "./OtherSections";
import UploadTypeDroppable from "./UploadTypeDroppable";

/**
 * Display for an application stage template associated with an application
 * template. This display includes basic identifying information about the
 * stage (e.g. headline) and the required documents that may be provided
 * by end users on the stages generated from this template.
 *
 * Allows:
 * 1. Adding and removing forms and upload types as requirements from the
 *    stage.
 * 2. Reordering the forms and templates on the stage.
 * 3. Clicking through to a page to edit the stage template.
 *
 * The adding and reordering functionality are managed via drag and drop.
 * The ids for these droppable areas are injected as props.
 */
const ApplicationStageTemplate = ({
  id,
  applicationTemplate,
  formDroppableId,
  uploadTypeDroppableId,
  showFormsInStages,
  showUploadTypesInStages,
}) => {
  const { data, loading } = useQuery(ApplicationStageTemplateQuery, {
    variables: { id },
  });

  if (loading) {
    return <Spinner />;
  }

  const { applicationStageTemplate } = data;

  const stageType = applicationStageTemplate.type.replace("StageTemplate", "");
  const isNormal = stageType === "Normal";

  return (
    <div
      className={classnames(styles.formList, styles.applicantStage)}
      data-testid="stage-template"
    >
      <div
        className={styles.applicantStageHeadline}
        data-testid="stage-template-headline"
      >
        {applicationStageTemplate.headlineTranslations.en} (Stage number:
        {applicationStageTemplate.stageNumber})
      </div>
      <div className={styles.applicantStageType}>{stageType}</div>
      {isNormal && (
        <OtherSections applicationStageTemplate={applicationStageTemplate} />
      )}
      <div className={styles.applicantStageRequirements}>
        {applicationStageTemplate.forms && showFormsInStages && (
          <FormDropppable
            id={id}
            applicationTemplate={applicationTemplate}
            formDroppableId={formDroppableId}
          />
        )}
        {applicationStageTemplate.uploadTypes && showUploadTypesInStages && (
          <UploadTypeDroppable
            id={id}
            applicationTemplate={applicationTemplate}
            uploadTypeDroppableId={uploadTypeDroppableId}
          />
        )}
      </div>
    </div>
  );
};

ApplicationStageTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  applicationTemplate: PropTypes.object.isRequired,
  formDroppableId: PropTypes.string,
  uploadTypeDroppableId: PropTypes.string,
  showUploadTypesInStages: PropTypes.bool.isRequired,
  showFormsInStages: PropTypes.bool.isRequired,
};

export default ApplicationStageTemplate;
