import { InputText } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import getErrorsForField from "./getErrorsForField";

const { t } = translationWithRoot("admin.integrations", {
  escapeJavascriptRoot: true,
});
// This form is based on lib/json_schemas/integration_configurations/data_export.json
// and in the future, we could likely generate these forms from other JSON schema files
/**
 * DataExportConfigurationForm component renders a form for creating a DataExport integration
 *
 * @param {Object} props.formState - The current state of the form.
 * @param {Function} props.setFormAttribute - Function to update form attributes.
 * @param {Array} props.validationErrors - Array of validation errors.
 */
const GCSCBucketConfigurationSubForm = ({
  formState,
  setFormAttribute,
  validationErrors,
}) => {
  const errorsForField = getErrorsForField(validationErrors);

  return (
    <Fragment>
      <InputText
        label={t("labels.gcs.gcs_bucket_name")}
        value={formState.gcsConfiguration?.gcsBucketName}
        onChange={setFormAttribute("gcsConfiguration.gcsBucketName")}
        error={errorsForField("gcsConfiguration.gcsBucketName")}
        required={true}
      />
      <InputText
        label={t("labels.gcs.gcs_bucket_path")}
        value={formState.gcsConfiguration?.gcsBucketPath}
        onChange={setFormAttribute("gcsConfiguration.gcsBucketPath")}
        error={errorsForField("gcsConfiguration.gcsBucketPath")}
        required={true}
      />
    </Fragment>
  );
};

GCSCBucketConfigurationSubForm.propTypes = {
  formState: PropTypes.object.isRequired,
  setFormAttribute: PropTypes.func.isRequired,
  validationErrors: PropTypes.array.isRequired,
};
export default GCSCBucketConfigurationSubForm;
