import {
  Flex,
  InputCheckboxGroup,
  InputDropdown,
  Surface,
} from "@heart/components";
import {
  fromLegacyCheckboxGroupValue,
  toLegacyCheckboxGroupValue,
} from "@heart/components/inputs/InputCheckboxGroup";
import I18n from "i18n-js";
import { range } from "lodash";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";
import ChildGendersOpenTo from "@components/placement_preferences/ChildGendersOpenTo";

import {
  PLACEMENT_INTEREST_DEFAULT_SLUGS,
  SEX_ASSIGNED_AT_BIRTH_OPTIONS,
  SIBLING_OPTIONS,
  RACES_AND_ETHNICITIES_OPEN_TO_OPTIONS,
  GENDERS_OPEN_TO_OPTIONS,
} from "@root/constants";

const { t } = translationWithRoot(
  "placement_preferences.child_youth_preferences"
);

const noPreferenceEthnicityOption = "no_preference";
const notOpenToSiblingGroupsOption = "not_open_to_sibling_groups";

const ChildYouthPreferences = ({
  demographicsOpenTo: {
    ageLowerOpenTo,
    ageHigherOpenTo,
    gendersOpenTo,
    ethnicitiesOpenTo,
    sexAssignedAtBirthOpenTo,
    numSiblingsOpenTo,
    openToAllGenders,
  },
  onDemographicsOpenToChanged,
  approvedAgeLower,
  approvedAgeHigher,
  onPlacementInterestsChanged,
  placementInterests,
  title,
  subtitle,
}) => {
  const gendersChangeHandler = ({
    gendersOpenToValue,
    openToAllGendersValue,
  }) => {
    onDemographicsOpenToChanged({
      gendersOpenTo: gendersOpenToValue,
      openToAllGenders: openToAllGendersValue,
    });
  };

  return (
    <Surface title={title} subtitle={subtitle}>
      <Flex column gap="300">
        <InputCheckboxGroup
          label={t("placement_interests_question")}
          id="placement_interests"
          name="placement_interests"
          required
          onChange={val =>
            onPlacementInterestsChanged(toLegacyCheckboxGroupValue(val))
          }
          value={fromLegacyCheckboxGroupValue(placementInterests)}
          values={Object.values(PLACEMENT_INTEREST_DEFAULT_SLUGS).map(
            value => ({
              label: I18n.t(["activerecord.models.placement_interest", value]),
              value: value,
            })
          )}
        />
        <ChildGendersOpenTo
          gendersOpenTo={
            /* default to all gender options */
            gendersOpenTo ||
            GENDERS_OPEN_TO_OPTIONS.map(gender =>
              I18n.t(`activerecord.enums.common.gender.${gender}`, {
                locale: "en",
              })
            )
          }
          openToAllGenders={openToAllGenders || "true"}
          requiredInput={true}
          onChangeHandler={gendersChangeHandler}
        />
        <InputCheckboxGroup
          id="sex_assigned_at_birth_open_to"
          name="sex_assigned_at_birth_open_to"
          label={t("sex_assigned_at_birth_open_to_title")}
          values={SEX_ASSIGNED_AT_BIRTH_OPTIONS.map(sex => ({
            /* Label is translated for user */
            label: I18n.t(
              `activerecord.enums.common.sex_assigned_at_birth.${sex}`
            ),
            /* Value is English string. Ticket to use slug instead: ENG-11366  */
            value: I18n.t(
              `activerecord.enums.common.sex_assigned_at_birth.${sex}`,
              {
                locale: "en",
              }
            ),
          }))}
          value={fromLegacyCheckboxGroupValue(sexAssignedAtBirthOpenTo)}
          onChange={value =>
            onDemographicsOpenToChanged({
              sexAssignedAtBirthOpenTo: toLegacyCheckboxGroupValue(value),
            })
          }
        />
        <InputDropdown
          id="age_lower_open_to"
          name="age_lower_open_to"
          label={t("age_lower_open_to_label")}
          values={range(
            approvedAgeLower,
            ageHigherOpenTo || approvedAgeHigher
          ).map(String)}
          value={ageLowerOpenTo || ""}
          onChange={value =>
            onDemographicsOpenToChanged({ ageLowerOpenTo: value })
          }
        />
        <InputDropdown
          id="age_higher_open_to"
          name="age_higher_open_to"
          label={t("age_higher_open_to_label")}
          values={range(
            (Number(ageLowerOpenTo) || approvedAgeLower) + 1,
            approvedAgeHigher + 1
          ).map(String)}
          value={ageHigherOpenTo || ""}
          onChange={value =>
            onDemographicsOpenToChanged({ ageHigherOpenTo: value })
          }
        />
      </Flex>
      <Flex column gap="300">
        <InputCheckboxGroup
          id="num_siblings_open_to"
          name="num_siblings_open_to"
          label={t("num_siblings_open_to_title")}
          values={SIBLING_OPTIONS.map(option => ({
            /* Label is translated for user */
            label: I18n.t(`activerecord.enums.common.siblings.${option}`),
            /* Value is English string. Ticket to use slug instead: ENG-11340  */
            value: I18n.t(`activerecord.enums.common.siblings.${option}`, {
              locale: "en",
            }),
            // if the user selects notOpenToSiblingGroupsOption, then disable all other options
            disabled:
              option !== notOpenToSiblingGroupsOption &&
              numSiblingsOpenTo &&
              numSiblingsOpenTo.includes(
                I18n.t(
                  `activerecord.enums.common.siblings.${notOpenToSiblingGroupsOption}`,
                  {
                    locale: "en",
                  }
                )
              ),
          }))}
          value={fromLegacyCheckboxGroupValue(numSiblingsOpenTo)}
          onChange={value => {
            const legacyVal = toLegacyCheckboxGroupValue(value);
            // if the user selects not_open_to_sibling_groups option,
            // then remove all other selected values
            return legacyVal.includes(
              I18n.t(
                `activerecord.enums.common.siblings.${notOpenToSiblingGroupsOption}`,
                {
                  locale: "en",
                }
              )
            )
              ? onDemographicsOpenToChanged({
                  numSiblingsOpenTo: [
                    I18n.t(
                      `activerecord.enums.common.siblings.${notOpenToSiblingGroupsOption}`,
                      {
                        locale: "en",
                      }
                    ),
                  ],
                })
              : onDemographicsOpenToChanged({ numSiblingsOpenTo: legacyVal });
          }}
        />
        <InputCheckboxGroup
          id="ethnicities_open_to"
          name="ethnicities_open_to"
          label={t("races_and_ethnicities_open_to_title")}
          values={RACES_AND_ETHNICITIES_OPEN_TO_OPTIONS.map(ethnicity => ({
            /* Label is translated for user */
            label: I18n.t(
              `activerecord.enums.common.ethnicities.${ethnicity}.label`
            ),
            /* Value is English string. Ticket to use slug instead: ENG-11325  */
            value: I18n.t(
              `activerecord.enums.common.ethnicities.${ethnicity}.label`,
              {
                locale: "en",
              }
            ),
            // if the user selects noPreferenceEthnicityOption, then disable all other options
            disabled:
              ethnicity !== noPreferenceEthnicityOption &&
              ethnicitiesOpenTo &&
              ethnicitiesOpenTo.includes(
                I18n.t(
                  `activerecord.enums.common.ethnicities.${noPreferenceEthnicityOption}.label`,
                  {
                    locale: "en",
                  }
                )
              ),
          }))}
          value={fromLegacyCheckboxGroupValue(ethnicitiesOpenTo)}
          onChange={value => {
            const legacyVal = toLegacyCheckboxGroupValue(value);

            // if the user selects no_preference option,
            // then remove all other selected values
            return legacyVal.includes(
              I18n.t(
                `activerecord.enums.common.ethnicities.${noPreferenceEthnicityOption}.label`,
                {
                  locale: "en",
                }
              )
            )
              ? onDemographicsOpenToChanged({
                  ethnicitiesOpenTo: [
                    I18n.t(
                      `activerecord.enums.common.ethnicities.${noPreferenceEthnicityOption}.label`,
                      {
                        locale: "en",
                      }
                    ),
                  ],
                })
              : onDemographicsOpenToChanged({ ethnicitiesOpenTo: legacyVal });
          }}
        />
      </Flex>
    </Surface>
  );
};

ChildYouthPreferences.propTypes = {
  demographicsOpenTo: PropTypes.shape({
    ageLowerOpenTo: PropTypes.string,
    ageHigherOpenTo: PropTypes.string,
    gendersOpenTo: PropTypes.arrayOf(PropTypes.string),
    ethnicitiesOpenTo: PropTypes.arrayOf(PropTypes.string),
    sexAssignedAtBirthOpenTo: PropTypes.arrayOf(PropTypes.string),
    numSiblingsOpenTo: PropTypes.arrayOf(PropTypes.string),
    openToAllGenders: PropTypes.string,
  }).isRequired,
  onDemographicsOpenToChanged: PropTypes.func.isRequired,
  approvedAgeLower: PropTypes.number.isRequired,
  approvedAgeHigher: PropTypes.number.isRequired,
  onPlacementInterestsChanged: PropTypes.func.isRequired,
  placementInterests: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default ChildYouthPreferences;
