import { Avatar, Flex, Text } from "@heart/components";
import Clickable from "@heart/components/clickable/Clickable";
import classNames from "classnames";
import PropTypes from "prop-types";

import {
  determineName,
  determineRelationshipTypeForTable,
} from "../relationships/sortRelationships";
import styles from "./Connection.module.scss";

/** A card representing someone in a child's genogram */
const Connection = ({
  keystoneAgencyHumanId,
  relationship = {},
  setRelationshipToView,
}) => {
  const { name, firstName, lastName } = determineName({
    keystoneAgencyHumanId,
    ...relationship,
  });
  return (
    <Clickable
      onClick={() => setRelationshipToView(relationship)}
      buttonClassname={styles.clickableSurface}
    >
      <Flex className={styles.container}>
        <Flex
          column
          className={classNames(styles.connection, styles.card)}
          align="center"
        >
          <Avatar firstName={firstName} lastName={lastName} size={600} />
          <Text textStyle="emphasis-100">{name}</Text>
          <Text textStyle="body-100">
            {determineRelationshipTypeForTable(relationship)}
          </Text>
        </Flex>
      </Flex>
    </Clickable>
  );
};
Connection.propTypes = {
  keystoneAgencyHumanId: PropTypes.number.isRequired,
  relationship: PropTypes.shape({
    lineageType: PropTypes.string,
    kinshipRelationship: PropTypes.string,
    kinshipRelationshipOtherDescription: PropTypes.string,
    fictiveKinDescription: PropTypes.string,
  }),
  setRelationshipToView: PropTypes.func.isRequired,
};

export default Connection;
