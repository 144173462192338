/* eslint-disable react/prop-types */

/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { getStraightPath, useInternalNode } from "@xyflow/react";

import { getEdgeParams } from "./FloatingEdgeUtils.js";

/** Pulled directly from reactflow's Floating Edges example, which
 * is why the code style doesn't match the rest of our codebase
 *
 * This edge will dynamically position itself around the centers of
 * the nodes it is connected to
 */
function FloatingEdge({ id, source, target, markerEnd, style }) {
  const sourceNode = useInternalNode(source);
  const targetNode = useInternalNode(target);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode
  );

  const [edgePath] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  return (
    <path
      id={id}
      className="react-flow__edge-path"
      d={edgePath}
      markerEnd={markerEnd}
      style={style}
    />
  );
}

export default FloatingEdge;
