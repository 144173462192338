// this file manages the state of the inline editting component
// it switches back and forth between the list view and the editting form view
import { useQuery } from "@apollo/client";
import { Button, Flex, Icons, LoadingOverlay } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { translationWithRoot } from "@components/T";

import PhoneNumbersByUser from "@graphql/queries/PhoneNumbersByUser";

import preventDefault from "@lib/preventDefault";

import CreatePhoneNumber from "./CreatePhoneNumber";
import EditPhoneNumbers from "./EditPhoneNumbers";
import PhoneNumberList from "./PhoneNumberList";

const { t, T } = translationWithRoot("phone_numbers.phone_numbers");

/**
 * PhoneNumbers component manages the state of the inline editing component.
 * It switches back and forth between the list view and the editing form view.
 *
 * @param {boolean} displayEdit - Flag to display the edit view.
 * @param {string} currentUrl - The current URL.
 * @param {string} personType - The type of person (e.g., user, admin).
 * @param {string} personId - The ID of the person.
 * @param {string} phoneNumberEditLink - The link for editing phone numbers.
 * @param {boolean} showPhoneNumberEditLink - Flag to show the phone number edit link.
 */
const PhoneNumbers = ({
  displayEdit,
  personType,
  personId,
  showPhoneNumberEditLink,
}) => {
  const { data, loading } = useQuery(PhoneNumbersByUser, {
    variables: { personType, personId },
  });
  const [showEdit, setShowEdit] = useState(displayEdit);
  const [showCreatePhoneNumberForm, setShowCreatePhoneNumberForm] =
    useState(false);

  const handleSuccess = () => {
    setShowEdit(false);
  };
  const handleCancel = preventDefault(() => {
    setShowEdit(false);
  });
  const handleEditClick = preventDefault(() => {
    setShowEdit(true);
  });

  return (
    <LoadingOverlay active={loading}>
      {showEdit ? (
        <Flex align="start" justify="space-between">
          <EditPhoneNumbers
            personType={personType}
            personId={personId}
            handleSuccess={handleSuccess}
            handleCancel={handleCancel}
          />
          <Icons.Times
            description={t("exit_edit_mode")}
            onClick={handleCancel}
          />
        </Flex>
      ) : (
        <Fragment>
          <Flex align="start" justify="space-between">
            <PhoneNumberList personId={personId} personType={personType} />
            <If
              condition={
                !loading &&
                showPhoneNumberEditLink &&
                data?.phoneNumbers?.length
              }
            >
              <Icons.Pencil description={t("edit")} onClick={handleEditClick} />
            </If>
          </Flex>

          {showCreatePhoneNumberForm ? (
            <CreatePhoneNumber
              personId={personId}
              personType={personType}
              handleSuccess={() => setShowCreatePhoneNumberForm(false)}
              onCancel={() => setShowCreatePhoneNumberForm(false)}
            />
          ) : (
            <Button
              variant="secondary"
              onClick={() => setShowCreatePhoneNumberForm(true)}
              icon={Icons.Plus}
            >
              <T t="add" />
            </Button>
          )}
        </Fragment>
      )}
    </LoadingOverlay>
  );
};

PhoneNumbers.propTypes = {
  personId: PropTypes.string.isRequired,
  personType: PropTypes.string.isRequired,
  displayEdit: PropTypes.bool,
  showPhoneNumberEditLink: PropTypes.bool,
};

export default PhoneNumbers;
