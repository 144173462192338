import { Button } from "@heart/components";
import PropTypes from "prop-types";

import styles from "./Photo.module.scss";

/**
 * Photo component to display a photo with a delete button.
 *
 * @param {Function} props.onDelete - Function to call when delete button is clicked.
 * @param {Object} props.photo - Photo object containing id, filePath, and fileName.
 * @param {boolean} props.isDeleteDisabled - Flag to disable the delete button.
 */
const Photo = ({ onDelete, photo, isDeleteDisabled }) => {
  const { id, filePath, fileName } = photo;

  return (
    <div key={id} className={styles.photoContainer}>
      <Button disabled={isDeleteDisabled} onClick={() => onDelete(id)}>
        Delete
      </Button>
      <img src={filePath} alt={fileName} />
    </div>
  );
};

Photo.propTypes = {
  onDelete: PropTypes.func.isRequired,
  photo: PropTypes.object.isRequired,
  isDeleteDisabled: PropTypes.bool.isRequired,
};

export default Photo;
