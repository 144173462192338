import I18n from "i18n-js";
import { toast as toastifyToast } from "react-toastify";

import ToastMessage from "./ToastMessage";

const toast = ({ type, title, message, ...toastifyOptions }) =>
  toastifyToast(
    <ToastMessage title={title} message={message} toastProps={{ type }} />,
    {
      type,
      ...toastifyOptions,
    }
  );

const setSessionStorageItem = type => message =>
  sessionStorage.setItem("flash", JSON.stringify([{ type, message }]));

/** Use these functions to create runtime notifications!
 * See `<ToastContainer>` / storybook for documentation. */
const toastFunctions = {
  informative: args =>
    toast({
      title: I18n.t("javascript.components.toast.status.informative"),
      ...args,
      type: "info",
    }),
  success: args =>
    toast({
      title: I18n.t("javascript.components.toast.status.success"),
      ...args,
      type: "success",
    }),
  notable: args =>
    toast({
      title: I18n.t("javascript.components.toast.status.notable"),
      ...args,
      type: "warning",
      autoClose: false,
    }),
  negative: args =>
    toast({
      title: I18n.t("javascript.components.toast.status.negative"),
      ...args,
      type: "error",
      autoClose: false,
    }),
  onNextPageLoad: {
    informative: setSessionStorageItem("info"),
    success: setSessionStorageItem("success"),
    notable: setSessionStorageItem("warning"),
    negative: setSessionStorageItem("error"),
  },
};

export default toastFunctions;
export const TOAST_TYPES = Object.keys(toastFunctions);
