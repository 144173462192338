import { Flex, Icons, Text } from "@heart/components";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";

import ModalTitle from "../ModalTitle";
import ModalWrapper from "../ModalWrapper";
import styles from "./ModalNotice.module.scss";

/** Our notice component designed to be used when further
 * actions from the modal are available - e.g. links to other pages.
 *
 * Using a modal allows us to accessibly give folks access to those
 * actions while still indicating that the action taken previously
 * was successful.
 *
 * By default, this component displays a green checkmark to indicate success.
 * Change the `icon` prop to `"error"` to instead display a red X to indicate
 * an error.
 */
const ModalNotice = ({
  icon = "success",
  title,
  children,
  hidden,
  onClose,
  "data-testid": testId,
}) => {
  const generatedId = uniqueId("modalNotice");
  return (
    <ModalWrapper
      modalTitleId={generatedId}
      onCancel={onClose}
      hidden={hidden}
      data-testid={testId}
    >
      <Flex column className={styles.modal}>
        <Flex justify="end">
          <Icons.Times description="Close Modal" onClick={onClose} />
        </Flex>
        <Flex column align="center">
          <If condition={icon === "success"}>
            <Text textColor="map-green-200" className={styles.icon}>
              {/* Hiding the icon from assistive tech, as it's not useful to folks */}
              <Icons.CheckCircle aria-hidden={true} />
            </Text>
          </If>
          <If condition={icon === "error"}>
            <Text textColor="danger-500" className={styles.icon}>
              {/* Hiding the icon from assistive tech, as it's not useful to folks */}
              <Icons.TimesCircle aria-hidden={true} />
            </Text>
          </If>
          <ModalTitle title={title} id={`modal-header-${generatedId}`} />
          {children}
        </Flex>
      </Flex>
    </ModalWrapper>
  );
};
ModalNotice.propTypes = {
  /** Modal contents */
  children: PropTypes.node,
  /** Action to take when the "x" button is clicked */
  onClose: PropTypes.func,
  /** Whether modal should be hidden or not */
  hidden: PropTypes.bool.isRequired,
  /** Title of modal */
  title: PropTypes.string.isRequired,
  /** Choice of Icon. One of "success" or "error" */
  icon: PropTypes.oneOf(["success", "error"]),
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};

export default ModalNotice;
