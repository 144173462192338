import { VolumeUp } from "@heart/components/icon/Icon";
import classNames from "classnames";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import preventDefault from "@lib/preventDefault";

import TextToSpeechContext from "./TextToSpeechContext";
import styles from "./VoiceChat.module.scss";
import { ASSISTANT, USER } from "./common";

/**
 * A chat message as displayed in the chat history.
 */
const ChatMessage = ({
  role,
  message,
  inputAudio,
  responseAudio,
  timestamp,
  startPlaying = false,
  debugMode = false,
  "data-testid": dataTestId,
}) => {
  const [audioPlaying, setAudioPlaying] = useState(false);
  const inputAudioUrl = useMemo(() => {
    if (inputAudio instanceof File) {
      return URL.createObjectURL(inputAudio);
    }

    return undefined;
  }, [inputAudio]);
  const today = DateTime.now().startOf("day");
  const dateTime = DateTime.fromISO(timestamp);

  const displayTimestamp =
    dateTime.startOf("day") === today
      ? dateTime.toLocaleTimeString()
      : dateTime.toLocaleString(DateTime.DATETIME_SHORT);

  const { speak, stopSpeaking } = useContext(TextToSpeechContext);

  const playAudio = useCallback(async () => {
    setAudioPlaying(true);

    await speak({ text: message, audioFile: responseAudio });

    setAudioPlaying(false);
  }, [message, responseAudio, speak]);

  const onClick = preventDefault(() => {
    if (audioPlaying) {
      setAudioPlaying(false);
      stopSpeaking();
    } else {
      playAudio();
    }
  });

  useEffect(() => {
    if (startPlaying) {
      playAudio();
    }
  }, [startPlaying, playAudio]);

  return (
    <div className={styles[role]} data-testid={dataTestId}>
      <div className={styles.chatMessage}>
        {message}
        <If condition={role === ASSISTANT}>
          <button
            onClick={onClick}
            className={classNames(styles.assistantVoiceButton, {
              [styles.playing]: audioPlaying,
            })}
          >
            <VolumeUp />
          </button>
        </If>
        <If condition={role === USER && inputAudioUrl && debugMode}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio controls>
            <source src={inputAudioUrl} type="audio/mp4" />
          </audio>
        </If>
      </div>
      <div className={styles.chatTimestamp}>{displayTimestamp}</div>
    </div>
  );
};

ChatMessage.propTypes = {
  role: PropTypes.oneOf([USER, ASSISTANT]).isRequired,
  message: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  startPlaying: PropTypes.bool,
  inputAudio: PropTypes.object,
  responseAudio: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  debugMode: PropTypes.bool,
  "data-testid": PropTypes.string,
};

export default ChatMessage;
