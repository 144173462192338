import { Flex, Icons, Menu } from "@heart/components";
import Avatar from "@heart/components/avatar/Avatar";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import styles from "./Header.module.scss";
import headerPropTypes from "./headerPropTypes";

const { t: headerT } = translationWithRoot("admin.header_links", {
  escapeJavascriptRoot: true,
});

const toggleWhatsNew = () => {
  // `announcekit` is a global variable, provided by the script in the footer.
  // The bundler doesn't know about it, but it works in the browser just fine.
  // eslint-disable-next-line no-undef
  announcekit.widget$newatbinti.open();
};

/**
 * This is simply a container for the things we want on the right side of the header
 */
const HeaderRight = ({
  user: { firstName, lastName, name },
  phantomModeEnabled,
  secondaryLinks,
  showSearchIcon,
  onSearchIconClicked,
  microphoneLink,
}) => (
  <Flex>
    <If condition={showSearchIcon}>
      <Icons.Search
        data-testid="header-search-button"
        className={styles.headerButton}
        onClick={() => onSearchIconClicked(true)}
        description={I18n.t("admin.common.search_names_header")}
      />
    </If>
    <If condition={!isEmpty(microphoneLink)}>
      <Icons.Microphone
        data-testid="header-microphone-link"
        className={styles.headerIconLink}
        href={microphoneLink}
        description={I18n.t("common.voice_navigation")}
      />
    </If>
    <Menu
      rightAligned
      menuDescription={`${name}'s Actions`} // TODO ENG-14960
      buttonComponent={({ isOpen, ...buttonProps }) => (
        <Fragment>
          <If condition={phantomModeEnabled}>
            <button
              className={styles.unstyledButton}
              aria-label={buttonProps.description}
              title={buttonProps.description}
              {...buttonProps}
            >
              <div
                className={styles.phantomIcon}
                aria-label="Phantom Mode" // TODO ENG-14960
              />
            </button>
          </If>
          <If condition={!phantomModeEnabled}>
            <Avatar
              size={500}
              firstName={firstName}
              lastName={lastName}
              {...buttonProps}
            />
          </If>
        </Fragment>
      )}
      linkItems={secondaryLinks.map(link => ({
        description:
          link.key === "stop_masquerading_as"
            ? headerT(link.key, { user: name })
            : headerT(link.key),
        href: link.url,
        "data-method": {
          toggle_phantom_mode: "patch",
          logout: "delete",
        }[link.key],
        onClick: link.key === "whats_new_at_binti" ? toggleWhatsNew : undefined,
      }))}
      data-testid="header-profile-menu"
    />
  </Flex>
);

HeaderRight.propTypes = {
  user: headerPropTypes.userPropType,
  phantomModeEnabled: PropTypes.bool,
  secondaryLinks: PropTypes.arrayOf(headerPropTypes.linkPropType),
  showSearchIcon: PropTypes.bool,
  onSearchIconClicked: PropTypes.func,
  /**
   * If provided, the Binti Blue (voice navigation/transcription) link
   * will be displayed in the header.
   */
  microphoneLink: PropTypes.string,
};

export default HeaderRight;
