import { Link } from "@heart/components";

import { translationWithRoot } from "@components/T";

import { formatDateTimeAsShortDate } from "@lib/dates";
import { policy } from "@lib/graphqlHelpers";

const { t } = translationWithRoot("family_finding.documents.documents_table");

export const getUploadDateColumnIndex = ({ displayAssociatedKin }) =>
  displayAssociatedKin ? 3 : 2;

// This is used to get select options for the associatedKin and uploadedBy filters
export const getNameSelectOptions = (documents, entityKey, nameKey) => {
  const uniqueIds = [];
  const uniqueOptions = [];
  documents.forEach(document => {
    // Some documents may have the same values, so we need
    // to filter out duplicates for the select options
    if (!uniqueIds.includes(document[entityKey].id)) {
      uniqueIds.push(document[entityKey].id);
      uniqueOptions.push({
        label: document[entityKey][nameKey],
        value: document[entityKey].id,
      });
    }
  });
  return uniqueOptions;
};

const tableColumns = ({ displayAssociatedKin, documents = [] }) =>
  [
    {
      columnName: {
        name: t("title"),
      },
      id: "title",
      cell: "title",
      columnSort: "title",
      filter: {
        type: "search",
        filterBy: "title",
        label: t("filters.search_document_titles"),
        topLevel: true,
      },
    },
    {
      columnName: { name: t("attachment") },
      id: "attachment",
      cell: ({ fileName, fileUrl, permissions }) =>
        policy({ permissions }).mayDownloadAttachment() ? (
          <Link href={fileUrl} rel="noopener noreferrer" target="_blank">
            {fileName}
          </Link>
        ) : (
          fileName
        ),
    },
    displayAssociatedKin && {
      columnName: { name: t("associated_kin") },
      id: "associatedKin",
      cell: "primaryAgencyHuman.fullName",
      filter: {
        type: "select",
        filterBy: "primaryAgencyHuman.id",
        label: t("associated_kin"),
        values: getNameSelectOptions(
          documents,
          "primaryAgencyHuman",
          "fullName"
        ),
      },
    },
    {
      columnName: { name: t("upload_date") },
      id: "uploadDate",
      columnSort: "createdAt",
      cell: ({ createdAt }) => formatDateTimeAsShortDate(createdAt),
      filter: {
        type: "custom_dates",
        label: t("upload_date"),
        filterBy: "createdAt",
        customDatesLabels: {
          start: t("filters.uploaded_after"),
          end: t("filters.uploaded_before"),
        },
      },
    },
    {
      columnName: {
        name: t("uploaded_by"),
        justify: "end",
      },
      id: "uploadedBy",
      cell: "creator.name",
      filter: {
        type: "select",
        filterBy: "creator.id",
        label: t("uploaded_by"),
        values: getNameSelectOptions(documents, "creator", "name"),
      },
    },
  ].filter(Boolean);

export default tableColumns;
