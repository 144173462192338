import { BintiAssistIcon } from "@heart/components";
import { DateTime } from "luxon";
import { If } from "tsx-control-statements/components";

import styles from "./TextChat.module.scss";
import { MessageProps, Role } from "./common";

/**
 * A chat message as displayed in the chat history.
 */
const ChatMessage = ({
  role,
  message,
  timestamp,
  "data-testid": dataTestId,
}: MessageProps & { "data-testid"?: string }) => {
  const today = DateTime.now().startOf("day");
  const dateTime = DateTime.fromISO(timestamp);

  const displayTimestamp =
    dateTime.startOf("day") === today
      ? dateTime.toLocaleString(DateTime.TIME_SIMPLE)
      : dateTime.toLocaleString(DateTime.DATETIME_SHORT);

  return (
    <div className={styles[role]} data-testid={dataTestId} data-role={role}>
      <div className={styles.chatMessage}>{message}</div>
      <div className={styles.chatTimestamp}>
        {displayTimestamp}
        <If condition={role === Role.ASSISTANT}>
          {" - "}
          <span className={styles.lineHeightIcon}>
            <BintiAssistIcon />
          </span>
          Binti AI
        </If>
      </div>
    </div>
  );
};

export default ChatMessage;
