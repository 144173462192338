import { useQuery, useMutation } from "@apollo/client";
import {
  Actions,
  Alert,
  Flex,
  InputDate,
  InputTextarea,
  MultiInputTemplate,
  SurfaceFieldset,
  SurfaceForm,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import _ from "lodash";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { preventionAgencyServiceReferralsPath } from "routes";

import { translationWithRoot } from "@components/T";
import SearchableHumanInput from "@components/prevention/agency_service_referrals/form/SearchableHumanInput";
import ServiceAndRateInput from "@components/prevention/agency_service_referrals/form/ServiceAndRateInput";

import { CreateAgencyServiceReferral } from "@graphql/mutations/prevention/CreateAgencyServiceReferral.graphql";
import { AgencyServicesForReferral } from "@graphql/queries/prevention/AgencyServicesForReferral.graphql";

import preventDefault from "@lib/preventDefault";

const { t } = translationWithRoot(
  "prevention.agency_service_referrals.form_page"
);

/**
 * @param {props}
 * @param {props.referringAgencyId} the id of the referring agency
 * @returns a form for making a service referral
 */
const AgencyServiceReferralsForm = ({ referringAgencyId }) => {
  const { data: servicesData } = useQuery(AgencyServicesForReferral, {
    variables: { referringAgencyId },
  });

  const { formState, setFormAttribute } = useBintiForm(null, {
    initialValue: {
      referringAgencyId,
      childrenWithPlans: [],
      recipients: [],
      service: {},
      serviceRates: [],
    },
  });

  const multiUpdate = value => {
    if (!_.isEqual(value, formState.recipients))
      setFormAttribute("recipients")(value);
  };

  const [missingRecipientError, setMissingRecipientError] = useState(null);
  const checkForErrors = sections => {
    if (sections.length === 0) {
      setMissingRecipientError(t("errors.missing_recipient"));
    } else {
      setMissingRecipientError(null);
    }
  };

  const [
    createReferral,
    { loading: creatingReferral, called, error, data, reset },
  ] = useMutation(CreateAgencyServiceReferral);
  let errorMessage = null;

  if (called) {
    const referral = data?.createPreventionAgencyServiceReferral;
    const referralErrors = referral?.errors;

    if (error) {
      errorMessage = "Error creating referral please contact Binti Support.";
    } else if (referralErrors) {
      errorMessage = `${referralErrors.join("\n")}`;
    } else if (referral?.agencyServiceReferral?.id) {
      window.location.assign(preventionAgencyServiceReferralsPath());
    }
  }

  const onSubmit = preventDefault(() => {
    const variables = {
      recipients: formState.recipients.map(
        ({ recipient, startDate, endDate }) => ({
          id: recipient.value,
          startDate,
          endDate,
        })
      ),
      childrenWithPlanIds: formState.childrenWithPlans.map(
        child => child.value
      ),
      agencyServiceId: formState.service.value,
      agencyServiceRateIds: formState.serviceRates.map(rate => rate.value),
      notes: formState.notes,
      agencyId: referringAgencyId,
    };
    createReferral({ variables });
  });

  return (
    <SurfaceForm
      title={""}
      actions={
        <Actions isSubmitting={creatingReferral} primaryAction={onSubmit} />
      }
    >
      <Flex column fullWidth>
        {/* Alert expects the children to be non-null, even if it is closed. */}
        <If condition={errorMessage}>
          <Alert
            isAlert={true}
            hidden={!errorMessage}
            title={"Error creating Service Referral"}
            onSubmit={() => reset()}
            submitText={"Ok"}
          >
            {errorMessage}
          </Alert>
        </If>
        <ServiceAndRateInput
          services={servicesData?.preventionAgencyServices || []}
          onServiceChange={service => {
            setFormAttribute("service")(service);
            setFormAttribute("serviceRates")([]);
          }}
          onRateChange={rate => setFormAttribute("serviceRates")(rate)}
          selectedService={formState.service}
          selectedRates={formState.serviceRates}
        />
        <InputTextarea
          value={formState.notes}
          label={t("notes")}
          onChange={notes => setFormAttribute("notes")(notes)}
        />
        <SurfaceFieldset title={t("recipients")}>
          <MultiInputTemplate
            hideTitle
            title={""}
            error={missingRecipientError}
            checkForErrors={checkForErrors}
            addSectionText={t("add_recipient")}
            defaultValues={{}}
            onChange={multiUpdate}
            onDelete={() => {}}
            removeSectionText={t("remove_recipient")}
            startingValues={[]}
            data-testid={"agency-service-referral-recipient-inputs"}
            sectionInputs={({ getMultiInputProps }) => {
              const inputProps = getMultiInputProps({ id: "recipient" });

              return (
                <Fragment>
                  <SearchableHumanInput
                    {...inputProps}
                    label={t("recipient")}
                    required
                  />
                  <InputDate
                    {...getMultiInputProps({ id: "startDate" })}
                    label={t("start_date")}
                    required
                  />
                  <InputDate
                    {...getMultiInputProps({ id: "endDate" })}
                    label={t("end_date")}
                    required
                  />
                </Fragment>
              );
            }}
          ></MultiInputTemplate>
        </SurfaceFieldset>
      </Flex>
    </SurfaceForm>
  );
};

AgencyServiceReferralsForm.propTypes = {
  referringAgencyId: PropTypes.string,
};

export default AgencyServiceReferralsForm;
