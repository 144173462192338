import { InputAddress } from "@heart/components";
import {
  propTypes as InputAddressPropTypes,
  valueProps,
} from "@heart/components/inputs/InputAddress";
import { omit } from "lodash";
import PropTypes from "prop-types";

import { AGENCY_HUMAN_ADDRESS_TYPES } from "@root/constants";

/** ### Usage
 *
 * A wrapper to display a simplified version of InputAddress. It just
 *    - Hides all non-location-related fields, including the primary checkbox, use
 *      as mailing checkbox, type dropdown, and inactive checkbox.
 *    - Wrangles props :)
 */
export const SimplifiedInputAddress = props => {
  const passThroughProps = {
    ...props,
    allowedAddressTypes: [props.addressType],
    disablePrimaryCheckbox: true,
    hideInactiveCheckbox: true,
    hideTypes: true,
    hideUseAsMailing: true,
    hidePrimaryCheckbox: true,
  };

  passThroughProps.value = {
    ...(passThroughProps.value || {}),
    type: [props.addressType],
  };

  passThroughProps.value.addressLine1 ||= "";
  passThroughProps.value.countryCode ||= "";
  passThroughProps.value.postalCode ||= "";
  passThroughProps.value.primary ||= true;
  passThroughProps.value.inactive ||= false;

  return <InputAddress {...passThroughProps} />;
};

const passThroughPropTypes = omit(
  InputAddressPropTypes,
  "allowedAddressTypes", // you may only pass one
  "disablePrimaryCheckbox", // we hide all metadata fields with this component.
  "hideInactiveCheckbox",
  "hideTypes",
  "hideUseAsMailing",
  "hidePrimaryCheckbox",
  "value" // We allow, but do not require, value
);

/**
 * For props see the InputAddress props, however the above `omit`ed props are ignored
 * and the addressType prop is required (only one type is allowed for this component).
 */
SimplifiedInputAddress.propTypes = {
  ...passThroughPropTypes,
  addressType: PropTypes.oneOf(AGENCY_HUMAN_ADDRESS_TYPES).isRequired,
  value: valueProps,
};

export default SimplifiedInputAddress;
