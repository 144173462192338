import { Button, Flex, InputCheckboxGroup, Text } from "@heart/components";
import {
  fromLegacyCheckboxGroupValue,
  toLegacyCheckboxGroupValue,
} from "@heart/components/inputs/InputCheckboxGroup";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";
import EmailDraft from "@components/availability_request/EmailDraft";
import SMSDraft from "@components/availability_request/SMSDraft";

import styles from "./AvailabilityRequest.module.scss";
import RecipientsTable from "./RecipientsTable";

const { t, T } = translationWithRoot("availability_request.draft_container");

const DraftContainer = ({
  agencyPlacements,
  onCancelRedirectTo,
  emailText,
  SMSText,
  channels,
  onPreview,
  setSMSText,
  setEmailText,
  setChannels,
  smsDisabled,
}) => {
  const onCancel = () => {
    window.location.replace(onCancelRedirectTo);
  };

  // For some agency placements, we don't ever want to send SMS messages.
  const smsDisabledAlways = smsDisabled;

  const canPreview = () => {
    const anyValidNumber = agencyPlacements.some(
      ap => ap.mobilePhoneNumber && !ap.optedOut
    );

    const anyValidEmail = agencyPlacements.some(ap => ap.email);

    const channelValidators = { email: anyValidEmail, sms: anyValidNumber };
    const channelIsValid = channel => channelValidators[channel];
    return channels.every(channelIsValid);
  };

  return (
    <div className={styles.flexDiv}>
      <div className={styles.recipientsTable}>
        <RecipientsTable
          agencyPlacements={agencyPlacements}
          showEmail={channels.includes("email")}
          showMobilePhoneNumber={!smsDisabledAlways && channels.includes("sms")}
        />
      </div>
      <section>
        <InputCheckboxGroup
          id="availability_request_channel_options"
          label={t("title")}
          value={fromLegacyCheckboxGroupValue(channels)}
          values={[
            {
              value: "email",
              label: t("email"),
            },
            {
              value: "sms",
              label: t("sms"),
              disabled: smsDisabledAlways,
            },
          ]}
          name="availability_request_channel_options"
          onChange={val => setChannels(toLegacyCheckboxGroupValue(val))}
        />
        <If condition={channels.includes("sms")}>
          <p>
            <T t="sms_one_way" />
          </p>
        </If>
      </section>
      <If condition={channels.includes("sms")}>
        <SMSDraft setSMSText={setSMSText} SMSText={SMSText} />
      </If>
      <If condition={channels.includes("email")}>
        <EmailDraft emailText={emailText} setEmailText={setEmailText} />
      </If>
      <Text as="p" textStyle="emphasis-100">
        <T t="privacy_reminder_html" />
      </Text>
      <Flex row>
        <Button
          disabled={channels.length === 0 || !canPreview()}
          onClick={onPreview}
        >
          {channels.length > 1 ? t("preview_messages") : t("preview_message")}
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          <T t="cancel" />
        </Button>
      </Flex>
    </div>
  );
};
DraftContainer.propTypes = {
  agencyPlacements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      email: PropTypes.string,
      mobilePhoneNumber: PropTypes.string,
      optedOut: PropTypes.bool,
    })
  ).isRequired,
  channels: PropTypes.arrayOf(PropTypes.oneOf(["email", "sms"])).isRequired,
  onCancelRedirectTo: PropTypes.string.isRequired,
  onPreview: PropTypes.func.isRequired,
  emailText: PropTypes.string.isRequired,
  SMSText: PropTypes.string.isRequired,
  setSMSText: PropTypes.func.isRequired,
  setEmailText: PropTypes.func.isRequired,
  setChannels: PropTypes.func.isRequired,
  smsDisabled: PropTypes.bool.isRequired,
};

export default DraftContainer;
