import { Flex } from "@heart/components";
import classNames from "classnames";
import noScroll from "no-scroll";
import PropTypes from "prop-types";
import React from "react";
import ReactModal from "react-modal";

import styles from "./ModalWrapper.module.scss";

const ModalWrapper = ({
  modalTitleId,
  children,
  hidden,
  isAlert = false,
  onCancel,
  "data-testid": testId,
  fullScreen,
  ...wrapperProps
}) => (
  <ReactModal
    isOpen={!hidden}
    className={styles.reactModal}
    overlayClassName={styles.modalOverlay}
    onAfterOpen={noScroll.on}
    onAfterClose={noScroll.off}
    onRequestClose={onCancel}
    role={isAlert ? "alertdialog" : "dialog"}
    aria={{ labelledby: `modal-header-${modalTitleId}` }}
  >
    <Flex
      row
      justify="center"
      align="center"
      className={styles.modalContainer}
      data-testid={testId}
      data-heart-component="Modal"
      {...wrapperProps}
    >
      <div
        className={classNames(styles.modal, {
          [styles.fullScreen]: fullScreen,
        })}
      >
        {children}
      </div>
    </Flex>
  </ReactModal>
);

ModalWrapper.propTypes = {
  /** An id for the modal title */
  modalTitleId: PropTypes.string,
  /** Modal contents */
  children: PropTypes.node.isRequired,
  /** Whether modal should be hidden or not */
  hidden: PropTypes.bool.isRequired,
  /** Whether the modal is an alert, as opposed to informational or
   * interactive
   */
  isAlert: PropTypes.bool,
  /** Action to take when cancel button is clicked */
  onCancel: PropTypes.func,
  /** Anti-pattern prop! Please use sparingly if at all
   *
   * Allows the modal to expand to cover the entirety of the screen. If you
   * need a modal this big, it's highly likely the information in the modal
   * should just be a separate page.
   */
  fullScreen: PropTypes.bool,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};
export default ModalWrapper;
