import { useQuery } from "@apollo/client";
import { times, values } from "lodash";
import PropTypes from "prop-types";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { Document } from "react-pdf/dist/entry.webpack";

import T from "@components/T";

import SigningEvent from "@graphql/queries/SigningEvent";

import BintiSignLoadingLogo from "@sprockets/images/bintisign-loading-logo.gif";

import styles from "../BintiSign.module.scss";
import BintiSignHeader from "./BintiSignHeader";
import SignaturePage from "./document/SignaturePage";
import SignaturePane from "./sidebar/SignaturePane";
import {
  findPageNumberWithNextAction,
  locationsForSignerRoleByPage,
  signatureLocationsByPage,
} from "./signingEventConverter";

const DEFAULT_PDF_WIDTH = 612;

const calcPageWidth = () => Math.min(DEFAULT_PDF_WIDTH, window.innerWidth);

const LoadingComponent = () => (
  <div className={styles.loading}>
    <div>
      <img src={BintiSignLoadingLogo} alt="" role="presentation" />
    </div>
    <h2>
      <T t="bintisign.bintisign.loading_your_document" />
    </h2>
  </div>
);

// Boiled down from the code example here
// https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
const base64ToBuffer = base64 =>
  Uint8Array.from(atob(base64), m => m.codePointAt(0));

/**
 * The BintiSign displays the content of the modal with the document and
 * side panel with a place to input your signature.
 *
 * This display is dependent not only on the document, but who the current
 * user is and for which role they are signing.
 */
const BintiSign = ({ onClose, signingEvent, activeSigner, setIsModalOpen }) => {
  // reload the signing event with the pdf contents only when some
  // actually clicks to sign. This query will populate the pdfContents
  // in the cache which will flow down in the `signingEvent` prop,
  // so we don't actually need to look at the return value of this
  // query. Note that this also sends updated locations in case
  // the rendering engine changed.
  useQuery(SigningEvent, {
    variables: { id: signingEvent.id, generatePdfContents: true },
  });

  // signingEvent.pdfContents is base64. Decode it once in a memo.
  const file = useMemo(
    () =>
      signingEvent.pdfContents && {
        // cache the whole file object, not just the data or else
        // the Document will reload continuously
        // https://github.com/wojtekmaj/react-pdf/issues/308#issuecomment-443538284
        data: base64ToBuffer(signingEvent.pdfContents),
      },
    [signingEvent.pdfContents]
  );
  const [numPages, setNumPages] = useState(0);
  const [pageRefs, setPageRefs] = useState([]);
  const [pageWidth, setPageWidth] = useState(() => calcPageWidth());
  const documentRef = useRef();
  const headerRef = useRef();

  const onLoadSuccess = ({ numPages: loadedNumPages }) => {
    setNumPages(loadedNumPages);

    window.addEventListener("resize", () => {
      setPageWidth(calcPageWidth());
    });
  };

  useEffect(() => {
    setPageRefs(times(numPages, () => createRef()));
  }, [numPages]);

  const scrollTo = ref => {
    if (!documentRef?.current || !ref?.current || !headerRef?.current) return;

    documentRef.current.scrollTop =
      ref.current.offsetTop - headerRef.current.offsetHeight;
  };

  // the ref of the page with the next location to sign.
  const nextLocationRef =
    pageRefs[
      findPageNumberWithNextAction({
        signingEvent,
        signerRole: activeSigner.role,
      }) - 1
    ];

  const signatureLocations = signatureLocationsByPage(signingEvent);
  const signatureLocationsForRoleByPage = locationsForSignerRoleByPage({
    signatureLocations,
    signerRole: activeSigner.role,
  });

  // note that this is slightly different than checking if the required
  // signature is "completed". That indicates that not only are all the
  // locations signed, but also that the user has accepted the document.
  //
  // We need to know if all the locations are signed here on the frontend
  // to determine if we should show the "accept" button to the user.
  const allLocationsSigned = values(signatureLocationsForRoleByPage).every(
    pageRoleLocations => pageRoleLocations.every(({ isSigned }) => isSigned)
  );

  return (
    <div className={styles.modalContainer}>
      <BintiSignHeader
        ref={headerRef}
        {...{ nextLocationRef, scrollTo, setIsModalOpen, allLocationsSigned }}
      />
      <div className={styles.body}>
        <div className={styles.document} ref={documentRef}>
          <Choose>
            <When condition={Boolean(file)}>
              <Document
                file={file}
                loading={<LoadingComponent />}
                onLoadSuccess={onLoadSuccess}
              >
                {times(numPages, pageNumber => (
                  <SignaturePage
                    ref={pageRefs[pageNumber]}
                    key={`sig-page-${pageNumber}`}
                    pageNumber={pageNumber + 1}
                    signatureLocations={
                      signatureLocations[pageNumber + 1] || []
                    }
                    {...{ activeSigner, signingEvent, pageWidth }}
                  />
                ))}
              </Document>
            </When>
            <Otherwise>
              <LoadingComponent />
            </Otherwise>
          </Choose>
        </div>
        <SignaturePane
          {...{
            pageRefs,
            signatureLocationsForRoleByPage,
            allLocationsSigned,
            scrollTo,
            activeSigner,
            signingEvent,
            onClose,
          }}
        />
      </div>
    </div>
  );
};

BintiSign.propTypes = {
  activeSigner: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  signingEvent: PropTypes.object.isRequired,
  userSignature: PropTypes.string,
  userSignatureId: PropTypes.number,
  userName: PropTypes.string,
};

export default BintiSign;
