import { Button, Link } from "@heart/components";
import PropTypes from "prop-types";
import { transferToAgencyApplicationPath } from "routes";
import transferAgency from "transfer_agency.png";

import { translationWithRoot } from "@components/T";

import styles from "./TransferCard.module.scss";

const { T } = translationWithRoot("transfer");

/**
 * Component to direct users to a form for transferring applications to another angency
 *
 * @param {Object} props.application - Application object
 */
const TransferToAgencyCard = ({ application }) => (
  <div className={styles.transferCard}>
    <img
      src={transferAgency}
      alt={"Transfer Agency"}
      className={styles.cardImg}
    />
    <h2 className={styles.transferH2}>
      <T t="change_agency_html" />
    </h2>
    <Link href={transferToAgencyApplicationPath(application.id)}>
      <Button>
        <T t="transfer_caregiver" />
      </Button>
    </Link>
  </div>
);

TransferToAgencyCard.propTypes = {
  application: PropTypes.object.isRequired,
};

export default TransferToAgencyCard;
