import { Button, Flex } from "@heart/components";
import { useList } from "@react-hookz/web";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { If } from "tsx-control-statements/components";

import ChatHistory from "./ChatHistory";
import ChatTextInput from "./ChatTextInput";
import { MessageProps, Role } from "./common";

/**
 * Generic voice chat component that allows the user to interact with an
 * assistant endpoint through text and voice.
 *
 * @param {object}  props
 * @param {MessageProps[]} props.initialMessages The initial messages to display in the chat, if any.
 * @param {function} props.onSubmit Callback function to call when a new message is sent.
 * @param {function} props.onClear Callback function to call when the chat is cleared.
 * @param {boolean} props.loading Whether the chat is currently loading after a call to onSubmit.
 * @param {boolean} props.showClear Whether to show the clear chat button.
 *
 */
const TextChat = ({
  initialMessages = [],
  onClear,
  onSubmit,
  loading,
  showClear,
}: {
  initialMessages: MessageProps[];
  onClear: () => void;
  onSubmit: (options: { input: string }) => Promise<{ response: string }>;
  loading: boolean;
  showClear: boolean;
}) => {
  const [messages, { push, clear: clearMessages }] =
    useList<MessageProps>(initialMessages);

  const onNewMessage = useCallback(
    async message => {
      // if we're loading, don't allow the user to send another message
      // because it'll confuse the assistant having two user messages
      // in a row.
      if (loading) return;

      const newMessageTime = DateTime.now().toISO();

      push({ role: Role.USER, message, timestamp: newMessageTime });

      // submit the message
      const { response } = await onSubmit({ input: message });

      push({
        role: Role.ASSISTANT,
        message: response,
        timestamp: DateTime.now().toISO(),
      });
    },
    [onSubmit, push, loading]
  );

  const clear = useCallback(() => {
    clearMessages();
    onClear();
  }, [clearMessages, onClear]);

  return (
    <Flex column>
      <ChatHistory messages={messages} loading={loading} />
      <ChatTextInput onNewMessage={onNewMessage} />
      <If condition={showClear}>
        <Button onClick={clear}>Clear Chat</Button>
      </If>
    </Flex>
  );
};

export default TextChat;
