import { useMutation } from "@apollo/client";
import { Upload, Surface, Link } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { translationWithRoot } from "@components/T";

import CreateLegacySupportingDocsFiles from "@graphql/mutations/CreateLegacySupportingDocsFiles.graphql";

import useShrine from "@lib/useShrine";

const { t } = translationWithRoot("form_file_uploader");

/**
 * LegacySupportingDocsFileUploader component for uploading files to a questionnaire response.
 *
 * @param {Object[]} files - Array of existing files
 * @param {string} title - Title of the questionnaire question
 * @param {string} description - Description of the questionnaire question
 * @param {boolean} disabled - Disabled state of the uploader
 */
const LegacySupportingDocsFileUploader = ({
  questionnaireResponseId,
  files: fs = [],
  title,
  description,
  disabled,
  questionPath,
}) => {
  const [files, setFiles] = useState(fs);
  const [createLegacySupportingDocsFiles] = useMutation(
    CreateLegacySupportingDocsFiles
  );

  const { prepareShrineFiles, ShrineErrorAlert } = useShrine();

  const onUpload = async filesToUpload => {
    const {
      data: {
        createLegacySupportingDocsFiles: { uploadedFiles },
      },
    } = await createLegacySupportingDocsFiles({
      variables: {
        questionnaireResponseId,
        questionPath,
        filesToUpload: await prepareShrineFiles(filesToUpload),
      },
    });

    setFiles([...files, ...uploadedFiles]);
  };

  return (
    <Surface title={title} subtitle={description}>
      <Upload disabled={disabled} onUpload={onUpload} />
      <ShrineErrorAlert />
      {files
        .filter(file => file.questionPath === questionPath)
        .map(file => (
          <Link href={file.filePath} key={file.id} target="_blank">
            {file.fileName}
          </Link>
        ))}
      <If condition={isEmpty(files)}>{t("no_files_to_display")}</If>
    </Surface>
  );
};

LegacySupportingDocsFileUploader.propTypes = {
  questionnaireResponseId: PropTypes.number.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      fileName: PropTypes.string,
      filePath: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  questionPath: PropTypes.string,
};

export default LegacySupportingDocsFileUploader;
