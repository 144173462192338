import { useMutation } from "@apollo/client";
import { Button, InputCheckbox, toast } from "@heart/components";
import classnames from "classnames";
import { compact, some, uniq, uniqBy, without } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

import { useGeneratedId } from "@lib/generateId";
import preventDefault from "@lib/preventDefault";

import styles from "./ApplicationTemplateDesigner.module.scss";

const FILTER_TYPES = Object.freeze({
  ASSIGNED: "assigned",
  UNASSIGNED: "unassigned",
});

const getSlug = sluggy => sluggy.slug;

/**
 * A display of all the required forms/upload types/etc. associated with an
 * application template.
 *
 * Allows:
 *
 * 1. Filtering display by various criteria.
 * 2. Adding more things as requirements.
 * 3. Removing things as requirements.
 * 4. Dragging things to stages to display to applicants/other adults.
 */
const AllRequirementsOfAType = ({
  applicationTemplate,
  stageRole,
  type,
  createMutation,
  removeMutation,
  AdderComponent,
  DisplayComponent,
  defaultIsCollapsed = false,
}) => {
  const filterId = useGeneratedId();
  const [createRequirement] = useMutation(createMutation, {
    onCompleted: mutationData => {
      const { errors } = mutationData[Object.keys(mutationData)[0]];
      if (errors.length > 0) {
        toast.negative({
          title: `Error adding ${type}`,
          message: errors.map(error => error.message).join(", "),
        });
      }
    },
  });

  const [removeRequirement] = useMutation(removeMutation);
  const [isCollapsed, setIsCollapsed] = useState(defaultIsCollapsed);

  // All across the entire template
  const things = uniqBy(
    compact(
      applicationTemplate.requirementTemplates.map(template => template[type])
    ),
    getSlug
  );

  // All things assigned to one or more application stage templates of the
  // currently displayed role.
  const assigned = uniqBy(
    compact(
      applicationTemplate.applicationStageTemplates
        .filter(stageTemplate => stageTemplate.role === stageRole)
        .flatMap(stageTemplate => stageTemplate[`${type}s`])
    ),
    getSlug
  );

  const isAssigned = thing =>
    some(assigned, assignedThing => assignedThing.slug === thing.slug);

  // Show which things are visible under the current set of filters.
  const [filterTypes, setFilterTypes] = useState([]);
  const [textFilter, setTextFilter] = useState("");

  const updateFilterTypes = (filterType, isPresent) => {
    if (isPresent) {
      setFilterTypes(uniq(filterTypes.concat([filterType])));
    } else {
      setFilterTypes(without(filterTypes, filterType));
    }
  };

  const thingsToShow = things.filter(thing => {
    const typesOk = filterTypes.every(filterType => {
      switch (filterType) {
        case FILTER_TYPES.ASSIGNED:
          return isAssigned(thing);
        case FILTER_TYPES.UNASSIGNED:
          return !isAssigned(thing);
        default:
          return true;
      }
    });

    // laziest possible, catch all filtering for anything anywhere.
    return (
      typesOk &&
      JSON.stringify(thing).toLowerCase().includes(textFilter.toLowerCase())
    );
  });

  // Callbacks for adding/removing forms from the template overall (not stages,
  // that is done via drag n drop).
  const onAdd = slug => {
    createRequirement({
      variables: {
        applicationTemplateId: applicationTemplate.id,
        [`${type}Slug`]: slug,
      },
    });
  };

  const onRemove = thing => () => {
    removeRequirement({
      variables: {
        applicationTemplateId: applicationTemplate.id,
        [`${type}Slug`]: thing.slug,
      },
    });
  };

  return (
    <Droppable droppableId={`${type}s`}>
      {provided => (
        <div
          ref={provided.innerRef}
          className={styles.allThings}
          data-testid="all-requirements-of-a-type"
        >
          <div className={styles.thingTypeHeader}>
            <span>All {type}s</span>{" "}
            <Button
              onClick={preventDefault(() => {
                setIsCollapsed(!isCollapsed);
              })}
            >
              {isCollapsed ? "[Show]" : "[Hide]"}
            </Button>
          </div>
          <div className={classnames({ [styles.collapsed]: isCollapsed })}>
            <AdderComponent
              agencySlug={applicationTemplate.agency.slug}
              agencyId={parseInt(applicationTemplate.agency.id, 10)}
              onAdd={onAdd}
            />
            <div className={styles.thingFilters}>
              <div className={styles.textFilter}>
                <input
                  id={filterId}
                  value={textFilter}
                  onChange={({ target }) => {
                    setTextFilter(target.value);
                  }}
                />
                <label className="hidden" htmlFor={filterId}>
                  {type} Filter
                </label>
                <i className="fa fa-search" aria-hidden="true"></i>
              </div>
              <div>
                <InputCheckbox
                  label="Assigned to a stage"
                  value={filterTypes.includes(FILTER_TYPES.ASSIGNED)}
                  onChange={checked => {
                    updateFilterTypes(FILTER_TYPES.ASSIGNED, checked);
                  }}
                />
                <InputCheckbox
                  label="Unassigned to a stage"
                  value={filterTypes.includes(FILTER_TYPES.UNASSIGNED)}
                  onChange={checked => {
                    updateFilterTypes(FILTER_TYPES.UNASSIGNED, checked);
                  }}
                />
              </div>
            </div>

            <div className={styles.thingList}>
              {thingsToShow.map((thing, index) => (
                <Draggable
                  key={thing.slug}
                  draggableId={`${type}:all:${thing.slug}`}
                  index={index}
                >
                  {draggableProvided => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.dragHandleProps}
                    >
                      <DisplayComponent
                        onRemove={onRemove(thing)}
                        dragHandleProps={draggableProvided.draggableProps}
                        {...{ [type]: thing }}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          </div>
        </div>
      )}
    </Droppable>
  );
};

AllRequirementsOfAType.propTypes = {
  /** An ApplicationTemplate GraphQL object */
  applicationTemplate: PropTypes.object.isRequired,
  /**
   * Role of stages currently visible. Used to determine if the objects
   * of `type` are already assigned in the current view.
   */
  stageRole: PropTypes.string.isRequired,
  /** The type as a string, probably "form" or "uploadType" */
  type: PropTypes.string.isRequired,
  /**
    A mutation used to create a requirement of type by slug.
    Should take that type's slug and an application template as variables.
  */
  createMutation: PropTypes.object.isRequired,
  /**
    A mutation used to remove a requirement of type by slug.
    Should take that type's slug and an application template as variables.
  */
  removeMutation: PropTypes.object.isRequired,
  /**
    A component used to add a requirement of this type.
    Should accept agencySlug and onAdd callback props.
  */
  AdderComponent: PropTypes.any,
  /**
    A component used to display a requirement of this type.
    Should accept the requirement type as "type", dragHandleProps, and
    onRemove callback props.
  */
  DisplayComponent: PropTypes.any,
  /**
   * Should the component start out collapsed? The user will have the ability
   * to show/hide.
   */
  defaultIsCollapsed: PropTypes.bool,
};

export default AllRequirementsOfAType;
