import { InputCheckboxGroup, Surface } from "@heart/components";

import { translationWithRoot } from "@components/T";

import {
  fromLegacyCheckboxGroupValue,
  toLegacyCheckboxGroupValue
} from "@heart/components/inputs/InputCheckboxGroup";
import { CASE_NOTE, FORM, TranscriptionType } from "./constants";

const { t } = translationWithRoot(
  "transcription.select_transcription_type_page"
);

/**
 * Form for selecting the type of transcription to create.
 * This form is the last step of the CreateTranscriptionProcessForm
 * Wizard.
 */
const SelectTranscriptionTypePage = ({
  transcriptionTypes = [],
  setTranscriptionTypes,
}: {
  transcriptionTypes?: TranscriptionType[];
  setTranscriptionTypes: (newTranscriptionTypes: TranscriptionType[]) => void;
}) => (
  <Surface title={t("title")} hideTitle>
    <InputCheckboxGroup
      label={t("transcription_type")}
      required
      values={[
        { value: CASE_NOTE, label: t("transcription_type_case_note") },
        { value: FORM, label: t("transcription_type_form") },
      ]}
      value={fromLegacyCheckboxGroupValue(transcriptionTypes)}
      onChange={types => setTranscriptionTypes(toLegacyCheckboxGroupValue(types))}
    />
  </Surface>
);

export default SelectTranscriptionTypePage;
