import { sum } from "lodash";
import { useMemo, useRef } from "react";

import styles from "./VoicePulser.module.scss";
import recordingIcon from "./recording.svg";

// this function helps to magnify small values to be more visible
// when setting the volume indicator opacity. volumes tend to be
// be small but can spike to be large, so this helps compensate
// for that and make the volume indicator more visible when people
// are speaking softly.
//
// https://math.stackexchange.com/a/158515
const factor = 100;
const smallValueMagnifier = (value, n = factor) =>
  ((n + 1) * value) / (n * value + 1);

/**
 * React hook for creating a transparency pulsing effect that responds
 * to the volume of audio input.
 */
const useVolumePulser = () => {
  const iconRef = useRef<HTMLImageElement>();

  const onAudioActivity = useMemo(() => {
    // using a closure instead of state since these change on every
    // animation frame when audio is active
    //
    // 100 is derived from the values we saw in testing, ranging from
    // ~100-2000. we start here so the jump isn't as jarring when this
    // auto-adjusts.
    let largestVolumeSeen = 100;
    let resetTimeout = null;

    return dataArray => {
      if (!iconRef.current) return;

      const currentVolume = sum(dataArray);
      largestVolumeSeen = Math.max(currentVolume, largestVolumeSeen);

      const newOpacity = Math.floor(
        smallValueMagnifier(currentVolume / largestVolumeSeen) * 100
      );

      // unfortunately react isn't actually fast enough to set this
      // via state and props, so we set opacity via a ref for performance
      iconRef.current.setAttribute("style", `--iconOpacity: ${newOpacity}%`);
      if (resetTimeout) {
        clearTimeout(resetTimeout);
      }

      // reset the opacity after a short delay if there's no
      // more speaking before then.
      resetTimeout = setTimeout(() => {
        iconRef.current.setAttribute("style", "--iconOpacity: 25%");
      }, 100);
    };
  }, []);

  const PulserIcon = () => (
    <img
      src={recordingIcon}
      alt=""
      aria-hidden
      ref={iconRef}
      className={styles.icon}
    />
  );

  return { PulserIcon, onAudioActivity };
};

export default useVolumePulser;
