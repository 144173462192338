import PropTypes from "prop-types";

import styles from "./ApplicationTemplateDesigner.module.scss";
import PresentNotPresent from "./PresentNotPresent";

/**
 * Other sections for ApplicationStageTemplate
 */
const OtherSections = ({ applicationStageTemplate }) => (
  <ul className={styles.applicantStageOtherSections}>
    <li>
      References section{" "}
      <PresentNotPresent
        value={applicationStageTemplate.hasReferencesSection}
      />
    </li>
    <li>
      Other adults section{" "}
      <PresentNotPresent
        value={applicationStageTemplate.hasOtherAdultsInHomeSection}
      />
    </li>
    <li>
      Children in home section{" "}
      <PresentNotPresent
        value={applicationStageTemplate.hasChildrenInHomeSection}
      />
    </li>
  </ul>
);

OtherSections.propTypes = {
  applicationStageTemplate: PropTypes.object.isRequired,
};

export default OtherSections;
