import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { useState, useCallback } from "react";

import RecordFormatAutocompleteQuery from "@graphql/queries/RecordFormatAutocomplete.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

/**
 * Autocomplete component for selecting a form or an upload type from a mixed list
 */
const RelatedRecordFormatsInput = ({
  name,
  required = false,
  initialRelatedRecordFormats = [],
  formId = null,
  uploadTypeId = null,
}) => {
  const [selectedRecords, setSelectedRecords] = useState(
    initialRelatedRecordFormats
  );
  const valuesFromResponse = useCallback(
    ({ recordFormatMatches }) =>
      recordFormatMatches.map(({ value, label }) => ({
        value,
        label,
      })),
    []
  );

  return (
    <InputAutocompleteGraphQL
      label={I18n.t("views.admin.forms.related_requirements")}
      query={RecordFormatAutocompleteQuery}
      queryVariables={{
        limit: 15,
        relatedToFormId: formId,
        relatedToUploadTypeId: uploadTypeId,
      }}
      valuesFromResponse={valuesFromResponse}
      required={required}
      value={selectedRecords}
      onChange={newSelectedRecords => {
        setSelectedRecords(newSelectedRecords);
      }}
      name={name}
      isMulti={true}
    />
  );
};

RelatedRecordFormatsInput.propTypes = {
  /**
   * True if the family selection is required, false otherwise.
   */
  required: PropTypes.bool,
  /**
   * The currently selected Forms and Upload types
   * These should be in the format ["Form:123"]
   */
  initialRelatedRecordFormats: PropTypes.arrayOf(PropTypes.string),
  /**
   * The name of the input field.
   */
  name: PropTypes.string,
  /**
   * The name of the form we're relating the requirements to.
   * (you cannot select other forms or upload types that share templates with this form)
   */
  formId: BintiPropTypes.ID,
  /**
   * The name of the upload type we're relating the requirements to.
   * (you cannot select other upload types or forms that share templates with this upload type)
   */
  uploadTypeId: BintiPropTypes.ID,
};

export default RelatedRecordFormatsInput;
