import { InputText } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import getErrorsForField from "./getErrorsForField";

const { t } = translationWithRoot("admin.integrations", {
  escapeJavascriptRoot: true,
});
// This form is based on lib/json_schemas/integration_configurations/data_export.json
// and in the future, we could likely generate these forms from other JSON schema files
/**
 * DataExportConfigurationForm component renders a form for creating a DataExport integration
 *
 * @param {Object} props.formState - The current state of the form.
 * @param {Function} props.setFormAttribute - Function to update form attributes.
 * @param {Array} props.validationErrors - Array of validation errors.
 */
const SFTPConfigurationSubForm = ({
  formState,
  setFormAttribute,
  validationErrors,
}) => {
  const errorsForField = getErrorsForField(validationErrors);

  return (
    <Fragment>
      <InputText
        label={t("labels.sftp.hostname")}
        value={formState.sftpConfiguration?.hostname}
        onChange={setFormAttribute("sftpConfiguration.hostname")}
        error={errorsForField("sftpConfiguration.hostname")}
        required={true}
      />
      <InputText
        label={t("labels.sftp.port")}
        value={formState.sftpConfiguration?.port}
        description={t("labels.sftp.port_description")}
        onChange={setFormAttribute("sftpConfiguration.port")}
        error={errorsForField("sftpConfiguration.port")}
        type="number"
        required={false}
      />
      <InputText
        label={t("labels.sftp.username")}
        value={formState.sftpConfiguration?.username}
        onChange={setFormAttribute("sftpConfiguration.username")}
        error={errorsForField("sftpConfiguration.username")}
        required={true}
      />
      <InputText
        label={t("labels.sftp.path")}
        value={formState.sftpConfiguration?.path}
        onChange={setFormAttribute("sftpConfiguration.path")}
        error={errorsForField("sftpConfiguration.path")}
        required={false}
      />
    </Fragment>
  );
};

SFTPConfigurationSubForm.propTypes = {
  formState: PropTypes.object.isRequired,
  setFormAttribute: PropTypes.func.isRequired,
  validationErrors: PropTypes.array.isRequired,
};
export default SFTPConfigurationSubForm;
