import { Button, Modal, Flex, InputDropdown } from "@heart/components";
import InputCsrfToken from "@heart/components/inputs/InputCsrfToken";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { changeApplicationTemplateAdminApplicationPath } from "routes";
import transferTemplate from "transfer_template.png";

import { translationWithRoot } from "@components/T";

import styles from "./TransferCard.module.scss";

const { T } = translationWithRoot("transfer");

/**
 * Card component to handle transferring applications to another template
 *
 * @param {Object} props.application - Application object
 * @param {Array} props.applicationTemplates - Application templates to transfer to
 ** @param {string} props.csrfToken - The CSRF token
 */
const TransferTemplateCard = ({
  application,
  applicationTemplates,
  csrfToken,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [confirmationHidden, setConfirmationHidden] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const formRef = useRef(null);

  const handleTemplateChange = value => {
    setSelectedTemplate(value);
  };

  return (
    <Flex align="center" row>
      <form
        ref={formRef}
        onSubmit={() => {
          setSubmitting(true);
        }}
        action={changeApplicationTemplateAdminApplicationPath({
          id: application.id,
        })}
        method="POST"
      >
        <div key={application.id} className={styles.transferCard}>
          <img
            src={transferTemplate}
            alt={"Transfer Template"}
            className={styles.cardImg}
          />
          <h2 className={styles.transferH2}>
            <T t="change_template_html" />
          </h2>
          <InputCsrfToken csrfToken={csrfToken} />
          <Button onClick={() => setConfirmationHidden(false)}>
            {<T t="change_template_title" />}
          </Button>
        </div>
        {/* Hidden input to submit the selected template */}
        <input type="hidden" name="template_slug" value={selectedTemplate} />
      </form>

      <If condition={!confirmationHidden}>
        <Modal
          hidden={confirmationHidden}
          onCancel={() => setConfirmationHidden(true)}
          onSubmit={() => {
            setSubmitting(true);
            formRef.current.submit();
          }}
          submitting={submitting}
          title={<T t="different_lineage" />}
        >
          <Flex column>
            <InputDropdown
              id="templates"
              label={<T t="lineage_select" />}
              required
              values={applicationTemplates.map(({ displayName, slug }) => [
                displayName,
                slug,
              ])}
              onChange={handleTemplateChange}
            />

            <T t="confirm_different_lineage" />
          </Flex>
        </Modal>
      </If>
    </Flex>
  );
};

TransferTemplateCard.propTypes = {
  application: PropTypes.object.isRequired,
  applicationTemplates: PropTypes.array.isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default TransferTemplateCard;
