/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function () {
  $(document).on("click", ".js-expand-panel", function () {
    const $a = $(this);
    $a.html("Collapse")
      .removeClass("js-expand-panel")
      .addClass("js-collapse-panel")
      .attr("aria-expanded", true);
    $a.parents(".js-accordion").find(".js-accordion-contents").show(250);
    return false;
  });

  $(document).on("click", ".js-collapse-panel", function () {
    const $a = $(this);
    $a.html("Expand")
      .removeClass("js-collapse-panel")
      .addClass("js-expand-panel")
      .attr("aria-expanded", false);
    $a.parents(".js-accordion").find(".js-accordion-contents").hide(250);
    return false;
  });

  //############################################################
  // Hooks for Accordion panels for Active Admin form sections
  //############################################################
  $(function () {
    const $accordion_wrapper = $(".js-accordion-wrapper");
    $accordion_wrapper.addClass("js-accordion");
    $(".js-accordion-wrapper ol").addClass("js-accordion-contents");
    return $accordion_wrapper.each(function () {
      const $a = $(this);
      const $title = $(this).find("legend").first();
      if ($a.hasClass("js-expand") || $a.parent().find(".error").length !== 0) {
        const $collapse_button = $(
          `<button class="secondary-button js-form-collapse-panel" aria-expanded=true> \
<i class="fa fa-minus"/> \
</button>`
        );
        return $title.append($collapse_button);
      } else {
        const $expand_button = $(
          `<button class="secondary-button js-form-expand-panel" aria-expanded=false> \
<i class="fa fa-plus"/> \
</button>`
        );
        $title.append($expand_button);
        $a.find(".js-accordion-contents").css("display", "none");
        $a.find(".js-form-expand-panel").parent().addClass("add-border");
        return $a
          .find(".js-form-expand-panel")
          .parent()
          .parent()
          .addClass("is-collapse");
      }
    });
  });

  $(document).on("click", ".js-form-expand-panel", function () {
    const $a = $(this);
    $a.html("<i class='fa fa-minus'/>");
    $a.removeClass("js-form-expand-panel")
      .addClass("js-form-collapse-panel")
      .attr("aria-expanded", true);
    $a.parents(".js-accordion")
      .find(".js-accordion-contents")
      .css("opacity", 0)
      .show(250, () =>
        $a
          .parents(".js-accordion")
          .find(".js-accordion-contents")
          .css("opacity", 1)
      );
    $a.parent().removeClass("add-border");
    $a.parent().parent().removeClass("is-collapse");
    return false;
  });

  return $(document).on("click", ".js-form-collapse-panel", function () {
    const $a = $(this);
    $a.html("<i class='fa fa-plus'/>");
    $a.removeClass("js-form-collapse-panel")
      .addClass("js-form-expand-panel")
      .attr("aria-expanded", false);
    $a.parents(".js-accordion")
      .find(".js-accordion-contents")
      .css("opacity", 0)
      .hide(250, () =>
        $a
          .parents(".js-accordion")
          .find(".js-accordion-contents")
          .css("opacity", 1)
      );
    $a.parent().addClass("add-border");
    $a.parent().parent().addClass("is-collapse");
    return false;
  });
});
