import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  MiniMap,
  Controls,
} from "@xyflow/react";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";

import DownloadButton from "./DownloadButton";
import FloatingEdge from "./FloatingEdge";
import styles from "./NodeDiagram.module.scss";
import "./packagedStyles.scss";

/** Function to dynamically determine the position of nodes */
export const getLaidOutElements = async ({
  nodes = [],
  edges = [],
  nodeWidth,
  nodeHeight,
  graphDirection,
  spaceBetweenLayers,
  spokeAndWheel,
}) => {
  const { default: ElkJS } = await import("elkjs");
  const elk = new ElkJS();

  /** Configuration setup to be used by elkjs when determining
   * the position of nodes
   */
  const graph = {
    id: "root",
    children: [],
    layoutOptions: {
      "elk.algorithm": spokeAndWheel ? "org.eclipse.elk.radial" : "layered",
      "elk.direction": graphDirection,
      "elk.layered.spacing.edgeNodeBetweenLayers": spaceBetweenLayers,
      "elk.layered.nodePlacement.bk.fixedAlignment": "BALANCED",
      "elk.partitioning.activate": true,
    },
  };

  /** Add attributes required by elkjs to nodes and edges */
  graph.children = nodes.map(node => ({
    ...node,
    width: nodeWidth,
    height: nodeHeight,
  }));

  graph.edges = edges.map(edge => ({
    ...edge,
    sources: [edge.source],
    targets: [edge.target],
  }));

  /** Dynamically determine position nodes should be rendered in */
  const { edges: newEdges, children } = await elk.layout(graph);
  /** Map attributes required by reactflow from elkjs returned format */
  const newNodes = children.map(node => ({
    ...node,
    position: {
      x: node.x,
      y: node.y,
    },
    height: null,
    width: null,
  }));

  return {
    nodes: newNodes,
    edges: newEdges,
  };
};

/** A Graph Generating Component that leverages [reactflow](https://reactflow.dev/) and
 * [elkjs](https://www.npmjs.com/package/elkjs) to dynamically generate nodes and edges
 */
const NodeDiagram = ({
  nodes = [],
  dynamicEdges = [],
  staticEdges = [],
  customNodes,
  customEdges,
  nodeWidth,
  nodeHeight,
  graphDirection = "DOWN",
  spaceBetweenLayers = 30,
  downloadFileName,
  spokeAndWheel,
  ...rest
}) => {
  const [computedNodes, setComputedNodes] = useNodesState(nodes);
  const [computedEdges, setComputedEdges] = useEdgesState([
    ...dynamicEdges,
    ...staticEdges,
  ]);

  useEffect(() => {
    const layoutElements = async () => {
      const { nodes: laidOutNodes, edges: laidOutEdges } =
        await getLaidOutElements({
          nodes,
          edges: dynamicEdges,
          nodeWidth,
          nodeHeight,
          graphDirection,
          spaceBetweenLayers,
          spokeAndWheel,
        });
      setComputedNodes(laidOutNodes);
      /** Add in edges that should be rendered but should not impact
       * dynamic positioning of nodes */
      setComputedEdges([...laidOutEdges, ...staticEdges]);
    };

    layoutElements();
  }, [
    nodes,
    dynamicEdges,
    staticEdges,
    nodeWidth,
    nodeHeight,
    graphDirection,
    spaceBetweenLayers,
    spokeAndWheel,
    setComputedNodes,
    setComputedEdges,
  ]);

  const edgeTypes = useMemo(
    () => ({ floating: FloatingEdge, ...customEdges }),
    [customEdges]
  );
  const nodeTypes = useMemo(() => customNodes, [customNodes]);

  return (
    <div className={styles.nodeDiagramContainer}>
      <ReactFlow
        nodes={computedNodes}
        edges={computedEdges}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        {...rest}
      >
        <DownloadButton fileName={downloadFileName} />
        <MiniMap />
        <Controls showInteractive={false} />
      </ReactFlow>
    </div>
  );
};
NodeDiagram.propTypes = {
  /** What nodes are included in the diagram? */
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      /* Whether a node should be displayed or hidden */
      hidden: PropTypes.bool,
      /* What type of node should be rendered */
      type: PropTypes.string,
      /* Data that will be passed through to the node for rendering */
      data: PropTypes.object,
      /** Position will be overwritten by our dynamic node positioning,
      /*  so initially giving it { x: 0, y: 0 } is great
      */
      position: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
      }).isRequired,
    })
  ),
  /** What edges should be used when dynamically determining positioning
   * of nodes?
   */
  dynamicEdges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      /* What is the origin of the edge */
      source: PropTypes.string.isRequired,
      /* What is the destination of the edge */
      target: PropTypes.string.isRequired,
      /* What handle should the origin of the edge be attached to */
      sourceHandle: PropTypes.string,
      /* What handle should the destination of the edge be attached to */
      targetHandle: PropTypes.string,
      /* What type of edge should be rendered. Unless customEdges are provided,
       * options are: "step", "straight", "smoothstep", "simplebezier". When
       * omitted, defaults ot a bezier style edge
       */
      type: PropTypes.string,
    })
  ),
  /** What edges should be rendered after the dynamic positions of
   * the provided nodes and dynamicEdges is determined?
   */
  staticEdges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      /* What is the origin of the edge */
      source: PropTypes.string.isRequired,
      /* What is the destination of the edge */
      target: PropTypes.string.isRequired,
      /* What handle should the origin of the edge be attached to */
      sourceHandle: PropTypes.string,
      /* What handle should the destination of the edge be attached to */
      targetHandle: PropTypes.string,
      /* What type of edge should be rendered. Unless customEdges are provided,
       * options are: "step", "straight", "smoothstep", "simplebezier". When
       * omitted, defaults ot a bezier style edge
       */
      type: PropTypes.string,
    })
  ),
  /** Any custom node components that the diagram
   * should be able to leverage, keyed by name
   */
  customNodes: PropTypes.objectOf(PropTypes.func),
  /** Any custom edge components that the diagram should be able to leverage,
   * keyed by name.
   *
   * By default a "floating" edge is available, which dynamically moves around
   * the nodes it is connected to
   */
  customEdges: PropTypes.objectOf(PropTypes.func),
  /** How wide is each node? */
  nodeWidth: PropTypes.number.isRequired,
  /** How tall is each node? */
  nodeHeight: PropTypes.number.isRequired,
  /** The hierarchical flow direction of the diagram */
  graphDirection: PropTypes.oneOf(["DOWN", "UP", "LEFT", "RIGHT"]),
  /** The amount of space that should be left between
   * node layers. Defaults to 30
   */
  spaceBetweenLayers: PropTypes.number,
  /** When provided, will add a button allowing folks to download an
   * image of the data displayed in the NodeDiagram, which will save
   * to the provided file name
   */
  downloadFileName: PropTypes.string,
  /** When true, uses a radial algorithm to lay out the nodes rather
   * than a layered algorithm
   *
   * Troubleshooting note: if nodes aren't laying out in a spoke & wheel
   * style, try swapping the source and target nodes when generating
   * edges for the diagram
   */
  spokeAndWheel: PropTypes.bool,
};

export default NodeDiagram;
