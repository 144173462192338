import { useMutation, useQuery } from "@apollo/client";
import { Icons, Link, ArrayDataTable } from "@heart/components";
import { Spinner } from "@heart/components/icon/Icon";
import I18n from "i18n-js";
import { PropTypes } from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";
import BannerContainer from "@components/shared/banner/BannerContainer";

import DeleteAgencyRelationship from "@graphql/mutations/DeleteAgencyRelationship.graphql";
import AgencyRelationships from "@graphql/queries/AgencyRelationships.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const { t } = translationWithRoot("permissions.agency_relationships");

const AgencyRelationshipsTable = () => {
  const { loading, data, error: queryError } = useQuery(AgencyRelationships);

  const agencyRelationships = data?.agencyRelationships || [];

  const [deleteRelationship, { loading: deleting, error: deleteError }] =
    useMutation(DeleteAgencyRelationship, {
      refetchQueries: [{ query: AgencyRelationships }],
    });

  const errorMessage = [queryError?.message, deleteError?.message]
    .filter(Boolean)
    .join(", ");

  if (errorMessage) {
    return (
      <BannerContainer type="error" message={`Ooopsie! ${errorMessage}`} />
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Table
      relationships={agencyRelationships}
      onDelete={id => deleteRelationship({ variables: { id } })}
      deleting={deleting}
    />
  );
};

const Table = ({ relationships, onDelete, deleting }) => (
  <ArrayDataTable
    title={t("existing_agency_relationships")}
    columns={[
      {
        cell: row =>
          row.isPreventionRelationship ? t("prevention") : t("standard"),
        columnName: {
          name: t("relationship_type"),
        },
        columnSort: "row.isPreventionRelationship",
        filter: {
          filterBy: ({ data, searchTerm }) => {
            if (searchTerm === "standard") {
              return !data.isPreventionRelationship;
            }
            if (searchTerm === "prevention") {
              return data.isPreventionRelationship;
            }
            return false;
          },
          label: t("relationship_type"),
          type: "select",
          values: [
            { label: t("standard"), value: "standard" },
            { label: t("prevention"), value: "prevention" },
          ],
        },
        id: "relationship-type",
      },
      {
        cell: "label",
        columnName: {
          name: t("relationship_label"),
        },
        columnSort: "label",
        filter: {
          filterBy: "label",
          label: t("search_by_label"),
          type: "search",
        },
        id: "relationship-label",
      },
      {
        cell: row => (
          <Link href={`/admin/agencies/${row.sourceAgency.id}`}>
            {row.sourceAgency.name}
          </Link>
        ),
        columnName: {
          name: t("source_agency"),
        },
        columnSort: "sourceAgency.name",
        filter: {
          filterBy: "sourceAgency.name",
          label: t("source_agency"),
          type: "search",
        },
        id: "source-agency-name",
      },
      {
        cell: row => (
          <Link href={`/permission_rule_sets/${row.permissionRuleSet.id}`}>
            {row.permissionRuleSet.label}
          </Link>
        ),
        columnName: {
          name: t("permission_rule_set"),
        },
        columnSort: "permissionRuleSet.label",
        filter: {
          filterBy: "permissionRuleSet.label",
          label: t("permission_rule_set"),
          type: "search",
        },
        id: "rule-set-label",
      },
      {
        cell: row => (
          <Link href={`/admin/agencies/${row.targetAgency.id}`}>
            {row.targetAgency.name}
          </Link>
        ),
        columnName: {
          name: t("target_agency"),
        },
        columnSort: "targetAgency.name",
        filter: {
          filterBy: "targetAgency.name",
          label: t("target_agency"),
          type: "search",
        },
        id: "target-agency-name",
      },
      {
        columnName: { name: I18n.t("javascript.components.common.actions") },
        cell: row => (
          <Icons.Trash
            description={t("delete_agency_relationship")}
            onClick={() => onDelete(row.id)}
            disabled={deleting}
          />
        ),
        id: "trash-icon",
      },
    ]}
    data={relationships}
  />
);

Table.propTypes = {
  relationships: PropTypes.arrayOf(
    PropTypes.shape({
      id: BintiPropTypes.ID,
      label: PropTypes.string,
      sourceAgency: PropTypes.shape({ name: PropTypes.string }),
      targetAgency: PropTypes.shape({ name: PropTypes.string }),
      permissionRuleSet: PropTypes.shape({ label: PropTypes.string }),
    })
  ),
  onDelete: PropTypes.func,
  deleting: PropTypes.bool,
};

export default AgencyRelationshipsTable;
