import { Actions, Flex } from "@heart/components";
import I18n from "i18n-js";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import styles from "./Alert.module.scss";
import ModalTitle from "./ModalTitle";
import ModalWrapper from "./ModalWrapper";

/** An alert! This component should be used when alerting the
 * user to something, and differs from the Modal in that it
 * isn't dismissable until the user clicks the submit button.
 *
 * When showing informational or interactable content, the
 * Modal should be used instead
 */

const Alert = ({
  children,
  displayLargeTitle = false,
  hidden,
  onSubmit,
  submitting,
  submitDisabled,
  submitText = I18n.t("views.common.submit"),
  submittingText = I18n.t("views.common.submitting"),
  title = I18n.t("javascript.components.common.alert"),
  "data-testid": testId,
}) => {
  const generatedId = uniqueId("alert");

  return (
    <ModalWrapper
      modalTitleId={generatedId}
      hidden={hidden}
      isAlert={true}
      data-testid={testId}
    >
      <Flex row justify="space-between" className={styles.alertContents}>
        <ModalTitle
          displayLargeTitle={displayLargeTitle}
          title={title}
          id={`alert-header-${generatedId}`}
        />
      </Flex>
      <div className={styles.alertContents}>{children}</div>
      <div className={styles.alertActions}>
        <Actions
          primaryAction={onSubmit}
          primaryDisabled={submitDisabled}
          primarySubmittingText={submittingText}
          primaryText={submitText}
          isSubmitting={submitting}
        />
      </div>
    </ModalWrapper>
  );
};

Alert.propTypes = {
  /** Alert contents */
  children: PropTypes.node.isRequired,
  /** Whether title should be displayed larger than the
   * rest of the contents of the modal or not. Note: regardless
   * of whether the title is displayed as a title or as regular
   * text, the title is required for accessibility reasons */
  displayLargeTitle: PropTypes.bool,
  /** Whether modal should be hidden or not */
  hidden: PropTypes.bool.isRequired,
  /** Whether loading overlay should be displayed or not */
  loading: PropTypes.bool,
  /** Action to take when submit button is clicked */
  onSubmit: PropTypes.func.isRequired,
  /** Whether submitting button spinner should be displayed or not. Also
   * disables modal buttons when `true`
   */
  submitting: PropTypes.bool,
  /** Whether submit button should be disabled */
  submitDisabled: PropTypes.bool,
  /** Text for submit button */
  submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Text for submit button when submit is in progress */
  submittingText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Title of alert */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};
export default Alert;
