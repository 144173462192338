import { useQuery } from "@apollo/client";
import { InputDropdown, LoadingOverlay } from "@heart/components";
import gql from "graphql-tag";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("conditional_requirements");

/**
 * Form component for conditional requirements.
 */
const AssigneeDropdown = ({ applicationId, formState, setFormAttribute }) => {
  const { data, loading } = useQuery(
    gql`
      query ConditionalRequirementsForm(
        $applicationId: ID!
        $requirementTemplateId: ID!
      ) {
        conditionalRequirementsFormData(
          applicationId: $applicationId
          requirementTemplateId: $requirementTemplateId
        ) {
          assignees {
            label
            value
          }
        }
      }
    `,
    {
      variables: {
        applicationId,
        requirementTemplateId: formState.requirementTemplateId,
      },
    }
  );

  return (
    <LoadingOverlay active={loading}>
      <InputDropdown
        label={t("assignee_id")}
        value={formState.assigneeIds}
        values={data?.conditionalRequirementsFormData?.assignees || []}
        onChange={setFormAttribute("assigneeIds")}
      />
    </LoadingOverlay>
  );
};

AssigneeDropdown.propTypes = {
  applicationId: PropTypes.string,
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
};

export default AssigneeDropdown;
