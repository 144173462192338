import { useMutation } from "@apollo/client";
import {
  Button,
  Flex,
  InputText,
  Text,
  Link,
  Pill,
  Notice,
} from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { translationWithRoot } from "@components/T";

import VerifyOtp from "@graphql/mutations/VerifyOtp.graphql";

import preventDefault from "@lib/preventDefault";

import styles from "../Login.module.scss";
import OtpError from "./OtpError";

const { T, t } = translationWithRoot("permissions.otp_login");

const OtpVerify = ({
  selectedPhoneNumberId,
  verificationMethod,
  agencyAdminAssignmentId,
}) => {
  const [verifyOtpMutation, { loading: verifyingOtp }] = useMutation(VerifyOtp);
  const [showSuccessPill, setShowSuccessPill] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showLockout, setShowLockout] = useState(false);
  const [forgotPasswordUrl, setForgotPasswordUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);

  const handleSubmit = mutation =>
    preventDefault(ev => {
      setShowError(false);
      setShowSuccessPill(false);

      const fd = new FormData(ev.target);

      mutation({
        variables: {
          otpCode: fd.get("otpCode"),
          phoneNumberId: fd.get("phoneNumberId"),
          verificationMethod: fd.get("verificationMethod"),
          agencyAdminAssignmentId: fd.get("agencyAdminAssignmentId"),
        },
        onCompleted: ({ verifyOtp: result }) => {
          if (result.status === "approved") {
            setLoggingIn(true);
            setShowSuccessPill(true);
            window.location.replace(result.redirectTo);
          } else if (result.error !== null) {
            if (result.error.type === t("invalid_error_type")) {
              setErrorMessage(result.error.message);
              setShowError(true);
            } else if (result.error.type === t("lockout_error_type")) {
              setErrorMessage(result.error.message);
              setForgotPasswordUrl(result.redirectTo);
              setShowLockout(true);
            }
          }
        },
      });
    });

  if (showLockout) {
    return (
      <OtpError
        error={{
          message: errorMessage,
        }}
        forgotPasswordUrl={forgotPasswordUrl}
      />
    );
  }

  return (
    <Fragment>
      <Text textStyle="emphasis-200">
        <T t="verify_title" />
      </Text>
      {showSuccessPill && (
        <Pill variant="success" text={t("success_message")} />
      )}
      {showError && (
        <Notice title="Error logging in">
          <Text>{errorMessage}</Text>
        </Notice>
      )}
      <form onSubmit={handleSubmit(verifyOtpMutation)}>
        <Flex column align="center" gap="300">
          <div className={styles.field}>
            <InputText
              id="otpCodeTextInput"
              name="otpCode"
              label="Submit verification code"
              hideLabel
              placeholder="6-digit code"
              required
            />
            <InputText
              id="hiddenPhoneNumberInput"
              name="phoneNumberId"
              label="Verification phone number ID"
              hidden
              value={selectedPhoneNumberId}
            />
            <InputText
              id="hiddenVerificationMethod"
              name="verificationMethod"
              label="Verification method"
              hidden
              value={verificationMethod}
            />
            <InputText
              id="hiddenAgencyAdminAssignmentInput"
              name="agencyAdminAssignmentId"
              label="Agency Admin Assignment ID"
              hidden
              value={agencyAdminAssignmentId}
            />
          </div>
          <Button
            type="submit"
            submitting={verifyingOtp || loggingIn}
            variant="primary"
          >
            <T t="verify_button_text" />
          </Button>
        </Flex>
      </form>
      <Flex column align="center" gp="sm">
        <Text textColor="neutral-500" textStyle="body-100">
          {t("didnt_receive_code")}
        </Text>
        <Text textColor="neutral-500" textStyle="body-100">
          {t("please_verify")}
        </Text>
        <Link href="/users/verifications/new">
          <Text textStyle="body-100">{t("try_again")}</Text>
        </Link>
      </Flex>
      <Link
        href="https://binti.com/mfa_terms/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text textStyle="body-100">{t("mfa_terms_of_use")}</Text>
      </Link>
    </Fragment>
  );
};

OtpVerify.propTypes = {
  selectedPhoneNumberId: PropTypes.string.isRequired,
  verificationMethod: PropTypes.string.isRequired,
  agencyAdminAssignmentId: PropTypes.string.isRequired,
};

export default OtpVerify;
