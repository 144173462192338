import { clickable } from "@heart/core/clickable.module.scss";
import classNames from "classnames";
import { forwardRef } from "react";

type commonProps = { disabled?: boolean; "data-testid"?: string };

type exclusiveLinkProps = {
  href?: string;
  onClick?: never;
  anchorClassname?: string;
  buttonClassname?: never;
  type?: never;
};

type exclusiveButtonProps = {
  href?: never;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  anchorClassname?: never;
  buttonClassname?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
};

type ClickableLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  exclusiveLinkProps &
  commonProps;
type ClickableButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  exclusiveButtonProps &
  commonProps;

export type ClickableProps = ClickableLinkProps | ClickableButtonProps;
export type ClickableRefType = HTMLAnchorElement | HTMLButtonElement;

/**
 * Helper function to take the ambiguous props of a Clickable and return
 * the props that are exclusive to either a link or a button.
 */
export const cleanClickableProps = ({
  href,
  disabled,
  onClick,
  anchorClassname,
  buttonClassname,
  type,
}: {
  href?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  anchorClassname?: string;
  buttonClassname?: string;
  type?: "button" | "submit" | "reset";
  // Theoretically this should return exclusiveLinkProps | exclusiveButtonProps
  // but `any` makes the function work in `Button` and `Link` whereas the union
  // type does not for some reason.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any => {
  if (href && !disabled) return { href, anchorClassname } as exclusiveLinkProps;

  return { onClick, buttonClassname, type, disabled } as exclusiveButtonProps;
};

/**
 * A clickable link or button.
 *
 * Note: This component is not exported by Heart as it should be
 * used very sparingly!
 *
 * The `href` and `onClick` props are mutually exclusive - `href` will
 * render an `<a>` tag and `onClick` will render the children in a
 * `<button>` that has been given the visual style of a link
 *  _([why?](https://www.digitala11y.com/links-vs-buttons-a-perennial-problem/)_)
 *
 * @param href Link location
 * @param onClick onClick handler, if this Clickable just executes a callback.
 * @param anchorClassname className to put on the `<a/>` tag, should be used with an `href`
 * @param buttonClassname className to put on the `<button/>` tag, should be used with an `onClick`
 * or without an `href` (e.g. for submit buttons)
 * @param children Link contents
 * @param type Used to indicate when a button submits or resets a form, or is just a clickable button.
 * If you want to use this button to POST the current form (eg to a Rails/AA controller),
 * use `type="submit"`.
 * @param disabled Disables the button
 * @param clickableRef Ref to the underlying button or anchor element. This uses
 * the "custom ref property" pattern, which is a workaround for the fact that
 * forwardRef cannot forward overloaded functions which we would need given that
 * the ref would have a different type depending on whether the component is a button
 * or an anchor.
 */
const Clickable = forwardRef<ClickableRefType, ClickableProps>((props, ref) => {
  const {
    href,
    onClick,
    anchorClassname,
    buttonClassname,
    children,
    type = "button",
    disabled,
    ...rest
  } = props;

  if (href && !disabled) {
    return (
      <a
        ref={ref as React.RefObject<HTMLAnchorElement>}
        href={href}
        className={classNames(anchorClassname, clickable)}
        {...(rest as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      type={type}
      ref={ref as React.RefObject<HTMLButtonElement>}
      onClick={onClick}
      className={classNames(buttonClassname, clickable)}
      disabled={disabled}
      {...(rest as React.ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {children}
    </button>
  );
});
Clickable.displayName = "Clickable";

export default Clickable;
