import { Button, Flex, Icons, Text } from "@heart/components";
import { useMountEffect } from "@react-hookz/web";
import { omit, isEmpty, isNil, identity } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";

import generateId from "@lib/generateId";

import { useInputError } from "../inputs/common";
import SurfaceFormGroup from "./SurfaceFormGroup";

/** ### Usage
 *
 * A compound input designed to be used when multiple of the same entity can be entered.
 *
 * Because we are using `startingValues` to populate our form fields only on first render,
 * we need to wait until the data has been loaded before rendering the MultiInputTemplate.
 * To account for this, **MultiInputTemplate will not render when `startingValues` is nil**.
 * Pass an empty array if data has been loaded and there are no starting values to provide.
 *
 * Note: Because this component will dynamically remove inputs, it doesn't always play
 * nicely with password vaults like 1Password and LastPass which try to generate values
 * for fields with a given type
 *
 * ### Cypress
 * * First argument: a selector for each added section
 * * Second argument: the text on the add section button
 * * Third argument: an array of functions to call with cypress commands to execute within the
 * added section

 * example:
 * ```
 * cy.fillMultiInputTemplate(
 *   "[data-heart-component='InputAddress']",
 *   "Add Address",
 *   [
 *     () => {
 *        cy.fillInputAddress({ type: "Home" });
 *      },
 *     () => {
 *        cy.fillInputAddress({ type: "Incarcerated" });
 *     },
 *   ]
 * );
 * ```
 */
const MultiInputTemplateWrapper = ({ startingValues, ...props }) =>
  isNil(startingValues) ? null : (
    <MultiInputTemplate startingValues={startingValues} {...props} />
  );
MultiInputTemplateWrapper.propTypes = {
  /** Starting values for the input. Must be an array of objects where the
   * values are keyed by inputId
   *
   * To indicate that there are no starting values and that data has been fully
   * loaded, pass through an empty array
   */
  startingValues: PropTypes.arrayOf(PropTypes.shape),
};

const MultiInputTemplate = ({
  title,
  hideTitle,
  onChange,
  onDelete,
  defaultValues,
  firstSectionDefaultValues,
  sectionInputs,
  addSectionText,
  removeSectionText,
  startingValues,
  checkForErrors = () => "",
  sectionValidation = () => "",
  "data-testid": dataTestId,
  ...props
}) => {
  const [idsToDelete, setIdsToDelete] = useState([]);
  const [sections, setSections] = useState({});

  const addSection = ({ valuesForSection = {} }) => {
    const id = generateId();
    const sectionValues =
      firstSectionDefaultValues && isEmpty(sections)
        ? {
            ...defaultValues,
            ...firstSectionDefaultValues,
            ...valuesForSection,
          }
        : { ...defaultValues, ...valuesForSection };

    setSections(prevSections => {
      const updatedSections = { ...prevSections, [id]: sectionValues };

      // Call checkForErrors after state updates
      setTimeout(() => checkForErrors(Object.values(updatedSections)), 0);
      return updatedSections;
    });
    /** After adding the new section, we want to jump focus up to the top of
     * the newly added section to avoid forcing users to tab their way back up
     * through the inputs. Ideally we would put focus directly in the first input,
     * but given we aren't prescriptive about what the section inputs are this
     * is an intermediate, relatively low effort step in the right direction
     */
    setTimeout(() => {
      const sectionElement = document.getElementById(id);
      if (sectionElement) {
        const trashElement = sectionElement.querySelector(
          '[data-heart-component="ClickableIcon"]'
        );
        if (trashElement) trashElement.focus();
      }
    }, 0);
  };
  const removeSection = ({ sectionId }) => {
    if (sections[sectionId].id) {
      setIdsToDelete([...idsToDelete, sections[sectionId].id]);
    }
    setSections(omit(sections, sectionId));
  };

  /** Populate sections with any starting values provided */
  useMountEffect(() => {
    const startingSections = {};
    startingValues.forEach(valuesForSection => {
      startingSections[generateId()] = valuesForSection;
    });
    setSections(startingSections);
  });

  useEffect(() => {
    onChange(Object.values(sections));
  }, [onChange, sections]);

  useEffect(() => {
    if (onDelete) onDelete(idsToDelete);
  }, [onDelete, idsToDelete]);

  const getFieldValue = ({
    sectionValues,
    inputId,
    transformValueForInput = identity,
  }) => transformValueForInput(sectionValues[inputId]);

  const setValueForField =
    ({ sectionId, inputId, transformValueForFormState = identity }) =>
    newSectionValue =>
      setSections({
        ...sections,
        [sectionId]: {
          ...sections[sectionId],
          [inputId]: transformValueForFormState(newSectionValue),
        },
      });

  const buildSecondaryButton = ({ sectionId }) => (
    <Icons.Trash
      onClick={() => removeSection({ sectionId })}
      description={removeSectionText}
    />
  );

  const error = useInputError(props);

  return (
    <Fragment>
      {Object.entries(sections).map(([sectionId, sectionValues]) => (
        <SurfaceFormGroup
          id={sectionId}
          key={sectionId}
          title={title}
          hideTitle={hideTitle}
          secondary={buildSecondaryButton({ sectionId })}
          data-testid={dataTestId}
        >
          {sectionInputs({
            getMultiInputProps: ({
              id: inputId,
              transformValueForInput,
              transformValueForFormState,
            }) => ({
              onChange: setValueForField({
                sectionId,
                inputId,
                transformValueForFormState,
              }),
              value: getFieldValue({
                sectionValues,
                inputId,
                transformValueForInput,
              }),
            }),
          })}
          <If condition={!isEmpty(sectionValidation(sectionValues))}>
            <Text textColor="danger-600" role="alert">
              {sectionValidation(sectionValues)}
            </Text>
          </If>
        </SurfaceFormGroup>
      ))}
      {/* TODO: ENG-12656 */}
      <div>
        <Flex column>
          <Button variant="secondary" onClick={addSection}>
            {addSectionText}
          </Button>
          <If condition={error}>
            <span role="alert">{error}</span>
          </If>
        </Flex>
      </div>
    </Fragment>
  );
};
MultiInputTemplate.propTypes = {
  /** Optional error text that will be displayed to the user */
  error: PropTypes.string,
  /**
   * Optional function that checks for validation errors based on the current sections.
   * This function is called after a section is added, and is responsible for determining
   * if an error should be hidden. Typically, this would be used to ensure that a
   * minimum number of sections are present or that certain conditions are met within the
   * form.
   *
   * Example Usage:
   *
   * const checkForErrors = (sections) => {
   *   if (sections.length === 0) {
   *     setError("Please add at least one section.");
   *   } else {
   *     setError(null);
   *   }
   * };
   *
   * <MultiInputTemplate
   *   checkForErrors={checkForErrors}
   *   // other props
   * />
   */
  checkForErrors: PropTypes.func,
  /** Title of each subsection, displayed in the top left. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Whether or not the title of each subsection is hidden. */
  hideTitle: PropTypes.bool,
  /** Subtitle of the subsection, displayed in the top left */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Function which will be called with an array of objects mapping between
   * the inputId and the value for each subsection
   */
  onChange: PropTypes.func.isRequired,
  /** Function which will be called with an array of ids for sections that
   * have been removed from the form by the user. If a section without an
   * id is deleted, this callback will not be triggered
   */
  onDelete: PropTypes.func,
  /** Default values for each field, keyed by input `id` */
  defaultValues: PropTypes.object.isRequired,
  /** Optional default values that are specific to the first section. Will be
   * splatted alongside defaultValues so can just be values that differ
   * from regular defaults */
  firstSectionDefaultValues: PropTypes.object,
  /** A function that renders all the inputs required for a section
   * Takes in a `getMultiInputProps` function, which should be called with
   * an `id` for each `Input`, splatting in the results to each `Input`
   *
   * Optionally, `transformValueForFormState` and `transformValueForInput` can be
   * provided. This is primarily useful when an input expects a format that differs
   * from the format that is returned by the database, e.g. translating an enum to
   * a label/value pair for `InputFilterable`
   */
  sectionInputs: PropTypes.func.isRequired,
  /** What the button for adding another section of inputs should say */
  addSectionText: PropTypes.string.isRequired,
  /** What the alt text for the button removing a section of inputs should say */
  removeSectionText: PropTypes.string.isRequired,
  /** Starting values for the input. Must be an array of objects where the
   * values are keyed by inputId
   *
   * To indicate that there are no starting values and that data has been fully
   * loaded, pass through an empty array
   */
  startingValues: PropTypes.arrayOf(PropTypes.shape),
  /** A validation function that will be called with the values of each section
   * and can return an error to display. The arguments of the function will be
   * the value for each input, keyed by input ids.
   */
  sectionValidation: PropTypes.func,
  /** Test ID for Cypress or Jest, added to each individual section */
  "data-testid": PropTypes.string,
};

export default MultiInputTemplateWrapper;
