import { useMutation } from "@apollo/client";
import { Flex, FlexItem, Button } from "@heart/components";
import { useToggle } from "@react-hookz/web";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React from "react";

import DeletePermissionRule from "@graphql/mutations/DeletePermissionRule";
import * as ResourceActionQuery from "@graphql/queries/ResourceAction.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import ConditionSummary from "./ConditionSummary";
import PermissionsPropTypes from "./PermissionsPropTypes";
import styles from "./ResourceAction.module.scss";
import ResourceActionDescription from "./ResourceActionDescription";

const SomePermissions = ({
  viewOnlyMode,
  permissionRuleId,
  queryVariables,
}) => {
  const [showConditionDetails, toggleShowConditionsDetails] = useToggle(false);

  const [deletePermissionRuleMutation] = useMutation(DeletePermissionRule);
  const deletePermissionRule = () => {
    deletePermissionRuleMutation({
      variables: { id: permissionRuleId },
      refetchQueries: [
        {
          query: ResourceActionQuery,
          variables: queryVariables,
        },
      ],
    });
  };

  return (
    <div
      data-testid={`resource-action-group-${queryVariables.resource}-${queryVariables.action}`}
    >
      <Flex justify="space-between" align="center" row>
        <ResourceActionDescription queryVariables={queryVariables} />
        <Button variant="tertiary" onClick={toggleShowConditionsDetails}>
          {I18n.t(
            showConditionDetails
              ? "javascript.components.permissions.hide_condition_details"
              : "javascript.components.permissions.show_condition_details"
          )}
        </Button>

        <FlexItem className={styles.somePermission}>
          {I18n.t("javascript.components.permissions.conditional_permission")}
        </FlexItem>
        <If condition={!viewOnlyMode}>
          <Button variant="tertiary" onClick={deletePermissionRule}>
            {I18n.t("javascript.components.permissions.revoke")}
          </Button>
        </If>
      </Flex>
      <If condition={showConditionDetails}>
        <ConditionSummary
          queryVariables={queryVariables}
          viewOnly={viewOnlyMode}
        />
      </If>
    </div>
  );
};

SomePermissions.propTypes = {
  viewOnlyMode: PropTypes.bool,
  queryVariables: PermissionsPropTypes.queryVariables,
  permissionRuleId: BintiPropTypes.id,
};

export default SomePermissions;
