import {
  Flex,
  InputCheckbox,
  InputHidden,
  InputText,
  InputTextarea,
} from "@heart/components";
import inputStyles from "@heart/components/inputs/Input.module.scss";
import classNames from "classnames";
import I18n from "i18n-js";
import { isEmpty, isString } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useMemo, useState } from "react";

import { DISALLOWED_FORM_CHARACTERS } from "@root/constants";

import ComparePreviousAnswer from "./ComparePreviousAnswer";
import { questionPropTypes } from "./Question";
import { compareText } from "./comparisonHelper";

const DISALLOWED_FORM_CHARACTERS_REGEXP = new RegExp(
  `[${DISALLOWED_FORM_CHARACTERS.join("")}]`,
  "g"
);
const cleanDisallowedFormCharacters = s =>
  isString(s) ? s.replace(DISALLOWED_FORM_CHARACTERS_REGEXP, "") : s;

const questionnaireTextInputOptions = {
  text: { rows: 5, Component: InputTextarea },
  essay: { rows: 10, Component: InputTextarea },
  full_page: { rows: 10, Component: InputTextarea },
  novella: { rows: 10, Component: InputTextarea },
  string: { type: "text", Component: InputText },
  integer: { type: "number", Component: InputText },
};
export const questionnaireTextInputTypes = Object.keys(
  questionnaireTextInputOptions
);

/**
 * Converts a name attribute from a questionnaire form into a key for the
 * review state object, e.g. "questionnaire_response[answers][foo][bar]" ->
 * "foo.bar".
 *
 * @param {string} name the name attribute from a questionnaire form
 * @returns {string} the key for the review state object
 */
const nameToKey = name =>
  name
    ?.replace("questionnaire_response[answers][", "")
    ?.replace(/\]$/, "")
    ?.replaceAll("][", ".")
    ?.replaceAll(/[\][]/g, "");

const REVIEWED = "reviewed";
const UNREVIEWED = "unreviewed";
const MANUALLY_CHANGED = "manually changed";

/**
 * A textual input for questionnaires!  We built a bunch of features into our
 * inputs to support applicant forms which aren't relevant elsewhere in our
 * application, so this input is a wrapper around `<InputText>`/`<InputTextarea>`
 * to add those features.
 *
 * It's also responsible for translating from the questionnaire YML format
 * into our input props.  `questionnaireTextInputOptions`, above, shows the
 * various question types TAM can configure and our `Input` interpretation
 * of those types.
 */
const QuestionnaireTextInput = ({
  type,
  title,
  description,
  value: currentValue,
  previousValue,
  shouldComparePreviousAnswers,
  shouldPrePopulateFromPrevious,
  max_characters: maxCharacters,
  reviewState = {},
  ...inputProps
}) => {
  const [value, setValue] = useState(() => {
    if (shouldPrePopulateFromPrevious) {
      return cleanDisallowedFormCharacters(currentValue || previousValue || "");
    }

    return cleanDisallowedFormCharacters(currentValue || "");
  });
  const key = useMemo(() => nameToKey(inputProps.name), [inputProps.name]);
  const [reviewValue, setReviewValue] = useState(reviewState[key]);
  const [reviewRequired, setReviewRequired] = useState(
    reviewState[key] === UNREVIEWED
  );

  const cleanedPreviousValue = useMemo(
    () => previousValue && cleanDisallowedFormCharacters(previousValue),
    [previousValue]
  );

  const inputDescription = (
    <Fragment>
      {description}
      <If condition={shouldComparePreviousAnswers}>
        <ComparePreviousAnswer
          similarity={compareText(value, cleanedPreviousValue)}
          previousValue={cleanedPreviousValue}
          questionType={type === "textarea" ? "text" : "string"}
        />
      </If>
    </Fragment>
  );

  const showCharacterWarning =
    value.length > maxCharacters - 10 && maxCharacters !== null;
  const characterWarning = (
    <If condition={showCharacterWarning}>
      <div
        className={classNames(inputStyles.characterLimit, {
          [inputStyles.error]: value.length > maxCharacters,
        })}
      >
        {I18n.t(
          "javascript.components.questionnaires.string_question.character_limit"
        )}{" "}
        {value.length} / {maxCharacters}
      </div>
    </If>
  );

  const inputLabel = ({ Label, ...labelProps }) => (
    <Flex row justify="space-between">
      <Label {...labelProps}>{title}</Label>
      {characterWarning}
    </Flex>
  );

  const { Component, ...questionTypeProps } =
    questionnaireTextInputOptions[type];

  return (
    <Fragment>
      <Component
        /** When the input is disabled, we don't need to worry about things like
         * displaying character counts as the value cannot be changed
         */
        label={inputProps.disabled ? title : inputLabel}
        description={inputDescription}
        value={value}
        onChange={v => {
          setValue(cleanDisallowedFormCharacters(v));

          if (reviewRequired) setReviewRequired(false);
        }}
        maxCharacters={maxCharacters}
        {...inputProps}
        {...questionTypeProps}
      />
      <Choose>
        <When condition={reviewRequired}>
          <InputCheckbox
            name={`questionnaire_response[review_state][${key}]`}
            label={I18n.t(
              "javascript.components.questionnaires.questionnaire_text_input.confirm_correct"
            )}
            htmlValue={reviewValue}
            value={reviewValue === REVIEWED}
            required
            onChange={checked => {
              if (checked) {
                setReviewValue(REVIEWED);
              } else {
                setReviewValue(UNREVIEWED);
              }
            }}
          />
        </When>
        <When condition={!isEmpty(reviewValue)}>
          {/* if there is a review value, we need to wipe it out
          with a hidden input if the user changes the value */}
          <InputHidden
            name={`questionnaire_response[review_state][${key}]`}
            value={MANUALLY_CHANGED}
          />
        </When>
      </Choose>
    </Fragment>
  );
};
QuestionnaireTextInput.propTypes = {
  ...questionPropTypes,
  reviewState: PropTypes.object,
};

export default QuestionnaireTextInput;
