import { useMutation } from "@apollo/client";
import {
  Actions,
  Button,
  InputCheckbox,
  InputDate,
  InputDropdown,
  InputTextarea,
  PageContainer,
  SurfaceForm,
  Surface,
  Flex,
} from "@heart/components";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

import UpdateApplicationStatus from "@graphql/mutations/UpdateApplicationStatus.graphql";

import { snakeCaseToEnum } from "@lib/graphqlHelpers";
import preventDefault from "@lib/preventDefault";

import OverridePanel from "./OverridePanel";
import StatusChangeErrors from "./StatusChangeErrors";
import UpdateApplicationStatusDateLink from "./UpdateApplicationStatusDateLink";
import dynamicFields from "./dynamicFields";
import statusReasonValues from "./statusReasonValues";

const { T, t } = translationWithRoot("change_application_status");

/**
 * UI for changing an application's status.
 *   Applications > Row action "Change Application Status"
 */
const ChangeApplicationStatus = ({
  application,
  allowedStatusMap,
  applicationFormStatusData,
  applicationRenewalDate,
  currentRenewalDate,
  renewalTemplates,
  currentRenewalTemplate,
  canSelectRenewalTemplate,
  ffAppeals,
  appealStatus,
  contactMethods,
  appealLocations,
  defaultAppealLocation,
  appeal,
}) => {
  const [applicationStatus, setApplicationStatus] = useState(
    application.status
  );
  const [statusDate, setStatusDate] = useState(application.statusDate ?? null);

  const [statusReason, setStatusReason] = useState("");
  const [statusExplanation, setStatusExplanation] = useState("");
  const [applicationSignedDate, setApplicationSignedDate] = useState(
    application.dateApplicationStarted
  );
  const [notificationDate, setNotificationDate] = useState(
    application.notificationDate
  );
  const [contactMethod, setContactMethod] = useState(undefined);
  const [appealLocation, setAppealLocation] = useState(defaultAppealLocation);

  const [nextRenewalDueDate, setNextRenewalDueDate] = useState(
    applicationRenewalDate
  );
  const [notSigned, setNotSigned] = useState(application.notSigned);
  const [overriddenReason, setOverriddenReason] = useState("");
  const [overriddenExplanation, setOverriddenExplanation] = useState("");
  const [errors, setErrors] = useState([]);
  const [selectedRenewalTemplateId, setSelectedRenewalTemplateId] = useState(
    currentRenewalTemplate
  );

  // Convenience function to look up display text for a given status from allowedStatusMap
  const statusText = status =>
    allowedStatusMap.find(([mapStatus]) => status === mapStatus)[1];
  const [updateApplicationStatus, { loading }] = useMutation(
    UpdateApplicationStatus
  );

  // If the application's status has not changed, then disable the submit button
  // and don't show the extra informational fields.
  const appStatusChanged = application.status !== applicationStatus;
  const isApproved = applicationStatus === "approved";
  const isApprovedRenewal =
    application.applicationType === "Renewal" && isApproved;
  const {
    allowDate,
    requireStatusReason,
    requireExplanation,
    requireDateApplicationSigned,
    allowNotSigned,
  } = dynamicFields(
    applicationStatus,
    application["outOfCountySending?"],
    applicationFormStatusData
  );

  const requireOverride = !isEmpty(errors);
  const submitForm = preventDefault(() => {
    const variables = {
      applicationId: application.id,
      status: snakeCaseToEnum(applicationStatus),
      statusDate,
      statusReason,
      statusExplanation,
      notificationDate,
      contactMethod,
      selectedRenewalTemplateId,
      // only send appealLocation if the below conditions are true
      ...(appStatusChanged && ffAppeals && { appealLocation }),
      ...(appStatusChanged && isApprovedRenewal && { nextRenewalDueDate }),
      ...((requireDateApplicationSigned || allowNotSigned) && {
        dateApplicationStarted: applicationSignedDate,
      }),
      ...(allowNotSigned && { notSigned }),
      ...(requireOverride && {
        overriddenReason,
        overriddenExplanation,
      }),
    };

    updateApplicationStatus({ variables }).then(
      ({
        data: {
          updateApplicationStatus: { errors: appErrors },
        },
      }) => {
        if (appErrors.length) {
          setErrors(appErrors);
        } else {
          // TODO: https://binti.atlassian.net/browse/ENG-6710
          // Since this page has non-React components on it, we are navigating
          // away from the page so that the non-React data is not stale.
          window.location.href = "?success=1";
        }
      }
    );
  });

  return (
    <PageContainer>
      <StatusChangeErrors
        errors={errors}
        applicationId={application.id}
        licenseId={application.licenseId}
        status={applicationStatus}
      />
      <SurfaceForm
        title="Application Status"
        subtitle={
          <T t="current_status" status={statusText(application.status)} />
        }
        onSubmit={submitForm}
        actions={
          <Actions
            primaryDisabled={
              !application.canEditApplicationStatus || !appStatusChanged
            }
            isSubmitting={loading}
            primarySubmittingText={t("submitting_text")}
            primaryText={<T t="submit_button_text" />}
          />
        }
      >
        <If condition={application.canOverride}>
          <OverridePanel
            visible={requireOverride}
            onChangeReason={setOverriddenReason}
            onChangeExplanation={setOverriddenExplanation}
            reason={overriddenReason}
            explanation={overriddenExplanation}
          />
        </If>
        <InputDropdown
          required
          label={<T t="update_status_label" />}
          name="application[status]"
          value={applicationStatus}
          values={allowedStatusMap.map(([status, text]) => [text, status])}
          onChange={setApplicationStatus}
          hideBlank
          disabled={!application.canEditApplicationStatus}
        />
        <If condition={allowDate}>
          <InputDate
            label={
              <T t="status_date_label" status={statusText(applicationStatus)} />
            }
            id="status-date-picker"
            name="application[status_date]"
            value={statusDate}
            onChange={setStatusDate}
            disabled={!appStatusChanged}
            required
          />
          <If condition={!appStatusChanged && isApproved}>
            <UpdateApplicationStatusDateLink
              allowedStatusMap={allowedStatusMap}
              application={application}
            />
          </If>
        </If>
        <If condition={appStatusChanged && requireStatusReason}>
          <InputDropdown
            required
            label={
              <T
                t="status_reason_label"
                status={statusText(applicationStatus)}
              />
            }
            name="application[status_reason]"
            value={statusReason}
            values={statusReasonValues(
              application,
              applicationFormStatusData,
              applicationStatus
            ).map(value => [
              I18n.t(
                `activerecord.attributes.application/status_reasons.${value}`
              ),
              value,
            ])}
            onChange={setStatusReason}
          />
        </If>
        <If condition={appStatusChanged && requireExplanation}>
          <InputTextarea
            required
            label={
              <T
                t="status_explanation_label"
                status={statusText(applicationStatus)}
              />
            }
            name="application[status_explanation]"
            value={statusExplanation}
            rows={4}
            onChange={setStatusExplanation}
          />
        </If>
        <If condition={canSelectRenewalTemplate && renewalTemplates.length > 1}>
          {appStatusChanged && isApproved && (
            <InputDropdown
              label={<T t="next_renewal_type" />}
              name="application[renewal_template]"
              value={selectedRenewalTemplateId}
              values={renewalTemplates.map(([label, value]) => [label, value])}
              onChange={setSelectedRenewalTemplateId}
            />
          )}
        </If>
        <If
          condition={
            appStatusChanged &&
            ffAppeals &&
            (applicationStatus === "denied" || applicationStatus === "closed")
          }
        >
          <InputDropdown
            label={I18n.t(
              "javascript.components.change_application_status.appeals.notice_sent"
            )}
            onChange={setContactMethod}
            value={contactMethod}
            values={contactMethods.map(([label, value]) => [label, value])}
          />
          <InputDate
            label={I18n.t(
              "javascript.components.change_application_status.appeals.date_of_notice"
            )}
            onChange={setNotificationDate}
            value={notificationDate}
            required={false}
          />
          {appealLocations.length > 1 && (
            <InputDropdown
              label={I18n.t(
                "javascript.components.change_application_status.appeals.appeal_location"
              )}
              description={I18n.t(
                "javascript.components.change_application_status.appeals.appeal_location_description"
              )}
              onChange={setAppealLocation}
              value={appealLocation}
              values={appealLocations.map(([label, value]) => [label, value])}
            />
          )}
        </If>
        <If
          condition={
            appStatusChanged &&
            !isApprovedRenewal &&
            (requireDateApplicationSigned || allowNotSigned)
          }
        >
          <InputDate
            label={<T t="date_signed_label" />}
            id="application-signed-date-picker"
            name="application[date_application_started]"
            onChange={setApplicationSignedDate}
            value={applicationSignedDate}
            required={isApproved}
            disabled={notSigned}
            description={I18n.t(
              "javascript.components.change_application_status.date_application_started_hint"
            )}
          />
        </If>
        <If condition={appStatusChanged && isApprovedRenewal}>
          <InputDate
            label={<T t="next_renewal_date_label" />}
            id="application-renewal-date-picker"
            name="application[next_renewal_due_date]"
            onChange={setNextRenewalDueDate}
            value={nextRenewalDueDate}
            description={
              <T
                t="next_renewal_date_desc"
                existing_renewal_date={currentRenewalDate}
              />
            }
          />
        </If>
        <If condition={appStatusChanged && allowNotSigned}>
          <InputCheckbox
            value={notSigned}
            onChange={newNotSigned => {
              setApplicationSignedDate(null);
              setNotSigned(newNotSigned);
            }}
            name="application[not_signed]"
            htmlValue={notSigned.toString()}
            label={<T t="not_signed_label" />}
          />
        </If>
      </SurfaceForm>
      <If condition={ffAppeals}>
        <If condition={ffAppeals && appealStatus === "noAppeal"}>
          <Surface
            title={I18n.t(
              "javascript.components.change_application_status.appeals.appeals"
            )}
            subtitle={<T t="appeals.no_appeals" />}
            secondary={
              <Button href={`/applications/${application.id}/new_appeal`}>
                {<T t="appeals.create_new_appeal" />}
              </Button>
            }
          ></Surface>
        </If>
        <If condition={ffAppeals && appealStatus === "ongoing"}>
          <Surface
            title={I18n.t(
              "javascript.components.change_application_status.appeals.appeals"
            )}
            subtitle={<T t="appeals.ongoing" />}
            secondary={
              <Button
                variant="secondary"
                href={`/applications/${application.id}/appeals/${appeal.id}`}
              >
                {<T t="appeals.view_appeal" />}
              </Button>
            }
          ></Surface>
        </If>
        <If condition={ffAppeals && appealStatus === "concluded"}>
          <Surface
            title={I18n.t(
              "javascript.components.change_application_status.appeals.appeals"
            )}
            subtitle={<T t="appeals.concluded" />}
            secondary={
              <Flex>
                <Button
                  variant="primary"
                  href={`/applications/${application.id}/new_appeal`}
                >
                  {<T t="appeals.create_new_appeal" />}
                </Button>
                <Button
                  variant="secondary"
                  href={`/applications/${application.id}/appeals/${appeal.id}`}
                >
                  {<T t="appeals.view_appeal" />}
                </Button>
              </Flex>
            }
          ></Surface>
        </If>
      </If>
    </PageContainer>
  );
};

ChangeApplicationStatus.propTypes = {
  /** Mappings of [raw DB status, status text to display to the user]
   *  for allowed status transitions.
   *  Lookup would be easier as a (status -> display text) object, but `camelize_props`
   *  would de-snake the statuses so we have to do this as an array. */
  allowedStatusMap: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
  application: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    statusDate: PropTypes.string,
    applicationType: PropTypes.string.isRequired,
    canEditApplicationStatus: PropTypes.bool.isRequired,
    canOverride: PropTypes.bool.isRequired,
    notSigned: PropTypes.bool.isRequired,
    licenseId: PropTypes.number.isRequired,
    "outOfCountySending?": PropTypes.bool.isRequired,
    dateApplicationStarted: PropTypes.string,
    nextRenewalDueDate: PropTypes.string,
    notificationDate: PropTypes.string,
    licenseType: PropTypes.string,
  }),
  /** This comes from ruby constant APPLICATION_FORM_STATUS_DATA */
  applicationFormStatusData: PropTypes.object.isRequired,
  applicationRenewalDate: PropTypes.string,
  currentRenewalDate: PropTypes.string,
  renewalTemplates: PropTypes.array,
  currentRenewalTemplate: PropTypes.number,
  canSelectRenewalTemplate: PropTypes.bool,
  ffAppeals: PropTypes.bool,
  appeal: PropTypes.shape({
    id: PropTypes.number,
  }),
  appealStatus: PropTypes.oneOf(["noAppeal", "concluded", "ongoing"]),
  contactMethods: PropTypes.array,
  appealLocations: PropTypes.array,
  defaultAppealLocation: PropTypes.string,
};

export default ChangeApplicationStatus;
