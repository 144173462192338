import { useMutation } from "@apollo/client";
import { Modal, toast } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import _ from "lodash";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import CreateConditionalRequirementsMutation from "@graphql/mutations/CreateConditionalRequirements.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import ConditionalRequirementForm from "./ConditionalRequirementForm";

const { t } = translationWithRoot("conditional_requirements");

const initialFormState = {};

/**
 * Form for creating an conditional requirement.
 */
const ConditionalRequirementCreateModal = ({ applicationId, onClose }) => {
  const { formState, setFormAttribute } = useBintiForm(initialFormState);

  const [createConditionalRequirements, { loading }] = useMutation(
    CreateConditionalRequirementsMutation,
    {
      onCompleted: () => {
        onClose();

        toast.success({
          title: t("create_success.header"),
          message: t("create_success.body"),
        });
      },
    }
  );

  const onSubmit = preventDefault(() =>
    createConditionalRequirements({
      variables: {
        input: {
          applicationId,
          assigneeIds: JSON.parse(formState.assigneeIds),
          ..._.omit(formState, "__typename", "assigneeIds"),
        },
      },
    })
  );

  return (
    <Modal
      title={t("title")}
      onSubmit={onSubmit}
      onCancel={onClose}
      submitting={loading}
    >
      <ConditionalRequirementForm
        applicationId={applicationId}
        formState={formState}
        setFormAttribute={setFormAttribute}
      />
    </Modal>
  );
};

ConditionalRequirementCreateModal.propTypes = {
  applicationId: BintiPropTypes.ID,
  onClose: PropTypes.func,
};

export default ConditionalRequirementCreateModal;
