import { useLazyQuery } from "@apollo/client";
import {
  HeartTable,
  InputRadioGroup,
  InputText,
  Link,
} from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useEffect } from "react";
import { editAdminAgencyWorkerPath, rolePath } from "routes";

import { translationWithRoot } from "@components/T";

import AgencyWorkersWithPermissionForAgency from "@graphql/queries/AgencyWorkersWithPermissionForAgency.graphql";

import getErrorsForField from "./getErrorsForField";

const { t: integrationsT } = translationWithRoot("admin.integrations", {
  escapeJavascriptRoot: true,
});
const { t } = translationWithRoot("integrations.clear");

const { Caption, Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

// This form is based on lib/json_schemas/integration_configurations/clear.json
// and in the future, we could likely generate these forms from other JSON schema files
const ClearConfigurationForm = ({
  agencyId,
  formState,
  setFormState,
  setFormAttribute,
  validationErrors,
}) => {
  const errorsForField = getErrorsForField(validationErrors);
  const [agencyWorkers, { data }] = useLazyQuery(
    AgencyWorkersWithPermissionForAgency,
    {
      variables: {
        agencyId,
        action: "create",
        resource: "family_finding_clear_search",
      },
    }
  );

  useEffect(() => {
    if (formState.pricingModel === "seat") agencyWorkers();
  }, [agencyWorkers, formState.pricingModel]);

  return (
    <Fragment>
      <InputText
        label={integrationsT("labels.username")}
        value={formState.username}
        onChange={setFormAttribute("username")}
        error={errorsForField("username")}
        required
      />
      <InputText
        label={integrationsT("labels.password")}
        value={formState.password}
        onChange={setFormAttribute("password")}
        error={errorsForField("password")}
        required
      />
      <InputRadioGroup
        value={formState.pricingModel}
        label={integrationsT("labels.pricing_model")}
        values={[
          { label: t("window"), value: "window" },
          { label: t("seat"), value: "seat" },
        ]}
        onChange={pricing =>
          setFormState({
            ...formState,
            pricingModel: pricing,
            transactionFeeCap: undefined,
            numberOfSeats: undefined,
          })
        }
        error={errorsForField("pricingModel")}
        required
      />
      <If condition={formState.pricingModel === "window"}>
        <InputText
          label={integrationsT("labels.transaction_fee_cap")}
          value={formState.transactionFeeCap}
          onChange={setFormAttribute("transactionFeeCap")}
          error={errorsForField("transactionFeeCap")}
          required
        />
      </If>
      <If condition={formState.pricingModel === "seat"}>
        <InputText
          label={integrationsT("labels.number_of_seats")}
          value={formState.numberOfSeats}
          onChange={setFormAttribute("numberOfSeats")}
          error={errorsForField("numberOfSeats")}
          required
        />
        {t("permissions_table_description")}
        <Table>
          <Caption>
            {t("accounts_with_access", {
              number:
                `(${data?.agencyWorkersWithPermissionForAgency.length})` || "",
            })}
          </Caption>
          <Thead>
            <Tr>
              <Th>{t("name")}</Th>
              <Th>{t("assigned_role")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.agencyWorkersWithPermissionForAgency?.map(
              ({
                id,
                name,
                roleForPermissions: { id: roleId, name: roleName },
              }) => (
                <Tr key={name}>
                  <Td>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href={editAdminAgencyWorkerPath(id)}
                    >
                      {name}
                    </Link>
                  </Td>
                  <Td>
                    <Link target="_blank" href={rolePath(roleId)}>
                      {roleName}
                    </Link>
                  </Td>
                </Tr>
              )
            )}
          </Tbody>
        </Table>
      </If>
    </Fragment>
  );
};

ClearConfigurationForm.propTypes = {
  agencyId: PropTypes.number.isRequired,
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
  setFormAttribute: PropTypes.func.isRequired,
  validationErrors: PropTypes.array,
};
export default ClearConfigurationForm;
