import { useMutation } from "@apollo/client";
import {
  Button,
  HeartTable,
  Icons,
  Flex,
  InputDate,
  InputDropdown,
  toast,
} from "@heart/components";
import I18n from "i18n-js";
import { get, isEmpty, omit, pick, set, uniqueId } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";
import {
  Table as OldTable,
  Caption as OldCaption,
  Thead as OldThead,
  Tbody as OldTbody,
  Tr as OldTr,
  Th as OldTh,
  Td as OldTd,
} from "@components/reusable_ui/Table";

import UpdateProgramAssignments from "@graphql/mutations/UpdateProgramAssignments.graphql";

import preventDefault from "@lib/preventDefault";

const { T } = translationWithRoot("program_assignments");

const uniqueIdGenerator = () => uniqueId("programsForChild");

export const errorUtilities = {
  checkProgramsForOverlap: sortedChildProgramEntries => {
    /* note: program entries are sorted in REVERSE chronological order */
    const checkDateOverlap = (programA, programB) => {
      /* if the second program assignment doesn't have an end date
       *   the first program assignment shouldn't exist */
      if (isEmpty(programB.endDate)) return true;
      /* if the second program start date is before the first program
       *   end date, they overlap bc they're listed reverse chronologically
       *
       * we use slice to strip off any timestamps if present */
      return programA.startDate.slice(0, 10) <= programB.endDate.slice(0, 10);
    };

    if (isEmpty(sortedChildProgramEntries)) return false;
    const sameProgramAssignments = {};
    /* sort programs into groups by program id */
    sortedChildProgramEntries.forEach(entry => {
      const program = entry[1];
      sameProgramAssignments[program.programId] = [
        ...(sameProgramAssignments[program.programId] || []),
        program,
      ];
    });

    /* Only keep lists that have more than one program assignment */
    const multipleProgramAssignmentGroups = Object.values(
      sameProgramAssignments
    ).filter(listOfSamePrograms => listOfSamePrograms.length > 1);
    /* Early return if there are no groups of like programs */
    if (isEmpty(multipleProgramAssignmentGroups)) return false;

    return !isEmpty(
      /* filter through each pair of dates to determine whether any
       *   overlap, if none overlap list should be empty */
      multipleProgramAssignmentGroups.filter(programAssignmentGroup => {
        for (let i = 0; i < programAssignmentGroup.length - 1; i += 1) {
          if (
            checkDateOverlap(
              programAssignmentGroup[i],
              programAssignmentGroup[i + 1]
            )
          ) {
            return true;
          }
        }
        return false;
      })
    );
  },

  flattenErrorMessages: ({ graphQLErrors, networkError }) => {
    let errors = [];
    if (!isEmpty(graphQLErrors)) {
      errors = graphQLErrors.map(({ message }) => message);
    }
    if (!isEmpty(networkError)) {
      errors.push(networkError);
    }

    return errors.join(", ");
  },
};

/** Editor for Child Programs for a given child.
 *    Child details > Edit Child Programs > New | Edit
 */
const ProgramsForm = ({
  childId,
  availablePrograms,
  currentProgramAssignments,
  onEditRedirectTo,
  onSuccessRedirectTo,
  editable,
  mayUpdatePrograms = true,
  useOldTable,
}) => {
  let Table;
  let Caption;
  let Thead;
  let Tbody;
  let Tr;
  let Th;
  let Td;
  if (useOldTable) {
    Table = OldTable;
    Caption = OldCaption;
    Thead = OldThead;
    Tbody = OldTbody;
    Tr = OldTr;
    Th = OldTh;
    Td = OldTd;
  } else {
    ({ Table, Caption, Thead, Tbody, Tr, Th, Td } = HeartTable);
  }

  const redirect = type => {
    window.location.replace(
      type === "edit" ? onEditRedirectTo : onSuccessRedirectTo
    );
  };

  const [updateProgramAssignments] = useMutation(UpdateProgramAssignments, {
    onError: err =>
      toast.negative({
        message: errorUtilities.flattenErrorMessages(err),
      }),
    onCompleted: () => {
      setSubmitting(false);
      redirect();
    },
  });

  const [submitting, setSubmitting] = useState(false);
  const [formRowErrors, setFormRowErrors] = useState({});
  const [deletedProgramAssignmentIds, setDeletedProgramAssignmentIds] =
    useState([]);
  const [childPrograms, setChildPrograms] = useState(
    /* When rendering each row, if we are reliant on an arbitrary index the
     *   row instances don't behave independently. To avoid this issue, we
     *   determine uniqueIds to associate with each row.
     *
     * We also want to track what the form currently has as the start and end
     *   dates so we can display some validation error messaging as appropriate
     */
    currentProgramAssignments.reduce(
      (memo, program) => ({
        ...memo,
        [uniqueIdGenerator()]: {
          ...program,
          startDate: isEmpty(program.startDate)
            ? new Date().toISOString()
            : program.startDate,
        },
      }),
      {}
    )
  );

  /* we want to be able to look up a program by id to display the name
   *   when in read-only mode, but to do so we need to reverse the order
   *   of keys and values provided in the original availablePrograms array
   */
  const availableProgramsById = Object.fromEntries(
    availablePrograms.map(([programName, programId]) => [
      programId,
      programName,
    ])
  );

  const sortedChildProgramEntries = Object.entries(childPrograms).sort(
    (programA, programB) =>
      /* sort programs in reverse chronological order by start date */
      programA[1].startDate < programB[1].startDate ? 1 : -1
  );

  const checkDateChronology = ({ id, startDate, endDate }) => {
    const errors = { start_date_error: isEmpty(startDate) };
    if (!isEmpty(endDate) && !isEmpty(startDate)) {
      /* We don't want to take time into account in these
       *   cases, so we convert the string to a date and strip
       *   off the timestamp
       *
       * This comparison sets `end_date_error` to `true` if the
       *   startDate is later than the endDate, which in turn
       *   triggers an error to display indicating they have to
       *   be in a valid chronological order
       */
      errors.end_date_error = startDate.slice(0, 10) > endDate.slice(0, 10);
    }

    setErrorsForRow({
      id,
      errors,
    });
  };

  const setErrorsForRow = ({ id, errors }) => {
    let newFormErrors = formRowErrors;
    Object.entries(errors).forEach(([key, val]) => {
      if (val) {
        newFormErrors = set(newFormErrors, `${id}.${key}`, val);
      } else {
        newFormErrors = omit(newFormErrors, [`${id}.${key}`]);
      }
    });
    setFormRowErrors(newFormErrors);
  };

  const addRow = () => {
    const id = uniqueIdGenerator();
    setChildPrograms({
      ...childPrograms,
      [id]: {
        programId: "",
        startDate: DateTime.local().toISODate(),
        endDate: "",
      },
    });

    setErrorsForRow({
      id,
      errors: { program_name_error: true },
    });
  };

  const removeRow = id => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        I18n.t("javascript.components.program_assignments.are_you_sure")
      )
    ) {
      if (childPrograms[id].id) {
        setDeletedProgramAssignmentIds([
          ...deletedProgramAssignmentIds,
          childPrograms[id].id,
        ]);
      }

      setChildPrograms(omit(childPrograms, [id]));
      setFormRowErrors(omit(formRowErrors, [id]));
    }
  };

  const onSubmit = preventDefault(() => {
    setSubmitting(true);

    const programAssignments = Object.values(childPrograms).map(program =>
      pick(program, ["id", "programId", "startDate", "endDate"])
    );
    updateProgramAssignments({
      variables: {
        childId: parseInt(childId, 10),
        deletedProgramAssignmentIds,
        programAssignments,
      },
    });
  });

  const hasProgramOverlaps = errorUtilities.checkProgramsForOverlap(
    sortedChildProgramEntries
  );
  return (
    <form onSubmit={onSubmit}>
      <Table>
        <Caption>
          <Flex justify="space-between">
            <T t={`table_caption_${editable ? "editable" : "show"}`} />
            <If condition={mayUpdatePrograms}>
              <Button
                onClick={editable ? addRow : () => redirect("edit")}
                icon={editable ? Icons.Plus : Icons.Pencil}
              >
                {I18n.t(
                  `javascript.components.program_assignments.${
                    editable ? "add_row_button" : "edit_programs"
                  }`
                )}
              </Button>
            </If>
          </Flex>
        </Caption>
        <Thead>
          <Tr>
            <Th>
              <T t="program" />
            </Th>
            <Th>
              <T t="start_date" />
            </Th>
            <Th>
              <T t="end_date" />
            </Th>
            <If condition={editable}>
              <Th>
                <T t="actions" />
              </Th>
            </If>
          </Tr>
        </Thead>
        <Tbody>
          {/* eslint-disable-next-line compat/compat */}
          {sortedChildProgramEntries.map(([id, program]) => (
            <Tr key={id}>
              <Td>
                <If condition={editable}>
                  <InputDropdown
                    label={<T t="program_label" />}
                    hideLabel
                    required
                    values={availablePrograms}
                    value={program.programId}
                    onChange={newProgramId => {
                      setChildPrograms({
                        ...childPrograms,
                        [id]: {
                          ...childPrograms[id],
                          programId: newProgramId,
                        },
                      });

                      setErrorsForRow({
                        id,
                        errors: { program_name_error: isEmpty(newProgramId) },
                      });
                    }}
                    error={
                      get(formRowErrors, `${id}.program_name_error`)
                        ? I18n.t(
                            "javascript.components.program_assignments.program_error"
                          )
                        : undefined
                    }
                  />
                </If>
                <If condition={!editable}>
                  {availableProgramsById[program.programId]}
                </If>
              </Td>
              <Td>
                <If condition={editable}>
                  <InputDate
                    label={<T t="start_date_label" />}
                    hideLabel
                    value={program.startDate}
                    onChange={newDate => {
                      setChildPrograms({
                        ...childPrograms,
                        [id]: {
                          ...childPrograms[id],
                          startDate: newDate,
                        },
                      });

                      checkDateChronology({
                        id,
                        startDate: newDate,
                        endDate: program.endDate,
                      });
                    }}
                    error={
                      get(formRowErrors, `${id}.start_date_error`)
                        ? I18n.t(
                            "javascript.components.program_assignments.start_date_error"
                          )
                        : undefined
                    }
                    required
                  />
                </If>
                <If condition={!editable}>{program.startDate}</If>
              </Td>
              <Td>
                <If condition={editable}>
                  <InputDate
                    label={<T t="end_date_label" />}
                    hideLabel
                    value={program.endDate}
                    onChange={newDate => {
                      setChildPrograms({
                        ...childPrograms,
                        [id]: { ...childPrograms[id], endDate: newDate },
                      });

                      checkDateChronology({
                        id,
                        startDate: program.startDate,
                        endDate: newDate,
                      });
                    }}
                    error={
                      get(formRowErrors, `${id}.end_date_error`)
                        ? I18n.t(
                            "javascript.components.program_assignments.end_date_error"
                          )
                        : undefined
                    }
                  />
                </If>
                <If condition={!editable}>{program.endDate}</If>
              </Td>
              <If condition={editable}>
                <Td>
                  <Button
                    variant="secondary"
                    onClick={() => removeRow(id)}
                    icon={Icons.Trash}
                    description={I18n.t(
                      "javascript.components.program_assignments.remove_row_button"
                    )}
                  />
                </Td>
              </If>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <If condition={hasProgramOverlaps}>
        <div className="errors space-above-1" role="alert">
          <T t="overlap_error" />
        </div>
      </If>
      <If condition={editable}>
        <Flex justify="end" className="space-above-2">
          <Button variant="secondary" disabled={submitting} onClick={redirect}>
            {I18n.t("views.common.cancel")}
          </Button>
          <Button
            type="submit"
            submitting={submitting}
            variant="primary"
            disabled={
              hasProgramOverlaps ||
              !isEmpty(
                /* Filter through each row's errors, disable submit
                 *   button if any error keys are true */
                Object.values(formRowErrors).filter(
                  err => !isEmpty(Object.values(err).filter(Boolean))
                )
              )
            }
          >
            {I18n.t("views.common.submit")}
          </Button>
        </Flex>
      </If>
    </form>
  );
};

ProgramsForm.propTypes = {
  childId: PropTypes.string.isRequired,
  availablePrograms: PropTypes.arrayOf(PropTypes.array).isRequired,
  currentProgramAssignments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      programId: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    })
  ).isRequired,
  onEditRedirectTo: PropTypes.string,
  onSuccessRedirectTo: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  mayUpdatePrograms: PropTypes.bool,
  /** This is currently necessary to make the styling of this table
   * match that of the ActiveAdmin tables on the Child Data Page.
   * Ideally we eventually remove that styling
   */
  useOldTable: PropTypes.bool,
};

export default ProgramsForm;
