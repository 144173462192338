import {
  NodeDiagramUnstyledHandle,
  Avatar,
  Flex,
  Text,
} from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Fragment } from "react";

import styles from "./Connection.module.scss";

/** A node representing the keystone child for our genograms, with
 * handles that can be connected to other ConnectionNodes
 */
const ChildNode = ({ data: { firstName, lastName, fullName } }) => (
  <Fragment>
    <NodeDiagramUnstyledHandle id="child" position="bottom" />
    <NodeDiagramUnstyledHandle id="sibling" position="top" />
    <NodeDiagramUnstyledHandle id="parent" position="top" />
    <NodeDiagramUnstyledHandle id="partner" position="bottom" />
    <NodeDiagramUnstyledHandle id="fictive-kin" />
    <Flex className={styles.container}>
      <Flex
        column
        className={classNames(styles.child, styles.card)}
        align="center"
      >
        <Avatar
          firstName={firstName}
          lastName={lastName}
          size={600}
          variant="light"
        />
        <Text textStyle="emphasis-100">{fullName}</Text>
      </Flex>
    </Flex>
  </Fragment>
);
ChildNode.propTypes = {
  data: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default ChildNode;
