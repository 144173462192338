import {
  offset,
  autoUpdate,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { Flex, FlexItem, LiveRegion } from "@heart/components";
import { useKeyboardEvent } from "@react-hookz/web";
import classNames from "classnames";
import { keyBy, isEmpty, uniqueId } from "lodash";
import PropTypes from "prop-types";
import { createRef, useEffect, useMemo, useState } from "react";

import useStateList from "@lib/react-use/useStateList";

import Clickable from "../clickable/Clickable";
import styles from "./Menu.module.scss";

/** Generate list of alphabet to tie key presses to */
const alpha = Array.from(Array(26)).map((e, i) => i + 65);
const alphabet = alpha.map(x => String.fromCharCode(x));

/**
 * Base component for building link menu popovers.  Contains a lot of logic to make the component
 * keyboard-navigable and friendly to screen readers!
 *
 * Most of the time, you'll probably be using `IconMenu` and `ButtonMenu`.  This story/component
 * exists to document all the props available across `Menu` implementations, and is also available
 * if you need to build any other custom Menu implementations.
 *
 * The a11y spec for this component can be found [here](https://www.w3.org/WAI/ARIA/apg/example-index/menu-button/menu-button-links.html)
 *
 * ### Cypress
 *   * `cy.clickMenuItem("Actions", "Waive")`
 *   * `cy.findMenuItem("Actions", "Waive").should("exist")`
 */
const Menu = ({
  buttonComponent,
  menuDescription,
  linkItems = [],
  rightAligned = false,
  "data-testid": testId,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  /** Initializing Floating UI's hook */
  const { refs, floatingStyles, context } = useFloating({
    open: showMenu,
    onOpenChange: setShowMenu,
    /** Specifying how the Menu should be aligned in relation to the button
     * that triggers the floating Menu content to appear
     */
    placement: rightAligned ? "bottom-end" : "bottom-start",
    /** Updating the floating Menu content's position automatically so that
     * as folks scroll around the page or resize their screen the Menu stays
     * anchored to the associated button
     */
    whileElementsMounted: autoUpdate,
    /** Setting our offset to -1 helps right aligned Menus display correctly */
    middleware: [offset(() => (rightAligned ? { crossAxis: -1 } : {}))],
  });
  const dismiss = useDismiss(context, { escapeKey: false });
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  /** In order to avoid setting focus on the initial render, we need to track
   * the first time a relevant key is pressed, and only then do we take
   * `state` into account in `useEffect`
   */
  const menuId = uniqueId();
  const linksById = useMemo(
    () =>
      keyBy(
        linkItems.map((item, index) => ({
          id: `menu-item-${index}`,
          linkRef: createRef(null),
          ...item,
        })),
        "id"
      ),
    [linkItems]
  );

  const {
    state: focusedItemId,
    prev,
    next,
    setStateAt,
    currentIndex,
  } = useStateList(Object.keys(linksById));

  const startingLetters = linkItems.map(item =>
    item?.description?.charAt(0).toUpperCase()
  );
  useKeyboardEvent(alphabet, keyPressed => {
    if (showMenu) {
      const key = keyPressed?.key?.toUpperCase();
      if (alphabet.includes(key)) {
        const lettersFromCurrentIndex = [
          ...startingLetters.slice(currentIndex + 1),
          ...startingLetters.slice(0, currentIndex + 1),
        ];
        const index = lettersFromCurrentIndex.indexOf(key);
        if (index !== -1) setStateAt(index + currentIndex + 1);
      }
    }
  });

  useKeyboardEvent("Escape", () => {
    if (showMenu) {
      setShowMenu(false);
      refs.reference.current.focus();
    }
  });
  useKeyboardEvent("ArrowLeft", e => {
    if (showMenu) {
      /** for all keys that usually will scroll the page, we want to prevent
       * that behavior while the menu is open as the arrow keys are being used
       * to scroll around the menu instead
       */
      e.preventDefault();
      setShowMenu(false);
      refs.reference.current.focus();
    }
  });
  useKeyboardEvent("ArrowRight", e => {
    /** refs.reference.current is the button element that opens the Menu */
    if (!showMenu && document.activeElement === refs.reference.current) {
      e.preventDefault();
      setShowMenu(true);
      setStateAt(0);
    } else if (showMenu) {
      e.preventDefault();
      setShowMenu(false);
      refs.reference.current.focus();
    }
  });
  useKeyboardEvent("ArrowUp", e => {
    /** refs.reference.current is the button element that opens the Menu */
    if (!showMenu && document.activeElement === refs.reference.current) {
      e.preventDefault();
      setShowMenu(true);
      setStateAt(linkItems.length - 1);
    } else if (showMenu) {
      e.preventDefault();
      prev();
    }
  });
  useKeyboardEvent("ArrowDown", e => {
    if (!showMenu && document.activeElement === refs.reference.current) {
      e.preventDefault();
      setShowMenu(true);
      setStateAt(0);
    } else if (showMenu) {
      e.preventDefault();
      next();
    }
  });
  useKeyboardEvent("Home", e => {
    if (showMenu) {
      e.preventDefault();
      setStateAt(0);
    }
  });
  useKeyboardEvent("End", e => {
    if (showMenu) {
      e.preventDefault();
      setStateAt(linkItems.length - 1);
    }
  });

  useEffect(() => {
    if (showMenu) {
      const refToFocus = linksById[focusedItemId].linkRef.current;
      if (!isEmpty(refToFocus)) refToFocus.focus({ preventScroll: true });
    }
  }, [showMenu, focusedItemId, linksById]);

  if (isEmpty(linkItems)) return null;

  const getButton = () => {
    const toggleMenu = () => {
      setStateAt(0);
      setShowMenu(!showMenu);
    };

    return buttonComponent({
      isOpen: showMenu,
      id: `menu-${menuId}`,
      ref: refs.setReference,
      "data-testid": testId,
      "aria-haspopup": "true",
      "aria-controls": `menuContent-${menuId}`,
      "aria-expanded": showMenu,
      description: menuDescription,
      "data-heart-component": "MenuButton",
      onClick: toggleMenu,
      ...getReferenceProps,
    });
  };

  return (
    <LiveRegion>
      <Flex
        justify={rightAligned ? "end" : "start"}
        data-heart-component="Menu"
      >
        {getButton()}
        <Flex
          as="ul"
          column
          id={`menuContent-${menuId}`}
          aria-labelledby={`menu-${menuId}`}
          ref={refs.setFloating}
          style={floatingStyles}
          className={classNames(styles.menuContent, {
            [styles.hidden]: !showMenu,
          })}
          role="menu"
          {...getFloatingProps}
        >
          {Object.values(linksById).map(
            ({ id, description, content, linkRef, ...linkProps }, index) => (
              <FlexItem key={index} as="li" role="presentation">
                <Clickable
                  id={id}
                  ref={linkRef}
                  tabIndex="-1"
                  role="menuitem"
                  anchorClassname={classNames(
                    styles.menuItem,
                    styles.menuItemAnchor
                  )}
                  buttonClassname={classNames(
                    styles.menuItem,
                    styles.menuItemButton
                  )}
                  data-heart-component="MenuItem"
                  {...linkProps}
                >
                  <If condition={isEmpty(content)}>{description}</If>
                  <If condition={!isEmpty(content)}>{content}</If>
                </Clickable>
              </FlexItem>
            )
          )}
        </Flex>
      </Flex>
    </LiveRegion>
  );
};
Menu.propTypes = {
  /** What component should spawn the menu?  This uses the `render props`
   * pattern - you give it a component that accepts all the usual props for
   * button elements that you should put on a `<button>` for the menu to work properly,
   * and also:
   *   * `isOpen`: so you can change the appearance of the button whether the
   *     menu is open or closed.  Destructure this whether you're using it or not;
   *    it shouldn't end up on the `<button>`.
   *   * `description`: contains `menuDescription`.  Make sure to also put this on
   *    `title` and `aria-label` so that the button can be read by screen readers.
   */
  buttonComponent: PropTypes.oneOfType([PropTypes.elementType]).isRequired,
  /** *Required when using an icon-only menu button.* Used to indicate to
   * screen readers what the purpose of the button is, and puts useful
   * hover text on the button.
   */
  menuDescription: PropTypes.string,
  /** A list of items that should be displayed as Link elements.
   * If provided with an empty list, menu will not be rendered.
   *
   * the `description` prop is used for keyboard navigation: when an a-z key
   * is pressed, it moves focus to the next menu item with a label that starts
   * with the typed character, if such a menu item exists
   *
   * If `content` is omitted, the `description` will be displayed
   */
  linkItems: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      content: PropTypes.node,
    })
  ).isRequired,
  /** Whether dropdown menu should be right-aligned, relative to the
   * menu button
   */
  rightAligned: PropTypes.bool,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};

export default Menu;
