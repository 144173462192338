import { InputRadioGroup, InputEthnicities } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

const { t, T } = translationWithRoot(
  "background_check_exemptions.personal_details_inputs"
);

const PersonalDetails = ({
  formState,
  setFormAttribute,
  annualIncomeOptions,
  useRacesInput,
}) => (
  <Fragment>
    <InputRadioGroup
      label={t("is_required_for_child")}
      name={"is_required_for_child"}
      orientation="row"
      value={formState.isRequiredForChild || false}
      values={[
        { label: "Yes", value: true },
        { label: "No", value: false },
      ]}
      onChange={setFormAttribute("isRequiredForChild")}
    />
    <If condition={formState.isRequiredForChild === true}>
      <InputRadioGroup
        label={t("is_relative_of_child")}
        name={"is_relative_of_child"}
        orientation="row"
        value={formState.isRelativeOfChild}
        values={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
        onChange={setFormAttribute("isRelativeOfChild")}
      />
      <InputRadioGroup
        label={t("is_child_tribal_member")}
        name={"is_child_tribal_member"}
        orientation="row"
        value={formState.isChildTribalMember}
        values={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
        onChange={setFormAttribute("isChildTribalMember")}
      />
      <InputRadioGroup
        label={t("annual_income")}
        name={"annual_income_range"}
        value={formState.adultProfile.annualIncomeRange || undefined}
        values={annualIncomeOptions.map(income => ({
          label: I18n.t(
            `activerecord.enums.background_check_exemption.annual_income.${income}`
          ),
          value: income,
        }))}
        onChange={newIncome => {
          setFormAttribute("adultProfile")({
            ...formState.adultProfile,
            annualIncomeRange: newIncome,
          });
        }}
      />
      <T t="describe_ethnicity" />
      <InputEthnicities
        useRacesInput={useRacesInput}
        required={false}
        selectedEthnicities={formState.adultProfile.ethnicities || undefined}
        onEthnicitiesChange={newEthnicities => {
          setFormAttribute("adultProfile")({
            ...formState.adultProfile,
            ethnicities: newEthnicities,
          });
        }}
        selectedRaces={formState.adultProfile.races || undefined}
        onRacesChange={newRaces => {
          setFormAttribute("adultProfile")({
            ...formState.adultProfile,
            races: newRaces,
          });
        }}
      />
    </If>
  </Fragment>
);

PersonalDetails.propTypes = {
  formState: PropTypes.object.isRequired,
  setFormAttribute: PropTypes.func.isRequired,
  annualIncomeOptions: PropTypes.array,

  /** Part of ethnicites/races migration. Remove in ENG-21169 */
  useRacesInput: PropTypes.bool.isRequired,
};

export default PersonalDetails;
