import { useMutation, useQuery } from "@apollo/client";
import { Flex, InputCheckboxGroup, Modal, Text } from "@heart/components";
import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

import { translationWithRoot } from "@components/T";
import createNameAndLabels from "@components/common/createNameAndLabels";

import CreateOrUpdateAgencyHuman from "@graphql/mutations/CreateOrUpdateAgencyHuman.graphql";
import AgencyHumanAutocompleteQuery from "@graphql/queries/AgencyHumanAutocomplete.graphql";
import PrimarySubdivisionsQuery from "@graphql/queries/PrimarySubdivisions.graphql";

import { dateTimeFromAnyFormat } from "@lib/dates";

import {
  fromLegacyCheckboxGroupValue,
  toLegacyCheckboxGroupValue,
} from "../../../heart/components/inputs/InputCheckboxGroup";
import {
  convertToAddressInputs,
  convertToPhoneNumberInputs,
} from "./modalConversions";

const { t } = translationWithRoot(
  "family_finding.potential_kin_search.potential_kin"
);

/**
 * Converts an array of potential kin into an object keyed by a combination of their name, DOB, and ID
 * Each entry includes the display label, value, and other kin properties
 * @param {Array} potentialKin - Array of potential kin objects
 * @returns {Object} Object with keys formatted as "name (DOB)-entityId" and values containing kin data
 */
export const potentialKinKeyedByIdentifiers = potentialKin =>
  potentialKin.reduce(
    (
      keyedObject,
      { fullName, dateOfBirth, entityId, confidential, sealed, ...kin }
    ) => ({
      ...keyedObject,
      [`${createNameAndLabels({
        fullName,
        dateOfBirth,
        options: {
          setZone: true,
        },
        confidential,
        sealed,
      })}-${entityId}`]: {
        label: createNameAndLabels({
          fullName,
          dateOfBirth,
          options: { setZone: true },
          confidential,
          sealed,
        }),
        value: `${createNameAndLabels({
          fullName,
          dateOfBirth,
          options: {
            setZone: true,
          },
          confidential,
          sealed,
        })}-${entityId}`,
        fullName,
        dateOfBirth,
        confidential,
        sealed,
        ...kin,
      },
    }),
    {}
  );

/**
 * Modal component for linking multiple potential connections to children
 * Allows users to select which children and potential connections to link
 */
const LinkAllPotentialConnectionsModal = ({
  childAgencyHumans,
  setChildAgencyHumans,
  potentialKin,
  hidden,
  setModalTypeVisibility,
  onCancel,
  setLinkConnectionResults,
}) => {
  const { data: primarySubdivisionsData = {} } = useQuery(
    PrimarySubdivisionsQuery,
    {
      variables: { countryCode: "US" },
    }
  );
  const { primarySubdivisions = [] } = primarySubdivisionsData;

  const humansFromResponse = response => [
    ...(response?.agencyHumanMatches || []).map(
      ({
        id,
        childId,
        fullName,
        dateOfBirth,
        agencyId,
        confidential,
        sealed,
      }) => ({
        label: createNameAndLabels({
          fullName,
          dateOfBirth,
          confidential,
          sealed,
        }),
        value: id,
        childId,
        agencyId,
      })
    ),
  ];
  const keyedPotentialKin = useMemo(
    () => potentialKinKeyedByIdentifiers(potentialKin),
    [potentialKin]
  );
  const potentialKinValues = useMemo(
    () => Object.values(keyedPotentialKin),
    [keyedPotentialKin]
  );
  const [selectedPotentialConnections, setSelectedPotentialConnections] =
    useState(Object.keys(keyedPotentialKin));

  useEffect(() => {
    setSelectedPotentialConnections(Object.keys(keyedPotentialKin));
  }, [keyedPotentialKin]);

  const [createOrUpdateAgencyHuman, { loading: createAHLoading }] = useMutation(
    CreateOrUpdateAgencyHuman,
    {
      // Setting bintiSkipMutationErrorHandling opts out this operation from the
      // global mutation error handler in BintiApolloProvider.
      context: { bintiSkipMutationErrorHandling: true },
    }
  );

  return (
    <Modal
      asForm
      hidden={hidden}
      onCancel={onCancel}
      title={t("link_all_potential_kin_as_potential_connections")}
      submitting={createAHLoading}
      submitText={t("create_new_potential_connections")}
      onSubmit={async () => {
        if (isEmpty(childAgencyHumans)) return;

        // Use allSettled to run all mutations to completion
        const results = await Promise.allSettled(
          selectedPotentialConnections.map(async label => {
            const {
              fullName,
              firstName,
              middleName,
              lastName,
              dateOfBirth,
              dateOfDeath,
              addresses,
              phoneNumbers,
              emailAddress,
              confidential,
              sealed,
            } = keyedPotentialKin[label];
            const { agencyId } = childAgencyHumans[0];
            /** Create all new AgencyHumans who will be potential connections,
             * and connecting them to the children selected
             */
            try {
              return await createOrUpdateAgencyHuman({
                variables: {
                  agencyId,
                  childAgencyHumanIds: childAgencyHumans.map(
                    ({ value }) => value
                  ),
                  firstName: firstName,
                  middleName: middleName,
                  lastName: lastName,
                  dateOfBirth: dateOfBirth,
                  dateOfDeath: dateOfDeath
                    ? dateTimeFromAnyFormat(dateOfDeath)
                    : null,
                  isDeceased: Boolean(dateOfDeath),
                  addresses: convertToAddressInputs({
                    addresses: addresses.filter(({ valid }) => valid),
                    agencyId,
                    primarySubdivisions,
                  }),
                  phoneNumbers: convertToPhoneNumberInputs({
                    phoneNumbers: phoneNumbers.filter(({ valid }) => valid),
                  }),
                  emailAddresses: emailAddress
                    ? [{ emailAddress: emailAddress, primary: true }]
                    : [],
                },
              });
            } catch (reason) {
              // Catch failed operations and re-throw a new Error with the
              // human's name and DOB attached, for later display
              throw new Error(
                createNameAndLabels({
                  fullName,
                  dateOfBirth,
                  confidential,
                  sealed,
                }),
                {
                  // Preserve the original reason
                  cause: reason,
                }
              );
            }
          })
        );

        setLinkConnectionResults(results);
        setModalTypeVisibility("success");
      }}
    >
      <Flex column gap="200">
        <Text>{t("link_help_text")}</Text>
        <InputAutocompleteGraphQL
          required
          label={t("children")}
          query={AgencyHumanAutocompleteQuery}
          queryVariables={{ filterForChildren: true }}
          valuesFromResponse={humansFromResponse}
          onChange={setChildAgencyHumans}
          value={childAgencyHumans}
          isMulti
        />
        <InputCheckboxGroup
          label={t("confirm_potential_kin_selected")}
          value={fromLegacyCheckboxGroupValue(selectedPotentialConnections)}
          values={potentialKinValues}
          onChange={val =>
            setSelectedPotentialConnections(toLegacyCheckboxGroupValue(val))
          }
          selectAll={{ type: "links" }}
        />
      </Flex>
    </Modal>
  );
};
LinkAllPotentialConnectionsModal.propTypes = {
  /** Whether modal should be hidden or not */
  hidden: PropTypes.bool.isRequired,
  /** A function to change the state variable which determines whether this modal is hidden */
  setModalTypeVisibility: PropTypes.func.isRequired,
  /** Action to take when cancel button is clicked */
  onCancel: PropTypes.func.isRequired,
  /** When provided, will prepopulate the InputFilterable with the provided child(ren).
   * Should be provided with a label/value pair, as well as the agencyId
   */
  childAgencyHumans: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      agencyId: PropTypes.string.isRequired,
      childId: PropTypes.string.isRequired,
    })
  ).isRequired,
  setChildAgencyHumans: PropTypes.func.isRequired,
  /** A list of the potential connections to be connected to the child(ren) */
  potentialKin: PropTypes.arrayOf(
    PropTypes.shape({ fullName: PropTypes.string.isRequired })
  ),
  /** Function to pass up results of mutation batch to outer components */
  setLinkConnectionResults: PropTypes.func.isRequired,
};

export default LinkAllPotentialConnectionsModal;
