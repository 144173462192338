import {
  Flex,
  InputCheckbox,
  InputRadioGroup,
  InputText,
  Link,
} from "@heart/components";
import classNames from "classnames";
import { isEmpty, isNil, omit } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Choose } from "tsx-control-statements/components";

import { translationWithRoot } from "@components/T";

import {
  inputCommonPropTypes,
  InputGroupLayout,
  inputGroupCommonPropTypes,
} from "./common";
import styles from "./common/RadioAndCheckboxCommonStyles.module.scss";

const { t } = translationWithRoot(
  "heart.components.inputs.input_checkbox_group"
);

const optionValue = val => val.value || val;
const optionLabel = val => val.label || val;

/** Converts the new object format to an array of values for existing
 * InputCheckboxGroup usecases
 */
export const toLegacyCheckboxGroupValue = (value = {}) =>
  Object.keys(value).reduce((convertedVals, val) => {
    if (!value[val].checked) return convertedVals;
    /** It's safe to remove "option" here only because none of our legacy
     * InputCheckboxes use a nested radio group. Theoretically that means
     * the option key should never even be present to begin with, but we're
     * clearing it out to make things more :sparkle: robust :sparkle:
     */
    const checkboxMetadata = omit(value[val], "checked", "option");
    return isEmpty(checkboxMetadata)
      ? [...convertedVals, val]
      : [...convertedVals, checkboxMetadata];
  }, []);

/** Converts an array of values into the new object format */
export const fromLegacyCheckboxGroupValue = value =>
  Array.isArray(value)
    ? (value || []).reduce(
        (convertedVals, val) => ({
          ...convertedVals,
          [optionValue(val)]:
            typeof val === "object"
              ? { checked: true, ...val }
              : { checked: true },
        }),
        {}
      )
    : value;

/** Determine values for selected checkboxes */
const selectedValuesArray = value =>
  Object.keys(value || []).reduce(
    (checkedVals, val) =>
      value[val].checked ? [...checkedVals, val] : checkedVals,
    []
  );

/** extract the values for all the checkboxes as an array
 *
 * we use the array format to determine whether all checkboxes have been selected
 * in order to make the select all/select none controls responsive to manually checking
 * and unchecking boxes
 */
const allValuesArray = values => {
  let allCheckboxValuesArray = [];
  values.forEach(val => {
    const optionVal = optionValue(val);
    /** We only want to add the value as a checkbox if there aren't any nested options, or
     * if those nested options are a radio group or textbox.
     *
     * If the nested options are checkboxes, the top level label isn't a selectable option
     */
    if (isEmpty(val.options) || val.nestedAsRadio || val.nestedAsText) {
      allCheckboxValuesArray.push(optionVal);
    }
    /** If the options are a radio group, we can skip them as they aren't impacted by
     * the "select all"/"select none" behavior.
     *
     * If the options are nested checkboxes, recursively determine the value of those
     * sub-options
     */
    if (val.options && !(val.nestedAsRadio || val.nestedAsText)) {
      const subCheckboxValsArray = allValuesArray(val.options);

      allCheckboxValuesArray =
        allCheckboxValuesArray.concat(subCheckboxValsArray);
    }
  });

  return allCheckboxValuesArray;
};

/**
 * A collection of checkbox inputs handled in aggregate.  When included in a `<form>` POST,
 * these are represented as a list of values in the HTTP params.
 *
 * **Note**: Using the `required` prop will *not* prevent form submissions.
 *
 * Relevant Cypress commands:
 *   * `cy.findRadioOrCheckboxInput(groupName, label).check()`
 */
const InputCheckboxGroup = props => {
  const {
    onChange,
    onBlur,
    value,
    values,
    selectAll = {},
    forLegacyCheckboxGroupAA,
  } = props;
  const [inputValue, setInputValue] = useState(value || {});
  useEffect(() => {
    if (value)
      setInputValue(
        forLegacyCheckboxGroupAA ? fromLegacyCheckboxGroupValue(value) : value
      );
  }, [value, forLegacyCheckboxGroupAA]);

  const selectAllClassName = classNames({
    [styles.indentAllInputs]: selectAll.type === "checkbox",
  });
  const selectAllLabel = selectAll.label || t("select_all");
  const allCheckboxValuesArray = useMemo(
    () => allValuesArray(values),
    [values]
  );
  const selectedCheckboxValues = useMemo(
    () => selectedValuesArray(inputValue),
    [inputValue]
  );

  /** Select All/Select None determination */
  const [allSelected, setAllSelected] = useState(false);
  const allValuesChecked =
    selectedCheckboxValues.length === allCheckboxValuesArray.length;
  useEffect(() => {
    if (allValuesChecked) setAllSelected(true);
    else setAllSelected(false);
  }, [allValuesChecked]);

  const handleChange = e => {
    let newValues;
    if (e.target.checked) {
      /** if the checkbox is being checked, set checked to true in the input value */
      newValues = { ...inputValue, [e.target.value]: { checked: true } };
    } else {
      /** if it's unchecked, reset any potential associated radio group value
       * and set checked to false
       */
      newValues = { ...inputValue, [e.target.value]: { checked: false } };
    }

    setInputValue(newValues);
    if (onChange) onChange(newValues);
  };

  const generateOption = ({ val, disabled, name, nested }) => (
    <li key={optionValue(val)}>
      <label
        className={classNames(styles.radioCheckContainer, selectAllClassName, {
          [styles.indent]: nested,
        })}
      >
        <input
          type="checkbox"
          onChange={handleChange}
          onBlur={onBlur}
          checked={inputValue[optionValue(val)]?.checked || false}
          name={name ? `${name}[]` : undefined}
          value={optionValue(val)}
          disabled={disabled || val.disabled}
        />
        {optionLabel(val)}
      </label>
    </li>
  );

  const SelectAllControl = () => {
    const allCheckboxValuesObject = allCheckboxValuesArray.reduce(
      (valsObject, val) => ({
        ...valsObject,
        [val]: { ...inputValue[val], checked: true },
      }),
      {}
    );

    if (selectAll.type === "checkbox") {
      return (
        <InputCheckbox
          label={selectAllLabel}
          value={allSelected}
          onChange={checked => {
            setInputValue(checked ? allCheckboxValuesObject : {});
            setAllSelected(checked);
            if (onChange) onChange(checked ? allCheckboxValuesObject : {});
          }}
        />
      );
    }
    if (selectAll.type === "links") {
      return (
        <Flex row>
          <Link
            onClick={() => {
              setInputValue(allCheckboxValuesObject);
              if (onChange) onChange(allCheckboxValuesObject);
            }}
            disabled={allValuesChecked}
          >
            {selectAllLabel}
          </Link>
          <Link
            onClick={() => {
              setInputValue({});
              if (onChange) onChange({});
            }}
            disabled={isEmpty(selectedCheckboxValues)}
          >
            {t("clear")}
          </Link>
        </Flex>
      );
    }
    return null;
  };

  if (isEmpty(values) && selectAll.type === "checkbox")
    return <InputCheckbox label={selectAllLabel} disabled />;

  return (
    <InputGroupLayout
      spacing="compact"
      {...props}
      value={
        forLegacyCheckboxGroupAA
          ? fromLegacyCheckboxGroupValue(inputValue)
          : inputValue
      }
      inputsComponent={({ disabled, name }) => (
        <Fragment>
          {/** required, data-testid, and ID are applicable for a single input;
           * since this component is a collection of inputs, we discard them */}
          <SelectAllControl />
          {values.map((val, ind) => (
            <Fragment key={`${optionValue(val)}-${ind}`}>
              {isNil(val.options) || val.nestedAsRadio || val.nestedAsText ? (
                generateOption({ val, disabled, name })
              ) : (
                <div className={classNames(selectAllClassName)}>
                  {optionLabel(val)}
                </div>
              )}
              {/** we have to define these components inline, as opposed to making them each
               * separate sub-components, because react will rerender the subcomponent
               * on each value change. ultimately that messes with the focus state of the
               * input, which is inaccessible for keyboard navigation. */}
              <Choose>
                <When condition={val.nestedAsRadio}>
                  <If
                    condition={
                      !isNil(val.options) && inputValue[val.value]?.checked
                    }
                  >
                    {/** Nested radio group options, only displayed when the top level
                     * checkbox is checked */}
                    <div
                      className={classNames(selectAllClassName, styles.indent)}
                    >
                      <InputRadioGroup
                        hideLabel
                        label={t("options_for_group", {
                          group: optionLabel(val),
                        })}
                        values={val.options}
                        value={inputValue[val.value]?.option || false}
                        onChange={selectedOption => {
                          const newValues = {
                            ...inputValue,
                            [val.value]: {
                              ...inputValue[val.value],
                              option: selectedOption,
                            },
                          };
                          setInputValue(newValues);
                          if (onChange) onChange(newValues);
                        }}
                      />
                    </div>
                  </If>
                </When>
                <When condition={val.nestedAsText}>
                  {/** Nested textbox, only displayed when the top level checkbox is checked. */}
                  <If condition={inputValue[val.value]?.checked}>
                    <div
                      className={classNames(selectAllClassName, styles.indent)}
                    >
                      <InputText
                        hideLabel
                        label={t("details_for_field", {
                          field: optionLabel(val),
                        })}
                        value={inputValue[val.value]?.details || ""}
                        onChange={details => {
                          const newValues = {
                            ...inputValue,
                            [val.value]: {
                              ...inputValue[val.value],
                              details,
                            },
                          };
                          setInputValue(newValues);
                          if (onChange) onChange(newValues);
                        }}
                      />
                    </div>
                  </If>
                </When>
                <Otherwise>
                  {/** Nested checkbox options */}
                  <If condition={!isNil(val.options)}>
                    {val.options.map(nestedVal =>
                      generateOption({
                        val: nestedVal,
                        disabled,
                        name,
                        nested: true,
                      })
                    )}
                  </If>
                </Otherwise>
              </Choose>
            </Fragment>
          ))}
        </Fragment>
      )}
    />
  );
};

InputCheckboxGroup.propTypes = {
  ...inputCommonPropTypes,
  ...inputGroupCommonPropTypes,
  /** The initial value (uncontrolled) of this input, or the current value (controlled)  */
  value: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string,
      checked: PropTypes.bool,
      option: PropTypes.string,
    })
  ),
  /** Available options to generate checkboxes
   *
   * Note: nestedAsRadio will render the nested options as a radio group, instead of
   * rendering the options as nested checkboxes. nestedAsText will display a textbox
   * under the top level checkbox when it is checked.
   */
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        /** a list of options that will be rendered as nested values */
        options: PropTypes.array,
        /** will render the nested options as a radio group instead of as checkboxes */
        nestedAsRadio: PropTypes.bool,
        /** will render a nested textbox when the top level checkbox is checked */
        nestedAsText: PropTypes.bool,
      })
    ),
  ]).isRequired,
  /** `onChange` is invoked with an array of the values selected (just the value) */
  onChange: PropTypes.func,
  /** This HTML `name` is the prefix for `[]` so the values are submitted as a list
   * in HTML form POST submissions. */
  name: PropTypes.string,
  /** When type = "links", adds Select All/Clear options as links to the top of the checkbox group.
   *
   * When type = "checkbox", adds a checkbox with the provided label to the top of the checkbox group.
   *
   * Note: this should NOT be used with a disabled checkbox option
   */
  selectAll: PropTypes.shape({
    type: PropTypes.oneOf(["links", "checkbox"]),
    label: PropTypes.string,
  }),
  /** Tells the InputCheckboxGroup that the value of the component should be held as an array of strings.
   *
   * All other places that use the legacy format handle conversion themselves, but Active Admin plucks
   * values directly from the inputs based on an input `name`
   */
  forLegacyCheckboxGroupAA: PropTypes.bool,
};
export default InputCheckboxGroup;
