import { useQuery } from "@apollo/client";
import {
  Button,
  ContentTabs,
  Layout,
  LoadingOverlay,
  Surface,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { familyFindingEffortsReportPersonPath } from "routes";

import { translationWithRoot } from "@components/T";
import useChildAgencyHumanPermissions from "@components/agency_humans/useChildAgencyHumanPermissions";
import ChildBreadcrumbs from "@components/child/ChildBreadcrumbs";
import { childCaseStatusPillProps } from "@components/child/status_pill/StatusPill";

import AgencyQuery from "@graphql/queries/Agency.graphql";

import ChildSidebar from "../child/ChildSidebar";
import ContactLogsTable from "./contact_logs/ContactLogsTable";
import RelationshipsTable from "./relationships/RelationshipsTable";
import RelationshipsTableOrGenogram from "./relationships/RelationshipsTableOrGenogram";

const { t } = translationWithRoot("family_finding.relationships_dashboard");

/** The foundational component for the Family Finding relationships dashboard
 *
 * This component contains the tabs for the relationships table, contact logs table,
 * and potential connections table
 */
const RelationshipsDashboard = ({
  child,
  childAgencyHumanId,
  addPersonFormPath,
  downloadRelationshipsCsvPath,
  editContactLogBasePath,
  downloadContactLogsCsvPath,
  childPermissions,
}) => {
  const permissions = useChildAgencyHumanPermissions(childAgencyHumanId);
  const { data: agencyData, loading: agencyDataLoading } = useQuery(
    AgencyQuery,
    {
      variables: { id: child.agencyId },
      skip: !child.agencyId,
    }
  );
  const familyFindingEnabled = agencyData?.agency?.familyFindingEnabled;

  const content = agencyDataLoading ? (
    <LoadingOverlay active={agencyDataLoading}>
      <span />
    </LoadingOverlay>
  ) : (
    <Surface hideTitle title={t("relationships_dashboard")}>
      <ContentTabs
        tabs={[
          {
            title: t("relationships"),
            slug: "relationships",
            contents: (
              <RelationshipsTableOrGenogram
                tab="relationships"
                familyFindingEnabled={familyFindingEnabled}
                addPersonFormPath={addPersonFormPath}
                childAgencyHumanId={childAgencyHumanId}
                downloadRelationshipsCsvPath={downloadRelationshipsCsvPath}
              />
            ),
          },
          familyFindingEnabled && {
            title: t("contact_logs"),
            slug: "contact_logs",
            contents: (
              <ContactLogsTable
                childAgencyHumanId={childAgencyHumanId}
                editContactLogBasePath={editContactLogBasePath}
                downloadContactLogsCsvPath={downloadContactLogsCsvPath}
              />
            ),
          },
          familyFindingEnabled && {
            title: t("potential_connections"),
            slug: "potential_connections",
            contents: (
              <RelationshipsTable
                tab="potentialConnections"
                addPersonFormPath={addPersonFormPath}
                keystoneAgencyHumanId={childAgencyHumanId}
                downloadRelationshipsCsvPath={downloadRelationshipsCsvPath}
              />
            ),
          },
          /** we can remove this filter when we remove the potential connections
           * feature flag as well */
        ].filter(Boolean)}
      />
    </Surface>
  );

  return (
    <Layout
      pageTitle={I18n.t("admin.child.relationships_dashboard_for_child", {
        child_name: child.fullName,
      })}
      secondary={
        permissions.mayDownloadFamilyFindingEffortsReport() &&
        familyFindingEnabled ? (
          <Button
            variant="secondary"
            href={familyFindingEffortsReportPersonPath(childAgencyHumanId, {
              format: "xlsx",
            })}
          >
            {I18n.t("services.family_finding.efforts_report.generate_report")}
          </Button>
        ) : null
      }
      breadcrumbs={
        <ChildBreadcrumbs childId={child.id} childName={child.fullName} />
      }
      main={{ content }}
      sidebar={{
        fullHeight: true,
        opaque: true,
        title: child.fullName,
        collapsibleOnDesktop: true,
        content: (
          <ChildSidebar
            currentPage="relationships_dashboard"
            avatar={{
              firstName: child.firstName,
              lastName: child.lastName,
            }}
            label={child.fullName}
            agencyHumanId={childAgencyHumanId}
            childId={child.id}
            childFullName={child.fullName}
            childPermissions={childPermissions}
            childCaseStatusPillProps={childCaseStatusPillProps(child)}
            modulePage="family_finding"
          />
        ),
      }}
    />
  );
};
RelationshipsDashboard.propTypes = {
  child: PropTypes.shape({
    id: PropTypes.number.isRequired,
    agencyId: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string,
  }),
  addPersonFormPath: PropTypes.string.isRequired,
  childAgencyHumanId: PropTypes.number.isRequired,
  downloadRelationshipsCsvPath: PropTypes.string.isRequired,
  editContactLogBasePath: PropTypes.string.isRequired,
  downloadContactLogsCsvPath: PropTypes.string.isRequired,
  childPermissions: PropTypes.shape({
    mayViewFamilyFindingSearches: PropTypes.bool,
  }).isRequired,
};

export default RelationshipsDashboard;
