import { LiveRegion } from "@heart/components";
import LogoSpinner from "@heart/components/loading_overlay/LogoSpinner";
import { useEffect, useRef } from "react";
import { If } from "tsx-control-statements/components";

import ChatMessage from "./ChatMessage";
import styles from "./TextChat.module.scss";
import { MessageProps } from "./common";

/**
 * Chat history component that displays a list of messages in a chat.
 *
 * It may auto-play the last message if it's from the assistant and the user
 * has interacted with the page as a welcome message or resumption of the chat.
 *
 * @param messages The messages to display in the chat, if any.
 * @param loading Whether the chat is currently loading after a call to onSubmit.
 */
const ChatHistory = ({
  messages = [],
  loading,
}: {
  messages: MessageProps[];
  loading: boolean;
}) => {
  const historyRef = useRef<HTMLDivElement>();

  const scrollToBottom = () => {
    window.setTimeout(() => {
      if (historyRef.current) {
        historyRef.current.scrollTop = historyRef.current.scrollHeight;
      }
    }, 0);
  };

  useEffect(scrollToBottom, [messages.length, loading]);

  return (
    <div ref={historyRef} className={styles.chatContainer}>
      <LiveRegion>
        {messages.map(({ role, message, timestamp }, index) => {
          const isLastMessage = index === messages.length - 1;

          return (
            <ChatMessage
              key={index}
              role={role}
              message={message}
              timestamp={timestamp}
              data-testid={
                isLastMessage ? "most-recent-chat-message" : undefined
              }
            />
          );
        })}
      </LiveRegion>
      <If condition={loading}>
        <div className={styles.loadingContainer}>
          <LogoSpinner />
        </div>
      </If>
    </div>
  );
};

export default ChatHistory;
