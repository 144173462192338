import { InputDate, InputFilterable, InputTextarea } from "@heart/components";
import classnames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";
import labelStyles from "@components/reusable_ui/forms/Label.module.scss";

import { useGeneratedIds } from "@lib/generateId";
import {
  isOtherTheOnlyOption,
  isOverridableForReason,
  OTHER_KEY,
  overridableOptions,
  OVERRIDE_REASON_KEYS,
  reasonLabel,
} from "@lib/overridableHelpers";

import styles from "./CreateOrEditOverrideForm.module.scss";

// this form is only for caseworkers
const providerRole = "caseworker";
const { t } = translationWithRoot("requirements.create_or_edit_override_form");

/** Form to explain why a particular requirement has been waived.
 *    Requirements-enabled application (IE Washington) > Documents
 */
const CreateOrEditOverrideForm = ({
  requirement,

  details,
  grantDate,
  reason,
  optionKey,

  setDetails,
  setGrantDate,
  setOptionKey,
  setReason,
}) => {
  const reasonIds = useGeneratedIds(OVERRIDE_REASON_KEYS.length);

  const optionKeyOptions = overridableOptions({
    requirement,
    providerRole,
    reason,
  });

  const optionKeyOption =
    optionKey && optionKeyOptions.find(({ value }) => value === optionKey);

  const otherTheOnlyOption = isOtherTheOnlyOption({
    requirement,
    providerRole,
    reason,
  });

  return (
    <div className="contains-inputs">
      <div className="form-group">
        <p
          className={classnames(
            labelStyles.label,
            labelStyles["label--required"]
          )}
        >
          {t("reason_category")}
        </p>
        {OVERRIDE_REASON_KEYS.map((reasonKey, i) => {
          // TODO ENG-12740 this should be an InputRadioGroup
          const isOverridable = isOverridableForReason({
            requirement,
            reason: reasonKey,
            providerRole: "caseworker",
          });

          const onChange = () => {
            setReason(reasonKey);
            if (
              isOtherTheOnlyOption({
                requirement,
                providerRole,
                reason: reasonKey,
              })
            ) {
              setOptionKey(OTHER_KEY);
            }
          };

          return (
            <label
              key={reasonIds[i]}
              className={classnames({
                [styles.disabledReasonCategory]: !isOverridable,
              })}
            >
              <input
                type="radio"
                id={reasonIds[i]}
                name="reason"
                checked={reason === reasonKey}
                disabled={!isOverridable}
                onChange={onChange}
              />
              {reasonLabel(reasonKey)}
              <If condition={!isOverridable}>
                {" "}
                ({t("reason_cannot_be_used")})
              </If>
            </label>
          );
        })}
      </div>
      <If condition={!isEmpty(reason) && !otherTheOnlyOption && optionKey}>
        <InputFilterable
          required
          label={t("specific_reason")}
          options={optionKeyOptions}
          value={optionKeyOption}
          onChange={({ value }) => {
            setOptionKey(value);
          }}
        />
      </If>
      <InputTextarea
        label={t("details")}
        value={details}
        onChange={setDetails}
      />
      <InputDate
        label={t("grant_date")}
        name="override_record[grant_date]"
        value={grantDate}
        onBlur={setGrantDate}
        required={true}
      />
    </div>
  );
};

CreateOrEditOverrideForm.propTypes = {
  requirement: PropTypes.object.isRequired,

  reason: PropTypes.string,
  optionKey: PropTypes.string,
  details: PropTypes.string,
  grantDate: PropTypes.string,

  setReason: PropTypes.func.isRequired,
  setOptionKey: PropTypes.func.isRequired,
  setDetails: PropTypes.func.isRequired,
  setGrantDate: PropTypes.func.isRequired,
};

export default CreateOrEditOverrideForm;
