import { NO_RELATIONSHIP, UNKNOWN, NOT_VERIFIED } from "@root/constants";

export const resetRelationshipState = ({ category, relationship }) => {
  if (category.value === relationship.relationshipCategory?.value)
    return relationship;

  const noRelationshipAttrsToOverwrite = {
    emotionalRelationshipStatuses: [],
    levelsOfSupport: [],
    levelsOfSupportOtherDescription: "",
    placementProviderEligibilitiesJson: {},
    placementProviderEligibilitiesDetails: "",
  };
  const unknownAttrsToOverwrite = {
    ...noRelationshipAttrsToOverwrite,
    verificationSource: NOT_VERIFIED,
  };
  const attrsToOverwrite = {
    fictiveKinDescription: undefined,
    kinshipRelationship: undefined,
    kinshipRelationshipOtherDescription: undefined,
    lineageType: undefined,
    parentalLine: undefined,
    partnerStatus: undefined,
  };

  return {
    ...relationship,
    ...attrsToOverwrite,
    ...(category.value === NO_RELATIONSHIP
      ? noRelationshipAttrsToOverwrite
      : {}),
    ...(category.value === UNKNOWN ? unknownAttrsToOverwrite : {}),
    relationshipCategory: category,
  };
};

export const copySubsetOfRelationshipDetails =
  ({ fieldsToCopy }) =>
  ({ relationshipToCopy, existingRelationshipDetails }) => {
    const existingRelationshipResetForNewCategory = resetRelationshipState({
      category: relationshipToCopy.relationshipCategory,
      relationship: existingRelationshipDetails,
    });

    return {
      ...existingRelationshipResetForNewCategory,
      ...fieldsToCopy.reduce(
        (fields, attrToCopy) => ({
          ...fields,
          [attrToCopy]: relationshipToCopy[attrToCopy],
        }),
        {}
      ),
    };
  };

export const copyRelationshipDetails = ({
  relationshipToCopy,
  existingRelationshipDetails,
}) => {
  const existingRelationshipResetForNewCategory = resetRelationshipState({
    category: relationshipToCopy.relationshipCategory,
    relationship: existingRelationshipDetails,
  });

  const attrsToCopy = Object.fromEntries(
    Object.entries(relationshipToCopy).filter(([key]) => {
      const startingStringsToIgnore = [
        "id",
        "relationshipId",
        "keystone",
        "source",
        "destination",
        "nonKeystone",
      ];
      return !startingStringsToIgnore.some(str =>
        key.match(new RegExp(`^${str}*`))
      );
    })
  );

  return {
    ...existingRelationshipResetForNewCategory,
    ...attrsToCopy,
  };
};
