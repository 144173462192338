import { useState } from "react";

export enum States {
  INITIAL = "INITIAL",
  RECORDING = "RECORDING",
  PAUSED = "PAUSED",
  STOPPED = "STOPPED",
}

/**
 * Hook for managing the TranscriptionInput state.
 *
 * This is the state machine for the recording interface:
 *
 * ```
 *                            Restart
 *                       ┌─────────────────┐
 *                       │                 │
 *                       │                 │
 * ┌───────┐ Start  ┌────▼────┐ Stop   ┌───┴───┐
 * │Initial├───────►│Recording├────────►Stopped│
 * └───────┘        └┬─────▲──┘        └───────┘
 *                   │     │
 *             Pause │     │ Resume
 *                  ┌▼─────┴─┐
 *                  │ Paused │
 *                  └────────┘
 * ```
 */
const useTranscriptionInputState = initialState => {
  const [state, setState] = useState<States>(initialState);

  const startRecording = () => {
    // any other state can transition to recording
    setState(States.RECORDING);
  };

  const pauseRecording = () => {
    if (state === States.RECORDING) {
      setState(States.PAUSED);
    } else {
      throw new Error("Cannot pause recording when not recording");
    }
  };

  const resumeRecording = () => {
    if (state === States.PAUSED) {
      setState(States.RECORDING);
    } else {
      throw new Error("Cannot resume recording when not paused");
    }
  };

  const stopRecording = () => {
    if ([States.RECORDING, States.PAUSED].includes(state)) {
      setState(States.STOPPED);
    } else {
      throw new Error("Cannot stop recording when not recording or paused");
    }
  };

  return {
    state,
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
  };
};

export default useTranscriptionInputState;
