export enum Role {
  ASSISTANT = "assistant",
  USER = "user",
}

export type MessageProps = {
  role: Role;
  message: string;
  timestamp: string;
};

// Js compatability
export const { USER, ASSISTANT } = Role;
