import { gql, useMutation, useQuery } from "@apollo/client";
import { Actions, LoadingOverlay, SurfaceForm, toast } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import I18n from "i18n-js";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import PlacementPeriodForm from "./PlacementPeriodForm";

const { t } = translationWithRoot("placement_period");

/** Placement periods store start and end dates without a timestamp, which
 * isn't great as it makes for easy timezone bugs.
 *
 * Because this is the case, we're accounting for the timezone before
 * returning an ISO string to send through to the mutation
 */

const initialFormState = {
  startDate: DateTime.local().toISODate(),
};

const placementPeriodQuery = gql`
  query CreatePlacementPeriod($childId: ID!, $placementPeriodId: ID) {
    placementPeriodFormData(
      childId: $childId
      placementPeriodId: $placementPeriodId
    ) {
      child {
        firstName
        lastName
      }
    }
  }
`;

const createPlacementPeriodMutation = gql`
  mutation CreatePlacementPeriod($input: CreatePlacementPeriodInput!) {
    createPlacementPeriod(input: $input) {
      placementPeriod {
        id
      }
      errors
      redirectTo
    }
  }
`;

/**
 * Form for creating a new placement period
 */
const CreatePlacementPeriod = ({ childId }) => {
  const [errors, setErrors] = useState([]);
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const agencyPlacementId = queryParams.get("agency_placement_id");
  if (initialFormState.careProvider == null && agencyPlacementId != null) {
    initialFormState.placementType = "went_with_relatives";
    initialFormState.careProvider = `AgencyPlacement:${agencyPlacementId}`;
  }

  const { formState, setFormAttribute } = useBintiForm(initialFormState);
  const { data, loading: dataLoading } = useQuery(placementPeriodQuery, {
    variables: {
      childId,
      placementPeriodId: null,
    },
  });
  const [createPlacementPeriod, { loading: mutationLoading }] = useMutation(
    createPlacementPeriodMutation,
    {
      onCompleted: ({ createPlacementPeriod: response }) => {
        if (response.errors.length === 0) {
          toast.onNextPageLoad.success(
            I18n.t(
              "views.admin.placements.placement_period.created_successfully"
            )
          );
          window.location = response.redirectTo;
        }

        setErrors(response.errors);
      },
    }
  );
  const referrerUrl = document.referrer ? new URL(document.referrer) : null;

  const referrer = referrerUrl
    ? `${referrerUrl.pathname}${referrerUrl.search}`
    : "";

  return (
    <LoadingOverlay active={dataLoading || mutationLoading}>
      <SurfaceForm
        title={`${t("placement_for")} ${
          data?.placementPeriodFormData.child.firstName
        } ${data?.placementPeriodFormData.child.lastName}`}
        actions={<Actions />}
        onSubmit={preventDefault(() => {
          createPlacementPeriod({
            variables: {
              input: {
                ...formState,
                startDate: formState.startDate,
                endDate: formState.endDate,
                childId,
                referrer,
              },
            },
          });
        })}
      >
        <PlacementPeriodForm
          childId={childId}
          formState={formState}
          setFormAttribute={setFormAttribute}
          errors={errors}
        />
      </SurfaceForm>
    </LoadingOverlay>
  );
};

CreatePlacementPeriod.propTypes = {
  childId: BintiPropTypes.ID,
};

export default CreatePlacementPeriod;
