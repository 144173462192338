import { ContentTabs, Flex, Text } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import EmailLogin from "./EmailLogin/EmailLogin";
import styles from "./Login.module.scss";
import SsoLogin from "./SsoLogin/SsoLogin";

const { t } = translationWithRoot("permissions.login");

/**
 * Login component. Displays tabs for email and SSO login.
 */
const Login = ({ returnUri, displayGoogleSso, googleSsoProps }) => {
  const tabs = [
    {
      title: t("email"),
      slug: "email",
      contents: (
        <EmailLogin
          displayGoogleSso={displayGoogleSso}
          googleSsoProps={googleSsoProps}
        />
      ),
    },
    {
      title: t("sso"),
      slug: "sso",
      contents: <SsoLogin returnUri={returnUri} />,
    },
  ];

  return (
    <Flex column align="center" data-testid="login">
      <div className={styles.background}></div>
      <div className={styles.circles}></div>
      <Flex column align="center" className={styles.container}>
        <Text as="h1" textStyle="emphasis-300">
          {t("log_in_to_binti")}
        </Text>
        <ContentTabs tabs={tabs}></ContentTabs>
      </Flex>
    </Flex>
  );
};

Login.propTypes = {
  returnUri: PropTypes.string,
  displayGoogleSso: PropTypes.bool,
  googleSsoProps: PropTypes.shape({
    googleOauth2ClientId: PropTypes.string,
    redirectPath: PropTypes.string,
    callbackPath: PropTypes.string,
  }),
};

export default Login;
