/* eslint max-classes-per-file: ["error", 2] */

/** Module for inserting page breaks into the Quill editor */
class QuillPageBreak {
  constructor(quill) {
    this.quill = quill;
    const toolbar = this.quill.getModule("toolbar");

    if (toolbar) {
      toolbar.addHandler("pagebreak", this.quillPageBreakHandler.bind(this));
    }
  }

  quillPageBreakHandler() {
    this.quill.focus();
    const range = this.quill.getSelection();
    this.quill.insertEmbed(range.index, "pagebreak", true, "user");
    this.quill.setSelection(range.index + 1, 0, "user");
  }
}

/** Function that will register the page break blot and the
 * page break module with Quill */
export const register = Quill => {
  const BlockEmbed = Quill.import("blots/block/embed");

  /** Blot for the page break element */
  class PageBreak extends BlockEmbed {
    static blotName = "pagebreak";

    static tagName = "p";

    static className = "page-break";

    static create(value) {
      const node = super.create(value);
      node.setAttribute("contenteditable", false);
      node.innerHTML = "Page Break";
      return node;
    }
  }

  Quill.register(PageBreak);
  Quill.register("modules/pageBreak", QuillPageBreak);
};

export default QuillPageBreak;
