import { useMutation } from "@apollo/client";
import {
  InputDropdown,
  InputText,
  SurfaceForm,
  Actions,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { translationWithRoot } from "@components/T";
import RoleDropdown from "@components/shared/RoleDropdown";

import CreateRole from "@graphql/mutations/CreateRole";
import CustomRoles from "@graphql/queries/CustomRoles";

import preventDefault from "@lib/preventDefault";

const { t, T } = translationWithRoot("permissions");

const RoleForm = ({
  onAddRole,
  allAgencies,
  parentComponentAgencyId,
  setParentComponentAgencyId,
  setFormHidden,
}) => {
  const [name, setName] = useState("Test Name");
  const [slug, setSlug] = useState("test-slug");
  const [agencyId, setAgencyId] = useState(parentComponentAgencyId);
  const [templateId, setTemplateId] = useState();

  const [createRole] = useMutation(CreateRole, {
    update: (cache, { data }) =>
      cache.updateQuery(
        {
          query: CustomRoles,
          variables: { agencyId: agencyId },
        },
        d => ({ customRoles: [...d.customRoles, data.createRole.role] })
      ),
  });

  const onSubmit = preventDefault(() => {
    createRole({
      variables: {
        role: {
          name,
          slug,
          agencyId: agencyId,
          templateId,
        },
      },
      onCompleted: ({ createRole: newRole }) => {
        // TODO: ENG-10412
        // We should handle potential errors here, and display a success or failure message
        onAddRole(newRole.role);
        setFormHidden(true);
        setParentComponentAgencyId(agencyId);
      },
    });
  });

  return (
    <SurfaceForm
      onSubmit={onSubmit}
      actions={
        <Actions
          cancelAction={() => setFormHidden(true)}
          primaryText={t("new_role_button")}
          cancelText={<T t="new_role_hide_button" />}
        />
      }
      title={t("new_role_header")}
    >
      <InputDropdown
        required
        label={I18n.t("views.common.agency")}
        data-testid="new-role-agency"
        values={allAgencies.map(x => [x.name, x.id])}
        value={agencyId}
        onChange={setAgencyId}
        hideBlank={true}
      />
      <RoleDropdown
        label={t("existing_role_to_copy")}
        data-testid="role-to-copy"
        required
        agencyId={agencyId}
        value={templateId}
        onChange={setTemplateId}
      />
      <InputText
        required
        label={I18n.t("views.common.name")}
        data-testid="new-role-name"
        value={name}
        onChange={setName}
      />
      <InputText
        required
        label={t("slug")}
        data-testid="new-role-slug"
        value={slug}
        onChange={setSlug}
      />
    </SurfaceForm>
  );
};

RoleForm.propTypes = {
  onAddRole: PropTypes.func,
  allAgencies: PropTypes.array,
  defaultAgencyId: PropTypes.number,
  parentComponentAgencyId: PropTypes.number,
  setParentComponentAgencyId: PropTypes.func,
  setFormHidden: PropTypes.func,
};

export default RoleForm;
