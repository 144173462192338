import { useMutation } from "@apollo/client";
import { toast } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import _ from "lodash";
import { useState } from "react";
import { agencyServicePath, agencyServicesPath } from "routes";

// import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import CreatePreventionAgencyService from "@graphql/mutations/prevention/CreateAgencyService.graphql";

import AgencyServiceForm from "./AgencyServiceForm";
import { FormProps } from "./types";

import AddAgencyServiceRate from "../../agency_service_rates/AddAgencyServiceRate";

const { t } = translationWithRoot("prevention.agency_services.form");

/**
 * Form to create a new agency service
 */
const NewAgencyServiceForm = (formProps: FormProps) => {
  const [error, setError] = useState(null);

  const checkForErrors = sections => {
    if (sections.length === 0) {
      setError(t("errors.add_service_rate"));
    } else {
      setError(null);
    }
  };

  const [createPreventionAgencyService] = useMutation(
    CreatePreventionAgencyService,
    {
      onCompleted: mutationData => {
        if (mutationData.createPreventionAgencyService.errors?.[0]?.message) {
          const errorMessage = mutationData.createPreventionAgencyService.errors[0].message;
          let errorTitle = t("errors.titles.add_service");
          if (errorMessage.includes("name")) {
            errorTitle = t("errors.titles.dup_name");
          } else if (errorMessage.includes("code")) {
            errorTitle = t("errors.titles.dup_rate_code");
          }

          toast.negative({
            title: errorTitle,
            message:
              errorMessage,
          });
          return;
        }
        window.location.href = agencyServicePath(
          mutationData.createPreventionAgencyService.agencyService.id
        );
      },
    }
  );

  const { formState, setFormAttribute } = useBintiForm(null, {
    initialValue: { agencyServiceRates: [] },
  });

  const multiUpdate = value => {
    if (!_.isEqual(value, formState.agencyServiceRates)) {
      setFormAttribute("agencyServiceRates")(value);
    }
  };

  const handleSubmit = () => {
    if (formState.agencyServiceRates.length === 0) {
      setError(t("errors.add_service_rate"));
      return;
    }

    createPreventionAgencyService({
      variables: {
        input: {
          agencyServiceRates: formState.agencyServiceRates,
          areasOfCoverage: formState.areasOfCoverage?.map(({ value }) => value),
          evidenceBasedPracticeId: formState.evidenceBasedPractice?.value,
          fundingSources: formState.fundingSources?.map(({ value }) => value),
          isFfpsa: formState.isFfpsa,
          name: formState.name,
          providerAgencyId: formState.providerAgency?.value,
          referringAgencyId: formState.referringAgency?.value,
          serviceTypes: formState.serviceTypes?.map(({ value }) => value),
        },
      },
    });
  };

  return (
    <AgencyServiceForm
      {...formProps}
      onSubmit={handleSubmit}
      setFormAttribute={setFormAttribute}
      formState={formState}
      cancelHref={agencyServicesPath()}
    >
      { /* @ts-expect-error We have a mysterious error that doesn't cause any issues when rendering the page */ }
      <AddAgencyServiceRate
        multiUpdate={multiUpdate}
        checkForErrors={checkForErrors}
        billingStructures={formProps.billingStructures}
        error={error}
      />
    </AgencyServiceForm>
  );
};

export default NewAgencyServiceForm;
