import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Actions,
  Breadcrumbs,
  InputDropdown,
  Layout,
  SurfaceForm,
  toast,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  adminAgencyIntegrationsPath,
  adminAgencyPath,
  newAdminAgencyIntegrationPath,
} from "routes";

import { translationWithRoot } from "@components/T";

import CreateIntegrationConfiguration from "@graphql/mutations/CreateIntegrationConfiguration.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import ClearConfigurationForm from "./ClearConfigurationForm";
import DataExportConfigurationForm from "./DataExportConfigurationForm";
import UnityConfigurationForm from "./UnityConfigurationForm";
import WebhookConfigurationForm from "./WebhookConfigurationForm";

const { t } = translationWithRoot("admin.integrations", {
  escapeJavascriptRoot: true,
});

const IntegrationTypesQuery = gql`
  query {
    IntegrationType: __type(name: "IntegrationType") {
      enumValues {
        name
      }
    }
  }
`;

/**
 * AddIntegrationPage component for adding a new integration.
 *
 * @param {Object} props.agency - The agency object.
 * @param {string} props.agency.id - The ID of the agency.
 * @param {string} props.agency.name - The name of the agency.
 */
const AddIntegrationPage = ({ agency }) => {
  const redirect = () => {
    const path = adminAgencyIntegrationsPath(agency.id);
    window.location.replace(path);
  };

  const [integrationType, setIntegrationType] = useState("");

  const { data: integrationTypesEnum } = useQuery(IntegrationTypesQuery);
  const integrationTypeOptions = () =>
    integrationTypesEnum
      ? integrationTypesEnum.IntegrationType.enumValues.map(
          ({ name: value }) => ({
            label: t(`types.${value}`),
            value: value,
          })
        )
      : [];

  const [createConfig, { loading, data }] = useMutation(
    CreateIntegrationConfiguration,
    {
      onCompleted: result => {
        if (isEmpty(result.createIntegrationConfiguration.errors)) {
          redirect();
        } else {
          toast.negative({
            title: "Error",
            message: result.createIntegrationConfiguration.errors.map(
              e => e.message
            ),
          });
        }
      },
    }
  );
  const validationErrors = data?.createIntegrationConfiguration?.errors || [];

  const { formState, setFormState, setFormAttribute } = useBintiForm();

  const breadcrumbs = (
    <Breadcrumbs
      pages={[
        { href: adminAgencyPath(agency.id), label: agency.name },
        {
          href: adminAgencyIntegrationsPath(agency.id),
          label: t("integrations"),
        },
        {
          href: newAdminAgencyIntegrationPath(agency.id),
          label: t("new"),
        },
      ]}
    />
  );

  return (
    <Layout
      breadcrumbs={breadcrumbs}
      pageTitle={`${agency.name} — ${t("new")}`}
      main={{
        content: (
          <SurfaceForm
            actions={
              <Actions
                primaryText={t("add")}
                primaryDisabled={loading}
                isSubmitting={loading}
              />
            }
            title={t("new")}
            onSubmit={preventDefault(() =>
              createConfig({
                variables: {
                  agencyId: agency.id,
                  integrationType: integrationType,
                  json: formState,
                },
              })
            )}
          >
            <InputDropdown
              onChange={integration => {
                setFormState({});
                setIntegrationType(integration);
              }}
              label={t("type")}
              name="integrationType"
              values={integrationTypeOptions()}
              value={integrationType}
              required
            />
            <If condition={integrationType === "unity"}>
              <UnityConfigurationForm
                formState={formState}
                setFormAttribute={setFormAttribute}
                validationErrors={validationErrors}
              />
            </If>
            <If condition={integrationType === "clear"}>
              <ClearConfigurationForm
                agencyId={agency.id}
                formState={formState}
                setFormState={setFormState}
                setFormAttribute={setFormAttribute}
                validationErrors={validationErrors}
              />
            </If>
            <If condition={integrationType === "webhook"}>
              <WebhookConfigurationForm
                formState={formState}
                setFormAttribute={setFormAttribute}
                validationErrors={validationErrors}
              />
            </If>
            <If condition={integrationType === "data_export"}>
              <DataExportConfigurationForm
                formState={formState}
                setFormAttribute={setFormAttribute}
                validationErrors={validationErrors}
              />
            </If>
          </SurfaceForm>
        ),
      }}
    />
  );
};

AddIntegrationPage.propTypes = {
  agency: PropTypes.shape({
    id: BintiPropTypes.ID,
    name: PropTypes.string,
  }),
};

export default AddIntegrationPage;
