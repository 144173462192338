import { Icons, Flex, Text } from "@heart/components";
import { Choose, When } from "tsx-control-statements/components";

/**
 * Standardized toast message contents format for use with `react-toastify`.
 *
 * @param {object} props
 * @param {string} props.title The Toast's title
 * @param {string} props.message The Toast's message content
 * @param {object} props.toastProps Additional toast props from `react-toastify` - see:
 * https://fkhadra.github.io/react-toastify/render-what-you-want
 * @returns {JSX.Element}
 */
const ToastMessage = ({
  title,
  message,
  toastProps: { type },
}: {
  title: string;
  message: string;
  toastProps: { type: "info" | "success" | "warning" | "error" };
}) => (
  <Flex column align="start" data-testid="toast">
    <Choose>
      <When condition={type === "info"}>
        <Icons.InfoCircle />
      </When>
      <When condition={type === "success"}>
        <Icons.CheckCircle />
      </When>
      <When condition={type === "warning"}>
        <Icons.StickyNote />
      </When>
      <When condition={type === "error"}>
        <Icons.ExclamationTriangle />
      </When>
    </Choose>
    <Text textStyle="emphasis-200">{title}</Text>
    <Text textStyle="supporting-100">{message}</Text>
  </Flex>
);

export default ToastMessage;
