import { useQuery } from "@apollo/client";
import { Layout, LoadingOverlay } from "@heart/components";
import gql from "graphql-tag";
import { If } from "tsx-control-statements/components";

import { translationWithRoot } from "@components/T";

import AgencyServiceBreadcrumbs from "./form/AgencyServiceBreadcrumbs";
import EditAgencyServiceForm from "./form/EditAgencyServiceForm";
import { FormProps } from "./form/types";

const { T } = translationWithRoot("prevention.agency_services.form");

export const query = gql`
  query AgencyService($agencyServiceId: ID!) {
    preventionAgencyService(agencyServiceId: $agencyServiceId) {
      id
      name
      providerAgency {
        id
        name
      }
      areasOfCoverage
      fundingSources
      serviceTypes
      isFfpsa
      evidenceBasedPractice {
        id
        displayTitle
      }
    }
  }
`;

/**
 * Page to edit an existing agency services.
 */
const EditAgencyServiceFormPage = ({
  agencyServiceId,
  ...formProps
}: FormProps & { agencyServiceId: number }) => {
  const { data, loading } = useQuery(query, {
    variables: { agencyServiceId },
  });
  const pageTitle = (
    <T t="edit_page_title" service_name={data?.preventionAgencyService?.name} />
  );

  return (
    <LoadingOverlay active={loading}>
      <If condition={!loading}>
        <Layout
          pageTitle={pageTitle}
          breadcrumbs={<AgencyServiceBreadcrumbs pageLabel={pageTitle} />}
          main={{
            content: (
              <EditAgencyServiceForm
                agencyService={data?.preventionAgencyService}
                {...formProps}
              />
            ),
            narrow: false,
          }}
        />
      </If>
    </LoadingOverlay>
  );
};

export default EditAgencyServiceFormPage;
