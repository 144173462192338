// import { gql, useQuery } from "@apollo/client";
import { Button } from "@heart/components";
import I18n from "i18n-js";
import { Fragment, useState } from "react";

import ConditionalRequirementCreateModal from "@components/conditional_requirements/ConditionalRequirementCreateModal";

import BintiPropTypes from "@lib/BintiPropTypes";

/**
 * Button to add an conditional requirement.
 */
const AddConditionalRequirementButton = ({ applicationId }) => {
  const [showConditionalRequirementModal, setShowConditionalRequirementModal] =
    useState(false);

  const buttonCopy = I18n.t(
    "javascript.components.conditional_requirements.add_button"
  );

  return (
    <Fragment>
      <Button
        variant="secondary"
        description={buttonCopy}
        name={buttonCopy}
        onClick={() => setShowConditionalRequirementModal(true)}
      >
        {buttonCopy}
      </Button>
      <If condition={showConditionalRequirementModal}>
        <ConditionalRequirementCreateModal
          applicationId={applicationId}
          onClose={() => setShowConditionalRequirementModal(false)}
        />
      </If>
    </Fragment>
  );
};

AddConditionalRequirementButton.propTypes = {
  applicationId: BintiPropTypes.ID,
};

export default AddConditionalRequirementButton;
