import I18n from "i18n-js";
import { reject, keys } from "lodash";
import PropTypes from "prop-types";

import NestedMultiFormInput from "@components/inputs/nested_multi/NestedMultiFormInput";

import {
  COMPLETE_RACE_OPTIONS,
  COMPLETE_ETHNICITY_OPTIONS,
  COMPLETE_NEW_ETHNICITY_OPTIONS,
} from "@root/constants";

import LiveRegion from "../live_region/LiveRegion";
import { useInputError, useInputId } from "./common";

const defaultEthnicitiesLabel = useRacesInput =>
  useRacesInput
    ? I18n.t("views.common.of_hispanic_origin_title")
    : I18n.t("views.common.ethnicity");

/** An input for ethnicities! This input uses our complete list of ethnicity
 * options by default, and renders a `NestedMultiFormInput`.
 */
const InputEthnicities = ({
  selectedEthnicities,
  onEthnicitiesChange,
  selectedRaces,
  onRacesChange,
  error,
  useRacesInput,
  ethnicitiesLabel = undefined,
  racesLabel = I18n.t("views.common.race"),
  ethnicitiesDescription = undefined,
  racesDescription = undefined,
  ...props
}) => {
  const displayedEthnicitiesLabel =
    ethnicitiesLabel || defaultEthnicitiesLabel(useRacesInput);

  // Part of ethnicites/races migration. Remove in ENG-21169
  const ethnicitiesOptions = useRacesInput
    ? COMPLETE_NEW_ETHNICITY_OPTIONS
    : COMPLETE_ETHNICITY_OPTIONS;

  const errorMessage = useInputError({ error });
  const id = useInputId(props);

  return (
    <LiveRegion>
      <NestedMultiFormInput
        options={ethnicitiesOptions}
        label={displayedEthnicitiesLabel}
        description={ethnicitiesDescription}
        selectedOptions={selectedEthnicities}
        onSelectedOptionsChange={onEthnicitiesChange}
      />
      <If condition={useRacesInput}>
        <NestedMultiFormInput
          label={racesLabel}
          description={racesDescription}
          id={id}
          // remove goofy reject/filter in ENG-21169
          options={reject(
            COMPLETE_RACE_OPTIONS,
            // we don't want to display this as an option to the user.
            o => keys(o)[0] === "imported_data"
          )}
          selectedOptions={selectedRaces}
          onSelectedOptionsChange={onRacesChange}
        />
      </If>
      {errorMessage}
    </LiveRegion>
  );
};

InputEthnicities.propTypes = {
  /** The ethnicities options that have been selected from the list. Should be a
   * variable held in state that gets updated in the onChange
   */
  selectedEthnicities: PropTypes.object,
  /** What should happen when a user checks or unchecks ethnicities options */
  onEthnicitiesChange: PropTypes.func.isRequired,

  /** The races options that have been selected from the list. Should be a
   * variable held in state that gets updated in the onChange
   */
  selectedRaces: PropTypes.object,
  /** What should happen when a user checks or unchecks races options */
  onRacesChange: PropTypes.func.isRequired,

  /** An error message which will be displayed if present */
  error: PropTypes.string,

  /** Part of ethnicites/races migration. Remove in ENG-21169 */
  useRacesInput: PropTypes.bool.isRequired,

  /** Optionally verride labels and descriptions for ethnicities and races.
   * Default translated strings are used if not provided, but for more complex
   * use cases react elements may be passed as well.
   */
  ethnicitiesLabel: PropTypes.node,
  racesLabel: PropTypes.node,
  ethnicitiesDescription: PropTypes.node,
  racesDescription: PropTypes.node,
};

// Allow wrappers to re-use prop types; active-admin-contained implementations need to
// add input names
export const { propTypes } = InputEthnicities;

export default InputEthnicities;
