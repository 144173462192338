import { useMutation, useQuery } from "@apollo/client";
import { Button, Icons, InputText } from "@heart/components";
import { compact, isEmpty, uniq } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

import Spinner from "@components/Spinner";

import CreateApplicationFormRequirementTemplate from "@graphql/mutations/CreateApplicationFormRequirementTemplate.graphql";
import SetApplicationStageTemplateFormSlugs from "@graphql/mutations/SetApplicationStageTemplateFormSlugs.graphql";
import ApplicationStageTemplateQuery from "@graphql/queries/ApplicationStageTemplate.graphql";

import { isTestEnvironment } from "@lib/environment";
import preventDefault from "@lib/preventDefault";

import styles from "./ApplicationTemplateDesigner.module.scss";
import Form from "./Form";

/**
 * A droppable section that is specialized for forms
 */
const FormDropppable = ({ id, applicationTemplate, formDroppableId }) => {
  const templateFormSlugs = uniq(
    compact(
      applicationTemplate.requirementTemplates.map(
        template => template.form?.slug
      )
    )
  );

  const { data, loading } = useQuery(ApplicationStageTemplateQuery, {
    variables: { id },
  });

  const [createApplicationFormRequirementTemplate] = useMutation(
    CreateApplicationFormRequirementTemplate
  );
  const onAddFormToTemplate = formSlug => () => {
    createApplicationFormRequirementTemplate({
      variables: {
        applicationTemplateId: applicationTemplate.id,
        formSlug,
      },
    });
  };

  const [setApplicationStageTemplateFormSlugs] = useMutation(
    SetApplicationStageTemplateFormSlugs
  );

  const onRemoveForm = formSlug => () => {
    setApplicationStageTemplateFormSlugs({
      variables: {
        applicationStageTemplateId: applicationStageTemplate.id,
        formSlugs: applicationStageTemplate.forms
          .map(form => form.slug)
          .filter(slug => slug !== formSlug),
      },
    });
  };

  const [addFormSlug, setAddFormSlug] = useState("");
  const onAddFormSlug = preventDefault(() => {
    setApplicationStageTemplateFormSlugs({
      variables: {
        applicationStageTemplateId: applicationStageTemplate.id,
        formSlugs: applicationStageTemplate.forms
          .map(form => form.slug)
          .concat([addFormSlug]),
      },
    }).then(() => {
      setAddFormSlug("");
    });
  });

  if (loading) {
    return <Spinner />;
  }

  const { applicationStageTemplate } = data;

  return (
    <Droppable droppableId={formDroppableId}>
      {provided => (
        <div ref={provided.innerRef}>
          <div className={styles.thingTypeHeader}>Stage Forms</div>
          {applicationStageTemplate.forms.map((form, index) => (
            <Draggable
              key={form.slug}
              draggableId={`form:stage-${applicationStageTemplate.stageNumber}:${form.slug}`}
              index={index}
            >
              {draggableProvided => (
                <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                >
                  <Form
                    form={form}
                    onRemove={onRemoveForm(form.slug)}
                    dragHandleProps={draggableProvided.dragHandleProps}
                    isMissingFromTemplate={
                      !templateFormSlugs.includes(form.slug)
                    }
                    onAddToTemplate={onAddFormToTemplate(form.slug)}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {isEmpty(applicationStageTemplate.forms) && (
            <div className={styles.dndEmpty}>Drag here to add a form</div>
          )}
          {provided.placeholder}
          <If condition={isTestEnvironment()}>
            {/* Cypress flakes a lot with drag n drop - this gives us an out */}
            <InputText
              label="Add form slug"
              value={addFormSlug}
              onChange={setAddFormSlug}
            />
            <Button
              variant="secondary"
              onClick={onAddFormSlug}
              icon={Icons.Plus}
              description="Add form slug"
            />
          </If>
        </div>
      )}
    </Droppable>
  );
};

FormDropppable.propTypes = {
  id: PropTypes.string.isRequired,
  applicationTemplate: PropTypes.object.isRequired,
  formDroppableId: PropTypes.string,
};

export default FormDropppable;
