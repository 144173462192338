import * as Apollo from "@apollo/client";
import { MockedProvider } from "@apollo/client/testing";
import "@testing-library/jest-dom";
import { render, screen, fireEvent, waitFor } from "@testing-library/react";
import React from "react";

import { translationWithRoot } from "@components/T";

import PreventionDocumentDeleteModal from "./PreventionDocumentDeleteModal";

const { t } = translationWithRoot("prevention.profile_page.delete_modal");

describe("PreventionDocumentDeleteModal", () => {
  const handleCloseModal = jest.fn();
  const refetchQuery = { kind: "Document" };
  const requirementHolderId = 123;
  const requirementIdToDelete = 456;

  const renderModal = (props = {}) =>
    render(
      <MockedProvider>
        <PreventionDocumentDeleteModal
          handleCloseModal={handleCloseModal}
          isHidden={false}
          refetchQuery={refetchQuery}
          requirementHolderId={requirementHolderId}
          requirementIdToDelete={requirementIdToDelete}
          {...props}
        />
      </MockedProvider>
    );

  beforeEach(() => {
    jest.clearAllMocks();
    // By default, return a no-op mutate function and { loading: false } for useMutation
    const mutateFn = jest.fn();
    jest
      .spyOn(Apollo, "useMutation")
      .mockReturnValue([mutateFn, { loading: false }]);
  });

  it("renders the modal with the correct title and body", () => {
    renderModal();
    const heading = screen.getByText(t("delete_document"), { selector: "h3" });
    expect(heading).toBeInTheDocument();
    expect(screen.getByText(t("body"))).toBeInTheDocument();
  });

  it("displays the submit button with the correct text", () => {
    renderModal();
    const submitButton = screen.getByText(t("delete_document"), {
      selector: "button",
    });
    expect(submitButton).toBeInTheDocument();
  });

  it("hides the modal when isHidden = true", () => {
    renderModal({ isHidden: true });
    expect(screen.queryByText(t("delete_document"))).not.toBeInTheDocument();
  });

  it("calls deleteDocument mutation on submit", async () => {
    const mutateFn = jest.fn().mockResolvedValueOnce({});
    Apollo.useMutation.mockReturnValue([mutateFn, { loading: false }]);

    renderModal();
    const submitButton = screen.getByText(t("delete_document"), {
      selector: "button",
    });
    fireEvent.click(submitButton);

    await waitFor(() => {
      expect(mutateFn).toHaveBeenCalledWith({
        variables: {
          requirementId: requirementIdToDelete,
          deleteUploadedRecords: true,
        },
      });
    });
  });

  it("displays loading state when mutation is in progress", () => {
    const mutateFn = jest.fn();
    Apollo.useMutation.mockReturnValue([mutateFn, { loading: true }]);

    renderModal();
    expect(screen.getByText(t("submitting_text"))).toBeInTheDocument();
  });

  it("cancelling the modal calls handleCloseModal", () => {
    renderModal();
    const cancelButton = screen.getByText("Cancel");
    fireEvent.click(cancelButton);
    expect(handleCloseModal).toHaveBeenCalled();
  });
});
