import { Alert } from "@heart/components";
import I18n from "i18n-js";
import React, { useState } from "react";

const attachmentError = path => `attachments.errors.${path}`;

/**
 * Creates an alert modal for displaying errors
 */
const useErrorModal = () => {
  const [errorCode, setErrorModalCode] = useState(null);

  const modal = () => (
    <Alert
      hidden={!errorCode}
      onSubmit={() => {
        setErrorModalCode(null);
      }}
      key={"prevention-error-modal"}
      isAlert={true}
      title={I18n.t("javascript.components.common.alert")}
      submitText={I18n.t(attachmentError("ok"))}
    >
      <span>{errorCode && I18n.t(attachmentError(errorCode))}</span>
    </Alert>
  );

  return [modal, setErrorModalCode];
};

export default useErrorModal;
