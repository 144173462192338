import { useMutation } from "@apollo/client";
import { Button, DetailsTable, Flex, Modal } from "@heart/components";
import { isNil, isEmpty } from "lodash";
import PropTypes from "prop-types";
import {
  editRelationshipAsChildConnectionPath,
  familyFindingClearPersonSearchPath,
  newFamilyFindingContactLogPath,
} from "routes";

import { translationWithRoot } from "@components/T";

import CreateClearPersonSearch from "@graphql/mutations/CreateClearPersonSearch.graphql";

import { formatDateTimeAsShortDate } from "@lib/dates";
import { policy } from "@lib/graphqlHelpers";
import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";
import useFeatureFlag from "@lib/useFeatureFlag";

import { FICTIVE_KIN, OTHER, WORKER } from "@root/constants";

import useChildAgencyHumanPermissions from "../../agency_humans/useChildAgencyHumanPermissions";
import {
  determineNonKeystoneId,
  determineSelectedPlacementProviderEligibilities,
} from "./relationshipConversion";
import {
  determineName,
  determineRelationshipTypeForTable,
  filterLevelsOfSupport,
} from "./sortRelationships";

const { t: enumT } = translationWithRoot("activerecord.enums.relationships", {
  escapeJavascriptRoot: true,
});
const { t: relationshipT } = translationWithRoot(
  "relationships.table.view_modal"
);

/** A modal for viewing the details of a relationship between two AgencyHumans */
const ViewRelationshipModal = ({
  keystoneAgencyHumanId,
  relationship,
  setRelationshipToView,
  editRelationshipsForAgencyHuman,
  setEditRelationshipsForAgencyHuman,
}) => {
  const { flag: ffIntegratedSearch } = useFeatureFlag(
    "ff_integrated_search_01_2024"
  );
  const { flag: ffGenogramMVP032025 } = useFeatureFlag(
    "ff_genogram_actual_mvp_03_2025"
  );

  const {
    destinationAgencyHuman,
    sourceAgencyHuman,
    kinshipRelationship,
    parentalLine,
    levelsOfSupport,
    placementProviderEligibilitiesJson = {},
    placementProviderEligibilitiesDetails,
    emotionalRelationshipStatuses = [],
    additionalRelationshipDetails,
    verificationSource,
    updatedAt,
  } = relationship || {};

  const notFictiveKin = kinshipRelationship !== FICTIVE_KIN;
  const { name, firstName, keystoneName, keystoneFirstName } = determineName({
    keystoneAgencyHumanId,
    destinationAgencyHuman,
    sourceAgencyHuman,
  });
  const nonKeystoneAgencyHumanId = determineNonKeystoneId({
    keystoneAgencyHumanId,
    relationship,
  });

  const permissions = useChildAgencyHumanPermissions(keystoneAgencyHumanId);
  const view = useBase64SearchParam("view");

  const canCreateSearch =
    permissions.mayCreatePotentialKinSearch() && ffIntegratedSearch;
  const [createClearPersonSearch, { loading: creatingSearch }] = useMutation(
    CreateClearPersonSearch,
    {
      onCompleted: mutationData => {
        window.location = familyFindingClearPersonSearchPath(
          mutationData.createClearPersonSearch.search.id
        );
      },
    }
  );

  if (isNil(relationship)) return null;

  return (
    <Modal
      title={relationshipT("title", {
        name,
        keystoneName,
      })}
      submitText={relationshipT("close")}
      onCancel={() => setRelationshipToView()}
      onSubmit={() => setRelationshipToView()}
      hidden={isNil(relationship) || !!editRelationshipsForAgencyHuman}
      hideCancel={true}
    >
      <Flex column gap="300">
        <DetailsTable
          caption={relationshipT("caption")}
          hideCaption={true}
          details={[
            {
              label: relationshipT("relationship_type"),
              value: determineRelationshipTypeForTable(relationship),
            },
            notFictiveKin && {
              label: relationshipT("parental_line"),
              value: isNil(parentalLine)
                ? ""
                : parentalLine
                    .split("_and_")
                    .map(line => enumT(`parental_lines.${line}`))
                    .join(", "),
            },
            {
              label: relationshipT("placement_option"),
              value: `${determineSelectedPlacementProviderEligibilities(
                placementProviderEligibilitiesJson
              )
                .map(ppe => {
                  const { details } =
                    placementProviderEligibilitiesJson[ppe] || {};
                  return `${enumT(`placement_provider_eligibilities.${ppe}`)}${
                    isEmpty(details) ? "" : ` - ${details}`
                  }`;
                })
                .join(", ")}`,
            },
            {
              label: relationshipT("placement_option_details"),
              value: placementProviderEligibilitiesDetails,
            },
            {
              label: relationshipT("level_of_support"),
              value: filterLevelsOfSupport(levelsOfSupport)
                .map(los => {
                  if (los === OTHER) return levelsOfSupport[OTHER].details;
                  return enumT(`level_of_support.${los}`);
                })
                .join(", "),
            },
            {
              label: relationshipT("emotional_relationship_status"),
              value: emotionalRelationshipStatuses
                .map(status => enumT(`emotional_status.${status}`))
                .join(", "),
            },
            {
              label: relationshipT("additional_details"),
              value: additionalRelationshipDetails,
            },
            {
              label: relationshipT("verified_by_worker"),
              value: verificationSource === WORKER ? "Yes" : "No",
            },
            {
              label: relationshipT("last_updated"),
              value: formatDateTimeAsShortDate(updatedAt),
            },
          ].filter(Boolean)}
        />
        <If condition={ffGenogramMVP032025 && view === "genogram"}>
          <Flex column align="center">
            <If condition={policy(relationship).mayEdit()}>
              <Button
                variant="secondary"
                href={editRelationshipAsChildConnectionPath({
                  /** In the context of the relationships table the child is the keystone agency
                   * human, but in the context of editing the relationship we want the non-child
                   * to be the keystone and all members of the sibling group to be the non-keystone
                   * agency humans, so in crafting this link we're flipping the ids
                   */
                  child_agency_human_id: keystoneAgencyHumanId,
                  keystone_agency_human_id: nonKeystoneAgencyHumanId,
                })}
              >
                {relationshipT("edit_relationship_to_child", {
                  childName: keystoneFirstName,
                  connectionName: firstName,
                })}
              </Button>
            </If>
            <If condition={notFictiveKin && policy(relationship).mayEdit()}>
              <Button
                variant="secondary"
                onClick={() =>
                  setEditRelationshipsForAgencyHuman(nonKeystoneAgencyHumanId)
                }
              >
                {relationshipT("edit_other_relationships", {
                  connectionName: firstName,
                })}
              </Button>
            </If>
            <If condition={canCreateSearch}>
              <Button
                variant="secondary"
                submitting={creatingSearch}
                onClick={async () => {
                  await createClearPersonSearch({
                    variables: { agencyHumanId: nonKeystoneAgencyHumanId },
                  });
                }}
              >
                {relationshipT("launch_pkos")}
              </Button>
            </If>
            <If condition={policy(relationship).mayCreateContactLog()}>
              <Button
                variant="secondary"
                href={
                  `${newFamilyFindingContactLogPath()}?child_agency_human_id=${keystoneAgencyHumanId}` +
                  `&contact_agency_human_ids=${nonKeystoneAgencyHumanId}`
                }
              >
                {relationshipT("record_new_contact")}
              </Button>
            </If>
          </Flex>
        </If>
      </Flex>
    </Modal>
  );
};
ViewRelationshipModal.propTypes = {
  keystoneAgencyHumanId: PropTypes.number.isRequired,
  relationship: PropTypes.shape({
    destinationAgencyHuman: PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
    }),
    sourceAgencyHuman: PropTypes.shape({ fullName: PropTypes.string }),
    kinshipRelationship: PropTypes.string,
    parentalLine: PropTypes.string,
    lineageType: PropTypes.string,
    levelsOfSupport: PropTypes.object,
    placementProviderEligibilitiesJson: PropTypes.shape({
      checked: PropTypes.bool,
    }),
    placementProviderEligibilitiesDetails: PropTypes.string,
    emotionalRelationshipStatuses: PropTypes.arrayOf(PropTypes.string),
    additionalRelationshipDetails: PropTypes.string,
    verificationSource: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  editRelationshipsForAgencyHuman: PropTypes.string,
  setEditRelationshipsForAgencyHuman: PropTypes.func,
  setRelationshipToView: PropTypes.func.isRequired,
};

export default ViewRelationshipModal;
