import classNames from "classnames";
import React, { forwardRef } from "react";

import Clickable, {
  ClickableProps,
  ClickableRefType,
  cleanClickableProps,
} from "../clickable/Clickable";
import styles from "./Link.module.scss";

/**
 * Use the `<Link>` component when you want your user to click on
 * some text!
 *
 * The `href` and `onClick` props are mutually exclusive - `href` will
 * render an `<a>` tag and `onClick` will render the children in a
 * `<button>` that has been given the visual style of a link
 *  _([why?](https://www.digitala11y.com/links-vs-buttons-a-perennial-problem/)_)
 *
 * Any props not documented here will be passed to the respective `<a>`
 * or `<button>` tags, but this is mostly for interoperability with libraries
 * like `react-collapsed`.
 *
 * The `<Link/>` component accepts a `ref` to enable things like keyboard functionality
 *
 * Migration notes!  Until [ENG-10731] has been completed, this is the
 * best way to make a clickable icon.  See the story below and don't forget to
 * add a `description` when you do this!
 *
 * ### How to find URLs to link to
 * For any links that point to an object we've queried in GraphQL, when you query the object
 * you can also request the `linkToView`/`linkToEdit` fields of that object.  This is the
 * convention we're adopting to make it easy to link to database objects - if the resource
 * you're fetching doesn't have one, add it to the schema!  See `Types::Agency` for an example.
 *
 * If you're trying to link to something other than a GraphQL object, interpolation
 * (ie ``<Link href={`/admin/complaints/${complaint.id}`}>``)  is the best way to go
 * until we engineer something more clever.
 */
const Link = forwardRef<
  ClickableRefType,
  ClickableProps & { description?: string; destructive?: boolean }
>(
  (
    {
      children,
      description,
      destructive = false,
      disabled = false,
      href,
      onClick,
      ...restProps
    },
    ref
  ) => {
    const textColorClasses = {
      // <a> tags don't have a `disabled` option, so we use CSS to disable the link
      [styles.disabled]: disabled,
      [styles.normal]: !disabled && !destructive,
      [styles.destructive]: !disabled && destructive,
    };

    return (
      <Clickable
        aria-label={description}
        data-heart-component="link"
        ref={ref}
        title={description}
        {...cleanClickableProps({
          href,
          disabled,
          onClick,
          anchorClassname: classNames(styles.link, textColorClasses),
          buttonClassname: classNames(
            styles.button,
            styles.link,
            textColorClasses
          ),
        })}
        {...restProps}
      >
        {children}
      </Clickable>
    );
  }
);
Link.displayName = "Link";

export default Link;
