import { useQuery, useMutation } from "@apollo/client";
import {
  ArrayDataTable,
  Surface,
  Button,
  Layout,
  Flex,
  Modal,
  toast,
  Breadcrumbs,
  TableCellList,
  LabeledContent,
} from "@heart/components";
import { Pencil, Trash, Play } from "@heart/components/icon/Icon";
import { isNil, snakeCase } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  adminAgencyIntegrationsPath,
  adminAgencyPath,
  editAdminAgencyIntegrationPath,
  newAdminAgencyIntegrationPath,
} from "routes";

import { translationWithRoot } from "@components/T";

import DeleteIntegrationConfiguration from "@graphql/mutations/DeleteIntegrationConfiguration.graphql";
import TriggerIntegrationConfiguration from "@graphql/mutations/TriggerIntegrationConfiguration.graphql";
import IntegrationConfigurations from "@graphql/queries/IntegrationConfigurations.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const { t } = translationWithRoot("admin.integrations", {
  escapeJavascriptRoot: true,
});

const infoCell = node => {
  const nodeInfo = { ...node.json, ...node.integrationCredentialsExpiration };
  const entries = Object.entries(nodeInfo)
    .filter(
      ([key, value]) =>
        !key.toLowerCase().includes("password") &&
        !key.toLowerCase().includes("secret") &&
        !key.toLowerCase().includes("typename") &&
        // now let's clean up any empty values from the cell
        !isNil(value) &&
        value !== ""
    )
    .map(([key, value]) => (
      <LabeledContent
        key={key}
        label={t(
          `labels.${key
            .replace(/Configuration/, "")
            .split(".")
            .map(word => snakeCase(word))
            .join(".")}`
        )}
        content={value.toString()} // adding toString() here so we can display booleans
      />
    ));
  return <TableCellList items={entries} />;
};

/**
 * IntegrationsPage component displays the integrations for a given agency.
 *
 * @param {Object} props.agency - The agency object.
 * @param {string} props.agency.id - The ID of the agency.
 * @param {string} props.agency.name - The name of the agency.
 */
const IntegrationsPage = ({ agency }) => {
  const [
    integrationConfigurationToDelete,
    setIntegrationConfigurationToDelete,
  ] = useState();

  const [
    integrationConfigurationToTrigger,
    setIntegrationConfigurationToTrigger,
  ] = useState();

  const refetchQueries = [
    {
      query: IntegrationConfigurations,
      variables: { agencyId: agency.id },
    },
  ];

  const { data, loading } = useQuery(IntegrationConfigurations, {
    variables: { agencyId: agency.id },
  });

  const [deleteIntegrationConfiguration, { loading: deleting }] = useMutation(
    DeleteIntegrationConfiguration,
    {
      refetchQueries,
      onCompleted: () => {
        setIntegrationConfigurationToDelete(undefined);
        toast.success({
          title: t("delete_modal.success"),
          message: t("delete_modal.deleted", {
            type: t(
              `types.${integrationConfigurationToDelete.integrationType}`
            ),
          }),
        });
      },
    }
  );

  const [triggerIntegrationConfiguration, { loading: running }] = useMutation(
    TriggerIntegrationConfiguration,
    {
      refetchQueries,
      onCompleted: () => {
        setIntegrationConfigurationToTrigger(undefined);
        toast.success({
          title: t("run_modal.success"),
          message: t("run_modal.triggering", {
            type: t(
              `types.${integrationConfigurationToTrigger.integrationType}`
            ),
          }),
        });
      },
    }
  );

  const content = (
    <Surface>
      <ArrayDataTable
        actions={
          <Button href={newAdminAgencyIntegrationPath(agency.id)}>
            {t("add")}
          </Button>
        }
        title={t("integrations")}
        columns={[
          {
            cell: node => t(`types.${node.integrationType}`),
            columnName: {
              name: t("type"),
            },
            id: "integration_type",
          },
          {
            cell: infoCell,
            columnName: {
              name: t("info"),
            },
            id: "json",
          },
          {
            columnName: {
              name: t("controls"),
            },
            id: "actions",
            cell: node => (
              <Flex>
                <Pencil
                  description={t("edit")}
                  href={editAdminAgencyIntegrationPath(agency.id, node.id)}
                />
                <Trash
                  description={t("delete")}
                  onClick={() => setIntegrationConfigurationToDelete(node)}
                />
              </Flex>
            ),
          },
          {
            columnName: {
              name: t("actions"),
            },
            id: "actions",
            cell: node => (
              // Only show the play button for data export integrations, until we expand
              // this functionality to other integration types
              <If condition={node.integrationType === "data_export"}>
                <Flex>
                  <Play
                    description={t("manually_trigger")}
                    onClick={() => setIntegrationConfigurationToTrigger(node)}
                  />
                </Flex>
              </If>
            ),
          },
        ]}
        data={data?.integrationConfigurations?.nodes || []}
        loading={loading}
      />
      <Modal
        hidden={isNil(integrationConfigurationToDelete)}
        title={t("delete_modal.confirm")}
        submitting={deleting}
        submitDangerButton
        submitText={t("delete_modal.confirm_yes")}
        submittingText={t("delete_modal.deleting")}
        onCancel={() => setIntegrationConfigurationToDelete(undefined)}
        onSubmit={() =>
          deleteIntegrationConfiguration({
            variables: { id: integrationConfigurationToDelete.id },
          })
        }
      >
        <If condition={integrationConfigurationToDelete}>
          {t("delete_modal.are_you_sure", {
            type: t(
              `types.${integrationConfigurationToDelete.integrationType}`
            ),
          })}
        </If>
      </Modal>

      <Modal
        hidden={isNil(integrationConfigurationToTrigger)}
        title={t("run_modal.confirm")}
        submitting={running}
        submitDangerButton
        submitText={t("run_modal.confirm_yes")}
        submittingText={t("run_modal.triggering")}
        onCancel={() => setIntegrationConfigurationToTrigger(undefined)}
        onSubmit={() =>
          triggerIntegrationConfiguration({
            variables: {
              id: integrationConfigurationToTrigger.id,
              type: integrationConfigurationToTrigger.integrationType,
            },
          })
        }
      >
        <If condition={integrationConfigurationToTrigger}>
          {t("run_modal.are_you_sure", {
            type: t(
              `types.${integrationConfigurationToTrigger.integrationType}`
            ),
          })}
        </If>
      </Modal>
    </Surface>
  );

  const breadcrumbs = (
    <Breadcrumbs
      pages={[
        {
          href: adminAgencyPath(agency.id),
          label: agency.name,
        },
        {
          href: adminAgencyIntegrationsPath(agency.id),
          label: t("integrations"),
        },
      ]}
    />
  );

  return (
    <Layout
      pageTitle={`${agency.name} — ${t("view")}`}
      main={{ content }}
      breadcrumbs={breadcrumbs}
    />
  );
};

IntegrationsPage.propTypes = {
  agency: PropTypes.shape({
    id: BintiPropTypes.ID,
    name: PropTypes.string,
  }),
};

export default IntegrationsPage;
