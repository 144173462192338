/* eslint-disable no-underscore-dangle */
import { useMutation } from "@apollo/client";
import { Alert } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import { UploadContext } from "@components/reusable_ui/useUpload";

import CreateApplicationAttachments from "@graphql/mutations/CreateApplicationAttachments.graphql";

import { typeEq } from "@lib/graphqlHelpers";
import useFeatureFlag from "@lib/useFeatureFlag";
import useShrine from "@lib/useShrine";

import UnmatchedRecordsTable from "./UnmatchedRecordsTable";

/**
 * Helper function to determine if a record is accessible to a partner agency.
 *
 * NOTE there is no actual permissions enforcement going on in this method,
 * it's simply a display helper for agency workers in the parent agency
 * so they can see what is accessible to their partner agencies.
 */
export const isPartnerAccessible = record => {
  if (
    (typeEq("Attachment", record) || typeEq("ApplicationMiscFile", record)) &&
    record.category === "otherCBOUploadedByAdmin"
  ) {
    return true;
  }

  if (
    typeEq("UploadedRecord", record) &&
    record.uploadType.cboWorkerCanAccess
  ) {
    return true;
  }

  if (typeEq("FormInstance", record) && record.form.cboWorkerCanAccess) {
    return true;
  }
  return false;
};

/** Other/Misc table for CBO/partner attachments */
const OtherPartnerDocumentsTable = ({ application, unmatchedRecords }) => {
  const [createApplicationAttachments] = useMutation(
    CreateApplicationAttachments
  );
  const [showAlert, setShowAlert] = useState(false);
  const { flag: ffShrineUploads032024, loading } = useFeatureFlag(
    "ff_shrine_application_misc_file_03_2025"
  );
  const records = unmatchedRecords.filter(isPartnerAccessible);

  const { prepareShrineFiles, ShrineErrorAlert } = useShrine();
  const uploadProvider = ffShrineUploads032024 ? "shrine" : "refile";

  const uploadFiles = async files => {
    if (files.length === 0) {
      setShowAlert(true);

      return;
    }

    let fileVariables;

    if (ffShrineUploads032024) {
      fileVariables = {
        files: [],
        shrineFiles: await prepareShrineFiles(files),
      };
    } else {
      fileVariables = { files };
    }

    await createApplicationAttachments({
      variables: {
        applicationId: application.id,
        category: "otherCBOUploadedByAdmin",
        ...fileVariables,
      },
    });
  };

  // avoid rendering the table until the feature flag is loaded.
  // it can cause a race condition if we try uploading when the feature flag is on,
  // but the ffShrineUploads032024 variable is still undefined while loading.
  if (loading) return null;

  const partnerAgencyLocalization = I18n.st(
    application.agency.stateAbbr,
    "partner_agency_abbreviation",
    { count: 1 }
  );

  return (
    <UploadContext.Provider value={{ uploadProvider }}>
      <UnmatchedRecordsTable
        title={I18n.t(
          "javascript.components.application_requirements.other_partner_documents_table.title",
          { partner_agency_abbreviation: partnerAgencyLocalization }
        )}
        {...{ application, records, uploadFiles }}
      />
      <ShrineErrorAlert />
      <Alert
        hidden={!showAlert}
        isAlert={true}
        title={I18n.t("javascript.components.common.alert")}
        onSubmit={() => setShowAlert(false)}
        submitText={I18n.t("attachments.errors.ok")}
      >
        {I18n.t("attachments.errors.unpermitted_format")}
      </Alert>
    </UploadContext.Provider>
  );
};

OtherPartnerDocumentsTable.propTypes = {
  application: PropTypes.object.isRequired,
  unmatchedRecords: PropTypes.array.isRequired,
};

export default OtherPartnerDocumentsTable;
