import { InputFilterable, Flex } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

/** Form fields conditionally shown on Inake Caregiver (/admin/intake_forms/new)
 * when an application template belongs to the parent agency of the user's current agency.
 *
 * e.g. if the application template belongs to Agency A, and a worker from Agency B is
 * intaking a caregiver these fields will be displayed
 */
const PartnerAgencyAssigned = ({
  agencyLabel,
  workerLabel,
  disabled,
  agencyCollection,
  workerCollection,
  currentPartnerAgency,
  currentPartnerWorker,
}) => {
  const [partnerAgency, setPartnerAgency] = useState(currentPartnerAgency);
  const [partnerWorker, setPartnerWorker] = useState(currentPartnerWorker);

  return (
    <Fragment>
      <Flex column>
        <InputFilterable
          label={agencyLabel}
          name="intake_form[sub_agency_ids]"
          values={agencyCollection.map(agency => ({
            id: agency.id,
            label: agency.name,
          }))}
          value={partnerAgency || []}
          onChange={setPartnerAgency}
          disabled={true}
          getOptionValue={option => option.id}
          isClearable
        />
        <InputFilterable
          label={workerLabel}
          name="intake_form[cbo_worker_ids]"
          value={partnerWorker || []}
          values={workerCollection.map(worker => ({
            id: worker.id,
            label: worker.name,
          }))}
          onChange={setPartnerWorker}
          getOptionValue={option => option.id}
          isClearable
          disabled={disabled}
        />
      </Flex>
    </Fragment>
  );
};

PartnerAgencyAssigned.propTypes = {
  agencyLabel: PropTypes.string.isRequired,
  workerLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  agencyCollection: PropTypes.array,
  workerCollection: PropTypes.array,
  currentPartnerAgency: PropTypes.array,
  currentPartnerWorker: PropTypes.array,
};

export default PartnerAgencyAssigned;
