import { Pill, Icons, Flex, IconMenu, Link } from "@heart/components";
import { contentFamilyFindingContactLogPath } from "routes";

import { translationWithRoot } from "@components/T";

import { formatDateTimeAsShortDate } from "@lib/dates";
import { policy } from "@lib/graphqlHelpers";

import { FAMILY_FINDING_CONTACT_LOG_CONTACT_METHOD_OTHER } from "@root/constants";

import Notes from "./Notes";
import contactStatusMap from "./contactStatusMap";

const { t: contactLogEnumT } = translationWithRoot(
  "activerecord.enums.family_finding_contact_log",
  {
    escapeJavascriptRoot: true,
  }
);
const { t: contactLogsT } = translationWithRoot("family_finding.contact_logs");

export const actionsLinkItems = (
  contactLog,
  setContactLogToView,
  setContactLogIdToDelete,
  editContactLogBasePath,
  childAgencyHumanId
) => {
  const items = [
    {
      description: contactLogsT("contact_logs_table.view_contact"),
      onClick: () => setContactLogToView(contactLog),
    },
  ];
  if (policy(contactLog).mayEdit()) {
    items.push({
      description: contactLogsT("common.edit_contact"),
      href: `${editContactLogBasePath}/${contactLog.id}/edit?child_agency_human_id=${childAgencyHumanId}`,
    });
  }
  if (policy(contactLog).mayDestroy()) {
    items.push({
      description: contactLogsT("contact_logs_table.delete_contact"),
      onClick: () => setContactLogIdToDelete(contactLog.id),
    });
  }
  return items;
};

const tableColumns = (
  setContactLogToView,
  setContactLogIdToDelete,
  editContactLogBasePath,
  childAgencyHumanId
) => [
  {
    columnName: {
      name: contactLogsT("common.persons_contacted"),
    },
    id: "persons_contacted",
    cell: ({ familyFindingContactLogsContactAgencyHumans }) =>
      familyFindingContactLogsContactAgencyHumans
        .map(
          record =>
            // A contact log will either be associated with an AgencyHuman
            // entity or will have an inputted string name for contactName
            record.contactAgencyHuman?.fullName || record.contactName
        )
        .join(", "),
  },
  {
    columnName: { name: contactLogsT("common.status") },
    id: "status",
    sortBy: "status",
    cell: ({ status }) => (
      <Flex justify="center">
        <Pill
          variant={contactStatusMap[status]}
          text={contactLogEnumT(`status.${status}`)}
        />
      </Flex>
    ),
  },
  {
    columnName: { name: contactLogsT("common.contact_method") },
    id: "contactMethod",
    cell: ({ contactMethod, contactMethodOther }) => {
      if (contactMethod === FAMILY_FINDING_CONTACT_LOG_CONTACT_METHOD_OTHER) {
        return `${contactLogEnumT(
          `contact_method.${contactMethod}`
        )}: ${contactMethodOther}`;
      }
      return contactLogEnumT(`contact_method.${contactMethod}`);
    },
  },
  {
    columnName: {
      name: contactLogsT("common.recorded_by"),
    },
    id: "recordedBy",
    cell: "recordedBy.name",
  },
  {
    columnName: { name: contactLogsT("common.notes") },
    id: "notes",
    cell: contactLog => {
      if (contactLog.familyFindingBulkOutreachCampaignId) {
        return (
          <Link
            href={contentFamilyFindingContactLogPath(contactLog.id)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactLog.notes}
          </Link>
        );
      }
      return (
        <Notes
          contactLog={contactLog}
          setContactLogToView={setContactLogToView}
        />
      );
    },
  },
  {
    columnName: { name: contactLogsT("common.date_contacted") },
    id: "contactedOn",
    sortBy: "contacted_on",
    cell: ({ contactedOn }) => formatDateTimeAsShortDate(contactedOn),
  },
  {
    columnName: {
      name: contactLogsT("contact_logs_table.actions"),
    },
    id: "actions",
    cell: contactLog => (
      <IconMenu
        icon={Icons.EllipsisVertical}
        menuDescription={contactLogsT("contact_logs_table.actions_description")}
        linkItems={actionsLinkItems(
          contactLog,
          setContactLogToView,
          setContactLogIdToDelete,
          editContactLogBasePath,
          childAgencyHumanId
        )}
        rightAligned
      />
    ),
  },
];

export default tableColumns;
