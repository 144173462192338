import { useMutation } from "@apollo/client";
import { Button, toast, useConfirmModal } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useCallback, useState } from "react";

import { translationWithRoot } from "@components/T";

import MigrateLegacyAppTemplatesToRequirements from "@graphql/mutations/MigrateLegacyAppTemplatesToRequirements.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const { t, T } = translationWithRoot(
  "application_templates.migrate_to_requirements_button"
);

/**
 * Button on the application template designer page that migrates a legacy
 * application template to the new requirements system.
 */
const MigrateToRequirementsButton = ({
  initiallyMigrated,
  applicationTemplateId,
}) => {
  const [migrate] = useMutation(MigrateLegacyAppTemplatesToRequirements, {
    variables: { id: applicationTemplateId },
  });
  const [migrated, setMigrated] = useState(initiallyMigrated);
  const { confirm, ConfirmModal } = useConfirmModal();

  const onClick = useCallback(async () => {
    if (migrated) {
      if (!(await confirm())) return;
    }

    try {
      await migrate();
      setMigrated(true);
      toast.success({ message: t("migration_worked") });
    } catch {
      toast.negative({ message: t("migration_failed") });
    }
  }, [confirm, migrate, migrated]);

  const labelKey = migrated
    ? "reset_requirements_template"
    : "migrate_to_requirements";

  return (
    <Fragment>
      <ConfirmModal>
        <T t="erase_requirements_work" />
      </ConfirmModal>
      <Button onClick={onClick} variant="secondary">
        <T t={labelKey} />
      </Button>
    </Fragment>
  );
};

MigrateToRequirementsButton.propTypes = {
  applicationTemplateId: BintiPropTypes.ID.isRequired,
  initiallyMigrated: PropTypes.bool.isRequired,
};

export default MigrateToRequirementsButton;
