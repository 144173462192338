import { Background } from "@heart/components";
import { ElementType, ReactElement } from "react";

import { If } from "tsx-control-statements/components";
import classNames from "classnames";
import styles from "./Pill.module.scss";

type PillProps = {
  text: string | ReactElement,
  icon?: ElementType,
  variant?: "alert" | "neutral" | "info" | "success" | "warning",
  collapsed?: boolean,
};

/**
 * A rounded pill that displays text, e.g. a status, tag, category, or informational
 * label.
 *
 * ## Features
 * - Display simple text with color-coded backgrounds for different statuses
 * - Can include an icon from Font Awesome for additional visual context
 *
 * ## Component Usage
 *
 * Basic pill:
 * ```jsx
 * <Pill text="Active" variant="success" />
 * ```
 *
 * With an icon:
 * ```jsx
 * <Pill text="Confidential" icon={Icons.EyeSlash} variant="warning" />
 * ```
 *
 * Available variants:
 * - `alert` - For critical or error states
 * - `neutral` - For default or neutral states (default)
 * - `info` - For informational items
 * - `success` - For successful or positive states
 * - `warning` - For caution or warning states
 *
 * @param variant Which variant?
 * @param text What should the pill say?
 * @param icon one of the components from `import { Icons } from "@heart/components"`
 * @param collapsed when true, will display just the icon provided and will move the
 * text to a description of the icon for assistive tech. In these cases, text should
 * always be a string
 */
const Pill = ({
  text,
  icon: IconComponent,
  variant = "neutral",
  collapsed,
}: PillProps) => {
  const backgroundColor = {
    alert: "alert-100",
    neutral: "info-50",
    info: "info-100",
    success: "success-100",
    warning: "warning-100",
  };

  return (
    <Background
      as="span"
      color={backgroundColor[variant]}
      className={styles.pill}
      data-heart-component="Pill"
      data-variant={variant}
    >
      <If condition={IconComponent}>
        <span className={classNames({[styles.withMargin] : !collapsed})}>
          <IconComponent
            description={collapsed ? text : undefined}
          />
        </span>
      </If>
      <If condition={!collapsed}>{text}</If>
    </Background>
  );
};

export default Pill;
