import { useMutation, useQuery } from "@apollo/client";
import {
  Actions,
  Flex,
  InputCheckbox,
  InputDate,
  InputFilterable,
  InputPhone,
  InputSSN,
  InputText,
  Layout,
  LoadingOverlay,
  SurfaceForm,
  Text,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import { intersection, isEmpty, isEqual, pickBy } from "lodash";
import { useState } from "react";
import { familyFindingClearPersonSearchPath } from "routes";
import thomsonReutersLogo from "thomson-reuters-logo.png";

import { translationWithRoot } from "@components/T";

import CreateClearPersonSearch from "@graphql/mutations/CreateClearPersonSearch.graphql";
import ClearPersonSearch from "@graphql/queries/ClearPersonSearch.graphql";
import PrimarySubdivisionsQuery from "@graphql/queries/PrimarySubdivisions.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import ConfirmationModal from "./ConfirmationModal";

const { T, t } = translationWithRoot("family_finding.potential_kin_search");

const { T: CommonT } = translationWithRoot("views.common", {
  escapeJavascriptRoot: true,
});

const invalidCombos = [
  ["firstName"],
  ["firstName", "middleName"],
  ["firstName", "city"],
  ["firstName", "state"],
  ["firstName", "zip"],
  ["middleName"],
  ["middleName", "addressLine1"],
  ["middleName", "addressLine1", "addressLine2"],
  ["middleName", "city"],
  ["middleName", "state"],
  ["middleName", "zip"],
  ["middleName", "city", "zip"],
  ["middleName", "state", "zip"],
  ["dateOfBirth"],
  ["ageRangeMin", "ageRangeMax"],
  ["addressLine1"],
  ["addressLine2"],
  ["addressLine1", "addressLine2"],
  ["city"],
  ["city", "state"],
  ["city", "state", "zip"],
  ["city", "zip"],
  ["state"],
  ["state", "zip"],
  ["zip"],
];

/**
 * Form for searching for potential kin
 */
const SearchForm = ({ editingSearchId }) => {
  const [hideConfirmationModal, setHideConfirmationModal] = useState(true);
  const { data: search, loading: queryLoading } = useQuery(ClearPersonSearch, {
    variables: { id: editingSearchId },
    skip: !editingSearchId,
  });

  const searchFields = search?.clearPersonSearch?.searchFields;
  const { formState, setFormAttribute, setFormState } =
    useBintiForm(searchFields);

  const [createSearch, { loading: mutationLoading }] = useMutation(
    CreateClearPersonSearch
  );

  const { data: primarySubdivisionsData } = useQuery(PrimarySubdivisionsQuery, {
    // We only run searches on US addresses
    variables: { countryCode: "US" },
  });

  const stateLabel = stateValue => {
    if (primarySubdivisionsData) {
      const stateMatch = primarySubdivisionsData.primarySubdivisions.find(
        subdiv => subdiv.code === stateValue
      );
      return stateMatch ? stateMatch.name : null;
    }
    return null;
  };

  const clearAll = () => setFormState({});

  const onSubmit = () => {
    // if the new search has the same fields as the search being edited,
    // just redirect to the results for that search
    if (isEqual(pickBy(formState), pickBy(searchFields))) {
      window.location = familyFindingClearPersonSearchPath(editingSearchId);
    } else {
      createSearch({
        variables: { criteria: formState },
      }).then(({ data }) => {
        window.location = familyFindingClearPersonSearchPath(
          data.createClearPersonSearch.search.id
        );
      });
    }
  };

  const noSearchCriteria = isEmpty(pickBy(formState));
  const validSearchCriteria = () => {
    if (noSearchCriteria) return false;

    const presentFields = Object.keys(pickBy(formState));

    const invalid = invalidCombos.filter(
      combo =>
        combo.length === presentFields.length &&
        intersection(combo, presentFields).length === presentFields.length
    );
    return isEmpty(invalid);
  };

  return (
    <LoadingOverlay active={queryLoading}>
      <ConfirmationModal
        onSubmit={preventDefault(onSubmit)}
        onCancel={() => setHideConfirmationModal(true)}
        hidden={hideConfirmationModal}
      />
      <Layout
        pageTitle={<T t="search_form.title" />}
        subtitle={<T t="search_form.subtitle" />}
        secondary={
          <img
            style={{ height: "64px" }}
            src={thomsonReutersLogo}
            alt={t("thomson_reuters_logo_alt")}
          />
        }
        main={{
          content: (
            <SurfaceForm
              title={<T t="search_form.search_criteria" />}
              actions={
                <Actions
                  cancelAction={clearAll}
                  cancelText={<T t="search_form.clear_all" />}
                  primaryText={<T t="search_form.search" />}
                  primaryDisabled={noSearchCriteria || !validSearchCriteria()}
                  isSubmitting={mutationLoading}
                />
              }
              onSubmit={preventDefault(() => setHideConfirmationModal(false))}
            >
              <Flex row mobileColumn fullWidth>
                <InputText
                  label={<CommonT t="first_name" />}
                  value={formState.firstName}
                  onChange={setFormAttribute("firstName")}
                  fullWidth
                />
                <InputText
                  label={<CommonT t="middle_name" />}
                  value={formState.middleName}
                  onChange={setFormAttribute("middleName")}
                  fullWidth
                />
                <InputText
                  label={<CommonT t="last_name" />}
                  value={formState.lastName}
                  onChange={setFormAttribute("lastName")}
                  fullWidth
                />
              </Flex>
              <InputCheckbox
                label={<T t="search_form.flexible_search" />}
                value={formState.flexibleSearch}
                onChange={setFormAttribute("flexibleSearch")}
              />
              <Flex row mobileColumn fullWidth>
                <InputDate
                  label={<CommonT t="date_of_birth" />}
                  value={formState.dateOfBirth}
                  onChange={setFormAttribute("dateOfBirth")}
                />
                <InputSSN
                  label={<T t="common.ssn" />}
                  value={formState.ssn}
                  onChange={setFormAttribute("ssn")}
                />
                <InputText
                  label={<T t="common.drivers_license" />}
                  value={formState.driversLicenseNumber}
                  onChange={setFormAttribute("driversLicenseNumber")}
                  fullWidth
                />
                <InputText
                  label={<T t="common.entity_id" />}
                  value={formState.entityId}
                  onChange={setFormAttribute("entityId")}
                  fullWidth
                />
              </Flex>
              <Flex row mobileColumn fullWidth>
                <Flex row>
                  <InputText
                    label={<T t="common.age_range_min" />}
                    type="number"
                    value={formState.ageRangeMin}
                    onChange={setFormAttribute("ageRangeMin")}
                  />
                  <InputText
                    label={<T t="common.age_range_max" />}
                    type="number"
                    value={formState.ageRangeMax}
                    onChange={setFormAttribute("ageRangeMax")}
                  />
                </Flex>
                <InputPhone
                  label={<CommonT t="phone_number" />}
                  value={formState.phoneNumber}
                  onChange={setFormAttribute("phoneNumber")}
                />
                <InputText
                  label={<CommonT t="email_address" />}
                  value={formState.email}
                  type="email"
                  onChange={setFormAttribute("email")}
                  fullWidth
                />
              </Flex>
              <Flex row mobileColumn fullWidth>
                <InputText
                  label={<T t="common.address_line_1" />}
                  value={formState.addressLine1}
                  onChange={setFormAttribute("addressLine1")}
                  fullWidth
                />
                <InputText
                  label={<T t="common.address_line_2" />}
                  value={formState.addressLine2}
                  onChange={setFormAttribute("addressLine2")}
                  fullWidth
                />
                <InputText
                  label={<T t="common.city" />}
                  value={formState.city}
                  onChange={setFormAttribute("city")}
                  fullWidth
                />
              </Flex>
              <Flex row mobileColumn fullWidth>
                <InputFilterable
                  label={<T t="common.state" />}
                  values={
                    primarySubdivisionsData
                      ? primarySubdivisionsData.primarySubdivisions.map(
                          ({ code, name }) => ({
                            label: name,
                            value: code,
                          })
                        )
                      : []
                  }
                  value={{
                    label: stateLabel(formState.state),
                    value: formState.state,
                  }}
                  onChange={({ value }) => setFormAttribute("state")(value)}
                  fullWidth
                />
                <InputText
                  label={<T t="common.zip" />}
                  value={formState.zip}
                  onChange={setFormAttribute("zip")}
                  fullWidth
                />
              </Flex>
              <If condition={!noSearchCriteria && !validSearchCriteria()}>
                <Text textColor="danger-600">
                  <T t="search_form.notice" />
                </Text>
              </If>
            </SurfaceForm>
          ),
        }}
      />
    </LoadingOverlay>
  );
};

SearchForm.propTypes = {
  editingSearchId: BintiPropTypes.ID,
};

export default SearchForm;
