import { useQuery } from "@apollo/client";
import { InputHidden, InputCheckbox } from "@heart/components";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { translationWithRoot } from "@components/T";

import Can from "@graphql/queries/Can.graphql";

const { t } = translationWithRoot("child.child_case_status");

/** A component to set the child's case status to confidential and/or sealed
 *
 * This can only be used in an uncontrolled Active Admin form input currently
 */
const ChildCaseStatus = ({
  confidentialInputName,
  sealedInputName,
  confidentialInitialValue,
  sealedInitialValue,
}) => {
  const [confidential, setConfidential] = useState(confidentialInitialValue);
  const [sealed, setSealed] = useState(sealedInitialValue);
  const [error, setError] = useState("");

  const { data: { can: canUpdateConfidential } = {} } = useQuery(Can, {
    variables: { resource: "child", action: "update_confidential" },
  });

  const { data: { can: canUpdateSealed } = {} } = useQuery(Can, {
    variables: { resource: "child", action: "update_sealed" },
  });

  useEffect(() => {
    if (sealed && confidential && canUpdateConfidential) {
      setError(t("error"));
    } else {
      // Clear error if one is unchecked
      setError("");
    }
  }, [sealed, confidential, canUpdateConfidential]);

  return (
    <div className="contains-inputs">
      <If condition={canUpdateConfidential}>
        {/* adding default hidden input to always send value */}
        <InputHidden name={confidentialInputName} value="false" />
        <InputCheckbox
          label={t("confidential_label")}
          name={confidentialInputName}
          htmlValue="true"
          onChange={setConfidential}
          value={confidential}
        />
      </If>
      <If condition={canUpdateSealed}>
        {/* adding default hidden input to always send value */}
        <InputHidden name={sealedInputName} value="false" />
        <InputCheckbox
          label={t("sealed_label")}
          name={sealedInputName}
          htmlValue="true"
          onChange={setSealed}
          value={sealed}
          error={error}
        />
      </If>
    </div>
  );
};

ChildCaseStatus.propTypes = {
  /** Name of confidential input field */
  confidentialInputName: PropTypes.string,
  /** Name of sealed input field */
  sealedInputName: PropTypes.string,
  /** Whether the child is confidential currently */
  confidentialInitialValue: PropTypes.bool.isRequired,
  /** Whether the child is sealed currently */
  sealedInitialValue: PropTypes.bool.isRequired,
};

export default ChildCaseStatus;
