import { Avatar, Flex, Icons, Pill, Text } from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useContext } from "react";

import { translationWithRoot } from "@components/T";

import { LayoutContext } from "./Layout";
import styles from "./Sidebar.module.scss";

const { t } = translationWithRoot("heart.components.sidebar");

const ListItems = ({ items = [], ordered, withDividers, collapsed }) => {
  const children = items.map(
    ({ id, items: subitems, content, icon, ...props }) => (
      <li
        key={id}
        {...props}
        className={classNames({
          [styles.sidebarItem]: !collapsed,
          [styles.addBorder]: withDividers,
        })}
      >
        {collapsed ? icon || null : content}
        <If condition={subitems}>
          <ListItems items={subitems} ordered={ordered} collapsed={collapsed} />
        </If>
      </li>
    )
  );

  const ListComponent = ordered ? "ol" : "ul";
  const ExpandedListComponent = () => (
    <ListComponent
      className={classNames(styles.sidebarItems, styles.listStyleNone)}
    >
      {children}
    </ListComponent>
  );
  const CollapsedListComponent = () => (
    <Flex
      column
      gap="200"
      as={ordered ? "ol" : "ul"}
      className={styles.listStyleNone}
      align="center"
    >
      {children}
    </Flex>
  );

  if (collapsed) {
    return <CollapsedListComponent>{children}</CollapsedListComponent>;
  }

  return (
    <ExpandedListComponent className={styles.sidebarItems}>
      {children}
    </ExpandedListComponent>
  );
};
ListItems.propTypes = {
  items: PropTypes.array,
  ordered: PropTypes.bool,
  withDividers: PropTypes.bool,
  collapsed: PropTypes.bool,
};

/** A Sidebar that can be given to our Layout component */
const Sidebar = ({
  avatar,
  label,
  pillProps,
  ordered = false,
  fixed,
  items,
  withDividers,
}) => {
  const { isMobile, desktopCollapsed, setDesktopCollapsed } =
    useContext(LayoutContext);
  const isDesktopAndCollapsed = !isMobile && desktopCollapsed;
  const CollapseIcon = isDesktopAndCollapsed ? Icons.Bars : Icons.Times;

  return (
    <Flex
      as="nav"
      gap="300"
      column
      aria-labelledby="sidebarLabel"
      className={classNames({
        [styles.fixed]: fixed,
      })}
      data-heart-component="Sidebar"
    >
      <Flex justify={isDesktopAndCollapsed ? "center" : "end"}>
        {/* We're limiting collapsibility to sidebars with avatars for now,
        as profiles with large tables is our current use case. If you have
        another usecase please raise the issue with #guild_frontend.
        on mobile, collapsibility of the sidebar is taken care of by Layout
        */}
        <If condition={!isMobile && avatar}>
          <CollapseIcon
            description={
              isDesktopAndCollapsed
                ? t("expand_sidebar")
                : t("collapse_sidebar")
            }
            aria-hidden={isMobile}
            aria-expanded={!isDesktopAndCollapsed}
            aria-controls="page_sidebar"
            onClick={() => {
              setDesktopCollapsed(!isDesktopAndCollapsed);
            }}
          />
        </If>
      </Flex>
      <If condition={avatar}>
        <Flex align="center" justify="center" column>
          <Avatar
            size={isDesktopAndCollapsed ? 500 : 800}
            firstName={avatar.firstName}
            lastName={avatar.lastName}
          />
          <If condition={!isDesktopAndCollapsed}>
            <Text textStyle="emphasis-200">{label}</Text>
          </If>
        </Flex>
      </If>
      <If condition={pillProps}>
        <Flex justify={avatar ? "center" : undefined}>
          <Pill {...pillProps} collapsed={isDesktopAndCollapsed} />
        </Flex>
      </If>
      <div id="sidebarLabel" className={styles.hidden}>
        {t("navigation", { label })}
      </div>
      <ListItems
        items={items}
        ordered={ordered}
        withDividers={withDividers}
        collapsed={isDesktopAndCollapsed}
      />
    </Flex>
  );
};
Sidebar.propTypes = {
  /** When present, displays the provided label and an Avatar */
  avatar: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  /** A descriptor for the entire sidebar, required for a11y */
  label: PropTypes.string.isRequired,
  /** When provided, will render a Pill component under the sidebar label.
   *
   * This pill should have an icon if the sidebar is collapsible, so that the
   * pill can also be collapsed.
   */
  pillProps: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    variant: PropTypes.oneOf([
      "alert",
      "neutral",
      "info",
      "success",
      "warning",
    ]),
  }),
  /** Are the elements of the sidebar in a specific order (e.g. form navigation)
   * or are they independent elements (e.g. different pages for a profile)?
   * Defaults to false
   */
  ordered: PropTypes.bool,
  /** Set to true if the sidebar should be fixed as you scroll */
  fixed: PropTypes.bool,
  /** What items should be listed in the sidebar */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** A unique id for the item */
      id: PropTypes.string.isRequired,
      /** Any subitems for the item. Subitems should match
       * the same prop structure as the items prop
       */
      items: PropTypes.array,
      /** Content of the item */
      content: PropTypes.node.isRequired,
      /** When the sidebar is collapsible, this icon will be shown
       * in place of the content when the sidebar is collapsed on
       * non-mobile devices
       */
      icon: PropTypes.node,
    })
  ),
  /** Should the items in the sidebar be visually divided? */
  withDividers: PropTypes.bool,
};

export default Sidebar;
