import { useMutation } from "@apollo/client";
import {
  Actions,
  Breadcrumbs,
  Layout,
  SurfaceForm,
  toast,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import {
  adminAgencyIntegrationsPath,
  adminAgencyPath,
  editAdminAgencyIntegrationPath,
} from "routes";

import { translationWithRoot } from "@components/T";

import UpdateIntegrationConfiguration from "@graphql/mutations/UpdateIntegrationConfiguration.graphql";

import preventDefault from "@lib/preventDefault";

import ClearConfigurationForm from "./ClearConfigurationForm";
import DataExportConfigurationForm from "./DataExportConfigurationForm";
import UnityConfigurationForm from "./UnityConfigurationForm";
import WebhookConfigurationForm from "./WebhookConfigurationForm";

const { t } = translationWithRoot("admin.integrations", {
  escapeJavascriptRoot: true,
});

/**
 * EditIntegrationPage component for editing integration configurations.
 *
 * @param {Object} props.agency - The agency object.
 * @param {Object} props.integrationConfiguration - The integration configuration object.
 */
const EditIntegrationPage = ({ agency, integrationConfiguration }) => {
  const redirect = () => {
    const path = adminAgencyIntegrationsPath(agency.id);
    window.location.replace(path);
  };

  const { formState, setFormState, setFormAttribute } = useBintiForm(
    integrationConfiguration.json
  );

  const [updateConfig, { loading, data: updateData }] = useMutation(
    UpdateIntegrationConfiguration,
    {
      onCompleted: result => {
        if (isEmpty(result.updateIntegrationConfiguration.errors)) {
          redirect();
        } else {
          toast.negative({
            title: "Error",
            message: result.updateIntegrationConfiguration.errors.map(
              e => e.message
            ),
          });
        }
      },
    }
  );
  const validationErrors =
    updateData?.updateIntegrationConfiguration?.errors || [];

  const breadcrumbs = (
    <Breadcrumbs
      pages={[
        { href: adminAgencyPath(agency.id), label: agency.name },
        {
          href: adminAgencyIntegrationsPath(agency.id),
          label: t("integrations"),
        },
        {
          href: editAdminAgencyIntegrationPath(
            agency.id,
            integrationConfiguration.id
          ),
          label: t("edit"),
        },
      ]}
    />
  );

  return (
    <Layout
      breadcrumbs={breadcrumbs}
      pageTitle={`${agency.name} — ${t("edit")}`}
      main={{
        content: (
          <SurfaceForm
            actions={
              <Actions
                primaryText={t("save")}
                primaryDisabled={loading}
                isSubmitting={loading}
              />
            }
            title={t("edit")}
            onSubmit={preventDefault(() =>
              updateConfig({
                variables: { id: integrationConfiguration.id, json: formState },
              })
            )}
            submitting={loading}
          >
            <If
              condition={integrationConfiguration.integrationType === "unity"}
            >
              <UnityConfigurationForm
                formState={formState}
                setFormAttribute={setFormAttribute}
                validationErrors={validationErrors}
              />
            </If>
            <If
              condition={integrationConfiguration.integrationType === "clear"}
            >
              <ClearConfigurationForm
                agencyId={agency.id}
                formState={formState}
                setFormState={setFormState}
                setFormAttribute={setFormAttribute}
                validationErrors={validationErrors}
              />
            </If>
            <If
              condition={integrationConfiguration.integrationType === "webhook"}
            >
              <WebhookConfigurationForm
                formState={formState}
                setFormAttribute={setFormAttribute}
                validationErrors={validationErrors}
              />
            </If>
            <If
              condition={
                integrationConfiguration.integrationType === "data_export"
              }
            >
              <DataExportConfigurationForm
                formState={formState}
                setFormAttribute={setFormAttribute}
                validationErrors={validationErrors}
              />
            </If>
          </SurfaceForm>
        ),
      }}
    />
  );
};

EditIntegrationPage.propTypes = {
  agency: PropTypes.object,
  integrationConfiguration: PropTypes.object,
};

export default EditIntegrationPage;
