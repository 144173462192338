import {
  Surface,
  GraphQLDataTable,
  Pill,
  Flex,
  Icons,
  Layout,
} from "@heart/components";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

import Events from "@graphql/queries/integrations/Events.graphql";

import EventEditModal from "./EventEditModal";

const { t } = translationWithRoot("admin.integrations", {
  escapeJavascriptRoot: true,
});

/**
 * A Table to display integration events.
 */
const EventsTable = () => {
  const [showEditModal, setShowEditModal] = useState(null);
  const [eventIdToEdit, setEventIdToEdit] = useState(null);

  return (
    <Layout
      pageTitle={"Integration Events"}
      main={{
        content: (
          <Surface hideTitle>
            <GraphQLDataTable
              query={Events}
              queryTitle="events"
              filters={[]}
              defaultSort={{ sortBy: "slug", sortDirection: "ASC" }}
              columns={[
                {
                  columnName: { name: "Event Slug" },
                  id: "slug",
                  sortBy: "slug",
                  cell: "slug",
                },
                {
                  columnName: { name: "Status" },
                  id: "status",
                  sortBy: "status",
                  cell: ({ status }) => (
                    <Pill
                      variant={status === "enabled" ? "success" : "warning"}
                      text={t(`labels.${status}`)}
                    />
                  ),
                },
                {
                  columnName: { name: "Subscriber Count" },
                  id: "subscriberCount",
                  sortBy: "subscriberCount",
                  cell: "subscriberCount",
                },
                {
                  columnName: { name: "Actions" },
                  id: "actions",
                  cell: ({ id }) => (
                    <Flex row>
                      <Icons.Pencil
                        description="Edit"
                        onClick={() => {
                          setEventIdToEdit(id);
                          setShowEditModal(true);
                        }}
                      />
                    </Flex>
                  ),
                },
              ]}
            />
            <If condition={showEditModal}>
              <EventEditModal
                eventIdToEdit={eventIdToEdit}
                resetStateCallback={() => {
                  setEventIdToEdit(null);
                  setShowEditModal(false);
                }}
              />
            </If>
          </Surface>
        ),
      }}
    />
  );
};
EventsTable.propTypes = {};

export default EventsTable;
