import { useMutation } from "@apollo/client";
import {
  SurfaceForm,
  InputDropdown,
  Actions,
  Notice,
  Flex,
  Icons,
  InputTextarea,
  toast,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { rootPath } from "routes";

import { translationWithRoot } from "@components/T";

import TransferApplicationAgency from "@graphql/mutations/TransferApplicationAgency.graphql";

import preventDefault from "@lib/preventDefault";

const { t: transferReasonsT } = translationWithRoot(
  "transfer.transfer_reasons"
);
const { t } = translationWithRoot("transfer");

/**
 * Form for Transferring Applications between agencies
 */
const TransferApplicationForm = ({
  receivingAgencies,
  applicationTemplateLineages,
  licenseId,
  workerId,
  ongoingAppeal,
  currentPlacement,
  confidentialCaregiver,
  transferReasons,
}) => {
  const [reasonForTransfer, setReasonForTransfer] = useState();
  const [notes, setNotes] = useState();
  const [destinationAgency, setDestinationAgency] = useState(
    receivingAgencies.length === 1 ? receivingAgencies[0][1] : null
  );
  const [destinationTemplate, setDestinationTemplate] = useState(
    applicationTemplateLineages.length === 1
      ? applicationTemplateLineages[0][1]
      : null
  );
  const [transferApplication, { loading: mutationLoading }] = useMutation(
    TransferApplicationAgency,
    {
      onCompleted: () => {
        toast.success({
          title: "Success!",
          message: "Transferred Application",
        });
        window.location.replace(rootPath);
      },
    }
  );

  return (
    <SurfaceForm
      title={t("transfer_caregiver")}
      onSubmit={preventDefault(() => {
        transferApplication({
          variables: {
            licenseId: licenseId,
            workerId: workerId,
            receivingTemplateLineageId: destinationTemplate,
            reasonForTransfer: reasonForTransfer,
            notes: notes,
          },
        });
      })}
      actions={
        <Actions
          primaryDisabled={
            ongoingAppeal || currentPlacement || confidentialCaregiver
          }
          isSubmitting={mutationLoading}
          primarySubmittingText={I18n.t("views.common.submitting")}
          primaryText={I18n.t("views.common.submit")}
        />
      }
    >
      <If condition={ongoingAppeal}>
        <Notice
          title={
            <Flex>
              <Icons.ExclamationTriangle />
              {t("warning_appeal")}
            </Flex>
          }
        />
      </If>
      <If condition={confidentialCaregiver}>
        <Notice
          title={
            <Flex>
              <Icons.ExclamationTriangle />
              {t("warning_confidential")}
            </Flex>
          }
        />
      </If>
      <If condition={currentPlacement}>
        <Notice
          title={
            <Flex>
              <Icons.ExclamationTriangle />
              {t("warning_current_placement")}
            </Flex>
          }
        />
      </If>
      <InputDropdown
        label={t("select_destination_agency")}
        value={destinationAgency}
        onChange={setDestinationAgency}
        values={receivingAgencies.map(([label, value]) => [label, value])}
        required={true}
      />
      <InputDropdown
        label={t("select_destination_template")}
        value={destinationTemplate}
        onChange={setDestinationTemplate}
        hidden={!destinationAgency}
        required={true}
        values={applicationTemplateLineages
          .filter(template => template[2] === parseInt(destinationAgency, 10))
          .map(template => [template[0], template[1]])}
      />

      <InputDropdown
        label={t("select_transfer_reason")}
        value={reasonForTransfer}
        onChange={setReasonForTransfer}
        required={true}
        values={transferReasons.map(transferReason => [
          transferReasonsT(transferReason),
          transferReason,
        ])}
      />
      <If condition={reasonForTransfer === "other"}>
        <InputTextarea
          label={t("please_specify")}
          value={notes}
          onChange={setNotes}
          required={reasonForTransfer === "other"}
        />
      </If>
    </SurfaceForm>
  );
};

TransferApplicationForm.propTypes = {
  receivingAgencies: PropTypes.array,
  applicationTemplateLineages: PropTypes.array,
  currentPlacement: PropTypes.bool,
  confidentialCaregiver: PropTypes.bool,
  ongoingAppeal: PropTypes.bool,
  licenseId: PropTypes.number.isRequired,
  workerId: PropTypes.number.isRequired,
  transferReasons: PropTypes.array,
};

export default TransferApplicationForm;
