import { InputCheckboxGroup, InputDropdown } from "@heart/components";
import {
  fromLegacyCheckboxGroupValue,
  toLegacyCheckboxGroupValue,
} from "@heart/components/inputs/InputCheckboxGroup";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

const AttributeBasedConditionConfig = ({ onChange, configOptions }) => {
  const [attribute, setAttribute] = useState("status");
  const [values, setValues] = useState([]);

  const onChangeAttribute = newAttribute => {
    const newConditionConfig = { attribute: newAttribute, values };
    onChange(newConditionConfig);
    setAttribute(newAttribute);
  };

  const onChangeAllowedValues = newValues => {
    const newConditionConfig = { attribute, values: newValues };
    onChange(newConditionConfig);
    setValues(newValues);
  };

  return (
    <Fragment>
      <InputDropdown
        required
        label={I18n.t(
          "javascript.components.permissions.attribute_based_condition.choose_attribute"
        )}
        value={attribute}
        values={Object.keys(configOptions)}
        hideBlank
        onChange={onChangeAttribute}
      />
      <InputCheckboxGroup
        label={I18n.t(
          "javascript.components.permissions.attribute_based_condition.choose_values"
        )}
        id="attribute_based_condition_values"
        name="attributeValues"
        required
        value={fromLegacyCheckboxGroupValue(values)}
        values={configOptions[attribute]}
        onChange={val => onChangeAllowedValues(toLegacyCheckboxGroupValue(val))}
      />
    </Fragment>
  );
};

AttributeBasedConditionConfig.propTypes = {
  onChange: PropTypes.func,
  configOptions: PropTypes.object,
};

export default AttributeBasedConditionConfig;
