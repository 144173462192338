import Clickable from "@heart/components/clickable/Clickable";
import classNames from "classnames";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import styles from "./Avatar.module.scss";

/** A clickable avatar representing a worker, child, or other human in
 * Binti.  Currently does not support profile pictures - "initials" is
 * the only variant.
 *
 * This uses `<Clickable>` under-the-hood, so check out those docs to see
 * what else you can provide.  Namely, all the props other than the ones in
 * the table below are passed to the underlying link or button.
 *
 * ### Cypress
 *  * `cy.clickAvatar({firstName: "Ava", lastName: "Vatar"})`
 *    * If this proves cumbersome to use in tests, we can add a `fullName` prop
 *      that aids in test targeting and auto-descriptions; just let the FE Guild know.
 *  * `cy.clickAvatar({description: "Visit Ava's profile"})` for overridden descriptions.
 */
const Avatar = forwardRef(
  (
    {
      firstName = " ",
      lastName = " ",
      onClick,
      href,
      description,
      size,
      variant,
      ...buttonOrLinkProps
    },
    ref
  ) => {
    /** we seem to be getting `null` passed through in some spots
     * rather than just `undefined`, which takes precedence over the default
     * value provided. to get around that we're actually defining our defaults
     * here instead
     */
    const first = firstName || " ";
    const last = lastName || " ";

    const desc = description || `${first} ${last}`;
    const initials = `${first[0]}${last[0]}`;
    const initialsElem = <span className={styles.initials}>{initials}</span>;
    const commonProps = {
      "aria-label": desc,
      title: desc,
      "data-heart-component": "Avatar",
      "data-first-name": first,
      "data-last-name": last,
      ref,
    };
    const commonClassnames = [styles.container, styles[`size-${size}`]];
    if (variant) commonClassnames.push(styles[variant]);

    if (onClick || href)
      return (
        <Clickable
          {...commonProps}
          anchorClassname={classNames(...commonClassnames, styles.avatarAnchor)}
          buttonClassname={classNames(...commonClassnames, styles.avatarButton)}
          href={href}
          onClick={onClick}
          {...buttonOrLinkProps}
        >
          {initialsElem}
        </Clickable>
      );
    return (
      <div {...commonProps} className={classNames(...commonClassnames)}>
        {initialsElem}
      </div>
    );
  }
);
Avatar.displayName = "Avatar";

Avatar.propTypes = {
  /** The person's first name */
  firstName: PropTypes.string,
  /** The person's last name */
  lastName: PropTypes.string,
  /** `description` indicates to screen readers what the purpose of this icon is
   * and puts useful hover text on it. By default it'll be `firstName lastName` */
  description: PropTypes.string,
  /** The width/height of the Avatar, expressed as one of our $sizing-*
   * tokens from `variables.scss`. */
  size: PropTypes.oneOf([100, 200, 300, 400, 500, 600, 700, 800, 900]),
  /** Function to call when avatar is clicked. */
  /** Location, if this icon is clickable and redirects the user */
  href: PropTypes.string,
  /** onClick handler, if this icon is clickable and executes a callback. */
  onClick: PropTypes.func,
  /** Which variant? */
  variant: PropTypes.oneOf(["light"]),
};

export default Avatar;
