/* eslint-disable no-shadow */
import { useQuery } from "@apollo/client";
import { Icons, InputDropdown, LiveRegion } from "@heart/components";
import { forwardRef } from "react";

import Agencies from "@graphql/queries/Agencies.graphql";

const AgencyDropdown = forwardRef(
  ({ value, includeSlug = false, ...inputProps }, ref) => {
    const { data: { agencies } = {}, loading } = useQuery(Agencies);

    const agencyValues = agencies?.map(({ id, name, slug }) => ({
      label: includeSlug ? `${name} (${slug})` : name,
      value: id,
    }));

    const content = loading ? (
      <Icons.Spinner />
    ) : (
      <InputDropdown
        {...inputProps}
        values={agencyValues}
        value={String(value)}
        ref={ref}
      />
    );

    return <LiveRegion>{content}</LiveRegion>;
  }
);

// This is otherwise a standard dropdown but the values are
// hydrated from the backend.
const { values, ...propTypes } = InputDropdown.propTypes;
AgencyDropdown.propTypes = propTypes;

AgencyDropdown.displayName = "AgencyDropdown";

export default AgencyDropdown;
