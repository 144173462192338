import { gql, useQuery } from "@apollo/client";
import {
  Breadcrumbs,
  ContentTabs,
  Layout,
  LoadingOverlay,
  Surface,
} from "@heart/components";
import { PropTypes } from "prop-types";
import {
  adminRootPath,
  agencyServicesPath,
  preventionAgencyServiceReferralsPath,
} from "routes";

import { translationWithRoot } from "@components/T";
import DashboardTable from "@components/prevention/agency_service_referrals/DashboardTable";

const { T, t } = translationWithRoot(
  "prevention.agency_service_referrals.dashboard"
);

/**
 * Exported for testing purposes only
 */
export const PreventionDashboardPageQuery = gql`
  query PreventionDashboardPageQuery {
    user {
      id
      bintiAdmin
      agencyId
    }
    preventionAgencyServiceReferralCounts {
      pending
      completed
      inProgress
      rejected
    }
  }
`;

/**
 * Creates a Dashboard page for the Agency Service Referrals
 */
const DashboardPage = ({ showCreateServiceReferralButton }) => {
  const { data, loading } = useQuery(PreventionDashboardPageQuery);

  const counts = data?.preventionAgencyServiceReferralCounts || {};

  const content = (
    <LoadingOverlay active={loading}>
      <Surface title={<T t="title" />} hideTitle>
        <ContentTabs
          tabs={[
            {
              title: t("tabs.pending"),
              slug: "pending",
              contents: (
                <DashboardTable
                  status={"pending"}
                  currentUserAgencyId={data?.user?.agencyId}
                  showCreateServiceReferralButton={
                    showCreateServiceReferralButton
                  }
                />
              ),
              count: counts.pending,
            },
            {
              title: t("tabs.in_progress"),
              slug: "in_progress",
              contents: (
                <DashboardTable
                  status={"in_progress"}
                  currentUserAgencyId={data?.user?.agencyId}
                  showCreateServiceReferralButton={
                    showCreateServiceReferralButton
                  }
                />
              ),
              count: counts.inProgress,
            },
            {
              title: t("tabs.completed"),
              slug: "completed",
              contents: (
                <DashboardTable
                  status={"completed"}
                  currentUserAgencyId={data?.user?.agencyId}
                  showCreateServiceReferralButton={
                    showCreateServiceReferralButton
                  }
                />
              ),
              count: counts.completed,
            },
            {
              title: t("tabs.rejected"),
              slug: "rejected",
              contents: (
                <DashboardTable
                  status={"rejected"}
                  currentUserAgencyId={data?.user?.agencyId}
                  showCreateServiceReferralButton={
                    showCreateServiceReferralButton
                  }
                />
              ),
              count: counts.rejected,
            },
          ]}
        />
      </Surface>
    </LoadingOverlay>
  );

  return (
    <Layout
      pageTitle={<T t="header" />}
      breadcrumbs={
        <Breadcrumbs
          pages={[
            {
              label: <T t="breadcrumbs.home" />,
              href: adminRootPath(),
            },
            {
              label: <T t="breadcrumbs.children_and_youth" />,
              href: agencyServicesPath(),
            },
            {
              label: <T t="breadcrumbs.title" />,
              href: preventionAgencyServiceReferralsPath(),
            },
          ]}
        />
      }
      main={{ content }}
    />
  );
};

DashboardPage.propTypes = {
  showCreateServiceReferralButton: PropTypes.bool,
};

export default DashboardPage;
