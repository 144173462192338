import { useQuery } from "@apollo/client";
import {
  Flex,
  InputFilterable,
  InputText,
  LoadingOverlay,
  Modal,
  MultiInputTemplate,
} from "@heart/components";
import { isNil } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { translationWithRoot } from "@components/T";

import AgencyHumanRelationships from "@graphql/queries/AgencyHumanRelationships.graphql";

import {
  mapConstantSetToValues,
  mapConstantToValue,
} from "@lib/constantsConversion";

import {
  PARENT,
  CHILD,
  SIBLING,
  PARTNER,
  EMOTIONAL_RELATIONSHIP_STATUSES,
  LINEAGE_TYPES,
} from "@root/constants";

const { t } = translationWithRoot(
  "relationships.table.edit_other_relationships_modal"
);

/** A modal for editing relationships that do not include a child for an AgencyHuman */
const EditOtherRelationshipsModal = ({
  keystoneAgencyHumanId,
  keystoneAgencyHumanFirstName,
  keystoneAgencyHumanFullName,
  setEditRelationshipsForAgencyHuman,
}) => {
  const { data: relationshipData, loading: relationshipsLoading } = useQuery(
    AgencyHumanRelationships,
    {
      variables: {
        agencyHumanId: keystoneAgencyHumanId,
        filterOutChildrenInCare: true,
      },
      skip: !keystoneAgencyHumanId,
    }
  );
  const [relationships, setRelationships] = useState();
  useEffect(() => {
    if (keystoneAgencyHumanId)
      setRelationships(relationshipData?.agencyHumanRelationships);
  }, [keystoneAgencyHumanId, relationshipData?.agencyHumanRelationships]);

  const VALID_ONE_DEGREE_RELATIONSHIPS = [PARENT, CHILD, SIBLING, PARTNER];

  return (
    <Modal
      title={t("title", { name: keystoneAgencyHumanFullName })}
      onCancel={() => setEditRelationshipsForAgencyHuman()}
      onSubmit={() => setEditRelationshipsForAgencyHuman()}
      hidden={isNil(keystoneAgencyHumanId)}
      fullScreen
      asForm
    >
      <LoadingOverlay active={relationshipsLoading}>
        <Flex column gap="300">
          <MultiInputTemplate
            hideTitle
            title={t("add_edit_relationships")}
            defaultValues={{}}
            onChange={setRelationships}
            startingValues={relationships}
            sectionInputs={({ getMultiInputProps }) => (
              <Flex align="center">
                {t("connection_is_the", { name: keystoneAgencyHumanFirstName })}
                <InputFilterable
                  {...getMultiInputProps({
                    id: "lineageType",
                    transformValueForFormState: value => value?.value,
                    transformValueForInput: value =>
                      mapConstantToValue({
                        constant: LINEAGE_TYPES,
                        translationKey: "relationships.lineage_type",
                        value,
                      }),
                  })}
                  label={t("lineage_type")}
                  values={mapConstantSetToValues({
                    constant: LINEAGE_TYPES,
                    translationKey: "relationships.lineage_type",
                  })}
                />
                <InputFilterable
                  {...getMultiInputProps({
                    id: "kinshipRelationship",
                    transformValueForFormState: value => value?.value,
                    transformValueForInput: value =>
                      mapConstantToValue({
                        constant: VALID_ONE_DEGREE_RELATIONSHIPS,
                        translationKey: "relationships.kinship_relationships",
                        value,
                      }),
                  })}
                  label={t("relationship_type")}
                  values={mapConstantSetToValues({
                    constant: VALID_ONE_DEGREE_RELATIONSHIPS,
                    translationKey: "relationships.kinship_relationships",
                  })}
                />
                {t("of")}
                <InputText label={t("name")} />
                {t("with_a")}
                <InputFilterable
                  isMulti
                  {...getMultiInputProps({
                    id: "emotionalRelationshipStatuses",
                    transformValueForFormState: value => value?.value,
                    transformValueForInput: statuses =>
                      statuses.map(value =>
                        mapConstantToValue({
                          constant: EMOTIONAL_RELATIONSHIP_STATUSES,
                          translationKey: "relationships.emotional_status",
                          value,
                        })
                      ),
                  })}
                  label={t("emotional_relationship_status")}
                  values={mapConstantSetToValues({
                    constant: EMOTIONAL_RELATIONSHIP_STATUSES,
                    translationKey: "relationships.emotional_status",
                  })}
                />
                {t("relationship")}
              </Flex>
            )}
            addSectionText={t("add_relationship")}
            removeSectionText={t("remove_relationship")}
          />
        </Flex>
      </LoadingOverlay>
    </Modal>
  );
};
EditOtherRelationshipsModal.propTypes = {
  keystoneAgencyHumanId: PropTypes.string,
  keystoneAgencyHumanFullName: PropTypes.string,
  keystoneAgencyHumanFirstName: PropTypes.string,
  setEditRelationshipsForAgencyHuman: PropTypes.func.isRequired,
};

export default EditOtherRelationshipsModal;
