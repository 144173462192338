import PropTypes from "prop-types";

/**
 * Just a plain React component for rendering flash message banners -
 * both the ones rendered by Rails and also the ones driven by
 * React.
 */
const BannerContainer = ({ type, message }) => (
  <div
    role="alert"
    className={`banner banner_${type}`}
    data-testid="banner_container"
  >
    {message}
  </div>
);

BannerContainer.propTypes = {
  /**
   * The content of the banner.  This can be a simple string but also
   * it can be more complex JSX.
   */
  message: PropTypes.node,
  type: PropTypes.oneOf(["error", "notice", "default"]),
};
export default BannerContainer;
