import { Button, Flex, FlexItem, Modal } from "@heart/components";
import InputCsrfToken from "@heart/components/inputs/InputCsrfToken";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { removeCoApplicantAdminApplicationPath } from "routes";

import { translationWithRoot } from "@components/T";

const { T } = translationWithRoot("admin.applications.change_role");

/**
 * Component to remove the co-applicant.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.mayRemoveCoApplicant - Flag indicating if the co-applicant can be removed.
 */
const RemoveCoApplicant = ({
  mayRemoveCoApplicant,
  csrfToken,
  applicationId,
}) => {
  // State atom to track if the form is submitting
  const [submitting, setSubmitting] = useState(false);

  // State atom to track if the confirmation modal is hidden
  const [confirmationHidden, setConfirmationHidden] = useState(true);

  const formRef = useRef(null);

  return (
    <Flex align="center" row>
      <If condition={mayRemoveCoApplicant}>
        <FlexItem>
          <form
            ref={formRef}
            onSubmit={() => {
              setSubmitting(true);
            }}
            action={removeCoApplicantAdminApplicationPath(applicationId)}
            method="POST"
          >
            <InputCsrfToken csrfToken={csrfToken} />
            <Button
              variant="secondary"
              submitting={submitting}
              onClick={e => {
                e.preventDefault();
                setConfirmationHidden(false);
              }}
            >
              <T t="remove_co_applicant" />
            </Button>
          </form>
        </FlexItem>

        <If condition={!confirmationHidden}>
          <Modal
            hidden={confirmationHidden}
            onCancel={() => setConfirmationHidden(true)}
            onSubmit={() => {
              setSubmitting(true);
              formRef.current.submit();
            }}
            submitting={submitting}
            title={<T t="remove_co_applicant" />}
          >
            <T t="confirm_remove_co_applicant" />
          </Modal>
        </If>
      </If>
    </Flex>
  );
};

RemoveCoApplicant.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  applicationId: PropTypes.number.isRequired,
  mayRemoveCoApplicant: PropTypes.bool.isRequired,
};

export default RemoveCoApplicant;
