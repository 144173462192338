// import { useMutation } from "@apollo/client";
import { Link, Modal } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { translationWithRoot } from "@components/T";

// This mutation will be implemented in an upcoming PR
// import IgnoreFormInstanceInRelatedRequirements from "@graphql/mutations/IgnoreFormInstanceInRelatedRequirements.graphql";
import BintiPropTypes from "@lib/BintiPropTypes";

const { T } = translationWithRoot(
  "application_requirements_shared.reset_form_link"
);

/**
 * Links to ignore an old form_instance from a related requirement on another template
 * includes a confirmation dialog
 */
// eslint-disable-next-line no-unused-vars
const ResetFormLink = ({ formInstance }) => {
  const [disabled, setDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  // This mutation will be implemented in an upcoming PR
  // const [ignoreFormInstanceInRelatedRequirements] = useMutation(
  //   IgnoreFormInstanceInRelatedRequirements
  // );

  const onConfirmClick = () => {
    // note that this never get set back to enabled - this button should be
    // disappeared by the parent when this works.
    setDisabled(true);

    // This mutation will be implemented in an upcoming PR
    // ignoreFormInstanceInRelatedRequirements({
    //  variables: { formInstanceId: formInstance.id },
    // }).then(() => {

    // this change updates state in a way that may affect requirement
    // fulfillments for either CM or applications. Ideally, it would
    // automatically fetch that appropriately updated fulfillment, but
    // this operation should be infrequently used and if it becomes
    // necessary, we can then make this data refresh more elegant.
    window.location.reload();
    // });
  };

  return (
    <Fragment>
      <Modal
        onCancel={() => {
          setModalOpen(false);
        }}
        onSubmit={onConfirmClick}
        hidden={!modalOpen}
        title={I18n.t("javascript.components.common.are_you_sure")}
        data-testid="reset-signatures-modal"
      >
        <T t="confirm_reset_to_most_recent_version" />
      </Modal>
      <Link
        onClick={() => {
          setModalOpen(true);
        }}
        disabled={disabled}
      >
        <T t="reset_to_most_recent_version" />
      </Link>
    </Fragment>
  );
};

ResetFormLink.propTypes = {
  formInstance: PropTypes.shape({ id: BintiPropTypes.ID }).isRequired,
};

export default ResetFormLink;
