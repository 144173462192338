import { InputDropdown, InputText } from "@heart/components";
import { startCase } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import T from "@components/T";

/**
 * AA form that kicks off an on-demand service run.  Each service has a distinct
 * set of arguments - select a service and this will render a text input
 * for each argument.
 *  Agency Tools > On-Demand Service Runs > New
 */
const NewOnDemandServiceRun = ({ serviceToArgsMap }) => {
  const allServiceNames = Object.keys(serviceToArgsMap).map(service => [
    // splitting on `::` here because our new services pattern will have this service
    // name be e.g., Services::Importers::ImportSiblings, and we just want ImportSiblings
    serviceNameHelper(service),
    service,
  ]);
  const [selectedService, setSelectedService] = useState(null);
  const allSelectedServiceArgs = serviceToArgsMap[selectedService] || [];

  return (
    <div className="contains-inputs">
      <InputDropdown
        required
        label="Service"
        name="on_demand_service_run[service_name]"
        values={allServiceNames}
        onChange={setSelectedService}
      />
      {selectedService &&
        allSelectedServiceArgs.map(({ name, required }) => (
          <InputText
            key={name}
            required={required}
            label={
              <T t={`new_on_demand_service_run.${selectedService}.${name}`} />
            }
            name={`on_demand_service_run[service_args][${name}]`}
          />
        ))}
    </div>
  );
};

const serviceNameHelper = serviceName => {
  const modules = serviceName.split("::");
  const lastModule = modules.pop();
  // we're refactoring our Import services to all use a base Import class, so we need to
  // handle the case where the service name is either Services::Importers::ImportSiblings
  // or Services::Importers::OtherAdults::Import
  if (lastModule === "Import") return `Import ${startCase(modules.pop())}`;

  return startCase(lastModule);
};

NewOnDemandServiceRun.propTypes = {
  /** serviceToArgsMap is an object mapping each service name to its arguments */
  serviceToArgsMap: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
      })
    )
  ),
};

export default NewOnDemandServiceRun;
