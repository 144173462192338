import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Breadcrumbs,
  Wizard,
  WizardPage,
  WizardFieldset,
} from "@heart/components";
import { Spinner } from "@heart/components/icon/Icon";
import I18n from "i18n-js";
import { curry, isEqual, isNil } from "lodash";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { translationWithRoot } from "@components/T";
import ErrorBanner from "@components/shared/banner/ErrorBanner";

import CreateOrUpdateArrestConvictionRecords from "@graphql/mutations/CreateOrUpdateArrestConvictionRecords.graphql";
import CreateOrUpdateExemptionInterviews from "@graphql/mutations/CreateOrUpdateExemptionInterviews.graphql";
import CreateOrUpdateExemptionRecommendations from "@graphql/mutations/CreateOrUpdateExemptionRecommendations.graphql";
import CreateOrUpdateExemptionRecordRequests from "@graphql/mutations/CreateOrUpdateExemptionRecordRequests.graphql";
import UpdateBackgroundCheckExemption from "@graphql/mutations/UpdateBackgroundCheckExemption";

import ArrestAndConvictionsLog from "./ArrestAndConvictionsLog";
import ExemptionDecision from "./ExemptionDecision";
import ExemptionDetails from "./ExemptionDetails";
import ExemptionInterviewLog from "./ExemptionInterviewLog";
import ExemptionRecommendations from "./ExemptionRecommendations";
import ExemptionRecordRequestLog from "./ExemptionRecordRequestLog";
import PersonalDetails from "./PersonalDetails";

const { t, T } = translationWithRoot("background_check_exemptions");

const backgroundCheckExemptionInfo = gql`
  query BackgroundCheckExemption($backgroundCheckExemptionId: ID!) {
    backgroundCheckExemption(
      backgroundCheckExemptionId: $backgroundCheckExemptionId
    ) {
      notificationDate
      notificationMethods
      responseDate
      startDate
      exemptionType
      isRequiredForChild
      isRelativeOfChild
      isChildTribalMember
      outcome
      conditions
      isLimitedToSpecificChild
      decisionDate
      approvedBy
      notes
      caseworker {
        id
        label
      }
      arrestConvictionRecords {
        id
        source
        incidentDate
        isOnProbation
        probationEndDate
        criminalCode {
          label # label contains code + description
          id
          severity
          codeType
          state
        }
      }
      exemptionInterviews {
        id
        interviewee
        interviewDate
        interviewMethod
        tribePresence
        notes
      }
      exemptionRecommendations {
        id
        recommendedBy
        workerRole
        conditions
        recommendationDate
        isLimitedToSpecificChild
        notes
        recommendation
      }
      adultProfile {
        id
        ethnicities
        races
        annualIncomeRange
      }
      application {
        agency {
          stateAbbr
        }
      }
      exemptionRecordRequests {
        id
        source
        requestDate
        receivedDate
        requestNotes
      }
    }
  }
`;

const BackgroundCheckExemption = ({
  backgroundCheckExemptionId,
  applicationId,
  agencyId,
  exemptionTypeData,
  adultProfileName,
  notificationMethodData,
  interviewMethodData,
  annualIncomeOptions,
  originPath,
  agencyBackgroundChecks,
  usStateOptions,
  codeSeverityList,
  codeTypes,
  outcomes,
  documentsPath,
  workerRoles,
  useRacesInput,
}) => {
  const [formState, setFormState] = useState({});
  const [createModalHidden, setCreateModalHidden] = useState(true);

  const agencyState = formState?.application?.agency?.stateAbbr;

  const { data, loading, error, refetch } = useQuery(
    backgroundCheckExemptionInfo,
    {
      variables: {
        backgroundCheckExemptionId: backgroundCheckExemptionId,
      },
    }
  );

  const setFormAttribute = curry((attribute, value) => {
    if (!isNil(value) && !isEqual(value, formState[attribute])) {
      setFormState({ ...formState, [attribute]: value });
    }
  });

  const arrestOrConvictionData =
    data?.backgroundCheckExemption.arrestConvictionRecords.map(record => ({
      ...record,
      source: { label: record.source, value: record.source },
    }));

  useEffect(() => {
    if (data?.backgroundCheckExemption) {
      setFormState(prevState => ({
        ...prevState,
        ...data.backgroundCheckExemption,
        arrestConvictionRecords: arrestOrConvictionData,
      }));
    }
  }, [data?.backgroundCheckExemption]);

  const exemptionRecordRequestData =
    data?.backgroundCheckExemption.exemptionRecordRequests;

  const interviewData = data?.backgroundCheckExemption.exemptionInterviews;

  const recommendationData =
    data?.backgroundCheckExemption.exemptionRecommendations;

  /** Mutation called on save at the end of page 1 and 6 */
  const [updateExemptionDetails] = useMutation(UpdateBackgroundCheckExemption, {
    onCompleted: refetch,
  });

  /** Mutation called on save at the end of Arrest/Conviction Records page 2 */
  const [updateArrestConvictionDetails] = useMutation(
    CreateOrUpdateArrestConvictionRecords,
    {
      onCompleted: refetch,
    }
  );

  /** Mutation called on save at the end of Exemption Record Requests Records page 3 */
  const [updateExemptionRecordRequestDetails] = useMutation(
    CreateOrUpdateExemptionRecordRequests,
    {
      onCompleted: refetch,
    }
  );

  /** Mutation called on save at the end of Exemption Interviews page 4 */
  const [updateExemptionInterviews] = useMutation(
    CreateOrUpdateExemptionInterviews,
    {
      onCompleted: refetch,
    }
  );

  /** Mutation called on save at the end of Exemption Recommendations page 5 */
  const [updateExemptionRecommendations] = useMutation(
    CreateOrUpdateExemptionRecommendations,
    {
      onCompleted: refetch,
    }
  );

  if (loading) {
    return <Spinner />;
  }
  if (error) return <ErrorBanner message={error.message} />;
  return (
    <Wizard
      breadcrumbs={
        <Breadcrumbs
          pages={[
            {
              label: I18n.t("application.applicant_data"),
              href: `/admin/applications/${applicationId}`,
            },
            {
              label: I18n.t("admin.header_links.background_checks"),
              href: `/admin/applications/${applicationId}/background_check_records`,
            },
            {
              label: t("background_check_exemption"),
              href: "#",
            },
          ].filter(Boolean)}
        />
      }
      title={`${t("background_check_exemption_for")} ${adultProfileName}`}
      notice={{
        title: t("new_exemption_feature"),
        body: (
          <T
            t="help_notice_html"
            help_desk_path={
              "https://binti.zendesk.com/hc/en-us/articles/25533456080275"
            }
          />
        ),
      }}
      pages={[
        <WizardPage
          key="step_one"
          pageTitle={t("page_label.information")}
          confirmBeforeCancel
          actionsProps={{
            primaryText: t("save_and_continue"),
            primarySubmittingText: "Saving",
            cancelHref: originPath,
            cancelText: t("cancel_and_exit"),
            cancelAction: () => {},
            // save and go forward a step
            primaryAction: () => {
              updateExemptionDetails({
                variables: {
                  ...formState,
                  backgroundCheckExemptionId,
                  // pass these nested attributes (ethnicities and annualIncomeRange) at a
                  // parent level with the other attributes in the formState so this
                  // mutation can do things if these attributes are passed in.
                  ethnicities: formState.adultProfile.ethnicities,
                  races: formState.adultProfile.races,
                  annualIncomeRange: formState.adultProfile.annualIncomeRange,
                },
              });
            },
          }}
          progress={1}
        >
          <WizardFieldset sectionTitle={t("page_label.exemption_details")}>
            {" "}
            <ExemptionDetails
              exemptionTypeData={exemptionTypeData}
              notificationMethodData={notificationMethodData}
              formState={formState}
              setFormAttribute={setFormAttribute}
            />
          </WizardFieldset>
          {agencyState === "CA" && (
            <WizardFieldset
              sectionTitle={t("page_label.personal_details")}
              collapsible
            >
              {" "}
              <PersonalDetails
                useRacesInput={useRacesInput}
                formState={formState}
                setFormAttribute={setFormAttribute}
                annualIncomeOptions={annualIncomeOptions}
              ></PersonalDetails>
            </WizardFieldset>
          )}
        </WizardPage>,

        <WizardPage
          key="step_two"
          pageTitle={t("page_label.arrests_and_convictions")}
          confirmBeforeCancel
          actionsProps={{
            primaryText: t("save_and_continue"),
            primarySubmittingText: "Saving",
            cancelText: t("cancel_and_go_back"),
            cancelAction: () => {},
            // save and go back a step
            secondaryAction: () =>
              updateArrestConvictionDetails({
                variables: {
                  ...formState,
                  backgroundCheckExemptionId,
                },
              }),
            // save and go forward a step
            primaryAction: () =>
              updateArrestConvictionDetails({
                variables: {
                  ...formState,
                  arrestConvictionRecords:
                    formState.arrestConvictionRecords.map(record => ({
                      ...record,
                      source: record.source.value,
                    })),
                  backgroundCheckExemptionId,
                },
              }),
            primaryDisabled: !createModalHidden,
          }}
          progress={1}
        >
          <WizardFieldset
            sectionTitle={t("page_label.arrests_and_convictions_log")}
            collapsible
          >
            <T t="page_label.conviction_log_text" />
            <ArrestAndConvictionsLog
              formState={formState}
              setFormAttribute={setFormAttribute}
              arrestConvictionRecords={arrestOrConvictionData}
              agencyId={agencyId}
              createModalHidden={createModalHidden}
              setCreateModalHidden={setCreateModalHidden}
              usStateOptions={usStateOptions}
              agencyBackgroundChecks={agencyBackgroundChecks}
              codeSeverityList={codeSeverityList}
              codeTypes={codeTypes}
            ></ArrestAndConvictionsLog>
          </WizardFieldset>
        </WizardPage>,

        <WizardPage
          key="step_three"
          pageTitle={t("page_label.record_requests")}
          confirmBeforeCancel
          actionsProps={{
            primaryText: t("save_and_continue"),
            primarySubmittingText: "Saving",
            cancelText: t("cancel_and_go_back"),
            cancelAction: () => {},
            // save and go back a step
            secondaryAction: () =>
              updateExemptionRecordRequestDetails({
                variables: {
                  ...formState,
                  backgroundCheckExemptionId,
                },
              }),
            // save and go forward a step
            primaryAction: () =>
              updateExemptionRecordRequestDetails({
                variables: {
                  ...formState,
                  backgroundCheckExemptionId,
                },
              }),
          }}
          progress={1}
        >
          <WizardFieldset
            sectionTitle={t("page_label.record_requests_logs")}
            collapsible
          >
            <T
              t="page_label.record_request_text_html"
              documents_path={documentsPath}
            />
            <ExemptionRecordRequestLog
              setFormAttribute={setFormAttribute}
              exemptionRecordRequests={exemptionRecordRequestData}
            ></ExemptionRecordRequestLog>
          </WizardFieldset>
        </WizardPage>,

        <WizardPage
          key="step_four"
          pageTitle={t("page_label.interviews")}
          confirmBeforeCancel
          actionsProps={{
            primaryText: t("save_and_continue"),
            primarySubmittingText: "Saving",
            cancelText: t("cancel_and_go_back"),
            cancelAction: () => {},
            // save and go back a step
            secondaryAction: () =>
              updateExemptionInterviews({
                variables: {
                  ...formState,
                  backgroundCheckExemptionId,
                },
              }),
            // save and go forward a step
            primaryAction: () =>
              updateExemptionInterviews({
                variables: {
                  ...formState,
                  backgroundCheckExemptionId,
                },
              }),
          }}
          progress={1}
        >
          <WizardFieldset
            sectionTitle={t("page_label.interview_log")}
            collapsible
          >
            <T
              t="page_label.interview_text_html"
              documents_path={documentsPath}
            />
            <ExemptionInterviewLog
              setFormAttribute={setFormAttribute}
              interviewMethodData={interviewMethodData}
              exemptionInterviews={interviewData}
            />
          </WizardFieldset>
        </WizardPage>,

        <WizardPage
          key="step_five"
          pageTitle={t("page_label.recommendations")}
          confirmBeforeCancel
          actionsProps={{
            primaryText: t("save_and_continue"),
            primarySubmittingText: "Saving",
            cancelText: t("cancel_and_go_back"),
            cancelAction: () => {},
            // save and go back a step
            secondaryAction: () =>
              updateExemptionRecommendations({
                variables: {
                  ...formState,
                  backgroundCheckExemptionId,
                },
              }),
            // save and go forward a step
            primaryAction: () =>
              updateExemptionRecommendations({
                variables: {
                  ...formState,
                  backgroundCheckExemptionId,
                },
              }),
          }}
          progress={1}
        >
          <WizardFieldset
            sectionTitle={t("page_label.exemption_recommendations")}
            collapsible
          >
            <T
              t="page_label.recommendation_text_html"
              documents_path={documentsPath}
            />

            <ExemptionRecommendations
              setFormAttribute={setFormAttribute}
              exemptionRecommendations={recommendationData}
              recommendedOutcomes={outcomes}
              workerRoles={workerRoles}
            />
          </WizardFieldset>
        </WizardPage>,

        <WizardPage
          key="step_six"
          pageTitle={t("page_label.decision")}
          confirmBeforeCancel
          actionsProps={{
            primaryText: t("finalize_and_exit"),
            primarySubmittingText: "Saving",
            cancelText: t("cancel_and_go_back"),
            cancelAction: () => {},
            // save and go back a step
            secondaryAction: () =>
              updateExemptionDetails({
                variables: {
                  ...formState,
                  backgroundCheckExemptionId,
                },
              }),
            // save current progress (last step does not go forward)
            primaryAction: () =>
              updateExemptionDetails({
                variables: {
                  ...formState,
                  backgroundCheckExemptionId,
                },
              }).then(() => {
                window.location = `${originPath}?exemptionSuccess=true`;
              }),
          }}
          progress={1}
        >
          <WizardFieldset
            sectionTitle={t("page_label.exemption_decision")}
            collapsible
          >
            <T t="page_label.decision_text" />
            <ExemptionDecision
              outcomes={outcomes}
              formState={formState}
              setFormAttribute={setFormAttribute}
            />
          </WizardFieldset>
        </WizardPage>,
      ]}
    ></Wizard>
  );
};

BackgroundCheckExemption.propTypes = {
  backgroundCheckExemptionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  applicationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  agencyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  adultProfileName: PropTypes.string.isRequired,
  exemptionTypeData: PropTypes.array.isRequired,
  notificationMethodData: PropTypes.array.isRequired,
  interviewMethodData: PropTypes.array.isRequired,
  annualIncomeOptions: PropTypes.array.isRequired,
  originPath: PropTypes.string.isRequired,
  agencyBackgroundChecks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  usStateOptions: PropTypes.object.isRequired,
  codeSeverityList: PropTypes.array.isRequired,
  codeTypes: PropTypes.array.isRequired,
  outcomes: PropTypes.array.isRequired,
  documentsPath: PropTypes.string.isRequired,
  workerRoles: PropTypes.array.isRequired,

  /** Part of ethnicites/races migration. Remove in ENG-21169 */
  useRacesInput: PropTypes.bool.isRequired,
};

export default BackgroundCheckExemption;
