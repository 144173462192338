import {
  InputCheckbox,
  InputCheckboxGroup,
  InputFilterable,
  InputFilterableGraphQL,
  InputText,
  InputTextarea,
} from "@heart/components";
import {
  fromLegacyCheckboxGroupValue,
  toLegacyCheckboxGroupValue,
} from "@heart/components/inputs/InputCheckboxGroup";
import { curry, isEqual, isNil, pull, snakeCase } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";
import { relationshipErrors } from "@components/agency_humans/AgencyHumanFormComputations";

import KinshipRelationshipTypes from "@graphql/queries/KinshipRelationshipTypes.graphql";

import {
  mapConstantSetToValues,
  mapConstantToValue,
  mapNestedEnumConstantToValues,
} from "@lib/constantsConversion";
import useFeatureFlag from "@lib/useFeatureFlag";

import {
  RELATIVE,
  FICTIVE_KIN,
  UNKNOWN,
  PARTNER,
  OTHER_PARTNER_STATUS,
  OTHER,
  LEVELS_OF_SUPPORT,
  LEVELS_OF_SUPPPORT_TOP_LEVEL_KEYS,
  RELATIONSHIP_CATEGORIES,
  PARTNER_STATUSES,
  RECOMMENDED,
  CURRENT_PLACEMENT,
  PLACEMENT_PROVIDER_ELIGIBILITIES,
  RECOMMENDED_PLACEMENT_PROVIDER_ELIGIBILITIES_SUB_OPTIONS,
  WORKER,
  NOT_VERIFIED,
} from "@root/constants";

import BaseRelationshipToChildSection from "./BaseRelationshipToChildSection";
import { resetRelationshipState } from "./transitionRelationshipState";

const { t } = translationWithRoot("agency_human.form.relationship_to_child");

const transformQueryData = ({ kinshipRelationshipTypes = {} }) =>
  pull(Object.keys(kinshipRelationshipTypes), "__typename").reduce(
    (values, key) => [
      ...values,
      {
        label: I18n.t(
          `activerecord.enums.relationships.degrees_of_relation.${snakeCase(
            key
          )}`
        ),
        options: mapConstantSetToValues({
          constant: kinshipRelationshipTypes[key],
          translationKey: "relationships.kinship_relationships",
        }),
      },
    ],
    []
  );

/** Helper to check if a value is a child of a given parent category */
const isChildOf = (parent, val) =>
  typeof val === "string" && val.startsWith(`${parent}.`);

/** Set of form fields representing the relationship between a connection and a child */
const RelationshipToChildSection = ({ relationship, setFormAttribute }) => {
  const { flag: ffPlacementOptions } = useFeatureFlag(
    "ff_placement_options_refresh_02_2025"
  );
  if (isNil(relationship)) return null;

  const placementProviderEligibilitiesValues =
    PLACEMENT_PROVIDER_ELIGIBILITIES.map(value => ({
      ...mapConstantToValue({
        translationKey: "relationships.placement_provider_eligibilities",
        value,
        translationPath: "activerecord.enums",
      }),
      options:
        ffPlacementOptions && value === "recommended"
          ? RECOMMENDED_PLACEMENT_PROVIDER_ELIGIBILITIES_SUB_OPTIONS.map(
              subValue =>
                mapConstantToValue({
                  translationKey:
                    "relationships.recommended_placement_provider_eligibilities_sub_options",
                  value: subValue,
                  translationPath: "activerecord.enums",
                })
            )
          : undefined,
      nestedAsRadio: ffPlacementOptions && value === "recommended",
      nestedAsText:
        ffPlacementOptions && ![CURRENT_PLACEMENT, RECOMMENDED].includes(value),
    }));

  const {
    relationshipCategory,
    kinshipRelationship,
    kinshipRelationshipOtherDescription = "",
    partnerStatus,
    levelsOfSupport = [],
    levelsOfSupportOtherDescription = "",
    fictiveKinDescription = "",
    additionalRelationshipDetails = "",
    placementProviderEligibilitiesJson = {},
    placementProviderEligibilitiesDetails,
    verificationSource,
  } = relationship;

  const isFictiveKin = relationshipCategory?.value === FICTIVE_KIN;
  const isRelative = relationshipCategory?.value === RELATIVE;
  const relationshipIsUnknown = relationshipCategory?.value === UNKNOWN;

  const resetRelationship = category =>
    setFormAttribute(resetRelationshipState({ category, relationship }));

  const setRelationshipAttribute = curry((attribute, value) => {
    if (!isNil(value) && !isEqual(value, relationship[attribute])) {
      setFormAttribute({ ...relationship, [attribute]: value });
    }
  });

  return (
    <Fragment>
      <InputFilterable
        label={t("relationship_category")}
        required
        onChange={resetRelationship}
        defaultValue={mapConstantToValue({
          value: UNKNOWN,
          translationKey: "relationships.category",
        })}
        value={relationshipCategory}
        values={mapConstantSetToValues({
          constant: RELATIONSHIP_CATEGORIES,
          translationKey: "relationships.category",
        })}
      />
      <If
        condition={[RELATIVE, FICTIVE_KIN].includes(
          relationshipCategory?.value
        )}
      >
        <If condition={isFictiveKin}>
          <InputText
            label={t("relationship_type")}
            required
            onChange={setRelationshipAttribute("fictiveKinDescription")}
            value={fictiveKinDescription}
          />
        </If>
        <If condition={isRelative}>
          <InputFilterableGraphQL
            label={t("relationship_type")}
            required
            onChange={value =>
              setFormAttribute({
                ...relationship,
                kinshipRelationship: value,
                partnerStatus: undefined,
                kinshipRelationshipOtherDescription: undefined,
              })
            }
            value={kinshipRelationship}
            query={KinshipRelationshipTypes}
            transformQueryData={transformQueryData}
          />
          <If condition={kinshipRelationship?.value === PARTNER}>
            <InputFilterable
              label={t("partner_status")}
              required
              onChange={setRelationshipAttribute("partnerStatus")}
              value={partnerStatus}
              values={mapConstantSetToValues({
                constant: PARTNER_STATUSES,
                translationKey: "relationships.partner_status",
              })}
            />
          </If>
          <If
            condition={
              kinshipRelationship?.value === OTHER ||
              partnerStatus?.value === OTHER_PARTNER_STATUS
            }
          >
            <InputText
              label={t("please_specify")}
              required
              onChange={setRelationshipAttribute(
                "kinshipRelationshipOtherDescription"
              )}
              value={kinshipRelationshipOtherDescription}
            />
          </If>
        </If>
        <BaseRelationshipToChildSection
          relationship={relationship}
          setRelationshipAttribute={setRelationshipAttribute}
        />
        <InputCheckboxGroup
          label={t("placement_option")}
          error={relationshipErrors.getPlacementEligibilitiesError({
            ffPlacementOptions,
            placementProviderEligibilitiesJson,
          })}
          onChange={setRelationshipAttribute(
            "placementProviderEligibilitiesJson"
          )}
          value={placementProviderEligibilitiesJson}
          values={placementProviderEligibilitiesValues}
        />
        <InputTextarea
          rows={2}
          label={t("placement_option_details")}
          onChange={setRelationshipAttribute(
            "placementProviderEligibilitiesDetails"
          )}
          value={placementProviderEligibilitiesDetails}
        />
        <InputCheckboxGroup
          label={t("level_of_support")}
          error={relationshipErrors.getLevelsOfSupportError({
            levelsOfSupport,
          })}
          onChange={los => {
            const legacyVal = toLegacyCheckboxGroupValue(los);

            // 1. Remove parent categories from the array using the extracted constant
            const newLevels = legacyVal.filter(
              val => !LEVELS_OF_SUPPPORT_TOP_LEVEL_KEYS.includes(val)
            );

            // 2. Iterate over parent categories to add them back if any children exist
            LEVELS_OF_SUPPPORT_TOP_LEVEL_KEYS.forEach(parent => {
              if (newLevels.some(val => isChildOf(parent, val))) {
                newLevels.push(parent);
              }
            });

            setFormAttribute({
              ...relationship,
              levelsOfSupport: newLevels,
              levelsOfSupportOtherDescription: newLevels.includes(OTHER)
                ? levelsOfSupportOtherDescription
                : "",
            });
          }}
          value={fromLegacyCheckboxGroupValue(levelsOfSupport)}
          values={mapNestedEnumConstantToValues({
            constant: LEVELS_OF_SUPPORT,
          })}
        />
        <If condition={levelsOfSupport.includes(OTHER)}>
          <InputText
            label={t("please_specify")}
            required
            value={levelsOfSupportOtherDescription}
            onChange={setRelationshipAttribute(
              "levelsOfSupportOtherDescription"
            )}
          />
        </If>
      </If>
      <If condition={!relationshipIsUnknown}>
        <InputCheckbox
          label={t("worker_confirmed_relationship")}
          onChange={checked =>
            setRelationshipAttribute("verificationSource")(
              checked ? WORKER : NOT_VERIFIED
            )
          }
          value={verificationSource === WORKER}
        />
      </If>
      <InputTextarea
        label={t("additional_relationship_details")}
        onChange={setRelationshipAttribute("additionalRelationshipDetails")}
        value={additionalRelationshipDetails}
      />
    </Fragment>
  );
};

RelationshipToChildSection.propTypes = {
  relationship: PropTypes.shape({
    relationshipCategory: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    parentalLine: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    lineageType: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    kinshipRelationship: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    kinshipRelationshipOtherDescription: PropTypes.string,
    partnerStatus: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    emotionalRelationshipStatuses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    levelsOfSupport: PropTypes.arrayOf(PropTypes.string),
    levelsOfSupportOtherDescription: PropTypes.string,
    placementProviderEligibilitiesJson: PropTypes.shape({
      checked: PropTypes.bool,
    }),
    placementProviderEligibilitiesDetails: PropTypes.string,
    fictiveKinDescription: PropTypes.string,
    additionalRelationshipDetails: PropTypes.string,
    verificationSource: PropTypes.string,
  }),
  setFormAttribute: PropTypes.func.isRequired,
};

export default RelationshipToChildSection;
