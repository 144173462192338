import { InputFilterable, InputFilterableAsync } from "@heart/components";
import { Component } from "react";

import preventDefault from "@lib/preventDefault";

import Spinner from "../Spinner";
import styles from "./MergePrimaryApplicants.module.scss";

/** Form for selecting which applicant to keep and which applicant to remove
 *  during a merge.
 *    Agency Tools > Merge > Merge Children
 */
class MergePrimaryApplicants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      superior: {
        selectedValue: {},
        workers: [],
        cboWorkers: [],
      },
      inferior: {
        selectedValue: {},
        workers: [],
        cboWorkers: [],
      },
      merged: {},
      doingMerge: false,
      result: false,
      errorMessage: false,
    };
  }

  onSuperiorPrimaryApplicantChange = application => {
    if (application === null) {
      this.setState({
        superior: {
          selectedValue: {},
          workers: [],
          cboWorkers: [],
          cwsCmsHomeId: null,
        },
        merged: {},
      });
      return;
    }

    $.ajax({
      url: `/admin/applications/${application.value}/merge_tool_json_details`,
      dataType: "json",
      contentType: "application/json",
      success: result => {
        this.setState({
          superior: {
            status: result.status,
            workers: result.workers,
            cboWorkers: result.cbo_workers,
            cwsCmsHomeId: result.cws_cms_home_id,
            goodCausesLink: result.good_causes_link,
            familyId: result.family_id,
            selectedValue: application,
          },
          merged: {},
        });
      },
    });
  };

  onInferiorPrimaryApplicantChange = application => {
    if (application === null) {
      this.setState({
        inferior: {
          selectedValue: {},
          workers: [],
          cboWorkers: [],
          cwsCmsHomeId: null,
        },
        merged: {},
      });
      return;
    }

    $.ajax({
      url: `/admin/applications/${application.value}/merge_tool_json_details`,
      dataType: "json",
      contentType: "application/json",
      success: result => {
        this.setState({
          inferior: {
            status: result.status,
            workers: result.workers,
            cboWorkers: result.cbo_workers,
            cwsCmsHomeId: result.cws_cms_home_id,
            goodCausesLink: result.good_causes_link,
            familyId: result.family_id,
            selectedValue: application,
          },
          merged: {},
        });
      },
    });
  };

  loadOptions = (prefix, callback) => {
    if (prefix.length < 3) {
      callback([]);
      return;
    }

    $.ajax({
      url: "/admin/applications/merge_tool_search_primary_applicant_autocomplete",
      dataType: "json",
      contentType: "application/json",
      data: { term: prefix },
      success: results => {
        callback(results.results);
      },
    });
  };

  statusChanged = selectedStatus => {
    this.setState({
      merged: {
        ...this.state.merged,
        status: selectedStatus,
      },
    });
  };

  workersChanged = selectedWorkers => {
    this.setState({
      merged: {
        ...this.state.merged,
        workers: selectedWorkers,
      },
    });
  };

  cboWorkerChanged = selectedCboWorker => {
    this.setState({
      merged: {
        ...this.state.merged,
        cboWorker: selectedCboWorker,
      },
    });
  };

  cwsCmsHomeIdChanged = selectedCwsCmsHomeId => {
    this.setState({
      merged: {
        ...this.state.merged,
        cwsCmsHomeId: selectedCwsCmsHomeId,
      },
    });
  };

  doMerge = preventDefault(() => {
    this.setState({ doingMerge: true, result: false, errorMessage: false });
    $.ajax({
      method: "post",
      url: "/admin/applications/merge_primary_applicants",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        superior_application_id: this.state.superior.selectedValue.value,
        inferior_application_id: this.state.inferior.selectedValue.value,
        merged: {
          ...this.state.merged,
          familyId: this.state.merged?.familyId?.value,
        },
      }),
      success: result => {
        this.setState({ result, doingMerge: false });
      },
      error: jqXHR => {
        let errorMessage;
        if ([400, 422].includes(jqXHR.status)) {
          errorMessage = jqXHR.responseJSON.error_message;
        } else {
          errorMessage =
            "An error has occurred. If you have the time, it would be " +
            "totally awesome of you if you could find the Honeybadger and " +
            "leave a comment there; and perhaps even create a Jira task :)";
        }
        this.setState({ errorMessage, doingMerge: false });
      },
    });
  });

  render() {
    const { superior, inferior, merged, doingMerge, result, errorMessage } =
      this.state;
    return (
      <div>
        <form onSubmit={this.doMerge} className={styles.form}>
          <table className={styles.mergePrimaryApplicants}>
            <tbody>
              <tr>
                <th>Primary Applicant</th>
                <td>
                  <InputFilterableAsync
                    label="Applicant to Keep"
                    cacheOptions={true}
                    loadOptions={this.loadOptions}
                    defaultOptions
                    onChange={this.onSuperiorPrimaryApplicantChange}
                    value={superior.selectedValue}
                    id="superior-autocomplete-input"
                  />
                </td>
                <td>
                  <InputFilterableAsync
                    label="Applicant to Mark As Duplicate"
                    cacheOptions={true}
                    loadOptions={this.loadOptions}
                    defaultOptions
                    onChange={this.onInferiorPrimaryApplicantChange}
                    value={inferior.selectedValue}
                  />
                </td>
                <td>{superior.selectedValue.label}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{superior.status}</td>
                <td>{inferior.status}</td>
                <td>
                  <InputFilterable
                    label="Merged Applicant Status"
                    hideLabel
                    name="merged-status"
                    clearable={false}
                    onChange={this.statusChanged}
                    value={merged.status}
                    options={[
                      { value: superior.status, label: superior.status },
                      { value: inferior.status, label: inferior.status },
                    ]}
                  />
                </td>
              </tr>
              <tr>
                <th>Workers</th>
                <td>
                  {superior.workers.map(worker => worker.name).join(", ")}
                </td>
                <td>
                  {inferior.workers.map(worker => worker.name).join(", ")}
                </td>
                <td>
                  <InputFilterable
                    label="Merged Applicant Workers"
                    hideLabel
                    isMulti
                    name="merged-workers"
                    clearable={true}
                    onChange={this.workersChanged}
                    value={merged.workers}
                    options={superior.workers
                      .concat(inferior.workers)
                      .map(worker => ({
                        value: worker.id,
                        label: worker.name,
                      }))}
                  />
                </td>
              </tr>
              <tr>
                <th>CBO Workers</th>
                <td>
                  {superior.cboWorkers
                    .map(cboWorker => cboWorker.name)
                    .join(", ")}
                </td>
                <td>
                  {inferior.cboWorkers
                    .map(cboWorker => cboWorker.name)
                    .join(", ")}
                </td>
                <td>
                  <InputFilterable
                    label="Merged Applicant CBO Workers"
                    hideLabel
                    name="merged-cbo-worker"
                    clearable={true}
                    onChange={this.cboWorkerChanged}
                    value={merged.cboWorker}
                    options={superior.cboWorkers
                      .concat(inferior.cboWorkers)
                      .map(cboWorker => ({
                        value: cboWorker.id,
                        label: cboWorker.name,
                      }))}
                  />
                </td>
              </tr>
              <tr>
                <th>CWS CMS Home ID</th>
                <td>{superior.cwsCmsHomeId}</td>
                <td>{inferior.cwsCmsHomeId}</td>
                <td>
                  <InputFilterable
                    hideLabel
                    label="CWS CMS Home ID"
                    name="merged-cws-cms-home-id"
                    clearable={true}
                    onChange={this.cwsCmsHomeIdChanged}
                    value={merged.cwsCmsHomeId}
                    options={[
                      {
                        value: superior.cwsCmsHomeId,
                        label: superior.cwsCmsHomeId,
                      },
                      {
                        value: inferior.cwsCmsHomeId,
                        label: inferior.cwsCmsHomeId,
                      },
                    ]}
                  />
                </td>
              </tr>
              <tr>
                <th>Good Causes</th>
                <td>
                  {superior.goodCausesLink && (
                    <a
                      href={superior.goodCausesLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Good Causes
                    </a>
                  )}
                </td>
                <td>
                  {inferior.goodCausesLink && (
                    <a
                      href={inferior.goodCausesLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Good Causes
                    </a>
                  )}
                </td>
              </tr>
              <tr>
                <th>Family ID (aka license external ID)</th>
                <td>{superior.familyId}</td>
                <td>{inferior.familyId}</td>
                <td>
                  <InputFilterable
                    hideLabel
                    label="Family ID"
                    name="merged-family-id"
                    clearable={true}
                    onChange={selectedFamilyId => {
                      this.setState({
                        merged: {
                          ...this.state.merged,
                          familyId: selectedFamilyId,
                        },
                      });
                    }}
                    value={merged.familyId}
                    options={[
                      {
                        value: superior.familyId,
                        label: superior.familyId,
                      },
                      {
                        value: inferior.familyId,
                        label: inferior.familyId,
                      },
                    ]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <input className={styles.doMerge} type="submit" value="Do Merge" />
        </form>
        <div className={styles.mergeStatus}>
          {doingMerge && (
            <span>
              Merging
              <Spinner />
            </span>
          )}
          {result && (
            <span>
              Merged{" "}
              <a href={result.inferior_path}>
                {result.inferior_name} (Inferior)
              </a>{" "}
              into{" "}
              <a href={result.superior_path}>
                {result.superior_name} (Superior)
              </a>
            </span>
          )}
          <span className={styles.errorMessage}>{errorMessage}</span>
        </div>
      </div>
    );
  }
}

export default MergePrimaryApplicants;
