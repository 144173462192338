import { isNil } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import EditOtherRelationshipsModal from "./EditOtherRelationshipsModal";
import ViewRelationshipModal from "./ViewRelationshipModal";
import { determineName } from "./sortRelationships";

/** A wrapper around our two relationship modals - one for viewing details
 * of a relationship and one for adding/editing relationships to other
 * non-children AgencyHumans
 */
const RelationshipModals = ({
  keystoneAgencyHumanId,
  relationship,
  setRelationshipToView,
}) => {
  const [editRelationshipsForAgencyHuman, setEditRelationshipsForAgencyHuman] =
    useState();
  const { destinationAgencyHuman, sourceAgencyHuman } = relationship || {};

  const { name, firstName } = determineName({
    keystoneAgencyHumanId,
    destinationAgencyHuman,
    sourceAgencyHuman,
  });

  if (isNil(relationship)) return null;

  return (
    <Fragment>
      <EditOtherRelationshipsModal
        keystoneAgencyHumanFullName={name}
        keystoneAgencyHumanFirstName={firstName}
        keystoneAgencyHumanId={editRelationshipsForAgencyHuman}
        setEditRelationshipsForAgencyHuman={setEditRelationshipsForAgencyHuman}
      />
      <ViewRelationshipModal
        keystoneAgencyHumanId={keystoneAgencyHumanId}
        relationship={relationship}
        setRelationshipToView={setRelationshipToView}
        editRelationshipsForAgencyHuman={editRelationshipsForAgencyHuman}
        setEditRelationshipsForAgencyHuman={setEditRelationshipsForAgencyHuman}
      />
    </Fragment>
  );
};
RelationshipModals.propTypes = {
  keystoneAgencyHumanId: PropTypes.number.isRequired,
  relationship: PropTypes.shape({
    destinationAgencyHuman: PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
    }),
    sourceAgencyHuman: PropTypes.shape({ fullName: PropTypes.string }),
    kinshipRelationship: PropTypes.string,
    parentalLine: PropTypes.string,
    lineageType: PropTypes.string,
    levelsOfSupport: PropTypes.object,
    placementProviderEligibilitiesJson: PropTypes.shape({
      checked: PropTypes.bool,
    }),
    placementProviderEligibilitiesDetails: PropTypes.string,
    emotionalRelationshipStatuses: PropTypes.arrayOf(PropTypes.string),
    additionalRelationshipDetails: PropTypes.string,
    verificationSource: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  setRelationshipToView: PropTypes.func.isRequired,
};

export default RelationshipModals;
