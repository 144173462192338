import PropTypes from "prop-types";

import InputReadOnly from "./InputReadOnly";
import {
  inputCommonPropTypes,
  textualInputPropTypes,
  useSimpleControlledInputProps,
  BasicInputLayout,
} from "./common";

/**
 * A `<textarea>` input, very similar to `<InputText>` for when you need to
 * ask the user for more than just a few words.
 */
const InputTextarea = props => {
  const { placeholder, rows = 5, maxCharacters } = props;
  const controlledInputProps = useSimpleControlledInputProps(props);

  if (props.disabled) {
    return <InputReadOnly {...props} />;
  }

  return (
    <BasicInputLayout
      {...props}
      inputComponent={commonInputProps => (
        <textarea
          {...commonInputProps}
          {...controlledInputProps}
          rows={rows}
          placeholder={placeholder}
          className="form-control"
          maxLength={maxCharacters}
        />
      )}
    />
  );
};

InputTextarea.propTypes = {
  ...inputCommonPropTypes,
  ...textualInputPropTypes,
  /** Number of rows (visual height) this textarea will display before
   * a scrollbar appears */
  rows: PropTypes.number,
  /** The initial (or current) field value as a string */
  value: PropTypes.string,
  /** Invoked with the current field value as an argument */
  onChange: PropTypes.func,
};

export default InputTextarea;
