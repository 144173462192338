import { useMutation } from "@apollo/client";
import { Modal, toast } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import DeleteRequirement from "@graphql/mutations/DeleteRequirement.graphql";

const { t } = translationWithRoot("prevention.profile_page.delete_modal");

/**
 * Modal component to confirm the deletion of a Prevention document.
 *
 * @param {function} handleCloseModal - Resets the state variables that control the modal.
 * @param {boolean} isHidden - Controls the visibility of the modal.
 * @param {object} refetchQuery - Apollo query used to refetch after deletion.
 * @param {number} requirementHolderId - ID of the requirement holder used to fetch documents.
 * @param {number} requirementIdToDelete - ID of the requirement to delete.
 * @returns {JSX.Element}
 */
const PreventionDocumentDeleteModal = ({
  handleCloseModal,
  isHidden,
  refetchQuery,
  requirementHolderId,
  requirementIdToDelete,
}) => {
  const [deleteDocument, { loading }] = useMutation(DeleteRequirement, {
    refetchQueries: [
      {
        query: refetchQuery,
        variables: { requirementHolderId },
      },
    ],
    onCompleted: () => {
      handleCloseModal();
      toast.success({
        title: t("success_title"),
        message: t("success_message"),
      });
    },
    onError: () => {
      toast.negative({
        title: t("error_title"),
        message: t("error_message"),
      });
      handleCloseModal();
    },
  });

  return (
    <Modal
      title={t("delete_document")}
      submitting={loading}
      submitDangerButton
      submitText={t("delete_document")}
      submittingText={t("submitting_text")}
      onCancel={() => {
        handleCloseModal();
      }}
      onSubmit={async () => {
        await deleteDocument({
          variables: {
            requirementId: requirementIdToDelete,
            deleteUploadedRecords: true,
          },
        });
      }}
      hidden={isHidden}
    >
      {t("body")}
    </Modal>
  );
};

PreventionDocumentDeleteModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired,
  refetchQuery: PropTypes.object.isRequired,
  requirementHolderId: PropTypes.number.isRequired,
  requirementIdToDelete: PropTypes.number,
};

export default PreventionDocumentDeleteModal;
