import { Flex, Link, Text } from "@heart/components";
import PropTypes from "prop-types";

const DownloadLink = ({ url, linkText }) => (
  <Flex row>
    <Link
      data-testid="download-incomplete-link"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Text textStyle="supporting-100">{linkText}</Text>
    </Link>
  </Flex>
);

DownloadLink.propTypes = {
  url: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

export default DownloadLink;
