import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  Wizard,
  WizardPage,
  WizardFieldset,
  Breadcrumbs,
  InputDate,
  InputTime,
  InputDropdown,
  InputText,
  InputTextarea,
  InputFilterableGraphQL,
  Text,
} from "@heart/components";
import { calculateProgress } from "@heart/components/wizard/Wizard";
import { curry, isEqual, isEmpty, isNil, omitBy } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { contentFamilyFindingContactLogPath } from "routes";

import { translationWithRoot } from "@components/T";
import createNameAndLabels from "@components/common/createNameAndLabels";
import CopyRelationshipDetailsButton from "@components/family_finding/relationships/CopyRelationshipDetailsButton";
import RelationshipToChildSection from "@components/family_finding/relationships/RelationshipToChildSection";
import {
  relationshipsForUI,
  relationshipForDB,
} from "@components/family_finding/relationships/relationshipConversion";

import CreateOrUpdateFamilyFindingContactLog from "@graphql/mutations/CreateOrUpdateFamilyFindingContactLog.graphql";
import CreateOrUpdateRelationship from "@graphql/mutations/CreateOrUpdateRelationship.graphql";
import AgencyHumanRelationships from "@graphql/queries/AgencyHumanRelationships.graphql";
import Can from "@graphql/queries/Can.graphql";
import FamilyFindingContactLog from "@graphql/queries/FamilyFindingContactLog.graphql";

import { mapConstantSetToValues } from "@lib/constantsConversion";

import {
  FAMILY_FINDING_CONTACT_LOG_SELECTABLE_STATUSES,
  FAMILY_FINDING_CONTACT_LOG_CONTACT_METHODS,
  FAMILY_FINDING_CONTACT_LOG_CONTACT_METHOD_OTHER,
} from "@root/constants";

import AgencyHumansInputWithContactInfo from "./AgencyHumansInputWithContactInfo";
import styles from "./ContactLogForm.module.scss";

const { t: contactLogsT } = translationWithRoot("family_finding.contact_logs");

/** Binding this callback to the core childAgencyHuman for this form */
export const getOptionsFromRelationshipsData = childAgencyHuman => data => {
  // The child is always an option in dropdowns for AgencyHumans.
  // For the dropdown of contactAgencyHumans, the child could be
  // selected to document that a contact was made with the child
  // themself. For the dropdown of childAgencyHumans, the child
  // will always be selected.
  const options = [
    {
      value: childAgencyHuman.id,
      label: createNameAndLabels({ ...childAgencyHuman, useUnknown: true }),
      ...childAgencyHuman,
    },
  ];
  data.agencyHumanRelationships.forEach(relationship => {
    if (relationship.destinationAgencyHuman.id !== childAgencyHuman.id) {
      options.push({
        value: relationship.destinationAgencyHuman.id,
        label: createNameAndLabels({
          ...relationship.destinationAgencyHuman,
          useUnknown: true,
        }),
        ...relationship.destinationAgencyHuman,
      });
    }
    if (relationship.sourceAgencyHuman.id !== childAgencyHuman.id) {
      options.push({
        value: relationship.sourceAgencyHuman.id,
        label: createNameAndLabels({
          ...relationship.sourceAgencyHuman,
          useUnknown: true,
        }),
        ...relationship.sourceAgencyHuman,
      });
    }
  });
  return options;
};

const AgencyTimeZoneQuery = gql`
  query Agency($id: ID!) {
    agency(id: $id) {
      id
      timeZone
    }
  }
`;

/** Transforming the formState into an object that matches
 * the structure expected by our graphql mutation and database
 */
export const contactLogForDB = ({
  contactLogId,
  agencyId,
  contactLog: {
    contactAgencyHumans,
    childAgencyHumans,
    contactMethod,
    contactMethodOther,
    status,
    notes,
    contactedOnDate,
    contactedOnTime,
    contactName,
  },
}) => ({
  id: contactLogId, // Will be undefined when creating a new contact log
  contactAgencyHumanIds: contactAgencyHumans.map(({ value }) => value),
  childAgencyHumanIds: childAgencyHumans.map(({ value }) => value),
  contactMethod,
  contactMethodOther,
  status,
  notes,
  contactedOnDate,
  contactedOnTime,
  agencyId,
  contactName,
});

/** A form for creating and updating a Family Finding contact log
 *
 * When only one contactAgencyHuman is selected who is not the child
 * that the contact log is being created on behalf of, this form will
 * also allow folks to edit the relationship details between the
 * contactAgencyHuman and any children selected.
 */
export const ContactLogForm = ({
  initialContactAgencyHumans = [],
  childAgencyHuman,
  originPath,
  contactLogId,
  action,
  agencyId,
}) => {
  const [contactLog, setContactLog] = useState({
    contactAgencyHumans: initialContactAgencyHumans,
    childAgencyHumans: [
      {
        value: childAgencyHuman.id,
        label: createNameAndLabels(childAgencyHuman),
        isFixed: true,
      },
    ],
    contactMethod: "",
    status: "",
    notes: "",
    contactedOnDate: "",
    contacteOnTime: "",
    contactName: "",
    tiedToBulkOutreachCampaign: false,
    contactMethodOther: "",
  });
  const [relationships, setRelationships] = useState({});
  const [showPersonsContactedError, setShowPersonsContactedError] =
    useState(false);

  // State to hold automated status options (statuses set automatically by the system)
  const [automatedStatusOptions, setAutomatedStatusOptions] = useState([]);

  const setContactLogAttribute = curry((attribute, value) => {
    setContactLog({ ...contactLog, [attribute]: value });
  });

  const setRelationshipsAttribute = curry((attribute, value) => {
    if (!isNil(value) && !isEqual(value, relationships[attribute]))
      setRelationships({ ...relationships, [attribute]: value });
  });

  const [createOrUpdateRelationship] = useMutation(CreateOrUpdateRelationship);
  const [createOrUpdateFamilyFindingContactLog] = useMutation(
    CreateOrUpdateFamilyFindingContactLog
  );

  // Get timezone for the agency.
  const { data: { agency } = {} } = useQuery(AgencyTimeZoneQuery, {
    variables: { id: agencyId },
  });
  const { data: { can: canEditRelationship } = {} } = useQuery(Can, {
    variables: { resource: "relationship", action: "edit" },
  });

  const { loading: loadingContactLog } = useQuery(FamilyFindingContactLog, {
    variables: { id: contactLogId },
    skip: action === "create",
    onCompleted: ({
      familyFindingContactLog: {
        id,
        contactMethod,
        contactMethodOther,
        status,
        notes,
        contactedOn,
        contactedOnTimeKnown,
        familyFindingContactLogsContactAgencyHumans,
        familyFindingContactLogsChildAgencyHumans,
        familyFindingBulkOutreachCampaignId,
      },
    }) => {
      setContactLog({
        id,
        contactAgencyHumans: familyFindingContactLogsContactAgencyHumans.reduce(
          (acc, { contactAgencyHuman }) =>
            !isNil(contactAgencyHuman)
              ? [
                  ...acc,
                  {
                    value: contactAgencyHuman.id,
                    label: contactAgencyHuman.fullName,
                    ...contactAgencyHuman,
                  },
                ]
              : acc,
          []
        ),
        // There is only ever one familyFindingContactLogsContactAgencyHuman record
        // with a non-null contactName
        contactName:
          familyFindingContactLogsContactAgencyHumans.find(
            ({ contactName }) => !isNil(contactName)
          )?.contactName || "",
        childAgencyHumans: (
          familyFindingContactLogsChildAgencyHumans || []
        ).map(({ childAgencyHuman: child }) => ({
          value: child.id,
          label: child.fullName,
          isFixed: child.id === childAgencyHuman.id,
        })),
        tiedToBulkOutreachCampaign: Boolean(
          familyFindingBulkOutreachCampaignId
        ),
        contactMethod: contactMethod,
        contactMethodOther: contactMethodOther,
        status: status,
        notes: notes,
        contactedOnDate: DateTime.fromISO(contactedOn, {
          setZone: true,
        }).toISODate(),
        contactedOnTime: contactedOnTimeKnown
          ? DateTime.fromISO(contactedOn, { setZone: true }).toISOTime({
              suppressMilliseconds: true,
              suppressSeconds: true,
            })
          : "",
      });
    },
  });

  const [loadRelationships, { loading: relationshipsLoading }] = useLazyQuery(
    AgencyHumanRelationships,
    {
      variables: {
        agencyHumanId: contactLog.contactAgencyHumans[0]?.value,
        filterByAgencyHumanIds: contactLog.childAgencyHumans.map(
          ({ value }) => value
        ),
      },
      skip: contactLog.contactAgencyHumans.length !== 1,
      onCompleted: data => {
        setRelationships({
          ...relationshipsForUI({
            data,
            keystoneAgencyHumanId: contactLog.contactAgencyHumans[0].value,
          }),
        });
      },
    }
  );

  const exactlyOneContactSelected =
    contactLog.contactAgencyHumans.length === 1 &&
    contactLog.contactAgencyHumans[0].value !== childAgencyHuman.id;

  useEffect(() => {
    // Do not load relationship data if there isn't exactly one contactAgencyHuman
    // selected or the contactAgencyHuman selected is the child themselves
    if (exactlyOneContactSelected) loadRelationships();
  }, [loadRelationships, exactlyOneContactSelected]);

  // Update automatedStatusOptions when contactLog.status changes
  useEffect(() => {
    if (
      contactLog.status &&
      !FAMILY_FINDING_CONTACT_LOG_SELECTABLE_STATUSES.includes(
        contactLog.status
      ) &&
      !automatedStatusOptions.includes(contactLog.status)
    ) {
      setAutomatedStatusOptions(prevAutomatedStatusOptions => [
        ...prevAutomatedStatusOptions,
        contactLog.status,
      ]);
    }
  }, [contactLog.status, automatedStatusOptions]);

  // Combine predefined selectable statuses with automated statuses for display
  const statusOptions = [
    ...FAMILY_FINDING_CONTACT_LOG_SELECTABLE_STATUSES,
    ...automatedStatusOptions,
  ];

  return (
    <Wizard
      breadcrumbs={
        <Breadcrumbs
          pages={[
            {
              label: childAgencyHuman.fullName,
              href: originPath,
            },
            {
              label: "Contact Log",
              href: "#",
            },
          ]}
        />
      }
      title={
        action === "create"
          ? contactLogsT("common.record_contact")
          : contactLogsT("common.edit_contact")
      }
      pages={[
        <WizardPage
          key="contact_log_form"
          pageTitle={
            action === "create"
              ? contactLogsT("common.record_contact")
              : contactLogsT("common.edit_contact")
          }
          progress={calculateProgress({
            requiredFields: [
              contactLog.contactAgencyHumans.length || contactLog.contactName,
              contactLog.childAgencyHumans.length,
              contactLog.contactedOnDate,
              contactLog.contactMethod,
              contactLog.status,
            ],
          })}
          actionsProps={{
            cancelHref: originPath,
            primaryAction: async () => {
              // Show error if neither of the contact inputs are filled out
              // and skip submitting any data. Scroll up to the top of the
              // page to see the error.
              if (
                !loadingContactLog &&
                isEmpty(contactLog.contactAgencyHumans) &&
                isEmpty(contactLog.contactName)
              ) {
                setShowPersonsContactedError(true);
                window.scrollTo({ top: 0, left: 0 });
              } else {
                if (canEditRelationship && exactlyOneContactSelected) {
                  await Promise.all(
                    contactLog.childAgencyHumans.map(({ value: id }) =>
                      createOrUpdateRelationship({
                        variables: relationshipForDB({
                          associatedChildAgencyHumanId: id,
                          /** If no preexisting relationship between two agency humans exists, and there
                           * are no changes made to the relationship details for those two agency humans,
                           * the data held in relationships state for that relationship will not have the
                           * required ids to successfully save an empty relationship. If that isn't the case
                           * the information generated will be overwritten by the information
                           * held in relationships state.
                           */
                          keystoneAgencyHumanId:
                            contactLog.contactAgencyHumans[0].value,
                          nonKeystoneAgencyHumanId: id,
                          ...omitBy(relationships[id], isNil),
                        }),
                      })
                    )
                  );
                }
                await createOrUpdateFamilyFindingContactLog({
                  variables: contactLogForDB({
                    contactLogId,
                    agencyId,
                    contactLog,
                  }),
                });
                window.location = originPath;
              }
            },
          }}
        >
          <WizardFieldset
            loading={loadingContactLog}
            sectionTitle={contactLogsT("common.contact_details")}
          >
            <If condition={showPersonsContactedError}>
              <Text textColor="danger-600" role="alert">
                {contactLogsT("contact_log_form.persons_contacted_error")}
              </Text>
            </If>
            <AgencyHumansInputWithContactInfo
              agencyHumans={contactLog.contactAgencyHumans}
              setAgencyHumans={setContactLogAttribute("contactAgencyHumans")}
              childAgencyHuman={childAgencyHuman}
              disabled={contactLog.tiedToBulkOutreachCampaign}
            />
            <InputText
              label={contactLogsT("contact_log_form.others_contacted")}
              value={contactLog.contactName}
              onChange={setContactLogAttribute("contactName")}
              disabled={contactLog.tiedToBulkOutreachCampaign}
            />
            <InputFilterableGraphQL
              required
              label={contactLogsT("common.on_behalf_of")}
              value={contactLog.childAgencyHumans}
              isMulti
              query={AgencyHumanRelationships}
              queryVariables={{
                agencyHumanId: childAgencyHuman.id,
                filterForChildrenInCare: true,
              }}
              transformQueryData={getOptionsFromRelationshipsData(
                childAgencyHuman
              )}
              onChange={setContactLogAttribute("childAgencyHumans")}
              isClearable={false}
              /** These styles remove the clear button from the core child
               * so they cannot be removed, as removing them breaks our
               * information architecture
               */
              styles={{
                multiValueLabel: (base, state) =>
                  state.data.isFixed ? { ...base, paddingRight: 6 } : base,
                multiValueRemove: (base, state) =>
                  state.data.isFixed ? { ...base, display: "none" } : base,
              }}
              disabled={contactLog.tiedToBulkOutreachCampaign}
            />
            <InputDate
              required
              label={contactLogsT("common.date_contacted")}
              value={contactLog.contactedOnDate}
              onChange={setContactLogAttribute("contactedOnDate")}
              maxDate={new Date()}
            />
            <InputTime
              label={contactLogsT("common.time_contacted")}
              value={contactLog.contactedOnTime}
              onChange={setContactLogAttribute("contactedOnTime")}
              description={
                agency
                  ? contactLogsT("contact_log_form.in_time_zone", {
                      time_zone: agency.timeZone,
                    })
                  : null
              }
            />
            <InputDropdown
              required
              label={contactLogsT("common.contact_method")}
              values={mapConstantSetToValues({
                constant: FAMILY_FINDING_CONTACT_LOG_CONTACT_METHODS,
                translationKey: "family_finding_contact_log.contact_method",
              })}
              value={contactLog.contactMethod}
              onChange={value => {
                setContactLog({
                  ...contactLog,
                  contactMethod: value,
                  contactMethodOther:
                    value !== FAMILY_FINDING_CONTACT_LOG_CONTACT_METHOD_OTHER
                      ? null
                      : contactLog.contactMethodOther,
                });
              }}
              disabled={contactLog.tiedToBulkOutreachCampaign}
            />
            {contactLog.contactMethod ===
              FAMILY_FINDING_CONTACT_LOG_CONTACT_METHOD_OTHER && (
              <InputText
                required
                label={contactLogsT("common.other_reason")}
                value={contactLog.contactMethodOther}
                onChange={setContactLogAttribute("contactMethodOther")}
                disabled={contactLog.tiedToBulkOutreachCampaign}
              />
            )}
            <InputDropdown
              required
              label={contactLogsT("common.status")}
              values={mapConstantSetToValues({
                constant: statusOptions,
                translationKey: "family_finding_contact_log.status",
              })}
              value={contactLog.status}
              onChange={setContactLogAttribute("status")}
            />
          </WizardFieldset>
          <If condition={contactLog.tiedToBulkOutreachCampaign}>
            <WizardFieldset
              loading={loadingContactLog}
              sectionTitle={contactLogsT(
                `contact_log_form.${contactLog.contactMethod}.section_title`
              )}
            >
              <div className={styles.letter}>
                <iframe
                  title="bulkOutreachCampaignNotification"
                  src={contentFamilyFindingContactLogPath(contactLog.id)}
                  className={styles.iframe}
                />
              </div>
            </WizardFieldset>
          </If>
          <If condition={!contactLog.tiedToBulkOutreachCampaign}>
            <WizardFieldset
              loading={loadingContactLog}
              sectionTitle={contactLogsT("common.notes")}
            >
              <InputTextarea
                label={contactLogsT("common.notes")}
                description={contactLogsT("contact_log_form.notes_description")}
                hideLabel
                value={contactLog.notes}
                onChange={setContactLogAttribute("notes")}
              />
            </WizardFieldset>
          </If>
          <If condition={canEditRelationship}>
            <WizardFieldset
              loading={relationshipsLoading}
              sectionTitle={contactLogsT(
                "contact_log_form.relationship_details"
              )}
            >
              <Text>
                <Choose>
                  <When condition={contactLog.contactAgencyHumans.length > 1}>
                    {contactLogsT(
                      "contact_log_form.relationship_details_multiple_people"
                    )}
                  </When>
                  <When
                    condition={
                      contactLog.contactAgencyHumans[0]?.value ===
                      childAgencyHuman.id
                    }
                  >
                    {contactLogsT(
                      "contact_log_form.relationship_details_to_self"
                    )}
                  </When>
                  <When condition={contactLog.contactAgencyHumans.length === 1}>
                    {contactLogsT(
                      "contact_log_form.relationship_details_description"
                    )}
                  </When>
                  <Otherwise>
                    {contactLogsT("contact_log_form.no_relationship_details")}
                  </Otherwise>
                </Choose>
              </Text>
              <If condition={exactlyOneContactSelected}>
                {contactLog.childAgencyHumans.map(
                  ({ value: id, label: name }, index) => (
                    <WizardFieldset
                      key={`relationship_to_${name}`}
                      sectionTitle={contactLogsT(
                        "contact_log_form.relationship_to_child",
                        {
                          name: contactLog.contactAgencyHumans[0].label,
                          childName: name,
                        }
                      )}
                      secondary={
                        <CopyRelationshipDetailsButton
                          copyToAgencyHumanId={id}
                          copyFromAgencyHumanId={childAgencyHuman.id}
                          copyFromName={childAgencyHuman.fullName}
                          formState={relationships}
                          defaultRelationshipState={{
                            keystoneAgencyHumanId:
                              contactLog.contactAgencyHumans[0].value,
                            nonKeystoneAgencyHumanId: id,
                          }}
                          setFormAttribute={setRelationshipsAttribute(id)}
                        />
                      }
                      collapsible
                      defaultCollapsed={index > 0}
                    >
                      <RelationshipToChildSection
                        relationship={
                          relationships[id] || {
                            keystoneAgencyHumanId:
                              contactLog.contactAgencyHumans[0].value,
                            nonKeystoneAgencyHumanId: id,
                          }
                        }
                        setFormAttribute={setRelationshipsAttribute(id)}
                      />
                    </WizardFieldset>
                  )
                )}
              </If>
            </WizardFieldset>
          </If>
        </WizardPage>,
      ]}
    ></Wizard>
  );
};

ContactLogForm.propTypes = {
  /** Where the form's cancel and final submit buttons should redirect to */
  originPath: PropTypes.string.isRequired,
  /** Optional starting person the contact log is associated with */
  initialContactAgencyHumans: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      addresses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          type: PropTypes.array,
          primary: PropTypes.bool,
          inactive: PropTypes.bool,
          addressLine1: PropTypes.string,
          addressLine2: PropTypes.string,
          city: PropTypes.string,
          primarySubdivision: PropTypes.string,
          postalCode: PropTypes.string,
          additionalDirections: PropTypes.string,
          customFields: PropTypes.string,
        })
      ).isRequired,
      childPhoneNumber: PropTypes.string,
      phoneNumbers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          category: PropTypes.string,
          primary: PropTypes.bool,
          active: PropTypes.bool,
          notes: PropTypes.string,
          formattedNumber: PropTypes.string,
        })
      ).isRequired,
      emailAddresses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          emailAddress: PropTypes.string,
          primary: PropTypes.bool,
          inactive: PropTypes.bool,
        })
      ).isRequired,
      socialMediaLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
  /** The core child the contact log is associated with */
  childAgencyHuman: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.array,
        primary: PropTypes.bool,
        inactive: PropTypes.bool,
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        primarySubdivision: PropTypes.string,
        postalCode: PropTypes.string,
        additionalDirections: PropTypes.string,
        customFields: PropTypes.string,
      })
    ).isRequired,
    childPhoneNumber: PropTypes.string,
    linkToView: PropTypes.string.isRequired,
  }).isRequired,
  /** Action the form is being used for - either creating or editing a contact log */
  action: PropTypes.oneOf(["edit", "create"]).isRequired,
  /** Id for the contact Log, only specified when editing an existing contact log */
  contactLogId: PropTypes.string,
  /** AgencyId for the contact log, needed when creating or updating the record upon saving */
  agencyId: PropTypes.string.isRequired,
};

export default ContactLogForm;
