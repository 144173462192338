import { Notice } from "@heart/components";
import I18n from "i18n-js";
import { compact, isEmpty, groupBy } from "lodash";
import PropTypes from "prop-types";
import {
  adminLicenseTrainingRequirementsPath,
  adminApplicationBackgroundCheckRecordsPath,
  documentsAdminApplicationPath,
  editAdminApplicationPath,
  editReferencesAdminApplicationPath,
  adminChildPlacementPeriodPath,
  adminLicensePath,
} from "routes";

const StatusChangeErrors = ({ errors, applicationId, status, licenseId }) => {
  if (isEmpty(errors)) return null;

  const errorsByType = groupBy(errors, "type");
  const errorMessages = Object.keys(errorsByType).flatMap(errorType => {
    const errorDetails = {
      incomplete_applicant_data: {
        url: editAdminApplicationPath(applicationId, {
          validate_for_status: status,
        }),
        text: "Applicant data",
      },
      incomplete_documents: {
        url: documentsAdminApplicationPath(applicationId),
        text: "Documents",
      },
      incomplete_background_checks: {
        url: adminApplicationBackgroundCheckRecordsPath(applicationId),
        text: "Background Checks",
      },
      incomplete_references: {
        url: editReferencesAdminApplicationPath(applicationId),
        text: "References",
      },
      incomplete_trainings: {
        url: adminLicenseTrainingRequirementsPath(licenseId),
        text: "Trainings",
      },
      family_id_required_for_approval: {
        url: adminLicensePath(licenseId),
        text: "Family ID",
      },
      family_id_required_for_approval_ca: {
        url: adminLicensePath(licenseId),
        text: "RFA ID",
      },
    }[errorType];

    if (errorDetails) {
      return (
        <IncompleteApprovalErrorMessage
          key={errorType}
          errorType={errorType}
          linkText={errorDetails.text}
          url={errorDetails.url}
        />
      );
    }

    if (errorType === "background_check_failure") {
      return (
        <li key={errorType}>
          A{" "}
          <a
            href={adminApplicationBackgroundCheckRecordsPath(applicationId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            background check
          </a>{" "}
          failed.
        </li>
      );
    }

    if (errorType === "incomplete_family_type") {
      return (
        <li key={errorType}>
          <a
            href={editAdminApplicationPath(applicationId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Family Type
          </a>{" "}
          must be selected before updating application
        </li>
      );
    }
    if (errorType.includes("child_in_home_on_status_close")) {
      // we use /:(.*)/ instead of ':' to split since we only want to split on the first ':'
      // LA has child names like "Client Number: 12345-123", we want to make sure those are still preserved
      // we need to use compact since /:(.*)/ add an extra "" in the array after the split
      const errorList = compact(errorType.split(/:(.*)/));
      const key = errorList.shift();
      return (
        <li key={key}>
          There is a child in home. Please update the following Placement
          Periods before closing this Applicant:
          <ul>
            {errorList.map(error => {
              const [childId, childName, placementPeriodId, startDate] =
                error.split("|");
              return (
                <li key={placementPeriodId}>
                  <a
                    href={adminChildPlacementPeriodPath(
                      childId,
                      placementPeriodId
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {startDate} placement of {childName}
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
      );
    }

    return errorsByType[errorType].map((error, index) => (
      <li key={`${errorType}${index}`}>{error.message}</li>
    ));
  });

  return (
    <Notice title="Error changing status">
      <div className="prose">
        <strong>
          Cannot change status to {I18n.t(`application.statuses.${status}`)}{" "}
          because
        </strong>
        <ul>{errorMessages}</ul>
      </div>
    </Notice>
  );
};

StatusChangeErrors.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  applicationId: PropTypes.number.isRequired,
  licenseId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  familyType: PropTypes.string,
};

const IncompleteApprovalErrorMessage = ({ errorType, linkText, url }) => (
  <li key={errorType}>
    <a href={url} target="_blank" rel="noopener noreferrer">
      {linkText}
    </a>{" "}
    required for this status change is incomplete
  </li>
);

IncompleteApprovalErrorMessage.propTypes = {
  errorType: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default StatusChangeErrors;
