import { useMutation, useQuery } from "@apollo/client";
import { Button, Icons, InputText } from "@heart/components";
import { compact, isEmpty, uniq } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

import Spinner from "@components/Spinner";

import CreateUploadTypeRequirementTemplate from "@graphql/mutations/CreateUploadTypeRequirementTemplate.graphql";
import SetApplicationStageTemplateUploadTypeSlugs from "@graphql/mutations/SetApplicationStageTemplateUploadTypeSlugs.graphql";
import ApplicationStageTemplateQuery from "@graphql/queries/ApplicationStageTemplate.graphql";

import { isTestEnvironment } from "@lib/environment";
import preventDefault from "@lib/preventDefault";

import styles from "./ApplicationTemplateDesigner.module.scss";
import UploadType from "./UploadType";

/**
 * A droppable section that is specialized for upload types
 */
const UploadTypeDroppable = ({
  id,
  applicationTemplate,
  uploadTypeDroppableId,
}) => {
  const templateUploadTypeSlugs = uniq(
    compact(
      applicationTemplate.requirementTemplates.map(
        template => template.uploadType?.slug
      )
    )
  );

  const { data, loading } = useQuery(ApplicationStageTemplateQuery, {
    variables: { id },
  });

  const [createUploadTypeRequirementTemplate] = useMutation(
    CreateUploadTypeRequirementTemplate
  );
  const onAddUploadTypeToTemplate = uploadTypeSlug => () => {
    createUploadTypeRequirementTemplate({
      variables: {
        applicationTemplateId: applicationTemplate.id,
        uploadTypeSlug,
      },
    });
  };

  const [setApplicationStageTemplateUploadTypeSlugs] = useMutation(
    SetApplicationStageTemplateUploadTypeSlugs
  );

  const onRemoveUploadType = uploadTypeSlug => () => {
    setApplicationStageTemplateUploadTypeSlugs({
      variables: {
        applicationStageTemplateId: applicationStageTemplate.id,
        uploadTypeSlugs: applicationStageTemplate.uploadTypes
          .map(uploadType => uploadType.slug)
          .filter(slug => slug !== uploadTypeSlug),
      },
    });
  };

  const [addUploadTypeSlug, setAddUploadTypeSlug] = useState("");
  const onAddUploadTypeSlug = preventDefault(() => {
    setApplicationStageTemplateUploadTypeSlugs({
      variables: {
        applicationStageTemplateId: applicationStageTemplate.id,
        uploadTypeSlugs: applicationStageTemplate.uploadTypes
          .map(uploadType => uploadType.slug)
          .concat([addUploadTypeSlug]),
      },
    }).then(() => {
      setAddUploadTypeSlug("");
    });
  });

  if (loading) {
    return <Spinner />;
  }

  const { applicationStageTemplate } = data;

  return (
    <Droppable droppableId={uploadTypeDroppableId}>
      {provided => (
        <div ref={provided.innerRef}>
          <div className={styles.thingTypeHeader}>Stage Upload Types</div>
          {applicationStageTemplate.uploadTypes.map((uploadType, index) => (
            <Draggable
              key={uploadType.slug}
              draggableId={`uploadType:stage-${applicationStageTemplate.stageNumber}:${uploadType.slug}`}
              index={index}
            >
              {draggableProvided => (
                <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                >
                  <UploadType
                    uploadType={uploadType}
                    onRemove={onRemoveUploadType(uploadType.slug)}
                    dragHandleProps={draggableProvided.dragHandleProps}
                    onAddToTemplate={
                      templateUploadTypeSlugs.includes(uploadType.slug) &&
                      onAddUploadTypeToTemplate(uploadType.slug)
                    }
                  />
                </div>
              )}
            </Draggable>
          ))}
          {isEmpty(applicationStageTemplate.uploadTypes) && (
            <div className={styles.dndEmpty}>
              Drag here to add an upload type
            </div>
          )}
          {provided.placeholder}
          <If condition={isTestEnvironment()}>
            {/* Cypress flakes a lot with drag n drop - this gives us an out */}
            <InputText
              label="Add uploadType slug"
              value={addUploadTypeSlug}
              onChange={setAddUploadTypeSlug}
            />
            <Button
              onClick={onAddUploadTypeSlug}
              description="Add uploadType slug"
              icon={Icons.Plus}
            />
          </If>
        </div>
      )}
    </Droppable>
  );
};

UploadTypeDroppable.propTypes = {
  id: PropTypes.string.isRequired,
  applicationTemplate: PropTypes.object.isRequired,
  uploadTypeDroppableId: PropTypes.string,
};

export default UploadTypeDroppable;
