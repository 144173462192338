import { IconMenu, Icons, Text } from "@heart/components";
import _ from "lodash";
import {
  agencyServicePath,
  newPreventionAgencyServiceReferralPath,
} from "routes";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot(
  "prevention.agency_services.dashboard.columns"
);
const ProviderNameColumn = {
  columnName: { name: t("provider_name") },
  cell: data => (
    <div>
      <Text textStyle="body-200">{data.providerAgency.name}</Text>
      <br />
      <Text textStyle="supporting-100" textColor="neutral-600">
        ID: {data.providerAgency.id}
      </Text>
    </div>
  ),
  id: "providerName",
  sortBy: "provider_agencies.name",
};

const ServiceNameColumn = {
  columnName: { name: t("service_name") },
  cell: data => data.agencyService.name,
  id: "serviceName",
  sortBy: "agency_services.name",
};

const ServiceRateNameColumn = {
  columnName: { name: t("service_rate_name") },
  cell: data => data.label,
  id: "serviceRateName",
  sortBy: "label",
};

const ActionsColumn = ({ showCreateServiceReferralButton }) => ({
  columnName: { name: t("actions"), justify: "end" },
  id: "actions",
  cell: data => {
    const linkItems = [
      {
        description: t("row_actions.service_details"),
        href: agencyServicePath(data.agencyService.id),
      },
    ];

    if (showCreateServiceReferralButton) {
      linkItems.push({
        description: t("row_actions.create_service_referral"),
        href: newPreventionAgencyServiceReferralPath(),
      });
    }
    return (
      <IconMenu
        icon={Icons.EllipsisVertical}
        menuDescription={t("action_menu_description", {
          agency_service_name: data.agencyService.name,
        })}
        linkItems={linkItems}
        rightAligned
      />
    );
  },
});

const buildCol = (name, sortBy = false) => ({
  columnName: { name: t(name) },
  id: name,
  cell: data => data[_.camelCase(name)],
  sortBy: sortBy ? name : undefined,
});

/**
 * Generates an array of column configurations for the agency services table.
 *
 * @param {Object} options - Options for configuring the columns.
 * @param {boolean} options.showCreateServiceReferralButton - Flag to determine if the create service referral button should be displayed.
 * @returns {Array<Object>} An array of column configuration objects.
 */
const columns = ({ showCreateServiceReferralButton }) => [
  ProviderNameColumn,
  ServiceNameColumn,
  ServiceRateNameColumn,
  buildCol("start_date", true),
  buildCol("end_date", true),
  {
    columnName: { name: t("billing_structure") },
    id: "billingStructure",
    cell: data => t(`billing_structures.${data.billingStructure}`),
  },
  {
    columnName: { name: t("amount") },
    id: "amount",
    cell: data =>
      data.billingStructure === "variable_one_time_fee"
        ? t("not_applicable")
        : data.amount,
    sortBy: undefined,
  },
  ActionsColumn({ showCreateServiceReferralButton }),
];
export default columns;
