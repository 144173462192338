import { Layout } from "@heart/components";

import { translationWithRoot } from "@components/T";
import { FormProps } from "@components/prevention/agency_services/form/types";

import AgencyServiceBreadcrumbs from "./form/AgencyServiceBreadcrumbs";
import NewAgencyServiceForm from "./form/NewAgencyServiceForm";

const { T } = translationWithRoot("prevention.agency_services");

/**
 * AgencyServiceFormPage displays the form to create a new agency service
 */
const NewAgencyServiceFormPage = (formProps: FormProps) => (
  <Layout
    pageTitle={<T t="create_new" />}
    breadcrumbs={<AgencyServiceBreadcrumbs pageLabel={<T t="create_new" />} />}
    main={{
      content: <NewAgencyServiceForm {...formProps} />,
      narrow: false,
    }}
  />
);

export default NewAgencyServiceFormPage;
