import { Breadcrumbs } from "@heart/components";
import { ReactElement } from "react";
import { agencyServicesPath, rootPath } from "routes";

import { translationWithRoot } from "@components/T";

const { T } = translationWithRoot("prevention.agency_services");

const AgencyServiceBreadcrumbs = ({
  pageLabel,
}: {
  pageLabel: ReactElement | string;
}) => (
  <Breadcrumbs
    pages={[
      {
        label: <T t="dashboard.breadcrumbs.home" />,
        href: rootPath(),
      },
      {
        label: <T t="dashboard.title" />,
        href: agencyServicesPath(),
      },
      {
        label: pageLabel,
        href: "#",
      },
    ]}
  />
);

export default AgencyServiceBreadcrumbs;
