import { useMutation } from "@apollo/client";
import { Flex, FlexItem, Icons, Button } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useState } from "react";

import DeletePermissionRule from "@graphql/mutations/DeletePermissionRule";
import * as ResourceActionQuery from "@graphql/queries/ResourceAction.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import AddPermissionCondition from "./AddPermissionCondition";
import PermissionsPropTypes from "./PermissionsPropTypes";
import styles from "./ResourceAction.module.scss";
import ResourceActionDescription from "./ResourceActionDescription";

const FullPermissions = ({
  viewOnlyMode,
  permissionRuleId,
  queryVariables,
}) => {
  const [showAddConditionModal, setShowAddConditionModal] = useState(false);

  const [deletePermissionRuleMutation] = useMutation(DeletePermissionRule);
  const deletePermissionRule = () =>
    deletePermissionRuleMutation({
      variables: { id: permissionRuleId },
      refetchQueries: [
        {
          query: ResourceActionQuery,
          variables: queryVariables,
        },
      ],
    });

  return (
    <div
      data-testid={`resource-action-group-${queryVariables.resource}-${queryVariables.action}`}
    >
      <Flex justify="space-between" align="center" row>
        <ResourceActionDescription queryVariables={queryVariables} />

        <FlexItem className={styles.fullPermission}>
          <span className={styles.iconWithPadding}>
            <Icons.Check />
          </span>
          {I18n.t("javascript.components.permissions.full_permission")}
        </FlexItem>
        <If condition={!viewOnlyMode}>
          <Button
            variant="tertiary"
            onClick={() => setShowAddConditionModal(true)}
          >
            {I18n.t("javascript.components.permissions.add_condition")}
          </Button>
          <Button
            variant="tertiary"
            onClick={deletePermissionRule}
            data-testid="grant-revoke-button"
          >
            {I18n.t("javascript.components.permissions.revoke")}
          </Button>
        </If>
      </Flex>
      <AddPermissionCondition
        hidden={!showAddConditionModal}
        onClose={() => setShowAddConditionModal(false)}
        queryVariables={queryVariables}
      />
    </div>
  );
};

FullPermissions.propTypes = {
  viewOnlyMode: PropTypes.bool,
  queryVariables: PermissionsPropTypes.queryVariables,
  permissionRuleId: BintiPropTypes.id,
};

export default FullPermissions;
