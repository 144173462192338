import { Button, Flex, Pill, toast } from "@heart/components";
import { Pause, Play, Redo, Stop } from "@heart/components/icon/Icon";
import useConfirmModal from "@heart/components/modal/useConfirmModal";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

import { translationWithRoot } from "@components/T";

import TranscriptionRecorder from "./TranscriptionRecorder";
import useTranscriptionInputState, {
  States,
} from "./useTranscriptionInputState";
import useVolumePulser from "./useVolumePulser";

const { t, T } = translationWithRoot("transcription.transcription_input");

/**
 * Displays a recording interface designed for longer audio input for
 * transcriptions. The recorder can be paused and resumed as well. Emits
 * the final recording via the onNewTranscription callback.
 *
 * This is the state machine for the recording interface:
 *
 * ```
 *                            Restart
 *                       ┌─────────────────┐
 *                       │                 │
 *                       │                 │
 * ┌───────┐ Start  ┌────▼────┐ Stop   ┌───┴───┐
 * │Initial├───────►│Recording├────────►Stopped│
 * └───────┘        └┬─────▲──┘        └───────┘
 *                   │     │
 *             Pause │     │ Resume
 *                  ┌▼─────┴─┐
 *                  │ Paused │
 *                  └────────┘
 * ```
 *
 * The recordingFile prop is used to determine if the user has already
 * provided a recording and if they should be prompted to confirm if they
 * want to rerecord. You can treat this as if it's a controlled input in
 * that sense.
 */
const TranscriptionInput = ({ onNewTranscription, recordingFile }) => {
  const [transcriptionRecorder, setTranscriptionRecorder] = useState(null);
  const {
    state,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
  } = useTranscriptionInputState(() => {
    if (!recordingFile) return States.INITIAL;

    return States.STOPPED;
  });
  const { ConfirmModal, confirm } = useConfirmModal();

  const { PulserIcon, onAudioActivity } = useVolumePulser();

  const onStart = useCallback(async () => {
    if (recordingFile && !(await confirm())) return;

    if (!TranscriptionRecorder.supportsAudioRecording()) {
      toast.negative({ message: t("audio_not_supported") });
      return;
    }

    if (transcriptionRecorder) {
      await transcriptionRecorder.stop();
    }

    setTranscriptionRecorder(
      await TranscriptionRecorder.createRecorder({ onSpeech: onAudioActivity })
    );
    startRecording();
  }, [
    recordingFile,
    confirm,
    transcriptionRecorder,
    onAudioActivity,
    startRecording,
  ]);

  // stop recording when component unmounts
  useEffect(() => {
    const cleanup = async () => {
      await transcriptionRecorder?.stop();
    };

    return cleanup;
  }, [transcriptionRecorder]);

  const onStop = useCallback(async () => {
    onNewTranscription(await transcriptionRecorder.stop());
    setTranscriptionRecorder(null);
    stopRecording();
  }, [onNewTranscription, transcriptionRecorder, stopRecording]);

  const onPause = useCallback(() => {
    pauseRecording();

    transcriptionRecorder.pause();
    transcriptionRecorder.onSpeech = () => {};
  }, [pauseRecording, transcriptionRecorder]);

  const onResume = useCallback(() => {
    resumeRecording();

    transcriptionRecorder.resume();
    transcriptionRecorder.onSpeech = onAudioActivity;
  }, [resumeRecording, transcriptionRecorder, onAudioActivity]);

  return (
    <Flex column>
      <ConfirmModal title={t("confirm_rerecord_title")}>
        <T t="confirm_rerecord_body" />
      </ConfirmModal>

      <Choose>
        <When condition={state === States.INITIAL}>
          <Flex row>
            <Button onClick={onStart} variant="secondary">
              <PulserIcon />
              <T t="record_audio" />
            </Button>
          </Flex>
        </When>
        <When condition={state === States.RECORDING}>
          <Flex column>
            <Flex row>
              <Pill
                text={
                  <Flex row>
                    <PulserIcon />
                    <T t="recording" />
                  </Flex>
                }
              />
            </Flex>
            <Flex row>
              <Button onClick={onStop} variant="danger">
                <Stop />
                <T t="finish_recording" />
              </Button>
              <Button onClick={onPause} variant="secondary">
                <Pause />
                <T t="pause_recording" />
              </Button>
            </Flex>
          </Flex>
        </When>
        <When condition={state === States.PAUSED}>
          <Flex column>
            <Flex row>
              <Pill
                text={
                  <Flex row>
                    <Pause />
                    <T t="recording_paused" />
                  </Flex>
                }
              />
            </Flex>
            <Flex row>
              <Button onClick={onStop} variant="danger">
                <Stop />
                <T t="finish_recording" />
              </Button>
              <Button onClick={onResume} variant="secondary">
                <Play />
                <T t="continue_recording" />
              </Button>
            </Flex>
          </Flex>
        </When>
        <When condition={state === States.STOPPED}>
          <Flex row>
            <Button onClick={onStart} variant="secondary">
              <Redo />
              <T t="redo_recording" />
            </Button>
          </Flex>
        </When>
      </Choose>
    </Flex>
  );
};

TranscriptionInput.propTypes = {
  /**
   * Callback function to call when a new recording is available with the recording File.
   */
  onNewTranscription: PropTypes.func.isRequired,
  /**
   * The existing recording that the user has provided.
   */
  recordingFile: PropTypes.object,
};

export default TranscriptionInput;
