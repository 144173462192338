import { InputFilterableAsync } from "@heart/components";
import I18n from "i18n-js";
import { Component } from "react";

import preventDefault from "@lib/preventDefault";

const EMPTY_CHILD_MODEL = {
  selectedValue: {},
  information: {},
  caseNotes: "",
  callLogs: "",
  url: "",
  placementSearches: "",
};
const CHILD_ATTRIBUTES = [
  "case_notes",
  "call_logs",
  "placement_searches",
  "id",
  "first_name",
  "last_name",
  "date_of_birth",
  "siblings",
  "primary_language",
  "ethnicities",
  "protected_tribe",
  "protected_tribe_names",
  "behavior_and_medical",
  "gender",
  "sex_assigned_at_birth",
  "creator_id",
  "agency_id",
  "comments",
  "school_id",
  "visitation_description",
  "in_school",
  "nps",
  "grade_level",
  "ssn",
  "nickname",
  "has_child",
  "psw_name",
  "psw_desk_number",
  "psw_cell_number",
  "psw_placement_recommendations",
  "diagnoses",
  "medication",
  "strengths",
  "therapist",
  "therapist_phone_number",
  "doctor",
  "doctor_phone_number",
  "dentist",
  "dentist_phone_number",
  "therapeutic_care",
  "allergies",
  "psycho_tropic_medication",
  "referring_caseworker",
  "referring_caseworker_phone_number",
  "referring_caseworker_email",
  "referring_agency_id",
  "psw_email",
  "can_be_in_home_with_pets",
  "parental_rights_terminated",
  "community_of_origin_zipcode",
  "referring_type",
  "gender_expression",
  "sexual_orientation",
  "other_youth_needs",
];

const LOCALIZED_CHILD_ATTRIBUTES = {
  sexual_orientation: "admin.child.sexual_orientation.orientation_option",
};

/** Form for selecting which child to keep and which child to remove
 *  during a merge.
 *    Agency Tools > Merge > Merge Children
 */
class MergeChildren extends Component {
  constructor(props) {
    super(props);

    this.state = {
      childToKeep: Object.assign({}, EMPTY_CHILD_MODEL),
      childToRemove: Object.assign({}, EMPTY_CHILD_MODEL),
      mergedChild: Object.assign({}, EMPTY_CHILD_MODEL),
      merged: {},
      doingMerge: false,
      result: false,
      errorMessage: false,
    };
  }

  onChildChange = () => {
    if (
      this.state.childToKeep.selectedValue.value &&
      this.state.childToRemove.selectedValue.value
    ) {
      $.ajax({
        url: "/admin/children/merged_details",
        dataType: "json",
        contentType: "application/json",
        data: {
          child_to_keep_id: this.state.childToKeep.selectedValue.value,
          child_to_remove_id: this.state.childToRemove.selectedValue.value,
        },
        success: result => {
          this.setState({
            mergedChild: {
              information: result,
            },
          });
        },
      });
    } else {
      this.setState({
        mergedChild: Object.assign({}, EMPTY_CHILD_MODEL),
      });
    }
  };

  onChildToKeepChange = user => {
    if (user === null) {
      this.setState({
        childToKeep: Object.assign({}, EMPTY_CHILD_MODEL),
        merged: {},
      });
    } else {
      $.ajax({
        url: `/admin/children/${user.value}/json_details`,
        dataType: "json",
        contentType: "application/json",
        success: result => {
          this.setState(
            {
              childToKeep: {
                information: result,
                selectedValue: { value: user.value, label: user.label },
              },
              merged: {},
            },
            this.onChildChange
          );
        },
      });
    }
  };

  onChildToRemoveChange = user => {
    if (user === null) {
      this.setState({
        childToRemove: Object.assign({}, EMPTY_CHILD_MODEL),
        merged: {},
      });
    } else {
      $.ajax({
        url: `/admin/children/${user.value}/json_details`,
        dataType: "json",
        contentType: "application/json",
        success: result => {
          this.setState(
            {
              childToRemove: {
                information: result,
                selectedValue: { value: user.value, label: user.label },
              },
              merged: {},
            },
            this.onChildChange
          );
        },
      });
    }
  };

  handleAttributeDisplayType = (attribute, data) => {
    if (attribute === "ethnicities") {
      return JSON.stringify(data);
    }
    return data;
  };

  loadOptions = (prefix, callback) => {
    if (prefix.length < 3) {
      callback([]);
    } else {
      $.ajax({
        url: "/admin/children/children_autocomplete",
        dataType: "json",
        contentType: "application/json",
        data: { term: prefix },
        success: results => {
          callback(results.results);
        },
      });
    }
  };

  localizeAttribute = (attributeName, value) => {
    if (value && attributeName in LOCALIZED_CHILD_ATTRIBUTES) {
      return I18n.t(`${LOCALIZED_CHILD_ATTRIBUTES[attributeName]}.${value}`);
    }
    return value;
  };

  doMerge = preventDefault(() => {
    this.setState({ doingMerge: true, result: false, errorMessage: false });
    $.ajax({
      method: "post",
      url: "/admin/children/merge_children",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        child_to_keep_id: this.state.childToKeep.selectedValue.value,
        child_to_remove_id: this.state.childToRemove.selectedValue.value,
        merged: this.state.merged,
      }),
      success: result => {
        this.setState({
          childToKeep: {
            information: result,
            selectedValue: this.state.childToKeep.selectedValue,
          },
          childToRemove: Object.assign({}, EMPTY_CHILD_MODEL),
          doingMerge: false,
        });
      },
      error: jqXHR => {
        let errorMessage;
        if (jqXHR.status === 400) {
          errorMessage = jqXHR.responseJSON.error_message;
        } else {
          errorMessage =
            "An error has occurred. If you have the time, it would be " +
            "totally awesome of you if you could find the Honeybadger and " +
            "leave a comment there; and perhaps even create a Jira task :)";
        }
        this.setState({ errorMessage, doingMerge: false });
      },
    });
  });

  render() {
    const { childToKeep, childToRemove, mergedChild, errorMessage } =
      this.state;
    return (
      <div>
        <form onSubmit={this.doMerge}>
          <table className="space-above-2 space-below-2">
            <thead>
              <tr>
                <th>Attribute Name</th>
                <th>Child Record to Keep</th>
                <th>Child Record to Remove</th>
                <th>Result will be</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Child Record</td>
                <td>
                  <InputFilterableAsync
                    label="Search Child to Keep"
                    cacheOptions={true}
                    loadOptions={this.loadOptions}
                    defaultOptions
                    onChange={this.onChildToKeepChange}
                    value={childToKeep.selectedValue}
                  />
                </td>
                <td>
                  <InputFilterableAsync
                    label="Search Child to Remove"
                    cacheOptions={true}
                    loadOptions={this.loadOptions}
                    defaultOptions
                    onChange={this.onChildToRemoveChange}
                    value={childToRemove.selectedValue}
                  />
                </td>
                <td>{childToKeep.selectedValue.label}</td>
              </tr>
              <tr>
                <td>URL</td>
                <td>
                  <a
                    href={childToKeep.information.url}
                    target="_blank"
                    rel="nopener noreferrer"
                  >
                    {childToKeep.information.url}
                  </a>
                </td>
                <td>
                  <a
                    href={childToRemove.information.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {childToRemove.information.url}
                  </a>
                </td>
                <td>
                  <a
                    href={mergedChild.information.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {mergedChild.information.url}
                  </a>
                </td>
              </tr>
              {CHILD_ATTRIBUTES.map(attribute => (
                <tr key={attribute}>
                  <td>{attribute}</td>
                  <td style={{ width: "500px" }}>
                    {this.handleAttributeDisplayType(
                      attribute,
                      this.localizeAttribute(
                        attribute,
                        childToKeep.information[attribute]
                      )
                    )}
                  </td>
                  <td style={{ width: "500px" }}>
                    {this.handleAttributeDisplayType(
                      attribute,
                      this.localizeAttribute(
                        attribute,
                        childToRemove.information[attribute]
                      )
                    )}
                  </td>
                  <td style={{ width: "500px" }}>
                    {this.handleAttributeDisplayType(
                      attribute,
                      this.localizeAttribute(
                        attribute,
                        mergedChild.information[attribute]
                      )
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {errorMessage && <div>{errorMessage}</div>}
          <input type="submit" value="Do Merge" />
        </form>
      </div>
    );
  }
}

export default MergeChildren;
