import {
  Surface,
  ContentTabs,
  Flex,
  Layout,
  Breadcrumbs,
} from "@heart/components";
import { adminGlobalSetupPath } from "routes";

import { translationWithRoot } from "@components/T";

import CreatePermissionRuleSetForm from "../PermissionRuleSets/CreatePermissionRuleSetForm";
import PermissionRuleSetsTable from "../PermissionRuleSets/PermissionRuleSetsTable";
import AgencyRelationshipsTable from "./AgencyRelationshipsTable";
import CreateAgencyRelationshipForm from "./CreateAgencyRelationshipForm";

const { t } = translationWithRoot("permissions.agency_relationships");

/**
 * Renders a layout that includes:
 *  - a table of agency relationships
 *  - a form to create new agency relationships.
 */
const AgencyRelationships = () => (
  <Layout
    breadcrumbs={
      <Breadcrumbs
        pages={[
          { href: adminGlobalSetupPath(), label: t("global_setup") },
          {
            href: "#",
            label: t("title"),
          },
        ]}
      />
    }
    main={{
      content: (
        <Surface title={t("title")} hideTitle>
          <ContentTabs
            tabs={[
              {
                contents: (
                  <Flex column gap="300">
                    <CreateAgencyRelationshipForm />
                    <AgencyRelationshipsTable />
                  </Flex>
                ),
                title: t("title"),
                slug: "title",
              },
              {
                contents: (
                  <Flex column gap="300">
                    <PermissionRuleSetsTable />
                    <CreatePermissionRuleSetForm />
                  </Flex>
                ),
                title: t("permission_rule_sets"),
                slug: "permission_rule_sets",
              },
            ]}
          />
        </Surface>
      ),
    }}
  />
);

export default AgencyRelationships;
