import { calculateProgress } from "@heart/components/wizard/Wizard";
import { curry, intersection, isEmpty } from "lodash";

import { translationWithRoot } from "@components/T";
import createNameAndLabels from "@components/common/createNameAndLabels";
import { determineSelectedPlacementProviderEligibilities } from "@components/family_finding/relationships/relationshipConversion";

import {
  WILLING,
  UNWILLING,
  PROHIBITED,
  NOT_APPLICABLE,
} from "@root/constants";

const { t } = translationWithRoot("agency_human.form");

export const valuesFromResponse = curry((childAgencyHumanId, response) => {
  const values = [{ label: t("create_new_person"), value: "new_agency_human" }];

  (response?.agencyHumanMatches || []).forEach(
    ({
      id,
      fullName,
      dateOfBirth,
      isEstimatedDateOfBirth,
      estimatedDateOfBirth,
      ...rest
    }) => {
      if (id !== childAgencyHumanId) {
        values.push({
          /** Display the Agency Human's name and date of birth if known in the dropdown
           * of search results for potential matches
           */
          label: createNameAndLabels({
            fullName,
            dateOfBirth,
            isEstimatedDateOfBirth,
            estimatedDateOfBirth,
            useUnknown: true,
          }),
          value: id,
          id,
          fullName,
          dateOfBirth,
          isEstimatedDateOfBirth,
          estimatedDateOfBirth,
          ...rest,
        });
      }
    }
  );

  return values;
});

/**
 * Calculates the percentage of required fields that are filled out for an agency human
 * @param {Object} params - Parameters for calculation
 * @param {Object} params.agencyHuman - The agency human object
 * @param {string} params.firstName - The first name of the agency human
 * @param {boolean} params.askedAboutProtectedTribe - Whether the user was asked about protected tribe membership
 * @param {Object} params.tribes - Information about tribe membership
 * @returns {number} The percentage of completion (0-100)
 */
export const calculateAgencyHumanPercentage = ({
  agencyHuman,
  firstName,
  askedAboutProtectedTribe,
  tribes,
}) => {
  let requiredFields = [agencyHuman, firstName];
  if (askedAboutProtectedTribe)
    requiredFields = [
      ...requiredFields,
      tribes?.isProtectedTribeMember !== undefined,
    ];
  return calculateProgress({ requiredFields });
};

export const agencyHumanErrors = {
  primaryAndInactiveError: ({
    entity: { primary, inactive },
    translationPrefix,
  }) =>
    primary && inactive ? t(`${translationPrefix}.inactive_primary_error`) : "",

  primaryCountError: ({ entities, translationPrefix }) => {
    if (isEmpty(entities)) return "";

    const primaryCount = entities.reduce(
      (count, { primary }) => (primary ? count + 1 : count),
      0
    );

    if (primaryCount > 1)
      return t(`${translationPrefix}.multiple_primary_error`);
    return "";
  },

  primaryAddressesError: ({ addresses = [] }) =>
    agencyHumanErrors.primaryCountError({
      entities: addresses.map(({ address }) => address),
      translationPrefix: "addresses",
    }),

  primaryEmailAddressesError: ({ emailAddresses = [] }) =>
    agencyHumanErrors.primaryCountError({
      entities: emailAddresses,
      translationPrefix: "email_address",
    }),

  primaryPhoneNumbersError: ({ phoneNumbers = [] }) =>
    agencyHumanErrors.primaryCountError({
      entities: phoneNumbers,
      translationPrefix: "phone_number",
    }),
};

/**
 * Checks if the agency human form is invalid based on various validation rules
 * @param {Object} params - Parameters for validation
 * @param {Object} params.formState - The form state containing contact information
 * @param {Array} params.formState.addresses - List of addresses
 * @param {Array} params.formState.emailAddresses - List of email addresses
 * @param {Array} params.formState.phoneNumbers - List of phone numbers
 * @param {boolean} params.ethnicitiesValid - Whether the ethnicities are valid
 * @param {boolean} params.racesValid - Whether the races are valid
 * @returns {boolean} True if the form is invalid, false otherwise
 */
export const agencyHumanInvalid = ({
  formState: { addresses = [], emailAddresses = [], phoneNumbers = [] },
  ethnicitiesValid,
  racesValid,
}) => {
  if (!ethnicitiesValid || !racesValid) return true;

  /** Invalid if primary and inactive are both selected for any entry */
  const noPrimaryInactiveAddressErrors = addresses.every(({ address }) =>
    isEmpty(
      agencyHumanErrors.primaryAndInactiveError({
        entity: address,
        translationPrefix: "addresses",
      })
    )
  );
  if (!noPrimaryInactiveAddressErrors) return true;

  const noPrimaryInactiveEmailErrors = emailAddresses.every(email =>
    isEmpty(
      agencyHumanErrors.primaryAndInactiveError({
        entity: email,
        translationPrefix: "email_address",
      })
    )
  );
  if (!noPrimaryInactiveEmailErrors) return true;

  /** The phone number model uses an "active" attribute, but we convert
   * to "inactive" after loading them for consistency with emails and addresses
   */
  const noPrimaryInactivePhoneErrors = phoneNumbers.every(phone =>
    isEmpty(
      agencyHumanErrors.primaryAndInactiveError({
        entity: phone,
        translationPrefix: "phone_number",
      })
    )
  );
  if (!noPrimaryInactivePhoneErrors) return true;

  /** Invalid if there is an error for addresses */
  const primaryAddressError = agencyHumanErrors.primaryAddressesError({
    addresses,
  });
  if (!isEmpty(primaryAddressError)) return true;

  /** Invalid if there is an error for email addresses */
  const primaryEmailError = agencyHumanErrors.primaryEmailAddressesError({
    emailAddresses,
  });
  if (!isEmpty(primaryEmailError)) return true;

  /** Invalid if there is an error for phone numbers */
  const primaryPhoneError = agencyHumanErrors.primaryPhoneNumbersError({
    phoneNumbers,
  });
  if (!isEmpty(primaryPhoneError)) return true;

  return false;
};

export const relationshipErrors = {
  getPlacementEligibilitiesError: ({
    ffPlacementOptions,
    placementProviderEligibilitiesJson = {},
  }) => {
    /** If recommended is checked but a sub option is not chosen,
     * require that a sub-option be selected
     */
    if (
      ffPlacementOptions &&
      placementProviderEligibilitiesJson.recommended?.checked &&
      isEmpty(placementProviderEligibilitiesJson.recommended?.option)
    )
      return t("relationship_to_child.please_select_recommended_sub_reason");

    /** Otherwise confirm that the selected values are compatible */
    const selectedPPE = determineSelectedPlacementProviderEligibilities(
      placementProviderEligibilitiesJson
    );
    let placementEligibilitiesError = "";
    if (selectedPPE.length > 1) {
      if (selectedPPE.includes(PROHIBITED)) {
        placementEligibilitiesError = t(
          "relationship_to_child.prohibited_cannot_be_selected_with_other_options"
        );
      } else if (selectedPPE.includes(NOT_APPLICABLE)) {
        placementEligibilitiesError = t(
          "relationship_to_child.not_applicable_cannot_be_selected_with_other_options"
        );
      } else if (intersection([WILLING, UNWILLING], selectedPPE).length > 1) {
        placementEligibilitiesError = t(
          "relationship_to_child.willing_and_unwilling_are_mutually_exclusive"
        );
      }
    }
    return placementEligibilitiesError;
  },

  /**
   * Validates that "not_applicable" is not selected alongside other level of support options
   * @param {Object} params - The parameters
   * @param {Array} params.levelsOfSupport - The selected levels of support
   * @returns {string} An error message if validation fails, empty string otherwise
   */
  getLevelsOfSupportError: ({ levelsOfSupport = [] }) => {
    if (
      levelsOfSupport.includes("not_applicable") &&
      levelsOfSupport.length > 1
    ) {
      return t(
        "relationship_to_child.not_applicable_cannot_be_selected_with_other_options"
      );
    }
    return "";
  },

  getParentalLineError: ({ parentalLine = [] }) =>
    parentalLine.length > 2
      ? t("relationship_to_child.please_select_two_or_fewer")
      : "",
};

/**
 * Checks if any relationships have validation errors
 * @param {Object} params - The parameters
 * @param {Array} params.relationships - The relationships to validate
 * @param {boolean} params.ffPlacementOptions - Flag for placement options feature
 * @returns {boolean} True if any relationship has validation errors, false otherwise
 */
export const relationshipsInvalid = ({
  relationships = [],
  ffPlacementOptions,
}) => {
  if (isEmpty(relationships)) return false;
  return !relationships.every(
    ({
      placementProviderEligibilitiesJson = {},
      parentalLine = [],
      levelsOfSupport = [],
    } = {}) =>
      isEmpty(
        relationshipErrors.getPlacementEligibilitiesError({
          placementProviderEligibilitiesJson,
          ffPlacementOptions,
        })
      ) &&
      isEmpty(relationshipErrors.getParentalLineError({ parentalLine })) &&
      isEmpty(relationshipErrors.getLevelsOfSupportError({ levelsOfSupport }))
  );
};
