import classNames from "classnames";
import { forwardRef } from "react";

import { translationWithRoot } from "@components/T";

import InputReadOnly from "./InputReadOnly";
import {
  inputTextPropTypes,
  useSimpleControlledInputProps,
  BasicInputLayout,
} from "./common";

const { t } = translationWithRoot("heart.components.inputs.input_text");

// See https://regex101.com/r/2E4cKl/1 for examples of what this pattern asserts
const EMAIL_REGEX = /^['\w+\-.]+@[A-Za-z\d\-]+(\.[A-Za-z\d\-]+)*\.[A-Za-z]{2,}$/ // eslint-disable-line
  .source;

/**
 * This `InputText` component covers all the "textual" inputs
 * you'll need.  By default it renders a `text` input - choose
 * `type="text|email|search|password|number|tel"` for
 * other kinds of text input.
 */
const InputText = forwardRef((props, ref) => {
  const {
    pattern,
    placeholder,
    type = "text",
    maxCharacters,
    onBlur = () => {},
    className,
    disabled,
  } = props;
  const controlledInputProps = useSimpleControlledInputProps(props);

  if (disabled) {
    return <InputReadOnly {...props} />;
  }

  return (
    <BasicInputLayout
      {...props}
      inputComponent={commonInputProps => {
        const inputProps = {
          ...commonInputProps,
          ...controlledInputProps,
          pattern: type === "email" ? pattern || EMAIL_REGEX : pattern,
          title: type === "email" ? t("email_title") : null,
          placeholder,
          className: classNames("form-control", className),
          maxLength: maxCharacters,
          onBlur,
        };
        return (
          <Choose>
            <When condition={type === "number"}>
              <input
                type="number"
                onWheel={e => {
                  // By default, when you scroll on a number input, the value increments/decrements.
                  // Users end up submitting inaccurate data because they tried to scroll the page.
                  // Fixing this is challenging and kind of hacky.  Here's the solution from
                  // https://medium.com/modernnerd-code/how-to-disabled-scrolling-on-html-number-input-in-react-6548841166fb

                  e.target.blur();
                  e.stopPropagation();
                  setTimeout(() => e.target.focus(), 0);
                }}
                {...inputProps}
                step={props.step}
                ref={ref}
              />
            </When>
            <Otherwise>
              <input type={type} {...inputProps} ref={ref} />
            </Otherwise>
          </Choose>
        );
      }}
    />
  );
});

InputText.displayName = "InputText";
InputText.propTypes = inputTextPropTypes;

export default InputText;
