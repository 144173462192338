import React from "react";

/** A LiveRegion component should be wrapped around any area
 * where content updates dynamically.
 *
 * As described in the [w3 documentation](https://www.w3.org/TR/wai-aria-1.1/#dfn-live-region):
 *
 * > Live regions are perceivable regions of a web page that are
 * > typically updated as a result of an external event when user
 * > focus may be elsewhere. These regions are not always updated
 * > as a result of a user interaction.
 *
 * It's important that we use LiveRegions so that non-sighted users are
 * alerted when updates occur in areas of the page outside their current focus.
 * Live regions enable assistive technologies, such as screen readers,
 * to be informed of updates without losing the users' place in the content.
 *
 * **Note:** This component **MUST** be present in the initial DOM rendering
 * for a page for it to work correctly. This means that it cannot be
 * part of a conditionally rendered component.
 *
 * This component should **NOT** be used in the following cases:
 * - When a component has an associated `role` prop, in which case
 * the `role` will take care of things for us. A list of `role`s
 * can be found [on the w3 website](https://www.w3.org/WAI/ARIA/apg/example-index/#examples_by_role_label)
 * - In the case of urgent alerts. These should be addressed using
 * an alert component, with `role="alert"` set
 *
 * @param as What kind of DOM element to use.  Defaults to `<span>`
 * @param atomic When `true`, assistive tech will present the entire region
 * to the user when something changes.
 *
 * When `false`, assistive tech will only present the changed
 * content to the user when something changes.
 *
 * Defaults to `false`
 *
 * Further documentation can be found [here](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-atomic)
 * @param updating Indicates that the contents of the live region are currently
 *  changing. This prop should be used when a region responds to
 * asynchronous actions, like waiting for a GraphQL response.
 * @param children The content that will be updated in the live region
 */
const LiveRegion = ({
  as: Component = "span",
  atomic = false,
  updating,
  children,
  ...props
}: {
  as?: React.ElementType;
  atomic?: boolean;
  updating?: boolean;
  children?: React.ReactNode;
}) => (
  <Component
    aria-live="polite"
    aria-atomic={atomic}
    aria-busy={updating}
    style={{ width: "100%" }}
    {...props}
  >
    {children}
  </Component>
);

export default LiveRegion;
