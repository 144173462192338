import { useQuery, useReactiveVar } from "@apollo/client";
import { Spinner } from "@heart/components/icon/Icon";
import { groupBy } from "lodash";
import React from "react";

import ErrorBanner from "@components/shared/banner/ErrorBanner";

import * as ResourceActions from "@graphql/queries/ResourceActions.graphql";

import PermissionsPropTypes from "./PermissionsPropTypes";
import ResourceActionGroup from "./ResourceActionGroup";
import { hideNoRuleResourceActionsVar } from "./permissionsReactiveVars";

const ResourceActionsTable = ({ queryVariables }) => {
  const { data, loading, error } = useQuery(ResourceActions, {
    variables: queryVariables,
  });
  const hideNoRuleResourceActions = useReactiveVar(
    hideNoRuleResourceActionsVar
  );

  if (loading) return <Spinner />;
  if (error) return <ErrorBanner message={error.message} />;

  const { resourceActions: resourceActionsAll } = data;
  const resourceActionsGrouped = groupBy(resourceActionsAll, "resource");

  return (
    <React.Fragment>
      {Object.entries(resourceActionsGrouped).map(
        ([resource, resourceActions]) => {
          if (
            hideNoRuleResourceActions &&
            resourceActions.every(ra => ra.permissionRules.length === 0)
          ) {
            return null;
          }

          return (
            <ResourceActionGroup
              resource={resource}
              actions={resourceActions.map(ra => ra.action)}
              queryVariables={queryVariables}
              key={resource}
            />
          );
        }
      )}
    </React.Fragment>
  );
};

ResourceActionsTable.propTypes = {
  queryVariables: PermissionsPropTypes.queryVariables,
};

export default ResourceActionsTable;
